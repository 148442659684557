import { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { connect, useSelector } from "react-redux";
import { SOCKET_EVENTS, SOCKET_URL } from "../helpers/utils";
// import { socketUrl } from "shared/helper";

export const SocketContext = createContext({
	socket: false,
});

const Socket = ({ children }) => {
	const [socket, setSocket] = useState(null);

	const { user } = useSelector((state) => state.auth);

	useEffect(() => {
		if (user) {
			const newSocket = io.connect(SOCKET_URL, {
				query: { id: `${user?._id}` },
				transports: ["websocket"],
				path: process.env.REACT_APP_SOCKET_PATH,
				reconnection: true,
				reconnectionDelay: 1000,
				reconnectionDelayMax: 5000,
				reconnectionAttempts: Infinity,
			});

			newSocket.on("connect", () => {
				console.log("WebSocket connected");
			});

			newSocket.on("disconnect", () => {
				console.log("WebSocket disconnected");
			});

			newSocket.on("error", (error) => {
				// console.error("WebSocket error:", error);
			});

			newSocket.on("connect_error", (err) => {
				// console.error("Connection error:", err);
				// console.log("Connection error -:", err);
			});

			let userStatusData = {
				id: user?._id,
				is_online: SOCKET_EVENTS?.USER_ONLINE,
			};
			newSocket.emit(SOCKET_EVENTS?.SET_USER_STATUS, userStatusData);

			setSocket(newSocket);

			return () => {
				let data = {
					id: user?._id,
					is_online: SOCKET_EVENTS?.USER_OFFLINE,
				};
				newSocket.emit(SOCKET_EVENTS?.SET_USER_STATUS, data);
				newSocket.disconnect();
			};
		}
	}, [user]);

	return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};
const mapStateToProps = (state) => ({
	auth: state.auth,
});
export const SocketProvider = connect(mapStateToProps)(Socket);
