import axios from "../../helpers/axios";
import {
    API_ACCESS_TOKEN,
    API_URL,
    catchHandler,
    dispatchError,
    dispatchLoading,
    dispatchToast,
    elseHandler,
} from "../../helpers/utils";

const SUBADMIN = `${API_URL}/admin/`;

/**
 * Get Sub Admin API call
 * @param {Number} page Page number
 * @param {Number} limit Per Page Limit
 * @param {String} search Search string
 * @param {String} id _id of Sub Admin
 * @param {Number} is_active Filter sub admin request by activity status
 * @param {String} status Filter sub admin request by status
 * @returns
 */
export const GetSubAdminList =
    ({ page = 1, limit = 10, search, id, is_active = 1 }) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "subAdmin", true);
        const params = {};
        params["page"] = page;
        params["limit"] = limit;
        params["is_active"] = is_active;
        if (id) params["id"] = id;
        if (search) params["search"] = search;
        axios
            .get(`${SUBADMIN}/sub-admin`, {
                params: { ...params },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch({
                        type: "GET_SUB_ADMIN_LIST",
                        payload: result?.data?.data?.data,
                    });
                    dispatch({
                        type: "GET_SUB_ADMIN_LIST_COUNT",
                        payload: result?.data?.data?.count,
                    });
                } else elseHandler(dispatch, "subAdmin", result?.data);
                dispatchLoading(dispatch, "subAdmin", false);
            })
            .catch(catchHandler(dispatch, "subAdmin"));
    };

export const AddSubAdmin =
    (data, handler, { page, limit, search }) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "subAdmin", true);
        axios
            .post(`${SUBADMIN}/sub-admin`, data, {
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch(GetSubAdminList({ page, limit, search }));
                    handler && handler();
                    dispatchError(dispatch, "subAdmin", undefined);
                } else elseHandler(dispatch, "subAdmin", result?.data);
                dispatchLoading(dispatch, "subAdmin", false);
            })
            .catch(catchHandler(dispatch, "subAdmin"));
    };

export const EditSubAdmin =
    (data, handler, { page, limit, search }) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "subAdmin", true);
        axios
            .put(`${SUBADMIN}/sub-admin`, data, {
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch(GetSubAdminList({ page, limit, search }));
                    handler && handler();
                    dispatchError(dispatch, "subAdmin", undefined);
                } else elseHandler(dispatch, "subAdmin", result?.data);
                dispatchLoading(dispatch, "subAdmin", false);
            })
            .catch(catchHandler(dispatch, "subAdmin"));
    };

export const DeleteSubAdmin =
    ({ ids }, setModalOpen, { page, limit, search }) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "decideBid", true);
        axios
            .delete(`${SUBADMIN}/sub-admin`, {
                data: { ids },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch(GetSubAdminList({ page, limit, search }));
                    setModalOpen(false);
                    dispatchError(dispatch, "decideBid", undefined);
                } else elseHandler(dispatch, "decideBid", result?.data);
                dispatchLoading(dispatch, "decideBid", false);
            })
            .catch(catchHandler(dispatch, "acceptBid"));
    };
