import * as Yup from "yup";
import { AMOUNT_REGEX, EMAIL_REGEX, PHONE_REGEX } from "../utils";
import { MAX_FILE_SIZE_IMAGE, isValidFileType } from "./authSchema";

export const contractValidation = Yup.object().shape({
    businessName: Yup.string().required("Please enter your business name"),
    bondsmanLicense: Yup.string().required("Field is Required"),
    contactNumber: Yup.string()
        .matches(PHONE_REGEX, "Please enter valid contact number")
        .required("Please enter contact number"),
    email: Yup.string()
        .matches(EMAIL_REGEX, "Please enter valid email")
        .required("Please enter email"),
    address1: Yup.string().required("Please enter address line 1"),
    address2: Yup.string().required("Please enter address line 2"),
    state: Yup.string().required("Please enter state"),
    city: Yup.string().required("Please enter city"),
    zip_code: Yup.string().required("Please enter zip code"),
    profile: Yup.mixed()
        .optional()
        .nullable()
        .test(
            "is-valid-type",
            "Not a valid image type",
            (value) =>
                !value ||
                isValidFileType(value && value?.name?.toLowerCase(), "image")
        )
        .test(
            "is-valid-size",
            "Max allowed size is 2MB",
            (value) => !value || (value && value.size <= MAX_FILE_SIZE_IMAGE)
        ),
});

export const contractESignValidation = Yup.object().shape({
    defendant: Yup.string().required("Please Enter Defendant's Full Name"),
    contactNumber: Yup.string()
        .matches(PHONE_REGEX, "Please enter valid contact number")
        .required("Please enter contact number"),
    address1: Yup.string().required("Please enter address line 1"),
    address2: Yup.string().required("Please enter address line 2"),
    state: Yup.string().required("Please enter state"),
    city: Yup.string().required("Please enter city"),
    zip_code: Yup.string().required("Please enter zip code"),
});

export const eSignCourtValidation = Yup.object().shape({
    court_name: Yup.string().required("Field is required"),
    case_number: Yup.string().required("Field is required"),
    judgeName: Yup.string().required("Please enter defendant's full name"),
    bailAmount: Yup.string()
        .required()
        .nullable()
        .matches(AMOUNT_REGEX, "Please enter valid amount"),
    court_date_time: Yup.string().required("Field is required"),
    police_department: Yup.string().required("Field is required"),
    booking_number: Yup.string().optional().nullable(),
    request_nature: Yup.string()
        .optional()
        .nullable()
        .min(2, "Please enter request nature")
        .max(255, "The maximum length for a request nature is 255 characters"),
    request_reason: Yup.string()
        .required("Field is required")
        .min(2, "Please enter request nature")
        .max(255, "The maximum length for a request reason is 255 characters"),
    additional_comments: Yup.string()
        .optional()
        .nullable()
        .min(2, "Please enter request nature")
        .max(
            255,
            "The maximum length for a additional comment is 255 characters"
        ),
});
