import axios from "../helpers/axios";
import { dispatchLoading, API_URL, catchHandler, dispatchToast } from "../helpers/utils";
import { GetProfileAPI } from "./auth";

export const GetNotification = (page, limit, id, close) => async (dispatch) => {
  dispatchLoading(dispatch, "notification", true);
  axios
    .get(API_URL + "/notification", { params: { page, limit, id }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        if (id) {
          close();
          dispatch(GetNotification(page, limit));
        } else {
          dispatch({
            type: "GET_NOTIFICATION",
            payload: result?.data?.data?.data,
          });

          dispatch({
            type: "GET_NOTIFICATION_COUNT",
            payload: result?.data?.data?.count,
          });

          dispatch({
            type: "GET_NOTIFICATION_UNREAD",
            payload: result?.data?.data?.unread_count,
          });
        }
      }
      dispatchLoading(dispatch, "notification", false);
    })
    .catch((err) => {});
};

export const EditNotification = (data) => async (dispatch) => {
  dispatchLoading(dispatch, "notification", true);
  axios
    .put(API_URL + "/setting/notification", data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(GetProfileAPI());
        dispatchToast(dispatch, "success", "Notifications status updated successfully");
      }
      dispatchLoading(dispatch, "notification", false);
    })
    .catch((err) => {});
};

export const GetNotificationTemplates = (page, limit, id) => async (dispatch) => {
  dispatchLoading(dispatch, "notificationTemplate", true);
  axios
    .get(API_URL + "/admin/notification/content", { params: { page, limit, id }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "GET_NOTIFICATION_TEMPLATES_LIST",
          payload: result?.data?.data?.data,
        });

        dispatch({
          type: "GET_NOTIFICATION_TEMPLATES_COUNT",
          payload: result?.data?.data?.count,
        });
      }
      dispatchLoading(dispatch, "notificationTemplate", false);
    })
    .catch((err) => {});
};

export const EditNotificationTemplates = (content, closeHandler, params) => async (dispatch) => {
  dispatchLoading(dispatch, "notificationTemplate", true);
  axios
    .put(API_URL + "/admin/notification/content", content, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        closeHandler();
        dispatch(GetNotificationTemplates(params?.pageId, params?.itemLimit));
      }
      dispatchLoading(dispatch, "notificationTemplate", false);
    })
    .catch(catchHandler(dispatch, "notificationTemplate"));
};

export const FCM_TOKEN = (token) => async (dispatch) => {
  dispatchLoading(dispatch, "token", true);
  axios
    .put(API_URL + "/auth/profile/fcm", { fcm: token }, { withCredentials: true })
    .then((result) => {
      dispatchLoading(dispatch, "token", false);
    })
    .catch((err) => {});
};
