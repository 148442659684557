import axios from "../helpers/axios";
import { API_URL, catchHandler, dispatchError, dispatchLoading, dispatchToast, elseHandler } from "../helpers/utils";
import PropTypes from "prop-types";
import { GetProfileAPI } from "./auth";

const PAYMENT_URL = API_URL + "/payment";

/**
 * Create Stripe Customer API Call
 * @param {Object} data Data Object
 * @param {string} data.invoice_id Invoice Id
 * @param {string} data.redirectURL On success Redirect Url
 * @returns {}
 */
export const CreateStripeCustomer = (data) => (dispatch) => {
  dispatchLoading(dispatch, "payment", true);
  axios
    .post(`${PAYMENT_URL}/customer`, {}, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        console.log("data", data);
        dispatch(CreatePaymentSession(data));
        dispatchError(dispatch, "auth", undefined);
      } else elseHandler(dispatch, "payment", result?.data);
      dispatchLoading(dispatch, "payment", false);
    })
    .catch(catchHandler(dispatch, "payment"));
};

/**
 * Create Payment Session API call
 * @param {Object} data Data Object
 * @param {string} data.invoice_id Invoice Id
 * @param {string} data.redirectURL On success Redirect Url
 * @returns {}
 */
export const CreatePaymentSession = (data) => (dispatch) => {
  dispatchLoading(dispatch, "payment", true);
  axios
    .post(PAYMENT_URL, data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        console.log("result?.data?.data?.url", result?.data?.data?.url);
        window.location.href = result?.data?.data?.url;
        dispatchError(dispatch, "auth", undefined);
      } else elseHandler(dispatch, "payment", result?.data);
      dispatchLoading(dispatch, "payment", false);
    })
    .catch(catchHandler(dispatch, "payment"));
};

/**
 * Connect Bank Account API call
 * @param {Object} data Data object containing return_url and refresh_url
 * @returns {}
 */
export const ConnectBankAccount = (data) => (dispatch) => {
  dispatchLoading(dispatch, "payment", true);
  axios
    .post(`${PAYMENT_URL}/stripe/bank`, data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        window.location.replace(result?.data?.data?.url);
      } else elseHandler(dispatch, "payment", result?.data);
      dispatchLoading(dispatch, "payment", false);
    })
    .catch(catchHandler(dispatch, "payment"));
};

/**
 * Create New Account API call
 * @param {Object} data Data object containing return_url and refresh_url
 * @returns {}
 */
export const CreateNewAccount = (data) => (dispatch) => {
  dispatchLoading(dispatch, "createBank", true);
  axios
    .post(`${PAYMENT_URL}/stripe/bank`, data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        window.location.replace(result?.data?.data?.url);
      } else elseHandler(dispatch, "createBank", result?.data);
      dispatchLoading(dispatch, "createBank", false);
    })
    .catch(catchHandler(dispatch, "createBank"));
};

/**
 * Connect Stripe Account API call
 * @param {String} id Id of stripe account
 * @returns {}
 */
export const ConnectStripeAccount = (id) => (dispatch) => {
  dispatchLoading(dispatch, "stripe", true);
  axios
    .get(`${PAYMENT_URL}/stripe`, {
      params: { id: id },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        // console.log("ConnectStripeAccount");
        window.location.replace(result?.data?.data?.url);
      } else elseHandler(dispatch, "stripe", result?.data);
      dispatchLoading(dispatch, "stripe", false);
    })
    .catch(catchHandler(dispatch, "stripe"));
};

export const StripeAccountCallBack = (data, handler) => (dispatch) => {
  dispatchLoading(dispatch, "callBack", true);
  axios
    .get(`${PAYMENT_URL}/stripe/callback`, {
      params: { ...data },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(FetchBondsmanStripeAccount());
        dispatch(GetProfileAPI());
        handler();
      } else elseHandler(dispatch, "callBack", result?.data);
      dispatchLoading(dispatch, "callBack", false);
    })
    .catch(catchHandler(dispatch, "callBack"));
};

/**
 * Remove Stripe Account API Call
 * @param {String} id Id of stripe account
 * @returns {}
 */
export const RemoveStripeAccount = (id) => (dispatch) => {
  dispatchLoading(dispatch, "payment", true);
  axios
    .delete(`${API_URL}/bondsman/account`, {
      data: { id },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch(GetProfileAPI());
        dispatchToast(dispatch, "success", "Your account has been removed successfully.");
      } else elseHandler(dispatch, "payment", result?.data);
    })
    .catch(catchHandler(dispatch, "payment"));
  dispatchLoading(dispatch, "payment", false);
};

/**
 * Remove Get Bondsman Account Detail
 * @param {String} setState remove of stripe id
 * @returns {}
 */
export const FetchBondsmanStripeAccount = () => (dispatch) => {
  dispatchLoading(dispatch, "payment", true);
  axios
    .get(`${API_URL}/bondsman/account`, {
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "GET_BONDSMAN_ACCOUNT_DETAIL",
          payload: result?.data?.data?.data,
        });

        dispatch({
          type: "GET_BONDSMAN_ACCOUNT_COUNT",
          payload: result?.data?.data?.count,
        });
      } else elseHandler(dispatch, "payment", result?.data);
    })
    .catch(catchHandler(dispatch, "payment"));
  dispatchLoading(dispatch, "payment", false);
};

/**
 * Payment Prop Types
 */
const commonPaymentPropTypes = {
  data: PropTypes.object.isRequired,
};
CreateStripeCustomer.propTypes = { ...commonPaymentPropTypes };
CreatePaymentSession.propTypes = { ...commonPaymentPropTypes };
ConnectBankAccount.propTypes = { ...commonPaymentPropTypes };
ConnectStripeAccount.propTypes = { id: PropTypes.string.isRequired };
