import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorLogo from "../Assets/Images/Icons/somethingWentWrongError.svg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import imageCompression from "browser-image-compression";

/** User Roles */
export const ROLE = {
	ADMIN: "admin",
	SUB_ADMIN: "sub-admin",
	CLIENT: "client",
	BOND_MAN: "bail_bondsman",
};

export const SOCKET_EVENTS = {
	NEW_NOTIFICATION: "new-notification",
	NEW_MESSAGE: "new_message",
	NEW_ROOM_CREATE: "bondsman_approved",
	SET_USER_STATUS: "set_user_status",
	USER_ONLINE: 1,
	USER_OFFLINE: 0,
	ROOM_LIST_UPDATE_ONLINE: "user_online_status",
	ROOM_LIST_UPDATE_OFFLINE: "user_offline_status",
};

/** ENV SETUP */
export const ALIAS = process.env.REACT_APP_ALIAS;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_ACCESS_TOKEN = process.env.REACT_APP_API_ACCESS_TOKEN;
export const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
export const PROFILE_URL = process.env.REACT_APP_PROFILE_URL;
export const BOND_DOCS_URL = process.env.REACT_APP_BOND_DOCS_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH;

// Firebase
export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
export const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
export const SERVICE_WORKER_REGISTER = process.env.REACT_APP_FIREBASE_SERVICE_WORKER_REGISTER;

console.log(process.env);

/** Common Variables */
export const AMOUNT_REGEX = /^[0-9]+(\.[0-9]+)?$/;
export const EMAIL_REGEX = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const ONLY_NUMBERS_REGEX = /^[0-9]+$/;
export const ONLY_ALPHABETS_REGEX = /^[A-Za-z ]+$/;

/** Date Functions Starts */
export const momentDateTimeFormat = "DD MMM, YYYY";
export const momentDateTimeFormatAMPM = "MM/DD/YYYY hh:mm A";
export const dayjsDateTimeFormat = "MM/DD/YYYY hh:mm A";
export const dayjsDateFormat = "MM/DD/YYYY";
export const dayjsYYYYMMDD = "YYYY/MM/DD";
export const dayjsOnlyLongDateFormat = "MM/DD/YYYY";
export const dayjsFormat = "MM/DD/YYYY";
export const dayjsTime = "hh:MM A";
export const momentDDMMYY = (data) => moment(data).format(dayjsDateFormat);
export const momentHHMMAMPM = (data) => moment(data).format(dayjsTime);
export const momentDDMMYYAMPM = (data) => moment(data).format(momentDateTimeFormatAMPM);
/** Date Functions Ends */

/** Error Handling Functions Starts */
export const dispatchLoading = (dispatch, scope, status) => dispatch({ type: "SET_LOADING", payload: { scope, status } });

export const dispatchToast = (dispatch, scope, status) => dispatch({ type: "SET_TOAST", payload: { scope, status } });

export const dispatchError = (dispatch, scope, status) => dispatch({ type: "SET_ERROR", payload: { scope, status } });

export const catchHandler = (dispatch, scope) => (err) => {
	console.log("Err", err);
	let errMsg = err?.response?.data?.msg
		? err?.response?.data?.msg
		: err?.response?.data?.message
		? err?.response?.data?.message
		: err?.response?.data?.error
		? err?.response?.data?.error
		: "Something went wrong";
	dispatchToast(dispatch, "error", errMsg);
	if (err.code === "ERR_NETWORK") dispatchToast(dispatch, "error", "Unable to connect to server");
	else if (err?.response?.status !== 401) {
		dispatchError(dispatch, scope, [errMsg]);
	} else if (err?.response?.status === 401) {
		localStorage.setItem("isLoggedIn", false);
		dispatchError(dispatch, scope, [errMsg]);
		// window.location.assign(`${CLIENT_URL}/auth/login`);
	} else dispatchToast(dispatch, "error", errMsg);
	dispatchLoading(dispatch, scope, false);
	dispatchError(dispatch, scope, [errMsg]);
};

export const elseHandler = (dispatch, scope, data) => {
	dispatchToast(dispatch, "error", data?.msg);
	dispatchError(dispatch, scope, data?.error);
};

export const elseHandler2 = (dispatch, scope, data) => {
	dispatchError(dispatch, scope, data?.error);
};

export const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.
	console.log("error", error);
	// Use Navigate
	const navigate = useNavigate();

	// Use Translation
	const { t } = useTranslation();

	// Use location
	const { pathname } = useLocation();

	// Use Ref
	const originalPathname = useRef(pathname);

	// Goto previous Page
	const goToBack = () => {
		navigate(-1);
	};

	// Use Effect
	useEffect(() => {
		if (pathname !== originalPathname.current) resetErrorBoundary();
	}, [pathname, resetErrorBoundary]);

	return (
		<div className="text-center w-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
			<div>
				<div className="px-3 mb-5 mt-4 header-logo">
					<img src={ErrorLogo} alt="header logo" className="img-fluid" onClick={(e) => navigate("/")} />
				</div>
				<h5 className="ff_rg mb-1 f18">{t("error.somethingWrong")}</h5>
				<p className="ff_rg f14">
					{t("error.thereWasProblem")}
					<span className="d-block ff_rg f14 mx-3">{t("error.pleaseRefresh")}</span>
				</p>
				<p className="ff_rg f14 mt-3 text-secondary">
					{t("error.somethingNot")} ?{" "}
					<span className="ff_md f14 text-dark">
						{t("error.contact")} :{" "}
						<Link className="ff_md f14 text-dark" to="mailto: support@bailbondsapp.ai">
							support@bailbondsapp.ai
						</Link>{" "}
					</span>
				</p>
				<button className="btn c-primary text-uppercase fw-semibold fs-6 px-4 txt-white mt-4 h48" onClick={() => goToBack()}>
					{t("label.goBack")}
				</button>
			</div>
		</div>
	);
};
/** Error Handling Functions Ends */

/**
 * Protected Route for Client
 * @param {Object} routeComponent - Object with route data
 * @param {String} routeComponent.roleName - Role of user
 * @param {Function} routeComponent.children - Child component / page
 * @returns {Function} Child Component
 */
export const ProtectedRoute = ({ roleName, children }) => {
	const { role } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	if (role === roleName) {
		return <React.Fragment>{children}</React.Fragment>;
	} else {
		navigate("/");
		// window.location.assign(`${CLIENT_URL}`)
	}
};

/**
 * Protected Route for Admin
 * @param {Object} routeComponent - Object with route data
 * @param {String} routeComponent.roleName - Role of user
 * @param {Function} routeComponent.children - Child component / page
 * @returns {Function} Child Component
 */
export const ProtectedRouteAdmin = ({ roleName, children }) => {
	const { role } = useSelector((state) => state.auth);
	console.log("role", role);
	const navigate = useNavigate();
	if (localStorage.getItem("userAccessToken") !== null && localStorage.getItem("userAccessToken") !== undefined && (role === "admin" || role === "sub-admin"))
		return <React.Fragment>{children}</React.Fragment>;
	else navigate(`/`, { replace: true });
};

/**
 * Protected Route for Bond Man
 * @param {Object} routeComponent - Object with route data
 * @param {String} routeComponent.roleName - Role of user
 * @param {Function} routeComponent.children - Child component / page
 * @returns {Function} Child Component
 */
export const ProtectedRouteBondMan = ({ roleName, children }) => {
	const { role } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	if (localStorage.getItem("userAccessToken") !== null && localStorage.getItem("userAccessToken") !== undefined && role === roleName)
		return <React.Fragment>{children}</React.Fragment>;
	else navigate(`/`, { replace: true });
};

/** Reset Function to remove data after login */
export const Reset = () => {
	// const navigate = useNavigate();
	localStorage.removeItem("userAccessToken");
	// navigate("/login");
};

/** Loader */
export const SiteLoader = ({ text }) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	if (pathname === "/") {
		return null;
	}
	return (
		<div
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				textAlign: "center",
				width: "calc(100% - 1rem)",
			}}
		>
			<div className="Loader">
				<div className="d-block">
					<div className="spinner-border" role="status"></div>
				</div>
			</div>
			<p style={{ fontSize: "1.5rem", lineHeight: "1.1" }}>{text !== undefined ? text : t("pleaseWait")}</p>
		</div>
	);
};

/** Global Button Loader */
export const BtnLoader = ({ className, size }) => {
	return (
		<Spin
			indicator={
				<LoadingOutlined
					style={{
						fontSize: size ? size : 16,
						color: "#fff",
					}}
					spin
					className={`me-2 ${className}`}
				/>
			}
		/>
	);
};

/** Function to compress image before API call */
export const imageCompressorFn = async (file, setState) => {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true,
	};
	try {
		const compFile = await imageCompression(file, options);
		setState(compFile);
	} catch (error) {
		setState(file);
	}
};

// Display Format Number
export const formatPhoneNumber = (phoneNumber) => {
	if (phoneNumber !== undefined) {
		// Remove non-numeric characters from the input
		const numericOnly = phoneNumber.replace(/\D/g, "");

		// Check if the numericOnly string has 10 digits
		if (numericOnly.length === 10) {
			// Format the phone number as 555-555-5555
			// return numericOnly.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
			return `(${numericOnly.substring(0, 3)}) ${numericOnly.substring(3, 6)}-${numericOnly.substring(6)}`;
		} else {
			// Handle invalid phone numbers (you may choose to throw an error or return the original input)
			console.error("Invalid contact number");
			return phoneNumber;
		}
	}
};

export const inputFormatPhoneNumber = (value) => {
	const numericOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
	const formattedValue = numericOnly.slice(0, 10); // Take the first 10 digits
	const formattedDisplay = formattedValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"); // Format as (XXX) XXX-XXXX
	return { formattedValue, formattedDisplay };
};

export function getTimeDifferenceString(date) {
	const utcDate = new Date(date);
	const currentTime = new Date().toISOString();
	const now = new Date(currentTime);
	const dateDiff = now - utcDate;
	const timeDifferenceInSeconds = dateDiff / 1000;

	// Helper function to format hours and minutes
	const formatTime = (date) => {
		const options = { hour: "numeric", minute: "numeric", hour12: true };
		return new Intl.DateTimeFormat("en-US", options).format(date);
	};

	if (timeDifferenceInSeconds < 60) {
		return "Now";
	} else if (timeDifferenceInSeconds < 3600) {
		const minutes = Math.floor(timeDifferenceInSeconds / 60);
		return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
		// } else if (timeDifferenceInSeconds < 86400) {
		//     const hours = Math.floor(timeDifferenceInSeconds / 3600);
		//     return `${hours} hour${hours === 1 ? "" : "s"} ago`;
	} else if (now.getDate() === utcDate.getDate() && timeDifferenceInSeconds < 86400) {
		return `Today ${formatTime(utcDate)}`;
	} else if (timeDifferenceInSeconds < 172800) {
		return `Yesterday ${formatTime(utcDate)}`;
	} else {
		const options = {
			day: "numeric",
			month: "short",
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		};
		return new Intl.DateTimeFormat("en-US", options).format(utcDate);
	}
}
