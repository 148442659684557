import * as Yup from "yup";
import { EMAIL_REGEX, ONLY_ALPHABETS_REGEX } from "../utils";

/** Sub Admin Validation Schema */
export const subadminValidationSchema = Yup.object().shape({
    fname: Yup.string()
        .matches(ONLY_ALPHABETS_REGEX, "Only alphabets are allowed")
        .max(40, "The maximum length for First name is 40 characters")
        .required("Please enter first name"),
    lname: Yup.string()
        .matches(ONLY_ALPHABETS_REGEX, "Only alphabets are allowed")
        .max(40, "The maximum length for Last name is 40 characters")
        .required("Please enter last name"),
    email: Yup.string()
        .matches(EMAIL_REGEX, "Please enter valid email")
        .required("Please enter email"),
});
