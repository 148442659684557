import axios from "../../helpers/axios";
import {
    API_URL,
    catchHandler,
    dispatchError,
    dispatchLoading,
    elseHandler,
} from "../../helpers/utils";

const BONDSMAN_URL = API_URL + `/client/near-by-bondsman`;
const BONDSMAN_PROFILE_URL = API_URL + `/bondsman/profile`;
const BONDSMAN_REQUEST = API_URL + `/bond/request/request-to-bondsman`;

/**
 * Update Get Nearby Bondsman Function
 * @param {Number} page Page Number
 * @param {Number} limit List Of Bondsman Display
 * @param {String} search Search Bondsman
 * @param {Number} lat Bondsman Address Latitude
 * @param {Number} long Bondsman Address Longitude
 * @returns {}
 */
export const FetchNearbyBondsman =
    (page, limit, search, lat, long) => (dispatch) => {
        dispatchLoading(dispatch, "nearByBondsman", true);
        axios
            .get(BONDSMAN_URL, {
                params: { page, limit, search, lat, long },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatchError(dispatch, "nearByBondsman", undefined);
                    dispatch({
                        type: "GET_NEARBY_BONDSMAN",
                        payload: result?.data?.data?.data,
                    });
                    dispatch({
                        type: "GET_NEARBY_BONDSMAN_COUNT",
                        payload: result?.data?.data?.count,
                    });
                } else elseHandler(dispatch, "nearByBondsman", result?.data);
                dispatchLoading(dispatch, "nearByBondsman", false);
            })
            .catch(catchHandler(dispatch, "nearByBondsman"));
        dispatchLoading(dispatch, "nearByBondsman", false);
    };

/**
 * Update Get Previously Bondsman Function
 * @param {Number} page Page Number
 * @param {Number} limit List Of Bondsman Display
 * @param {String} search Search Bondsman
 * @param {Number} lat Bondsman Address Latitude
 * @param {Number} long Bondsman Address Longitude
 * @returns {}
 */
export const FetchPreviouslyBondsman =
    (page, limit, search, lat, long) => (dispatch) => {
        dispatchLoading(dispatch, "nearByBondsman", true);
        axios
            .get(API_URL + `/client/previously-hired-bondsman`, {
                params: { page, limit, search, lat, long },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatchError(dispatch, "nearByBondsman", undefined);
                    dispatch({
                        type: "GET_PREVIOUSLY_BONDSMAN",
                        payload: result?.data?.data?.data,
                    });
                    dispatch({
                        type: "GET_PREVIOUSLY_BONDSMAN_COUNT",
                        payload: result?.data?.data?.count,
                    });
                } else elseHandler(dispatch, "nearByBondsman", result?.data);
                dispatchLoading(dispatch, "nearByBondsman", false);
            })
            .catch(catchHandler(dispatch, "nearByBondsman"));
        dispatchLoading(dispatch, "nearByBondsman", false);
    };

/**
 * Update Fetch Bondsman Profile Function
 * @param {Object} id
 * @returns {}
 */
export const FetchBondsmanProfile = (id) => (dispatch) => {
    dispatchLoading(dispatch, "viewProfile", true);
    axios
        .get(BONDSMAN_PROFILE_URL, {
            params: { id },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatchError(dispatch, "viewProfile", undefined);
                dispatch({
                    type: "GET_BONDSMAN_PROFILE",
                    payload: result?.data?.data,
                });
            } else elseHandler(dispatch, "viewProfile", result?.data);
            dispatchLoading(dispatch, "viewProfile", false);
        })
        .catch(catchHandler(dispatch, "viewProfile"));
    dispatchLoading(dispatch, "viewProfile", false);
};

export const SendBondRequestToBondsman =
    (data, navigate, count) => (dispatch) => {
        dispatchLoading(dispatch, "sendRequest", true);
        axios
            .post(BONDSMAN_REQUEST, data, {
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    navigate(
                        `/my-case/${data?.bond_request_id}/submit/${count}`
                    );

                    dispatch({
                        type: "SENT_TO_MORE_BONDSMAN_CLEAR_ALL",
                    });

                    dispatch({
                        type: "SENT_TO_FIND_BONDSMAN",
                        payload: [],
                    });

                    dispatch({
                        type: "FIND_BONDSMAN_TYPE",
                    });

                    dispatch({
                        type: "GET_LOCATION",
                        payload: undefined,
                    });
                } else elseHandler(dispatch, "sendRequest", result?.data);
                dispatchLoading(dispatch, "sendRequest", false);
            })
            .catch(catchHandler(dispatch, "sendRequest"));
        dispatchLoading(dispatch, "sendRequest", false);
    };
