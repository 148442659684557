import React from "react";
import Logo from "../../../Assets/Images/logo.png";
import { momentDDMMYY } from "../../../helpers/utils";
import { convertTimeZone } from "../../../Components/Input";
import { ContactNumber, FullName, round } from "../../../Components/Compoents";

const DetailTitle = "fw-bolder f14";
const DetailTileDetail = "mt-2 f13";

export const TransactionHistroy = (data) => {
    return (
        <div
            className="PaymentReceipt DownloadPDF"
            style={{ backgroundColor: "#F6F6F6" }}
        >
            <div className="download-header py-3 text-center c-white">
                <img src={Logo} alt="logo" className="h-100" />
            </div>
            <div className="text-center">
                <p className="fw-bolder f20 my-3">Payment Receipt</p>
            </div>
            <div className="row gy-2 px-3">
                <div className="col-6 f13">
                    <span className={DetailTitle}>Receipt Number : </span>{" "}
                    {data?.bond_request_id?.request_number}
                </div>
                <div className="col-6 f13">
                    <span className={DetailTitle}>Payment Date : </span>{" "}
                    {momentDDMMYY(convertTimeZone(data?.invoice?.updated_at))}
                </div>
                <div className="col-6 f13">
                    <span className={DetailTitle}>Transaction Id : </span>{" "}
                    {data?.invoice?.payment_intent}
                </div>
                <div className="col-6 f13"> &nbsp; </div>

                <div className="col-6">
                    <h4 className="fw-bolder mb-0 txt-primary f16">
                        Client Details
                    </h4>
                    <p className={DetailTileDetail}>
                        <span className={DetailTitle}>Client Name : </span>{" "}
                        {FullName(data?.client)}
                    </p>
                    <p className={DetailTileDetail}>
                        <span className={DetailTitle}>Email : </span>{" "}
                        {data?.client?.email}
                    </p>
                    <p className={DetailTileDetail}>
                        <span className={DetailTitle}>Contact Number : </span>{" "}
                        {ContactNumber(data?.client)}
                    </p>
                </div>
                <div className="col-6">
                    <h4 className="fw-bolder mb-0 txt-primary f16">
                        Bail Bondsman Details
                    </h4>
                    <p className={DetailTileDetail}>
                        <span className={DetailTitle}>Name : </span>{" "}
                        {FullName(data?.bondsman)}
                    </p>
                    <p className={DetailTileDetail}>
                        <span className={DetailTitle}>Email : </span>{" "}
                        {data?.bondsman?.email}
                    </p>
                    <p className={DetailTileDetail}>
                        <span className={DetailTitle}>Contact Number : </span>{" "}
                        {ContactNumber(data?.bondsman)}
                    </p>
                </div>
            </div>
            <div className="mt-3 px-3">
                <table className="table mb-0 table-striped border-1">
                    <thead>
                        <tr className="c-primary">
                            <th className="ff_bd f14 py-3 bg-transparent text-white border-0">
                                Service Details
                            </th>
                            <th className="ff_bd f14 py-3 text-end bg-transparent text-white border-0">
                                Amount
                            </th>
                        </tr>

                        {data?.invoice?.bondsman_service &&
                            data?.invoice?.bondsman_service?.map(
                                (ele, index) => (
                                    <tr key={index} className="f13">
                                        <td className="text-capitalize">
                                            {ele?.service === "Service Tax"
                                                ? `${ele?.service}(${ele?.percentage}%)`
                                                : ele?.service}
                                        </td>
                                        <td className="text-end">
                                            ${round(ele?.amount, 2)}
                                        </td>
                                    </tr>
                                )
                            )}
                    </thead>
                </table>
                <div className="d-flex justify-content-between pe-0 align-items-center">
                    <div className="col-6 ff_md f14 ps-2">
                        Thank you for your business
                    </div>
                    <div className="col-6 text-end">
                        <button className="c-success px-5 h46 text-light ff_bd f14 border-0">
                            Total Amount :{" "}
                            <span>
                                ${round(data?.invoice?.total_amount, 2)}
                            </span>
                        </button>
                    </div>
                </div>
                <br />

                <div className="px-3 py-4 mt-3">
                    <div className="row gy-3">
                        <div className="col-12">
                            <p className="text-center txt-hover-success ff_bd f20">
                                Amount Paid: $
                                {round(data?.invoice?.total_amount, 2)}
                            </p>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <br />
            </div>

            <div className="c-white px-3 py-4 mt-3 text-center">
                <div className="row gy-3">
                    <div className="col-12">
                        <p className="f20 fw-medium">
                            Thank you for your business
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionHistroy;
