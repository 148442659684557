import axios from "../helpers/axios";
import { API_URL, ROLE, SERVICE_WORKER_REGISTER, VAPID_KEY, catchHandler, dispatchError, dispatchLoading, dispatchToast, elseHandler, firebaseConfig } from "../helpers/utils";
import PropTypes from "prop-types";
import { initializeApp } from "firebase/app";
import { getMessaging, isSupported, getToken } from "firebase/messaging";
import { FCM_TOKEN } from "./Notification";

const AUTH_URL = `${API_URL}/auth`;
const app = initializeApp(firebaseConfig);

/**
 * User Sign Up API Call Function
 * @param {object} data User Data Object
 * @param {Function} navigate Navigate Function to redirect user based on their role
 * @returns
 */
export const SignupAPI = (data, navigate) => async (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.post(`${AUTH_URL}/register`, data, { withCredentials: true })
		.then(async (result) => {
			if (result?.data?.success) {
				dispatch({
					type: "LOGIN_SUCCESS",
					payload: result?.data?.data,
				});
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				// Get User Role & Redirect user to respective home page
				navigate("/auth/review-profile");
				localStorage.setItem("isLoggedIn", true);

				try {
					const result = await isSupported();
					if (result) {
						const messaging = getMessaging(app);
						const registration = await navigator?.serviceWorker?.register(SERVICE_WORKER_REGISTER);
						const token = await getToken(messaging, {
							serviceWorkerRegistration: registration,
							vapidKey: VAPID_KEY,
						});
						if (token) {
							dispatch(FCM_TOKEN(token));
						}
					}
				} catch (error) {
					console.error("Error:", error);
				} finally {
				}
			} else elseHandler(dispatch, "auth", result?.data);
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Get User Profile API
 *
 * @param {Function} navigate Function to navigate user
 * @returns {}
 */
export const GetProfileAPI = (navigate) => async (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.get(`${AUTH_URL}/profile`, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				if (result?.data?.data?.address?.city == null) {
					navigate("/auth/review-profile");
				}
				dispatch({
					type: "AUTH_LOAD_SUCCESS",
					payload: result?.data?.data,
				});
				dispatchError(dispatch, "auth", undefined);
			} else {
				dispatch({ type: "AUTH_LOAD_FAILURE" });
				elseHandler(dispatch, "auth", result?.data);
			}
			dispatchLoading(dispatch, "auth", false);
		})
		.catch((err) => {
			dispatch({ type: "AUTH_LOAD_FAILURE" });
			dispatchLoading(dispatch, "auth", false);
		});
};

/**
 * User Login API Call Function
 * @param {object} data User Data Object
 * @param {String} data.email Email Of User
 * @param {String} data.password Password Of User
 * @param {Function} navigate Navigate Function to redirect user based on their role
 * @returns
 */
export const LoginAPI = (data, navigate, selectedBondsman) => async (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.post(`${AUTH_URL}/login`, data, { withCredentials: true })
		.then(async (result) => {
			console.log("result", result);
			if (result?.data?.success) {
				dispatch({
					type: "LOGIN_SUCCESS",
					payload: result?.data?.data,
				});
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				// Get User Role & Redirect user to respective home page
				let userRole = result?.data?.data?.role[0];
				console.log("userRole", userRole);
				if (selectedBondsman) {
					navigate("/find-bondsman");
				} else {
					if (result?.data?.data?.address?.city === null) {
						navigate("/auth/review-profile");
					} else if (userRole === ROLE?.ADMIN) {
						navigate("/admin/dashboard");
					} else if (userRole === ROLE?.CLIENT) {
						navigate("/");
					} else if (userRole === ROLE?.BOND_MAN) {
						if (result?.data?.data?.is_approve === false && !result?.data?.data?.bondsman_license) {
							navigate("/bond-man/complete-profile");
						} else if (result?.data?.data?.is_approve === false && result?.data?.data?.bondsman_license !== null) {
							navigate("/bond-man/under-review");
						} else {
							navigate("/bond-man/dashboard");
						}
					} else if (userRole === ROLE?.SUB_ADMIN) {
						navigate("/sub-admin/dashboard");
					}
				}

				localStorage.setItem("isLoggedIn", true);

				try {
					const result = await isSupported();
					if (result) {
						const messaging = getMessaging(app);
						const registration = await navigator?.serviceWorker?.register(SERVICE_WORKER_REGISTER);
						const token = await getToken(messaging, {
							serviceWorkerRegistration: registration,
							vapidKey: VAPID_KEY,
						});
						if (token) {
							dispatch(FCM_TOKEN(token));
						}
					}
				} catch (error) {
					console.error("Error:", error);
				} finally {
				}
			} else elseHandler(dispatch, "auth", result?.data);
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Forgot Password API Call Function
 * @param {Object} data User Data
 * @param {String} data.email Email of the User
 * @param {Function} navigate Navigate Function to redirect user based on their role
 * @returns
 */
export const ForgotPasswordAPI = (data, setLinkSent, setEmail) => (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.post(`${AUTH_URL}/forgot-password`, data)
		.then((result) => {
			if (result?.data?.success) {
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				setLinkSent(true);
				setEmail(data?.email);
			} else elseHandler(dispatch, "auth", result?.data);
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Reset Password API Call Function
 * @param {Object} data Data Object
 * @param {String} data.token Token for forgot password
 * @param {String} data.password New Password
 * @param {Function} navigate Navigate Function to redirect user based on their role
 * @returns
 */
export const ResetPasswordAPI = (data, navigate) => (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.put(`${AUTH_URL}/reset-password`, data)
		.then((result) => {
			if (result?.data?.success) {
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				navigate("/auth/login");
			} else elseHandler(dispatch, "auth", result?.data);
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Logout API Call Function
 * @returns
 */
export const LogoutAPI = (navigate) => async (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.get(`${AUTH_URL}/logout`, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				dispatch({ type: "LOGOUT_SUCCESS", payload: null });
				dispatch({ type: "CLEAR_BOND_REQUEST" });
				dispatch({ type: "CLEAR_BONDS_MAN_LIST" });
				dispatch({ type: "CLEAR_DASHBOARD" });
				dispatch({ type: "CLEAR_BAIL" });
				dispatch({ type: "CLEAR_LIST_BOND_DOC" });
				dispatch({ type: "CLEAR_EMAIL_LIST" });
				dispatch({ type: "CLEAR_CHAT_UTILS" });
				dispatch({ type: "CLEAR_CHAT_MESSAGES" });
				localStorage.setItem("isLoggedIn", false);
				setTimeout(() => {
					navigate("/");
				}, 900);

				// Remove Bondsman
				dispatch({
					type: "SENT_TO_MORE_BONDSMAN_CLEAR_ALL",
				});

				dispatch({
					type: "SENT_TO_FIND_BONDSMAN",
					payload: [],
				});

				dispatch({
					type: "FIND_BONDSMAN_TYPE",
				});

				// Remove List

				dispatch({
					type: "GET_NEARBY_BONDSMAN",
					payload: [],
				});

				dispatch({
					type: "GET_NEARBY_BONDSMAN_COUNT",
				});
			} else elseHandler(dispatch, "auth", result?.data);
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Update Profile API Call Function
 * @param {Object} data Data Object contains User Informations
 * @param {String} userRole User's Role
 * @param {Function} navigate Function to navigate user to their respective home page
 * @returns {}
 */
export const UpdateProfileAPI = (data, userRole, navigate, navigateTo, selectedBondsman) => async (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.put(`${AUTH_URL}/profile`, data, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				dispatch(GetProfileAPI());
				setTimeout(() => {
					if (selectedBondsman) {
						navigate("/find-bondsman");
					} else {
						if (userRole === ROLE?.ADMIN) {
							navigate("/admin/dashboard");
						} else if (userRole === ROLE?.CLIENT) {
							navigate("/");
						} else if (userRole === ROLE?.BOND_MAN) {
							if (navigateTo) {
								navigate(navigateTo);
							} else {
								navigate("/bond-man/dashboard");
							}
						} else if (userRole === ROLE?.SUB_ADMIN) {
							navigate("/sub-admin/dashboard");
						}
					}
				}, 500);
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
			} else elseHandler(dispatch, "auth", result?.data);
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Update Profile API Call Function
 * @param {Object} data Data Object contains User Informations
 * @param {String} userRole User's Role
 * @param {Function} navigate Function to navigate user to their respective home page
 * @returns {}
 */
export const UpdateBondManProfileAPI = (data, detailData, navigate, user, currentPage) => async (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.put(`${API_URL}/bondsman/details`, detailData, {
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				if (currentPage) {
					dispatch(UpdateProfileAPI(data, ROLE?.BOND_MAN, navigate, "/bond-man/dashboard"));
				} else {
					dispatch(UpdateProfileAPI(data, ROLE?.BOND_MAN, navigate, "/bond-man/under-review"));
				}

				localStorage.setItem("isEditProfile", false);
			} else elseHandler(dispatch, "auth", result?.data);
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Update Profile API Call Function
 * @param {Object} data Data Object contains User Informations
 * @param {String} userRole User's Role
 * @param {Function} navigate Function to navigate user to their respective home page
 * @returns {}
 */
export const UpdateProfile = (data, navigate, role) => async (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.put(`${API_URL}/auth/profile`, data, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				dispatch(GetProfileAPI());
				if (role === "client") navigate("/");
				if (role === "admin") navigate("/admin/dashboard");
				if (role === ROLE.SUB_ADMIN) navigate("/sub-admin/dashboard");
				// if (role === "bail_bondsman") navigate("/bail-bond/dashboard");
				if (role === "bail_bondsman") navigate("/dashboard");
			} else {
				elseHandler(dispatch, "auth", result?.data);
			}
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Update Password API Call Function
 * @param {Object} data Data Object
 * @param {String} data.token Token for forgot password
 * @param {String} data.password New Password
 * @param {Function} navigate Navigate Function to redirect user based on their role
 * @returns
 */
export const UpdatePasswordAPI = (data, navigate, role) => (dispatch) => {
	dispatchLoading(dispatch, "auth", true);
	axios
		.put(`${AUTH_URL}/update-password`, data, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				if (role === "client") navigate("/");
				// if (role === "admin" || role === "sub-admin") navigate("/admin/dashboard");
				if (role === "admin") navigate("/admin/dashboard");
				if (role === ROLE.SUB_ADMIN) navigate("/sub-admin/dashboard");
				// if (role === "bail_bondsman") navigate("/bail-bond/dashboard");
				if (role === "bail_bondsman") navigate("/dashboard");
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
			} else {
				if (result?.data?.data?.[0]) {
					elseHandler(dispatch, "auth", result?.data?.data?.[0]);
				} else {
					elseHandler(dispatch, "auth", result?.data);
				}
			}
			dispatchLoading(dispatch, "auth", false);
		})
		.catch(catchHandler(dispatch, "auth"));
};

/**
 * Update Company Tax Details API
 * @param {Object} data Data Object
 * @param {Function} modalClose Function to close modal
 * @returns {}
 */
export const UpdateCompanyDetailsAPI = (data, modalClose) => (dispatch) => {
	dispatchLoading(dispatch, "companyProfile", true);
	axios
		.put(`${API_URL}/bondsman/tax`, data, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				dispatch(GetProfileAPI());
				modalClose();
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
			} else elseHandler(dispatch, "companyProfile", result?.data);
			dispatchLoading(dispatch, "companyProfile", false);
		})
		.catch(catchHandler(dispatch, "companyProfile"));
};

/**
 * Update Bondsman Place Form Fees API
 * @param {Object} data Data Object
 * @param {Function} modalClose Function to close modal
 * @returns {}
 */
export const UpdatePlatFormFees = (data, modalClose, setState) => (dispatch) => {
	dispatchLoading(dispatch, "platformFees", true);
	axios
		.put(`${API_URL}/admin/bondsman/platform_fee`, data, {
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				setState(data?.platform_fee);
				modalClose();
				// dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
			} else elseHandler(dispatch, "platformFees", result?.data);
			dispatchLoading(dispatch, "platformFees", false);
		})
		.catch(catchHandler(dispatch, "platformFees"));
};

/**
 * Prop Types
 */
const commonDataNavigatePropTypes = {
	data: PropTypes.object.isRequired,
	navigate: PropTypes.func.isRequired,
};
const commonNavigatePropTypes = { navigate: PropTypes.func.isRequired };

SignupAPI.propTypes = { ...commonDataNavigatePropTypes };

GetProfileAPI.propTypes = { ...commonNavigatePropTypes };

LoginAPI.propTypes = { ...commonDataNavigatePropTypes };

LogoutAPI.propTypes = { ...commonNavigatePropTypes };

ResetPasswordAPI.propTypes = { ...commonDataNavigatePropTypes };

UpdateProfileAPI.propTypes = {
	...commonDataNavigatePropTypes,
	userRole: PropTypes.string.isRequired,
	navigateTo: PropTypes.string.isRequired,
};

UpdateBondManProfileAPI.propTypes = {
	...commonDataNavigatePropTypes,
	detailData: PropTypes.object.isRequired,
	currentPage: PropTypes.number,
	user: PropTypes.object,
};

UpdateProfile.propTypes = {
	...commonDataNavigatePropTypes,
	role: PropTypes.string.isRequired,
};

UpdatePasswordAPI.propTypes = {
	...commonDataNavigatePropTypes,
	role: PropTypes.string.isRequired,
};

UpdateCompanyDetailsAPI.propTypes = {
	data: PropTypes.object.isRequired,
	modalClose: PropTypes.func.isRequired,
};
