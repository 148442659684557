import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
import { App as AppWrapper } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ALIAS, ErrorFallbackPage, SiteLoader } from "./helpers/utils";
import { ErrorBoundary } from "react-error-boundary";

// ToastHandler
import ToastHandler from "./helpers/ToastHandler";

// Translation
import "./helpers/i18n";

// CSS
import "./Admin/CSS/style.css";
import "./Assets/CSS/style.css";
import "./Assets/CSS/custom.css";
import "./Auth/CSS/style.css";
import "./Components/CSS/style.css";
import "./View/CSS/style.css";
import "./View/CSS/custom.css";
import "./BondMan/CSS/style.css";

// Swiper CSS
import "swiper/css";
import "swiper/css/navigation";
import { CookiesProvider } from "react-cookie";

// Download File - Temparory
import BailProcessCharge from "./View/Pages/Download/BailProcessCharge";

// Navigation Of Bondsman
import { bondsManNavigationData, AdminNavigationData, SubAdminNavigationData } from "./Components/data";
import { SocketProvider } from "./context/socket.context";
import { FetchNotification } from "./helpers/firebase";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Client
const ViewIndex = lazy(() => import("./View/index"));
const Home = lazy(() => import("./View/Pages/Home"));
const Privacy = lazy(() => import("./View/Pages/Privacy"));
const Terms = lazy(() => import("./View/Pages/Terms"));
const AboutUs = lazy(() => import("./View/Pages/AboutUs"));
const FAQs = lazy(() => import("./View/Pages/FAQs"));
const ContactUs = lazy(() => import("./View/Pages/ContactUs"));
const MyCase = lazy(() => import("./View/Pages/MyCases"));
const RequestForm = lazy(() => import("./View/Pages/RequestForm"));
const ReviewRequest = lazy(() => import("./View/Pages/ReviewRequest"));
const CaseSubmit = lazy(() => import("./View/Pages/CaseSubmit"));
const RequestDetail = lazy(() => import("./View/Pages/RequestDetail"));
const BailBondsmanDetails = lazy(() => import("./View/Pages/BailBondsmanDetails"));
const BondsmanViewProfile = lazy(() => import("./View/Pages/BondsmanViewProfile"));
const FindBondsman = lazy(() => import("./View/Pages/FindBondsman"));
const ClientBondsmanList = lazy(() => import("./View/Pages/BondsmanList"));
const ClientSelectBondsman = lazy(() => import("./View/Pages/SelectBondsman"));

// Auth Pages
const AuthIndex = lazy(() => import("./Auth/index"));
const Login = lazy(() => import("./Auth/View/Login"));
const SignUp = lazy(() => import("./Auth/View/SignUp"));
const ForgotPassword = lazy(() => import("./Auth/View/ForgotPassword"));
const ResetPassword = lazy(() => import("./Auth/View/ResetPassword"));
const ReviewProfile = lazy(() => import("./Auth/View/ReviewProfile"));

// Bond Man Pages
const BondManIndex = lazy(() => import("./BondMan/index"));
const BondManDashboard = lazy(() => import("./BondMan/View/Dashboard"));
const CompleteProfile = lazy(() => import("./BondMan/View/CompleteProfile"));
const UnderReview = lazy(() => import("./BondMan/View/UnderReview"));
const AllBonds = lazy(() => import("./BondMan/View/AllBonds"));
const RequestDetailBondsman = lazy(() => import("./BondMan/View/RequestDetail"));
const BondsmanInvoice = lazy(() => import("./BondMan/View/Invoice"));
const AnalyticsReports = lazy(() => import("./BondMan/View/AnalyticsReports"));
const CalenderAppointments = lazy(() => import("./BondMan/View/CalenderAppointments"));
const ClientHistory = lazy(() => import("./BondMan/View/ClientHistory"));
const DocumentRepository = lazy(() => import("./BondMan/View/DocumentRepository"));
const DocumentRepositoryFolder = lazy(() => import("./BondMan/View/DocumentRepositoryFolder"));
const EarningsOverview = lazy(() => import("./BondMan/View/EarningsOverview"));
const EducationalResources = lazy(() => import("./BondMan/View/EducationalResourcesUpdates"));
const EducationCourseDetail = lazy(() => import("./BondMan/View/EducationDetail"));
const MyCases = lazy(() => import("./BondMan/View/MyCases"));
const ReferralPartnership = lazy(() => import("./BondMan/View/ReferralPartnershipManagement"));
const ReviewsFeedback = lazy(() => import("./BondMan/View/ReviewsFeedback"));
const BondsmanContract = lazy(() => import("./BondMan/View/Contract"));
const BondsmanContractPreview = lazy(() => import("./BondMan/View/ContractPreview"));
const BondsmanESignPreview = lazy(() => import("./BondMan/View/ESignContract"));
const BondsmanPayout = lazy(() => import("./BondMan/View/Payout"));
const BondsmanReviewContract = lazy(() => import("./BondMan/View/ReviewContract"));
// Admin Pages
const AdminIndex = lazy(() => import("./Admin/adminIndex"));
const AdminDashboard = lazy(() => import("./Admin/View/dashboard"));
const AdminBailRequest = lazy(() => import("./Admin/View/BailRequest"));
const AdminBondsmanList = lazy(() => import("./Admin/View/BondsmanList"));
const AdminClientList = lazy(() => import("./Admin/View/ClientList"));
const AdminPaymentHistory = lazy(() => import("./Admin/View/PaymentHistory"));
const AdminEmailTemplate = lazy(() => import("./Admin/View/EmailTemplate"));
const AdminEditEmailTemplate = lazy(() => import("./Admin/View/EditEmailTemplate"));
const AdminStaticpage = lazy(() => import("./Admin/View/Staticpage"));
const AdminEditStaticPage = lazy(() => import("./Admin/View/EditStaticPage"));
const AdminSettings = lazy(() => import("./Admin/View/Settings"));
const AdminSubAdmin = lazy(() => import("./Admin/View/SubAdmin"));
const AdminBondmanDetail = lazy(() => import("./Admin/View/BondmanDetail"));
const AdminBailDetail = lazy(() => import("./Admin/View/Baildetail"));
const AdminClientDetail = lazy(() => import("./Admin/View/ClientDetail"));

// Sub Admin Pages
const SubAdminIndex = lazy(() => import("./SubAdmin/index"));
const SubAdminDashboard = lazy(() => import("./SubAdmin/View/Dashboard"));

// Profile
const Profile = lazy(() => import("./Components/Pages/Profile"));
const BondManProfile = lazy(() => import("./BondMan/View/Profile"));

// Change Password
const ChangePassword = lazy(() => import("./Components/Pages/ChangePassword"));

/** Error Page */
const PageNotFound = lazy(() => import("./Components/Pages/Error"));

/** DummyChat */
const DummyChat = lazy(() => import("./View/Pages/DummyChat"));
const Chat = lazy(() => import("./View/Pages/Chat/index"));
const SocketChatDemo = lazy(() => import("./View/Pages/Chat/socketDemo"));

const NotificationSettings = lazy(() => import("./View/Pages/NotificationTemplate"));
const NotificationList = lazy(() => import("./Components/Pages/NotificationList"));
const AdminNotificationTemplate = lazy(() => import("./Admin/View/NotificationTemplate"));

root.render(
	<React.StrictMode>
		<AppWrapper>
			<CookiesProvider>
				<Provider store={store}>
					<SocketProvider>
						{/* Changes Dev/Live */}
						<BrowserRouter basename={ALIAS}>
							{/* <BrowserRouter> */}
							<Suspense fallback={<SiteLoader />}>
								<ErrorBoundary FallbackComponent={ErrorFallbackPage}>
									<FetchNotification />
									{/* Route List */}
									<Routes>
										{/* Admin */}
										<Route path="/admin" element={<AdminIndex navigationData={AdminNavigationData} />}>
											<Route exact path="dashboard" element={<AdminDashboard />} />

											<Route exact path="bail-request">
												<Route exact path="" element={<AdminBailRequest />} />

												<Route exact path=":bailId/bail-detail" element={<AdminBailDetail />} />
											</Route>

											<Route exact path="bondsman-list">
												<Route exact path="" element={<AdminBondsmanList />} />

												<Route exact path=":bondsmanId/bondsman-detail" element={<AdminBondmanDetail />} />
											</Route>

											<Route exact path="client-list">
												<Route exact path="" element={<AdminClientList />} />

												<Route exact path=":clientId/client-detail" element={<AdminClientDetail />} />
											</Route>

											<Route exact path="payment-history" element={<AdminPaymentHistory />} />

											<Route path="email-template">
												<Route path="" element={<AdminEmailTemplate />} />

												<Route exact path=":id/edit" element={<AdminEditEmailTemplate />} />
											</Route>

											<Route path="static-pages">
												<Route path="" element={<AdminStaticpage />} />

												<Route exact path=":id/edit" element={<AdminEditStaticPage />} />
											</Route>

											<Route exact path="settings" element={<AdminSettings />} />

											<Route exact path="sub-admins" element={<AdminSubAdmin />} />

											<Route path="profile" element={<Profile />} />

											<Route path="change-password" element={<ChangePassword />} />

											<Route exact path="notification" element={<NotificationList />} />

											<Route exact path="notification-templates" element={<AdminNotificationTemplate />} />
										</Route>

										{/* Sub Admin */}
										<Route path="/sub-admin" element={<SubAdminIndex navigationData={SubAdminNavigationData} />}>
											<Route path="dashboard" element={<SubAdminDashboard />} />

											<Route exact path="bail-request">
												<Route exact path="" element={<AdminBailRequest />} />

												<Route exact path=":bailId/bail-detail" element={<AdminBailDetail />} />
											</Route>

											<Route exact path="bondsman-list">
												<Route exact path="" element={<AdminBondsmanList />} />

												<Route exact path=":bondsmanId/bondsman-detail" element={<AdminBondmanDetail />} />
											</Route>

											<Route exact path="client-list">
												<Route exact path="" element={<AdminClientList />} />

												<Route exact path=":clientId/client-detail" element={<AdminClientDetail />} />
											</Route>

											<Route exact path="payment-history" element={<AdminPaymentHistory />} />

											<Route path="email-template">
												<Route path="" element={<AdminEmailTemplate />} />

												<Route exact path=":id/edit" element={<AdminEditEmailTemplate />} />
											</Route>

											<Route path="static-pages">
												<Route path="" element={<AdminStaticpage />} />

												<Route exact path=":id/edit" element={<AdminEditStaticPage />} />
											</Route>

											<Route exact path="settings" element={<AdminSettings />} />

											{/* <Route exact path="sub-admins" element={<AdminSubAdmin />} /> */}

											<Route path="profile" element={<Profile />} />

											<Route path="change-password" element={<ChangePassword />} />

											<Route exact path="notification" element={<NotificationList />} />

											<Route exact path="notification-templates" element={<AdminNotificationTemplate />} />
										</Route>

										{/* Bondsman */}
										<Route path="/bond-man" element={<BondManIndex navigationData={bondsManNavigationData} />}>
											<Route
												path="dashboard"
												// element={<MyCases />}
												element={<BondManDashboard />}
											/>

											<Route path="all-bails">
												<Route path="" element={<AllBonds />} />

												<Route exact path=":bailId/request-detail" element={<RequestDetailBondsman />} />

												<Route exact path=":bailId/invoice" element={<BondsmanInvoice />} />

												<Route exact path=":bailId/invoice/:invoiceId" element={<BondsmanInvoice />} />

												<Route exact path=":bailId/contract" element={<BondsmanESignPreview />} />

												<Route exact path=":bailId/review-contract" element={<BondsmanReviewContract />} />
											</Route>

											<Route exact path="complete-profile" element={<CompleteProfile />} />
											<Route exact path="under-review" element={<UnderReview />} />

											<Route exact path="analytics-and-reports" element={<AnalyticsReports />} />
											<Route exact path="calendar-and-appointments" element={<CalenderAppointments />} />
											<Route exact path="client-history-and-management" element={<ClientHistory />} />

											<Route exact path="document-repository">
												<Route path="" element={<DocumentRepository />} />

												<Route path=":documentId" element={<DocumentRepositoryFolder />} />
											</Route>

											<Route exact path="earnings-overview" element={<EarningsOverview />} />

											<Route exact path="educational-resources-and-updates">
												<Route path="" element={<EducationalResources />} />
												<Route path=":courseId" element={<EducationCourseDetail />} />
											</Route>
											<Route exact path="my-cases" element={<MyCases />} />
											<Route exact path="referral-and-partnership-management" element={<ReferralPartnership />} />
											<Route exact path="reviews-and-feedback" element={<ReviewsFeedback />} />

											<Route path="contract">
												<Route path="" element={<BondsmanContract />} />

												<Route exact path="edit" element={<BondsmanContract />} />

												<Route exact path="preview" element={<BondsmanContractPreview />} />
											</Route>

											<Route exact path="chat" element={<Chat />} />
											<Route exact path="chat/:chatId" element={<Chat />} />

											<Route path="profile" element={<BondManProfile />} />

											<Route path="payout" element={<BondsmanPayout />} />

											<Route exact path="notification-settings" element={<NotificationSettings />} />

											<Route exact path="notification" element={<NotificationList />} />
										</Route>

										{/* Client */}
										<Route path="/" element={<ViewIndex />}>
											<Route exact path="dummy-chat" element={<DummyChat />} />
											<Route exact path="chat-demo" element={<Chat />} />
											<Route exact path="socket-chat-demo" element={<SocketChatDemo />} />
											<Route exact path="" element={<Home />} />
											<Route exact path="privacy" element={<Privacy />} />
											<Route exact path="terms" element={<Terms />} />
											<Route exact path="about" element={<AboutUs />} />
											<Route exact path="faq" element={<FAQs />} />
											<Route exact path="contact-us" element={<ContactUs />} />

											<Route path="/my-case">
												<Route exact path="" element={<MyCase />} />
												<Route exact path=":caseId/request-form" element={<RequestForm />} />
												<Route exact path=":caseId/review-request" element={<ReviewRequest />} />
												<Route exact path=":caseId/submit" element={<CaseSubmit />} />

												<Route exact path=":caseId/submit/:bondmanCount" element={<CaseSubmit />} />

												<Route exact path=":caseId/request-detail" element={<RequestDetail />} />

												<Route exact path=":caseId/bondsman-list" element={<ClientBondsmanList />} />

												<Route exact path=":caseId/select-bondsman" element={<ClientSelectBondsman />} />

												<Route exact path=":caseId/bondsman/:bondsmanId" element={<BailBondsmanDetails />} />
											</Route>

											<Route path="/payment-receipt" element={<BailProcessCharge />} />

											<Route path="profile" element={<Profile />} />

											<Route path="change-password" element={<ChangePassword />} />

											<Route exact path="chat" element={<Chat />} />
											<Route exact path="chat/:chatId" element={<Chat />} />

											<Route exact path="find-bondsman" element={<FindBondsman />} />

											<Route path="*" element={<PageNotFound />} />

											<Route exact path=":bondsmanId/bondsman-profile" element={<BondsmanViewProfile />} />

											<Route exact path="notification-settings" element={<NotificationSettings />} />

											<Route exact path="notification" element={<NotificationList />} />
										</Route>

										{/* Auth */}
										<Route path="/auth" element={<AuthIndex />}>
											<Route path="login" element={<Login />} />
											<Route path="sign-up" element={<SignUp />} />
											<Route path="sign-up/:role" element={<SignUp />} />
											<Route path="forgot-password" element={<ForgotPassword />} />
											<Route path="forgot-password/:token" element={<ResetPassword />} />
											<Route path="review-profile" element={<ReviewProfile />} />
										</Route>

										<Route path="*" element={<PageNotFound />} />
									</Routes>
									{/* Route List End */}
								</ErrorBoundary>
							</Suspense>
						</BrowserRouter>
						<ToastHandler />
					</SocketProvider>
				</Provider>
			</CookiesProvider>
		</AppWrapper>
	</React.StrictMode>
);
