import { FetchBail } from "../../Admin/Redux/BailRequest";
import axios from "../../helpers/axios";
import {
    API_ACCESS_TOKEN,
    API_URL,
    catchHandler,
    dispatchError,
    dispatchLoading,
    dispatchToast,
    elseHandler,
} from "../../helpers/utils";

const CASESURL = `${API_URL}/bondsman/my-cases`;

export const GetMyCases = (page, limit, status, search) => async (dispatch) => {
    dispatchLoading(dispatch, "bail", true);
    axios
        .get(CASESURL, {
            params: {
                page,
                limit,
                status,
                search,
            },
            withCredentials: true,
            headers: { Authorization: API_ACCESS_TOKEN },
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_MYCASES_LIST",
                    payload: result?.data?.data?.data,
                });

                dispatch({
                    type: "GET_MYCASES_COUNT",
                    payload: result?.data?.data?.count,
                });

                dispatch({
                    type: "GET_MYCASES_STATUS_COUNT",
                    payload: result?.data?.data?.status_count,
                });

                dispatchError(dispatch, "bail", undefined);
            } else elseHandler(dispatch, "bail", result?.data);
            dispatchLoading(dispatch, "bail", false);
        })
        .catch(catchHandler(dispatch, "bail"));
};

export const SetInitiateBondProcess = (data) => async (dispatch) => {
    dispatchLoading(dispatch, "initiate", true);
    axios
        .post(API_URL + "/bond/request/in-progress", data, {
            headers: { Authorization: API_ACCESS_TOKEN },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch(
                    FetchBail(
                        null,
                        null,
                        data?.bond_request_id,
                        null,
                        null,
                        null,
                        null
                    )
                );
                dispatchToast(dispatch, "success", result?.data?.msg);
                dispatchError(dispatch, "initiate", undefined);
            } else elseHandler(dispatch, "initiate", result?.data);
            dispatchLoading(dispatch, "initiate", false);
        })
        .catch(catchHandler(dispatch, "initiate"));
};

export const FetchInitiateBondProcess =
    (bond_request_id, setState) => async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .get(API_URL + "/bond/request/status", {
                params: { bond_request_id },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch({
                        type: "GET_INITIAL_BOND_STATUS",
                        payload: result?.data?.data?.status,
                    });

                    setState && setState(result?.data?.data?.status);
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const EditInitiateBondProcess = (data) => async (dispatch) => {
    dispatchLoading(dispatch, "bondsman", true);
    axios
        .put(API_URL + "/bond/request/status", data, {
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch(FetchInitiateBondProcess(data?.bond_request_id));
                dispatchError(dispatch, "bondsman", undefined);
            } else elseHandler(dispatch, "bondsman", result?.data);
            dispatchLoading(dispatch, "bondsman", false);
        })
        .catch(catchHandler(dispatch, "bondsman"));
};
