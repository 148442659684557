import axios from "../../helpers/axios";
import {
    API_ACCESS_TOKEN,
    API_URL,
    catchHandler,
    dispatchError,
    dispatchLoading,
    dispatchToast,
    elseHandler,
} from "../../helpers/utils";

export const CreateContract =
    (data, modalClose, setState, bond_request_id) => async (dispatch) => {
        dispatchLoading(dispatch, "createContract", true);
        axios
            .post(API_URL + "/bond/request/contract", data, {
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    modalClose();
                    setState(true);
                    bond_request_id &&
                        dispatch(GetContractList(bond_request_id));
                    dispatchToast(dispatch, "success", result?.data?.msg);
                    dispatchError(dispatch, "createContract", undefined);
                } else elseHandler(dispatch, "createContract", result?.data);
                dispatchLoading(dispatch, "createContract", false);
            })
            .catch(catchHandler(dispatch, "createContract"));
    };

export const GetContractList =
    (bond_request_id, setState) => async (dispatch) => {
        dispatchLoading(dispatch, "contract", true);
        axios
            .get(API_URL + "/bond/request/contract", {
                params: { bond_request_id },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch({
                        type: "GET_CONTRACT_LIST",
                        payload: result?.data?.data,
                    });

                    setState && setState(false);
                    dispatchError(dispatch, "contract", undefined);
                } else elseHandler(dispatch, "contract", result?.data);
                dispatchLoading(dispatch, "contract", false);
            })
            .catch(catchHandler(dispatch, "contract"));
    };

export const DeleteContract =
    (contract_id, bond_request_id, setState) => async (dispatch) => {
        dispatchLoading(dispatch, "contract", true);
        axios
            .delete(API_URL + "/bond/request/contract", {
                data: { contract_id },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    setState && setState(false);
                    dispatch(GetContractList(bond_request_id));

                    dispatchError(dispatch, "contract", undefined);
                } else elseHandler(dispatch, "contract", result?.data);
                dispatchLoading(dispatch, "contract", false);
            })
            .catch(catchHandler(dispatch, "contract"));
    };

export const SignContract = (contract_id) => async (dispatch) => {
    dispatchLoading(dispatch, "contract", true);
    axios
        .get(API_URL + "/bond/request/contract/view", {
            params: { contract_id },
            headers: { Authorization: API_ACCESS_TOKEN },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                window.location.href = result?.data?.data?.url;
                dispatchError(dispatch, "contract", undefined);
            } else elseHandler(dispatch, "contract", result?.data);
        })
        .catch(catchHandler(dispatch, "contract"));
};
export const ViewContract = (contract_id) => async (dispatch) => {
    dispatchLoading(dispatch, "contract", true);
    axios
        .get(API_URL + "/bond/request/contract/view", {
            params: { contract_id },
            headers: { Authorization: API_ACCESS_TOKEN },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                const { buffer, docName, mimetype } = result?.data?.data?.data;
                const uint8Array = new Uint8Array(buffer.data);
                const blob = new Blob([uint8Array], { type: mimetype });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = docName;
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
                dispatchError(dispatch, "contract", undefined);
            } else elseHandler(dispatch, "contract", result?.data);
            dispatchLoading(dispatch, "contract", false);
        })
        .catch(catchHandler(dispatch, "contract"));
};
