import { FetchNewBondManList } from "../../Components/Redux/NavigationLength";
import axios from "../../helpers/axios";
import { API_URL, catchHandler, dispatchError, dispatchLoading, dispatchToast, elseHandler } from "../../helpers/utils";

const BONDSMAN_URL = `${API_URL}/admin/`;

/**
 * Fetch Bondsman List API call
 * @param {Number} page Page number
 * @param {Number} limit Per Page Limit
 * @param {String} id _id of Request
 * @param {String} search Search string
 * @param {String} status Filter bondsman request by status
 * @param {Date} start_date Start Date
 * @param {Date} end_date End Date
 * @returns
 */
export const FetchBondsmanList = (page, limit, id, search, status, start_date, end_date) => async (dispatch) => {
	dispatchLoading(dispatch, "bondsman", true);
	axios
		.get(BONDSMAN_URL + "bondsman", {
			params: {
				page,
				limit,
				id,
				search,
				status,
				start_date,
				end_date,
			},
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				dispatch({
					type: "GET_ADMIN_BONDSMAN_LIST",
					payload: result?.data?.data?.data,
				});
				dispatch({
					type: "GET_ADMIN_BONDSMAN_COUNT",
					payload: result?.data?.data?.count,
				});
				dispatch({
					type: "GET_ADMIN_BONDSMAN_TYPE_COUNT",
					payload: result?.data?.data?.bondsman_count,
				});
				dispatchError(dispatch, "bondsman", undefined);
			} else elseHandler(dispatch, "bondsman", result?.data);
			dispatchLoading(dispatch, "bondsman", false);
		})
		.catch(catchHandler(dispatch, "bondsman"));
};

/**
 * Fetch Edit Bondsman Status API call
 * @param {String} id _id of Request
 * @param {String} status Filter bondsman request by status
 * @param {String} message Page number
 * @param handler Calling After Success
 * @returns
 */
export const EditBondsmanStatus = (id, status, message, handler) => async (dispatch) => {
	dispatchLoading(dispatch, "acceptBondsman", true);
	axios
		.put(BONDSMAN_URL + "bondsman/status", { id, status, message }, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				dispatch(FetchBondsmanList(null, null, id, null, null, null, null));
				message && handler();
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "acceptBondsman", undefined);
			} else elseHandler(dispatch, "acceptBondsman", result?.data);
			dispatchLoading(dispatch, "acceptBondsman", false);
		})
		.catch(catchHandler(dispatch, "acceptBondsman"));
};

/**
 * Fetch Redirect Bondsman Status API call
 * @param {String} id _id of Request
 * @param {String} status Filter bondsman request by status
 * @param navigate Navigate to Bondsman detail page
 * @returns
 */
export const EditBondsmanStatusRedirect = (id, status, navigate, role) => async (dispatch) => {
	dispatchLoading(dispatch, "rejectBondsman", true);
	axios
		.put(BONDSMAN_URL + "bondsman/status", { id, status }, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				navigate(role === "sub-admin" ? `/sub-admin/bondsman-list/${id}/bondsman-detail` : `/admin/bondsman-list/${id}/bondsman-detail`);
				dispatch(FetchNewBondManList("new"));
				dispatchError(dispatch, "rejectBondsman", undefined);
			} else elseHandler(dispatch, "rejectBondsman", result?.data);
			dispatchLoading(dispatch, "rejectBondsman", false);
		})
		.catch(catchHandler(dispatch, "rejectBondsman"));
};
