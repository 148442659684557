import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { App } from "antd";

function ToastHandler() {
  const { message } = App.useApp();

  // selector
  const { error, success } = useSelector((state) => state.msg);
  // use
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // useEffect
  useEffect(() => {
    if (error) {
      message.error(t(error));
      dispatch({ type: "CLEAR_TOAST" });
    }
    if (success) {
      message.success(t(success));
      dispatch({ type: "CLEAR_TOAST" });
    }
  }, [error, success, dispatch, t]);
  return <></>;
}

export default ToastHandler;
