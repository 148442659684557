import { combineReducers, configureStore } from "@reduxjs/toolkit";

const authReducer = (
  state = {
    isLoggedIn: -1,
    role: -1,
    isAuthenticated: -1,
  },
  action
) => {
  switch (action.type) {
    case "AUTH_LOAD_SUCCESS":
      return {
        ...state,
        isAuthenticated: action?.payload?.isAuthenticated,
        role: action?.payload?.role[0],
        isLoggedIn: true,
        user: action?.payload,
      };

    case "AUTH_LOAD_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        isLoggedIn: false,
        role: -1,
      };

    case "AUTH_LOGIN_SUCCESS":
      return {
        ...state,
        role: action.payload.role[0],
        isLoggedIn: true,
      };

    case "AUTH_LOGOUT_SUCCESS":
      return {
        isAuthenticated: false,
        isLoggedIn: false,
        user: undefined,
        role: -1,
      };

    case "LOGIN_SUCCESS":
      return {
        isLoggedIn: true,
        user: action.payload,
      };

    case "STATIC_LOGIN_LIST_SUCCESS":
      return {
        ...state,
        loginList: action.payload,
      };
    case "LOGOUT_SUCCESS":
      return {
        isLoggedIn: false,
        role: -1,
        isAuthenticated: -1,
      };

    default:
      return state;
  }
};

const errorReducer = (state = { authError: undefined, carError: undefined }, action) => {
  switch (action.type) {
    case "SET_ERROR":
      const temp = {};
      const { payload } = action;

      if (payload.status && payload.status.length) {
        payload.status &&
          payload?.status.forEach((ele) => {
            if (ele.param === "_error") {
              ele.nestedErrors?.forEach((ele1) => {
                const key = ele1.param.split(".").pop();
                temp[key] = ele1.msg;
              });
            } else if (ele.param?.includes("updates.")) {
              const key = ele.param.split(".").slice(1).join(".");
              temp[key] = ele.msg;
            } else {
              temp[ele.path] = ele.msg;
            }
          });
      }

      return { ...state, [`${payload.scope}Error`]: temp };

    default:
      return state;
  }
};

const loadingReducer = (state = { authLoading: false }, action) => {
  /**
   * UNIVERSAL LOADING HANDLER
   */
  switch (action.type) {
    case "SET_LOADING":
      return Object.assign({}, state, {
        [`${action.payload.scope}Loading`]: action.payload.status,
      });
    default:
      return state;
  }
};

const msgReducer = (state = { success: undefined, error: undefined }, action) => {
  /**
   * UNIVERSAL TOAST HANDLER
   */
  switch (action.type) {
    case "SET_TOAST":
      return Object.assign({}, state, {
        [`${action.payload.scope}`]: action.payload.status || "Something went wrong!",
      });
    case "CLEAR_TOAST":
      return { ...state, error: undefined, success: undefined };
    default:
      return state;
  }
};

const bondRequestReducer = (
  state = {
    requestFor: "",
    bondDetails: null,
    bondListCount: null,
    bondRequestList: null,
    brListPageNo: 1,
    brListLimit: 10,
    isStepActive: false,
    currentStep: 0,
    brStepEdit: false,
    docList: null,
  },
  action
) => {
  switch (action.type) {
    case "CREATE_BOND_SUCCESS":
      return {
        ...state,
        bondDetails: action.payload,
      };
    case "UPDATE_BOND_SUCCESS":
      let updatedBond = {
        ...state.bondDetails,
        ...action.payload,
      };
      return {
        ...state,
        bondDetails: updatedBond,
      };
    case "CLEAR_BOND_DETAILS":
      return {
        ...state,
        bondDetails: null,
        requestFor: "",
      };
    case "GET_BOND_LIST_SUCCESS":
      let bondList = {};
      action.payload.data.forEach((ele) => (bondList[ele?._id] = ele));
      return {
        ...state,
        bondRequestList: bondList,
        brListPageNo: action.payload.pageNo,
        brListLimit: action.payload.limit,
      };
    case "GET_BOND_LIST_COUNT":
      return {
        ...state,
        bondListCount: action.payload,
      };
    case "CHANGE_BR_STEP":
      return {
        ...state,
        currentStep: action.payload,
      };
    case "IS_STEPS_ACTIVE":
      return {
        ...state,
        isStepActive: action.payload,
      };
    case "EDIT_BR_STEPS":
      return {
        ...state,
        brStepEdit: action.payload,
      };
    case "REQUEST_FOR_CHANGE":
      return {
        ...state,
        requestFor: action.payload,
      };

    case "LIST_BOND_DOCUMENT_SUCCESS":
      return {
        ...state,
        docList: action.payload,
      };
    case "CLEAR_BOND_REQUEST":
      return {
        requestFor: "",
        bondDetails: null,
        bondListCount: null,
        bondRequestList: null,
        brListPageNo: 1,
        brListLimit: 10,
        isStepActive: false,
        currentStep: 0,
        brStepEdit: false,
        docList: null,
      };
    default:
      return state;
  }
};

const bondsmanListReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_ADMIN_BONDSMAN_LIST":
      return {
        ...state,
        bondsmanList: action.payload,
      };

    case "GET_ADMIN_BONDSMAN_COUNT":
      return {
        ...state,
        bondsmanCount: action.payload,
      };

    case "GET_ADMIN_BONDSMAN_NEW_COUNT":
      return {
        ...state,
        bondsmanNewCount: action.payload,
      };

    case "GET_BONDSMAN_SERVICE_LIST":
      return {
        ...state,
        bondsmanService: action.payload,
      };

    case "GET_BONDSMAN_BID_LIST":
      return {
        ...state,
        bondsmanBid: action.payload,
      };

    case "GET_BONDSMAN_ALL_BID_LIST":
      return {
        ...state,
        bondsmanAllBid: action.payload,
      };

    case "GET_BONDSMAN_ALL_BID_COUNT_LIST":
      return {
        ...state,
        bondsmanAllCountBid: action.payload,
      };

    case "GET_BONDSMAN_SELECTED_BID_LIST":
      return {
        ...state,
        bondsmanSelectedBid: action.payload,
      };

    case "GET_BONDSMAN_SELECTED_BID_COUNT_LIST":
      return {
        ...state,
        bondsmanSelectedCountBid: action.payload,
      };

    case "GET_ADMIN_BONDSMAN_TYPE_COUNT":
      return {
        ...state,
        bondsmanTypeCount: action.payload,
      };

    case "GET_PLACE_BID":
      return {
        ...state,
        placeBid: action.payload,
      };

    case "GET_INVOICE":
      return {
        ...state,
        bondsmanInvoiceList: action.payload,
      };

    case "GET_EDIT_INVOICE":
      return {
        ...state,
        bondsmanEditInvoiceList: action.payload,
      };

    case "GET_EDIT_INVOICE_DETAIL":
      return {
        ...state,
        editInvoiceDetail: action.payload,
      };

    case "CLEAR_BONDS_MAN_LIST":
      return {};

    default:
      return state;
  }
};

const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case "DASHBOARD_SUCCESS":
      return {
        ...state,
        dashboardItem: action.payload.value,
      };

    case "DASHBOARD_NEW_BAIL_REQUEST":
      return {
        ...state,
        newBailRequest: action.payload,
      };

    case "GET_BONDSMAN_DASHBOARD":
      return {
        ...state,
        bondsmanDashboard: action.payload.value,
      };

    case "CLEAR_DASHBOARD": {
      return {};
    }

    default:
      return state;
  }
};

const bailReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADMIN_BAIL_LIST_REQUEST":
      return {
        ...state,
        bailList: action.payload,
      };

    case "ADMIN_BAIL_LIST_COUNT":
      return {
        ...state,
        bailCount: action.payload,
      };

    case "ADMIN_BAIL_LIST_OF_COUNT":
      return {
        ...state,
        bailTypeCount: action.payload,
      };

    case "GET_CLIENT_BAIL_LIST":
      return {
        ...state,
        clientBailList: action.payload,
      };

    case "GET_CLIENT_BAIL_LIST_COUNT":
      return {
        ...state,
        clientBailCount: action.payload,
      };

    case "GET_CLIENT_BAIL_LIST_STATUS_COUNT":
      return {
        ...state,
        clientBailStatusCount: action.payload,
      };

    case "CLEAR_BAIL":
      return {};

    default:
      return state;
  }
};

const listBondDocReducer = (state = {}, action) => {
  switch (action.type) {
    case "LIST_BOND_DOCUMENT":
      return {
        ...state,
        listBondDocument: action.payload,
      };

    case "CLEAR_LIST_BOND_DOC":
      return {};

    default:
      return state;
  }
};

const emailReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_EMAIL_LIST_SUCCESS":
      return {
        ...state,
        emailList: action.payload,
      };

    case "GET_EMAIL_LIST_COUNT":
      return {
        ...state,
        emailListCout: action.payload,
      };

    case "CLEAR_EMAIL_LIST":
      return {};

    default:
      return state;
  }
};

const myCasesBondsman = (state = {}, action) => {
  switch (action.type) {
    case "GET_MYCASES_LIST":
      return {
        ...state,
        caseList: action.payload,
      };

    case "GET_MYCASES_COUNT":
      return {
        ...state,
        caseCount: action.payload,
      };

    case "GET_MYCASES_STATUS_COUNT":
      return {
        ...state,
        caseStatusCount: action.payload,
      };

    case "CLEAR_MYCASES":
      return {};

    default:
      return state;
  }
};

const chatUtilsReducer = (
  state = {
    showChatList: false,
    searchInChat: "",
    roomList: null,
    roomListCount: "",
    activeRoom: null,
    activeRequestNumber: "",
  },
  action
) => {
  switch (action.type) {
    case "HANDLE_USER_LIST_DISPLAY":
      return {
        ...state,
        showChatList: action.payload,
      };

    case "HANDLE_IN_CHAT_SEARCH":
      return {
        ...state,
        searchInChat: action.payload,
      };

    case "GET_ROOM_LIST":
      return {
        ...state,
        roomList: { ...state.roomList, ...action.payload },
      };

    case "ADD_NEW_ROOM":
      let tempRoomList = { ...state?.roomList };
      let newList = {};
      newList[action.payload?.id] = action.payload.roomObj;
      newList = { ...newList, ...tempRoomList };
      return {
        ...state,
        roomList: newList,
      };

    case "UPDATE_USER_STATUS":
      const updatedRoomList = { ...state?.roomList }; // Make a copy of the original roomList

      for (const roomId in updatedRoomList) {
        if (updatedRoomList.hasOwnProperty(roomId)) {
          const room = updatedRoomList[roomId];

          if (room && room?.members) {
            // Check if the user is a member of the room
            const memberIndex = room?.members.findIndex((member) => member.user._id === action.payload?.userId);

            if (memberIndex !== -1) {
              // Create a new copy of the room to avoid mutation
              const updatedRoom = { ...room };

              // Create a new copy of the members array to avoid mutation
              const updatedMembers = [...updatedRoom.members];

              // Create a new copy of the user to avoid mutation
              const updatedUser = {
                ...updatedMembers[memberIndex].user,
              };

              // Update the is_online status
              updatedUser.is_online = action.payload?.is_online; // Assuming you want to set it to 1

              // Update the member in the updatedMembers array
              updatedMembers[memberIndex] = {
                ...updatedMembers[memberIndex],
                user: updatedUser,
              };

              // Update the room in the updatedRoomList
              updatedRoom.members = updatedMembers;
              updatedRoomList[roomId] = updatedRoom;
            }
          }
        }
      }

      return {
        ...state,
        roomList: updatedRoomList,
      };

    case "UPDATE_UNREAD_COUNT":
      let updatedList = { ...state?.roomList };
      let roomId = action.payload?.roomId;

      if (roomId && updatedList[roomId]) {
        let updatedCount = updatedList[roomId]["unread_count"] + 1;

        // Create a new object instead of modifying the existing one
        let newObj = {
          ...updatedList[roomId],
          unread_count: updatedCount,
          last_message: {
            message: action.payload?.newLastMsg,
            type: null,
          },
        };

        delete updatedList[roomId];

        // Update the roomList with the new object
        updatedList = {
          [roomId]: newObj,
          ...updatedList,
        };

        return {
          ...state,
          roomList: updatedList,
        };
      }

    case "CLEAR_UNREAD_COUNT":
      const newClearRoomList = { ...state?.roomList };
      const clearRoom = newClearRoomList[action.payload?.room_id];
      clearRoom.unread_count = 0;
      newClearRoomList[action.payload?.room_id] = { ...clearRoom };
      return {
        ...state,
        roomList: newClearRoomList,
      };

    case "CHANGE_ACTIVE_ROOM_ORDER":
      let activeRoomChangeList = { ...state?.roomList };
      const orderOfRoomIds = [];
      orderOfRoomIds.push(action.payload?.room);

      Object.keys(activeRoomChangeList).forEach((roomId) => {
        if (roomId !== action.payload?.room) orderOfRoomIds.push(roomId);
      });

      const orderedRoomList = {};
      orderOfRoomIds.forEach((roomId) => {
        if (roomId === action.payload?.room) {
          let tempObj = { ...activeRoomChangeList[roomId] }; // Create a copy of the object
          tempObj.last_message = { ...tempObj.last_message };
          tempObj.last_message.message = action.payload.message;
          let zeroCount = 0;
          tempObj.unread_count = zeroCount;
          orderedRoomList[roomId] = tempObj;
        } else if (activeRoomChangeList[roomId]) {
          orderedRoomList[roomId] = {
            ...activeRoomChangeList[roomId],
          }; // Create a copy of the object
        }
      });

      return {
        ...state,
        roomList: orderedRoomList,
      };

    case "UPDATE_ROOM_LIST_CONT":
      return {
        ...state,
        roomListCount: action.payload,
      };

    case "CHANGE_ACTIVE_ROOM":
      let activeChangeRoomChangeList = { ...state?.roomList };

      // Create a copy of the room object to avoid state mutation
      let updatedRoom = {
        ...activeChangeRoomChangeList[action.payload?._id],
      };
      updatedRoom.unread_count = 0;

      // Update the room in the copied state object
      activeChangeRoomChangeList[action.payload?._id] = updatedRoom;

      return {
        ...state,
        activeRoom: action.payload,
        roomList: activeChangeRoomChangeList,
        activeRequestNumber: action.payload?.request_number,
      };

    case "CLEAR_CHAT_UTILS":
      return {
        showChatList: false,
        searchInChat: "",
        roomList: null,
        roomListCount: "",
        activeRoom: null,
      };

    case "HANDLE_ACTIVE_REQUEST_NUMBER":
      return {
        ...state,
        activeRequestNumber: action.payload,
      };

    default:
      return state;
  }
};

const chatMessagesReducer = (state = { roomChats: null }, action) => {
  switch (action.type) {
    case "GET_ROOM_MESSAGES":
      // Destructure the state for better readability
      const { roomChats } = state;
      // Create a new object based on the existing roomChats or an empty object
      const newMessages = { ...(roomChats || {}) };
      const room = action.payload?.room;

      if (!newMessages[room]) {
        // If the room does not exist, create it
        newMessages[room] = {
          messages: action.payload?.messages,
          count: action.payload?.count,
          currentPage: action.payload?.page,
        };
      } else if (newMessages[room].count !== action.payload?.count) {
        // If the room exists but the count is different, update messages
        newMessages[room] = {
          ...newMessages[room],
          messages: [
            // ...newMessages[room].messages,
            ...action.payload?.messages,
          ],
          currentPage: action.payload?.page,
          count: action.payload?.count,
        };
      }

      return {
        ...state,
        roomChats: { ...newMessages },
      };

    case "DELIVER_NEW_MSG":
      // Destructure the state for better readability
      const { roomChats: deliverRoomChats } = state;
      // Create a new object based on the existing roomChats or an empty object
      const newDeliverMessages = { ...(deliverRoomChats || {}) };
      const deliverRoom = action.payload?.room;
      const newMsgObj = action.payload?.messages;
      const newCount = newDeliverMessages[deliverRoom].count !== undefined ? newDeliverMessages[deliverRoom].count + 1 : 1;

      if (!newDeliverMessages[deliverRoom]) {
        // If the room does not exist, create it with the new message
        newDeliverMessages[deliverRoom] = {
          messages: [newMsgObj],
          count: newCount,
        };
      } else {
        // If the room exists, add the new message
        if (newDeliverMessages[deliverRoom].messages.length === 0) {
          newDeliverMessages[deliverRoom] = {
            ...newDeliverMessages[deliverRoom],
            messages: [newMsgObj],
            count: newCount,
          };
        } else {
          newDeliverMessages[deliverRoom] = {
            ...newDeliverMessages[deliverRoom],
            messages: [...newDeliverMessages[deliverRoom].messages, newMsgObj],
            count: newCount,
          };
        }
      }

      return {
        ...state,
        roomChats: { ...newDeliverMessages },
      };

    case "GET_SOCKET_MESSAGE":
      // Destructure the state for better readability
      const { roomChats: socketRoomChats } = state;
      // Create a new object based on the existing roomChats or an empty object
      const newSocketMessage = { ...(socketRoomChats || {}) };
      const socketRoom = action.payload?.room;
      if (!newSocketMessage[socketRoom]) {
        // If the room does not exist, create it with the new message
        newSocketMessage[socketRoom] = {
          messages: [action.payload?.msgObj],
        };
      } else if (newSocketMessage[socketRoom].messages[newSocketMessage[socketRoom].messages.length - 1]._id !== action.payload?._id) {
        // If the room exists, add the new message
        newSocketMessage[socketRoom] = {
          ...newSocketMessage[socketRoom],
          messages: [...newSocketMessage[socketRoom].messages, action.payload?.msgObj],
        };
      }
      return {
        ...state,
        roomChats: action.payload,
      };

    case "CLEAR_CHAT_MESSAGES":
      return { roomChats: null };

    default:
      return state;
  }
};

const clientReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_CLIENT_LIST":
      return {
        ...state,
        clientList: action.payload,
      };

    case "GET_CLIENT_LIST_COUNT":
      return {
        ...state,
        clientCount: action.payload,
      };

    case "CLEAR_CLIENT":
      return {};

    default:
      return state;
  }
};

const subAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_SUB_ADMIN_LIST":
      return {
        ...state,
        subAdminList: action.payload,
      };

    case "GET_SUB_ADMIN_LIST_COUNT":
      return {
        ...state,
        subAdminCount: action.payload,
      };

    case "CLEAR_SUB_ADMIN":
      return {};

    default:
      return state;
  }
};

const settingReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_SETTING":
      return {
        ...state,
        setting: action.payload,
      };

    case "CLEAR_SETTING":
      return {};

    default:
      return state;
  }
};

const locationReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_LOCATION":
      return {
        ...state,
        locationDetail: action.payload,
      };

    case "CLEAR_LOCATION":
      return {};

    default:
      return state;
  }
};

const nearByBondsmanReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_NEARBY_BONDSMAN":
      return {
        ...state,
        nearbyBondsmanList: action.payload,
      };

    case "GET_NEARBY_BONDSMAN_COUNT":
      return {
        ...state,
        nearbyBondsmanCount: action.payload,
      };

    case "GET_PREVIOUSLY_BONDSMAN":
      return {
        ...state,
        previouslyBondsmanList: action.payload,
      };

    case "GET_PREVIOUSLY_BONDSMAN_COUNT":
      return {
        ...state,
        previouslyBondsmanCount: action.payload,
      };

    case "CLEAR_NEARBY_BONDSMAN":
      return {};

    default:
      return state;
  }
};

const viewBondsmanProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_BONDSMAN_PROFILE":
      return {
        ...state,
        bondsmanProfile: action.payload,
      };

    case "CLEAR_BONDSMAN_PROFILE":
      return {};

    default:
      return state;
  }
};

const bondsmanRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_BONDSMAN_REQUEST_LIST":
      return {
        ...state,
        bondsmanRequestList: action.payload,
      };

    case "GET_BONDSMAN_SELECTED_LIST":
      return {
        ...state,
        bondsmanSelectedList: action.payload,
      };

    case "GET_BONDSMAN_SELECTED_COUNT_LIST":
      return {
        ...state,
        bondsmanSelectedCount: action.payload,
      };

    default:
      return state;
  }
};

const requestSendReducer = (state = {}, action) => {
  switch (action.type) {
    case "SENT_TO_MORE_BONDSMAN":
      return {
        ...state,
        sentToMoreBondsmanList: [],
      };

    case "SENT_TO_MORE_BONDSMAN_PREVIOUSLY_HIRE":
      return {
        ...state,
        sentToMoreBondsmanPreviouslyList: action.payload,
      };

    case "SENT_TO_MORE_BONDSMAN_NEARBY_HIRE":
      return {
        ...state,
        sentToMoreBondsmanNearbyList: action.payload,
      };

    case "SENT_TO_MORE_BONDSMAN_CLEAR_ALL":
      return {
        ...state,
        sentToMoreBondsmanPreviouslyList: [],
        sentToMoreBondsmanNearbyList: [],
      };

    case "FIND_BONDSMAN_TYPE":
      return {
        ...state,
        sentToMoreBondsmanType: action.payload,
      };

    case "SENT_TO_FIND_BONDSMAN":
      return {
        ...state,
        sentToMoreBondsmanFindList: action.payload,
      };

    default:
      return state;
  }
};

const stripeAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_BONDSMAN_ACCOUNT_DETAIL":
      return {
        ...state,
        bondsmanAccountDetail: action.payload,
      };

    case "GET_BONDSMAN_ACCOUNT_COUNT":
      return {
        ...state,
        bondsmanAccountCount: action.payload,
      };

    default:
      return state;
  }
};

const reviewReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_REVIEW":
      return {
        ...state,
        reviewList: action.payload,
      };

    case "GET_REVIEW_COUNT":
      return {
        ...state,
        reviewCount: action.payload,
      };

    default:
      return state;
  }
};

const transactionReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_BONDSMAN_TRANSACTION":
      return {
        ...state,
        bondsmanTransactionList: action.payload,
      };

    case "GET_BONDSMAN_TRANSACTION_COUNT":
      return {
        ...state,
        bondsmanTransactionCount: action.payload,
      };

    case "GET_BONDSMAN_EARNING_OVERVIEW":
      return {
        ...state,
        earningOverview: action.payload,
      };

    case "GET_ADMIN_PAYMENT_HISTORY":
      return {
        ...state,
        paymentHistoryList: action.payload,
      };

    case "GET_ADMIN_PAYMENT_HISTORY_COUNT":
      return {
        ...state,
        paymentHistoryCount: action.payload,
      };

    default:
      return state;
  }
};

const contractReducer = (state = {}, action) => {
  switch (action.type) {
    case "PREVIEW_CONTRACT_BUFFER_FILE":
      return {
        ...state,
        previewContractFile: action.payload,
      };

    case "PREVIEW_CONTRACT_INFO_FILE":
      return {
        ...state,
        infoData: action.payload,
      };

    case "GET_CONTRACT_LIST":
      return {
        ...state,
        contractList: action.payload,
      };

    default:
      return state;
  }
};

const allBailRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_ALL_BAIL_REQUEST":
      return {
        ...state,
        allBailList: action.payload,
      };

    case "GET_ALL_BAIL_COUNT":
      return {
        ...state,
        allBailCount: action.payload,
      };

    case "GET_ALL_BAIL_COUNT_CATEGORY":
      return {
        ...state,
        bailCategoryCount: action.payload,
      };

    default:
      return state;
  }
};

const staticPageReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_ALL_STATIC_PAGE":
      return {
        ...state,
        staticPageList: action.payload,
      };

    case "GET_ALL_STATIC_PAGE_COUNT":
      return {
        ...state,
        staticPageCount: action.payload,
      };

    default:
      return state;
  }
};

const initialBondReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_INITIAL_BOND_STATUS":
      return {
        ...state,
        bondsStatus: action.payload,
      };

    default:
      return state;
  }
};

const analyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_BAIL_RATIO":
      return {
        ...state,
        bailRation: action.payload,
      };

    case "GET_COMPLETION_REPORT":
      return {
        ...state,
        completionReport: action.payload,
      };

    case "GET_LAST_MONTH_REQUEST_OVERVIEW":
      return {
        ...state,
        lastMonthOverview: action.payload,
      };

    case "GET_LAST_MONTH_INCOME_DISTRIBUTION":
      return {
        ...state,
        lastIncomeDistribution: action.payload,
      };

    case "GET_INCOME_OVERVIEW":
      return {
        ...state,
        getIncomeOverview: action.payload,
      };

    default:
      return state;
  }
};

const FCMReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_FCM_TOKEN":
      return {
        ...state,
        fcmToken: action.payload,
      };

    default:
      return state;
  }
};

const NotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_NOTIFICATION":
      return {
        ...state,
        notificationList: action.payload,
      };

    case "GET_NOTIFICATION_COUNT":
      return {
        ...state,
        notificationCount: action.payload,
      };

    case "GET_NOTIFICATION_UNREAD":
      return {
        ...state,
        notificationUnread: action.payload,
      };

    case "GET_NOTIFICATION_TEMPLATES_LIST":
      return {
        ...state,
        notificationTemplateList: action.payload,
      };

    case "GET_NOTIFICATION_TEMPLATES_COUNT":
      return {
        ...state,
        notificationTemplateCount: action.payload,
      };

    default:
      return state;
  }
};

const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    error: errorReducer,
    loading: loadingReducer,
    msg: msgReducer,
    bondRequest: bondRequestReducer,
    bondmanList: bondsmanListReducer,
    admindashboard: dashboardReducer,
    bail: bailReducer,
    listBondDoc: listBondDocReducer,
    emailTemplate: emailReducer,
    staticPage: staticPageReducer,
    bondsmanCases: myCasesBondsman,
    chatUtils: chatUtilsReducer,
    chatMessages: chatMessagesReducer,
    client: clientReducer,
    subadmin: subAdminReducer,
    setting: settingReducer,
    location: locationReducer,
    nearbyBondsman: nearByBondsmanReducer,
    viewProfile: viewBondsmanProfileReducer,
    bondsmanRequest: bondsmanRequestReducer,
    requestBondsman: requestSendReducer,
    payoutAccount: stripeAccountReducer,
    review: reviewReducer,
    transaction: transactionReducer,
    contract: contractReducer,
    allBail: allBailRequestReducer,
    bondProcess: initialBondReducer,
    analytics: analyticsReducer,
    fcm: FCMReducer,
    notification: NotificationReducer,
  }),
});

export default store;
