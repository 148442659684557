import * as Yup from "yup";
import { AMOUNT_REGEX, EMAIL_REGEX, ONLY_ALPHABETS_REGEX } from "../utils";

const getCharacterValidationError = (str) => {
	return `Your password must have at least 1 ${str} character`;
};

/** Common Email Validation */
export const EmailValidationSchema = {
	email: Yup.string().matches(EMAIL_REGEX, "Please enter valid email").required("Please enter email"),
};

// Common Contact Number Validation
export const ContactNoValidationSchema = {
	contactNumber: Yup.string()
		.required("Please enter contact number")
		.matches(/^\d{10}$/, "Contact number must be exactly 10 digits"),
};

export const CaseInformationSchema = {
	police_department: Yup.string().required("Field is required"),
	booking_number: Yup.string()
		.optional()
		.nullable()
		.notOneOf([Yup.ref("case_number")], "Booking number cannot be same as case number"),
	case_number: Yup.string()
		.required("Field is required")
		.notOneOf([Yup.ref("booking_number")], "Case number cannot be same as booking number"),
	court_name: Yup.string().required("Field is required"),
	attorney_detail: Yup.string().optional().nullable(),
	court_date_time: Yup.string().required("Field is required"),
	request_nature: Yup.string().optional().nullable().min(2, "Please enter request nature").max(255, "The maximum length for a request's nature is 255 characters"),
	request_reason: Yup.string().required("Field is required").min(2, "Please enter request nature").max(255, "The maximum length for a request's reason is 255 characters"),
	additional_comments: Yup.string().optional().nullable().min(2, "Please enter request nature").max(255, "The maximum length for a additional comments is 255 characters"),
};

export const MAX_FILE_SIZE_IMAGE = 2097152; //2MB

const validFileExtensions = {
	image: ["jpg", "png", "jpeg"],
};

export function isValidFileType(fileName, fileType) {
	return fileName && validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1;
}

// Common New Password Validation Schema
const newPasswordSchema = (labelName) =>
	Yup.string()
		.required(`Please enter ${labelName}`)
		.min(8, "Password must have at least 8 characters")
		.max(40, "The maximum length for a password is 40 characters")
		.matches(/^\S*$/, "Password cannot contain any space")
		.matches(/[0-9]/, getCharacterValidationError("digit"))
		.matches(/[a-z]/, getCharacterValidationError("lowercase"))
		.matches(/[A-Z]/, getCharacterValidationError("uppercase"))
		.matches(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])/, getCharacterValidationError("special"));

export function capitalizeFirstLetter(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const commonUsNumberValidation = (fieldName) =>
	Yup.string()
		.matches(/^[0-9]{6,12}$/, `Please enter valid ${fieldName}`)
		.required(`Please enter ${fieldName}`)
		.test("is-valid-length", `${capitalizeFirstLetter(fieldName)} must be exactly 10 digits`, function (value) {
			// Remove non-numeric characters and check the length
			const numericOnly = value.replace(/\D/g, "");
			return numericOnly.length === 10;
		});

export const OptionalBailAmount = {
	bailAmount: Yup.string()
		.required("Please enter bail amount")
		.matches(AMOUNT_REGEX, "Please enter valid amount")
		.test("maxDigits", "Max 8 digits with 2 decimal points allowed", (value) => {
			if (!value) return true; // Allow empty value, as it's covered by the 'required' validation
			const validNumberRegex = /^\d{1,8}(\.\d{0,2})?$/; // Regular expression for max 8 digits with 2 decimal points
			return validNumberRegex.test(value);
		}),
};

/** Login Validation Schema */
export const loginValidationSchema = Yup.object().shape({
	...EmailValidationSchema,
	password: Yup.string().required("Please enter password"),
});

/** Sign Up Validation Schema */
export const signupValidationSchema = Yup.object().shape({
	...EmailValidationSchema,
	...ContactNoValidationSchema,
	fname: Yup.string()
		.matches(ONLY_ALPHABETS_REGEX, "Only alphabets are allowed")
		.max(40, "The maximum length for First name is 40 characters")
		.required("Please enter first name"),
	lname: Yup.string().matches(ONLY_ALPHABETS_REGEX, "Only alphabets are allowed").max(40, "The maximum length for Last name is 40 characters").required("Please enter last name"),
	password: newPasswordSchema("password"),
});

/** Sign Up Address Validation */
export const signupAddressValidationSchema = Yup.object().shape({
	profile_pic: Yup.mixed()
		.optional()
		.nullable()
		.test("is-valid-type", "Not a valid image type", (value) => !value || isValidFileType(value && value?.name?.toLowerCase(), "image")),
	zip_code: Yup.string().required("Please enter zip code"),
});

/** Forgot Password Validation */
export const forgotPasswordValidationSchema = Yup.object().shape({
	...EmailValidationSchema,
});

/** Reset Password Validation */
export const resetPasswordValidationSchema = Yup.object().shape({
	newPassword: newPasswordSchema("new password"),
	confirmNewPassword: Yup.string()
		.oneOf([Yup.ref("newPassword"), null], "New password & confirm new password must match")
		.required("Please enter confirm new password"),
});

/** Home Page -> Contact Us */
export const contactUsValidationSchema = Yup.object().shape({
	...EmailValidationSchema,
	...ContactNoValidationSchema,
	name: Yup.string().required("Please enter name"),
	message: Yup.string().required("Please Enter Message"),
});

//  Step 2 : {RelationBond} Full name and date of birth
export const relationBondDetailValidation = Yup.object().shape({
	name: Yup.string().required("Please enter full name"),
	date: Yup.string().required("Date is required"),
});

// Step 3 : Location of your arrest
export const arrestLocationValidation = Yup.object().shape({
	address1: Yup.string().required("Please enter address line 1"),
	address2: Yup.string().required("Please enter address line 2"),
	state: Yup.string().required("Please enter state"),
	city: Yup.string().required("Please enter city"),
	zip_code: Yup.string().required("Please enter zip code"),
	detention_center: Yup.string().required("Please select near by jail / detention center"),
});

// Step 4 : Enter Bail Amount
export const bailAmountValidation = Yup.object().shape({
	...OptionalBailAmount,
});

// Step 5 : Bail Terms
export const bailTermsValidation = Yup.object().shape({
	bailCondition: Yup.string().required("Field is required"),
});

// Step 6 : Contact Information
export const contactInformationValidation = Yup.object().shape({
	contactNumber: commonUsNumberValidation("contact number"),
	...EmailValidationSchema,
});

// Step 7 : Emergency Support Network
export const emergencySupportNetworkValidation = Yup.object().shape({
	name: Yup.string().required("Please enter full name"),
	relation: Yup.string().required("Please enter relation with you"),
	contactNumber: commonUsNumberValidation("emergency contact number"),
});

// Step 8:
export const caseInformationValidation = Yup.object().shape({
	...CaseInformationSchema,
});

export const termNotSetBailInfoValidation = Yup.object().shape({
	bailCondition: Yup.string().optional().nullable(),
	...OptionalBailAmount,
});

export const bailInformationEditValidation = Yup.object().shape({
	bailCondition: Yup.string().required("Field is required"),
	...OptionalBailAmount,
});

//Bail Amount Edit - Bondsman

export const bailAmountEditBondsmanValidation = Yup.object().shape({
	...OptionalBailAmount,
	bailCondition: Yup.string().required("Field is required"),
});

// Bond Request Form
export const requestBondValidation = Yup.object().shape({
	...CaseInformationSchema,
	defendant: Yup.string().required("Field is required"),
	bail_amount: Yup.string().optional().nullable(),
});

// Write A Review
export const WriteReviewValidation = Yup.object().shape({
	rating: Yup.number().required("Please provide star rating").min(1, "Please provide at least 1 star rating").max(5, "Rating must be at most 5"),
	review: Yup.string().required("Review is required").max(300, "Review must be at least 300 characters"),
});

// Reason For Reject
export const reasonForRejectValidation = Yup.object().shape({
	reason: Yup.string().required("Field is required"),
});

export const bondManProfileValidation = Yup.object().shape({
	...EmailValidationSchema,
	profile_pic: Yup.mixed()
		.optional()
		.nullable()
		.test("is-valid-type", "Not a valid image type", (value) => !value || isValidFileType(value && value?.name?.toLowerCase(), "image")),
	fname: Yup.string().required("Field is Required"),
	lname: Yup.string().required("Field is Required"),
	// contact: commonUsNumberValidation("contact Number"),
	contact: Yup.string()
		.required("Please enter contact number")
		.matches(/^\d{10}$/, "Contact number must be exactly 10 digits"),
	zip_code: Yup.string().required("Field is Required"),
	// experience: Yup.string()
	//     .matches(/^\d+$/, "Please enter only numeric characters")
	//     .matches(
	//         /^(?:\d|[1-9]\d|0[1-9])$/,
	//         "Experience cannot be greater than 99 years"
	//     )
	//     .max(2, "Please Enter valid experience")
	//     .required("Field is Required"),
	experience: Yup.string()
		.matches(/^[0-9]+$/, {
			message: "Please enter only numeric characters",
		})
		.test("max-years", "Experience cannot be greater than 99 years", (value) => {
			if (!value) return true; // If value is not provided, let the 'required' method handle it
			const numericValue = parseInt(value);
			return numericValue <= 99; // Check if the value is less than or equal to 99
		})
		.max(2, "Please enter a valid experience")
		.required("Field is Required"),
	bondsmanLicense: Yup.string().required("Field is Required"),
	service_charge: Yup.string()
		.matches(/^\d+$/, "Only number are allowed")
		.matches(/^(?:\d|[1-9]\d|0[1-9])$/, "Service charge cannot be greater than 99")
		.max(2, "Please Enter valid service charge")
		.required("Please enter your service charge"),
	bio: Yup.string().optional().nullable().max(255, "The maximum length for a bio is 255 characters"),
});

export const documentSchema = Yup.object().shape({
	docNames: Yup.array()
		.of(
			Yup.object().shape({
				name: Yup.string().min(3, "Document name is too short").required("Document name is required"),
			})
		)
		.required("Field is required")
		.min(1, "Please add atlease one document name"),
});

export const changePasswordValidationSchema = Yup.object().shape({
	oldPassword: Yup.string().required("Please enter old password"),
	newPassword: newPasswordSchema("new password"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("newPassword"), null], "New password and confirm new password should match")
		.required("Please enter confirm password"),
});
