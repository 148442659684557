export const PoliceDepartmentList = [
    {
        id: 1,
        name: "LAPD-Los Angeles Police Department (California)",
    },
    {
        id: 2,
        name: "NYPD-New York Police Department (New York)",
    },
    {
        id: 3,
        name: "CPD-Chicago Police Department (Illinois)",
    },
    {
        id: 4,
        name: "MPD-Metropolitan Police Department (Washington, D.C.)",
    },
    {
        id: 5,
        name: "BPD-Boston Police Department (Massachusetts)",
    },
    {
        id: 6,
        name: "SFPD-San Francisco Police Department (California)",
    },
    {
        id: 7,
        name: "SPD-Seattle Police Department (Washington)",
    },
    {
        id: 8,
        name: "APD-Atlanta Police Department (Georgia)",
    },
    {
        id: 9,
        name: "DPD-Dallas Police Department (Texas)",
    },
    {
        id: 10,
        name: "MDPD-Miami-Dade Police Department (Florida)",
    },
    {
        id: 11,
        name: "HPD-Houston Police Department (Texas)",
    },
    {
        id: 12,
        name: "PPD-Philadelphia Police Department (Pennsylvania)",
    },
    {
        id: 13,
        name: "DCPD-Denver Police Department (Colorado)",
    },
    {
        id: 14,
        name: "ACPD-Arlington County Police Department (Virginia)",
    },
    {
        id: 15,
        name: "LVPD-Las Vegas Metropolitan Police Department (Nevada)",
    },
    {
        id: 16,
        name: "FBI-Federal Bureau of Investigation",
    },
    {
        id: 17,
        name: "CIA-Central Intelligence Agency",
    },
    {
        id: 18,
        name: "DEA-Drug Enforcement Administration",
    },
    {
        id: 19,
        name: "NSA-National Security Agency",
    },
    {
        id: 20,
        name: "ATF-Bureau of Alcohol, Tobacco, Firearms and Explosives",
    },
    {
        id: 21,
        name: "Secret Service-Secret Service",
    },
    {
        id: 22,
        name: "DHS-Department of Homeland Security",
    },
    {
        id: 23,
        name: "ICE-U.S. Immigration and Customs Enforcement",
    },
    {
        id: 24,
        name: "CBP-U.S. Customs and Border Protection",
    },
    {
        id: 25,
        name: "NASA-National Aeronautics and Space Administration",
    },
    {
        id: 26,
        name: "EPA-Environmental Protection Agency",
    },
    {
        id: 27,
        name: "FEMA-Federal Emergency Management Agency",
    },
    {
        id: 28,
        name: "SSA-Social Security Administration",
    },
    {
        id: 29,
        name: "IRS-Internal Revenue Service",
    },
    {
        id: 30,
        name: "FDA-Food and Drug Administration",
    },
    {
        id: 31,
        name: "NRO-National Reconnaissance Office",
    },
];

export const CourtNames = [
    { id: 1, name: "Supreme Court of the United States (SCOTUS)" },
    { id: 2, name: "United States Courts of Appeals" },
    { id: 3, name: "United States District Courts" },
    { id: 4, name: "State Supreme Courts" },
    { id: 5, name: "State Courts of Appeals" },
    { id: 6, name: "State Trial Courts" },
    { id: 7, name: "County Courts" },
    { id: 8, name: "Municipal Courts" },
    { id: 9, name: "Family Courts" },
    { id: 10, name: "Probate Courts" },
    { id: 11, name: "Bankruptcy Courts" },
    { id: 12, name: "Small Claims Courts" },
];

// export const DetensionCenterData = [
//     {
//         id: 1,
//         city: "Scottsville",
//         name: "Allen County Detention Center",
//         state_full: "Kentucky",
//         address1: "196 Wood Street",
//         source_url: "http://www.allencountydetention.com/index2.html",
//         county: "Allen County",
//         phone: "(270) 237-3226",
//         state: "KY",
//         source_id: "ky-acdc",
//         zip_code: "42164",
//         email: "",
//         has_mugshots: true,
//     },
//     {
//         id: 2,
//         city: "Wickliffe",
//         name: "Ballard County Detention Center",
//         state_full: "Kentucky",
//         address1: "132 Mayolo Ave",
//         source_url: "http://www.ballardcountydetention.com/",
//         county: "Ballard County",
//         phone: "(270) 335-3691",
//         state: "KY",
//         source_id: "ky-bcdc",
//         zip_code: "42087",
//         email: "",
//         has_mugshots: true,
//     },
//     {
//         id: 3,
//         city: "Glasgow",
//         name: "Barren County Detention Center",
//         state_full: "Kentucky",
//         address1: "201 Samson Street",
//         source_url: "http://www.barrencountykyjail.com/",
//         county: "Barren County",
//         phone: "(270) 651-8806",
//         state: "KY",
//         source_id: "ky-bndc",
//         zip_code: "42141",
//         email: "bcjmatt@glasgow-ky.com",
//         has_mugshots: true,
//     },
//     {
//         id: 4,
//         city: "Paintsville",
//         name: "Big Sandy Regional Detention Center",
//         state_full: "Kentucky",
//         address1: "904 3rd St",
//         source_url: "http://www.bsrdc.com/",
//         county: "Big Sandy Regional",
//         phone: "(606) 297-5124",
//         state: "KY",
//         source_id: "ky-bsdc",
//         zip_code: "41240",
//         email: "",
//         has_mugshots: true,
//     },
//     {
//         id: 5,
//         city: "Paris",
//         name: "Bourbon County Detention Center",
//         state_full: "Kentucky",
//         address1: "101 Legion Drive",
//         source_url: "http://www.bourboncountydetention.com/",
//         county: "Bourbon County",
//         phone: "(859) 987-2137",
//         state: "KY",
//         source_id: "ky-budc",
//         zip_code: "40361",
//         email: "",
//         has_mugshots: true,
//     },
//     {
//         id: 6,
//         city: "Catlettsburg",
//         name: "Boyd County Detention Center",
//         state_full: "Kentucky",
//         address1: "2714 Panol Street",
//         source_url: "http://www.boydcountydetention.com/",
//         county: "Boyd County",
//         phone: "(606) 739-4224",
//         state: "KY",
//         source_id: "ky-bydc",
//         zip_code: "41129",
//         email: "",
//         has_mugshots: true,
//     },
//     {
//         id: 7,
//         city: "Danville",
//         name: "Boyle County Detention Center",
//         state_full: "Kentucky",
//         address1: "1862 S Danville Bypass",
//         source_url: "http://www.boylecountydetention.com/",
//         county: "Boyle County",
//         phone: "(859) 238-1127",
//         state: "KY",
//         source_id: "ky-bldc",
//         zip_code: "40422",
//         email: "",
//         has_mugshots: true,
//     },
//     {
//         id: 8,
//         city: "Hardinsburg",
//         name: "Breckinridge County Detention Center",
//         state_full: "Kentucky",
//         address1: "500 Glen Nash Ln",
//         source_url: "http://www.breckinridgecountydetention.com/",
//         county: "Breckinridge County",
//         phone: "(270) 756-6244",
//         state: "KY",
//         source_id: "ky-bddc",
//         zip_code: "40143",
//         email: "",
//         has_mugshots: true,
//     },
//     {
//         id: 9,
//         city: "Newport",
//         name: "Campbell County Detention Center",
//         state_full: "Kentucky",
//         address1: "601 Central Avenue",
//         source_url: "http://ccdc.campbellcounty.ky.gov/",
//         county: "Campbell County",
//         phone: "(859) 431-4611",
//         state: "KY",
//         source_id: "ky-cpdc",
//         zip_code: "41071",
//         email: "",
//         has_mugshots: true,
//     },
//     {
//         id: 10,
//         city: "Carrollton",
//         name: "Carroll County Detention Center",
//         state_full: "Kentucky",
//         address1: "800 Clay Street",
//         source_url: "http://www.carrolldetention.com/index2.html",
//         county: "Carroll County",
//         phone: "(502) 732-9499",
//         state: "KY",
//         source_id: "ky-ccdc",
//         zip_code: "41008",
//         email: "",
//         has_mugshots: true,
//     },
// ];

export const DetentionCenterData2 = [
    {
        city: "Bay Minette",
        name: "Baldwin County Sheriff's Office",
        state_full: "Alabama",
        address1: "200 Hand Ave",
        source_url: "http://www.sheriffofbaldwin.com/",
        county: "Baldwin County",
        phone: "(251) 937-0202",
        state: "AL",
        source_id: "al-bcso",
        zip_code: "36507",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Anniston",
        name: "Calhoun County Sheriff's Office",
        state_full: "Alabama",
        address1: "400 West 8th Street",
        source_url: "http://www.calcoso.org/jail.cfm",
        county: "Calhoun County",
        phone: "(256) 241-8023",
        state: "AL",
        source_id: "al-ccso",
        zip_code: "36201",
        email: "estarr@calcoso.org",
        has_mugshots: false,
    },
    {
        city: "La Fayette",
        name: "Chambers County Jail",
        state_full: "Alabama",
        address1: "105 Alabama Ave W",
        source_url: "http://www.chamberscountyjail.com/index.html",
        county: "Chambers County",
        phone: "(334) 864-4336",
        state: "AL",
        source_id: "al-ccj",
        zip_code: "36862",
        email: "info@chamberscountysheriff.org",
        has_mugshots: false,
    },
    {
        city: "Centre",
        name: "Cherokee County Sheriff's Office",
        state_full: "Alabama",
        address1: "110 Cedar Bluff Road",
        source_url: "http://www.cherokeecountyalsheriff.com/index.php",
        county: "Cherokee County",
        phone: "(256) 927-6435",
        state: "AL",
        source_id: "al-chso",
        zip_code: "35960",
        email: "",
        has_mugshots: true,
    },
    {
        city: "New Brockton",
        name: "Coffee Co Sheriff's Dept",
        state_full: "Alabama",
        address1: "4 County Complex",
        source_url: "http://vinelink.com",
        county: "Coffee County",
        phone: "(334) 894-5535",
        state: "AL",
        source_id: "al-ccsd",
        zip_code: "36351",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Colbert County Sheriff’s Office",
        state_full: "Alabama",
        address1: null,
        source_url: "http://colbertsheriff.net/",
        county: "Colbert County",
        phone: null,
        state: "AL",
        source_id: "al-colb",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Rockford",
        name: "Coosa County Sheriff's Office",
        state_full: "Alabama",
        address1: "296 School Street",
        source_url: "http://www.coosacountyso.org/",
        county: "Coosa County",
        phone: "(256) 377-2211",
        state: "AL",
        source_id: "al-coso",
        zip_code: "35136",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Dale County Sheriff's Office",
        state_full: "Alabama",
        address1: null,
        source_url: "http://www.daleso.com/",
        county: "Dale County",
        phone: null,
        state: "AL",
        source_id: "al-dale",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "DeKalb County Sheriff's Office",
        state_full: "Alabama",
        address1: "2801 Jordan Rd SW",
        source_url: "http://www.dekalbcountysheriff.org/",
        county: "DeKalb County",
        phone: "(256) 845-3801",
        state: "AL",
        source_id: "al-dcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wetumpka",
        name: "Elmore Co Sheriff's Dept",
        state_full: "Alabama",
        address1: "8955 US Highway 231",
        source_url: "http://vinelink.com",
        county: "Elmore County",
        phone: "(334) 567-5546",
        state: "AL",
        source_id: "al-ecsd",
        zip_code: "36092",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Etowah County Sheriff's Office",
        state_full: "Alabama",
        address1: "827 Forrest Ave",
        source_url: "http://vinelink.com",
        county: "Etowah County",
        phone: "(256) 543-2893",
        state: "AL",
        source_id: "al-ecso",
        zip_code: null,
        email: "tipline@etowahcounty.org",
        has_mugshots: true,
    },
    {
        city: "Fayette",
        name: "Fayette County Sheriff's Office",
        state_full: "Alabama",
        address1: "113 1St Ave NW",
        source_url: "http://www.fayetteso.com/",
        county: "Fayette County",
        phone: "(205) 932-3205",
        state: "AL",
        source_id: "al-fcso",
        zip_code: "35555",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Franklin County Sheriff's Office",
        state_full: "Alabama",
        address1: "748 Walnut Gate Rd",
        source_url: "http://www.claysheriffms.org/",
        county: "Franklin County",
        phone: "(256) 332-8820",
        state: "AL",
        source_id: "al-fran",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Jackson County Sheriff's Office",
        state_full: "Alabama",
        address1: "102 E Laurel St",
        source_url: "http://www.jacksoncountysheriffal.org/",
        county: "Jackson County",
        phone: "(256) 574-2610",
        state: "AL",
        source_id: "al-jack",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Birmingham",
        name: "Jefferson County Sheriff's Office",
        state_full: "Alabama",
        address1: "809 Richard Arrington Jr. Blvd. N",
        source_url: "http://www.jeffcosheriff.net/index.php",
        county: "Jefferson County",
        phone: "(205) 325-5670",
        state: "AL",
        source_id: "al-jcso",
        zip_code: "35203",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Huntsville",
        name: "Madison County Sheriff's Department",
        state_full: "Alabama",
        address1: "815 Wheeler Avenue",
        source_url: "http://sheriff.madisoncountyal.gov/",
        county: "Madison County",
        phone: "(256) 519-4839",
        state: "AL",
        source_id: "al-mcsd",
        zip_code: "35801",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Marion County Sheriff’s Office",
        state_full: "Alabama",
        address1: "280 Winchester Dr.",
        source_url: "http://marionsoal.com/",
        county: "Marion County",
        phone: "(205) 921-7433",
        state: "AL",
        source_id: "al-mari",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Guntersville",
        name: "Marshall County Sheriff's Office",
        state_full: "Alabama",
        address1: "423 Blount Avenue",
        source_url: "http://www.marshallcosheriff.org/",
        county: "Marshall County",
        phone: "(256) 582-2034",
        state: "AL",
        source_id: "al-mlso",
        zip_code: "35976",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Mobile",
        name: "Mobile County Sheriff's Office",
        state_full: "Alabama",
        address1: "450 St Emanuel St",
        source_url: "http://www.mobileso.com",
        county: "Mobile County",
        phone: "(251) 574-4702",
        state: "AL",
        source_id: "al-mcso",
        zip_code: "36603",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Montgomery",
        name: "Montgomery County Sheriff's Office",
        state_full: "Alabama",
        address1: "225 South McDonough Street",
        source_url: "http://www.montgomerysheriff.com/",
        county: "Montgomery County",
        phone: "(334) 832-1386",
        state: "AL",
        source_id: "al-myso",
        zip_code: "36104",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Decatur",
        name: "Morgan County Sheriff's Office",
        state_full: "Alabama",
        address1: "119 Lee St NE",
        source_url: "http://www.morgancountysheriffsoffice.com/",
        county: "Morgan County",
        phone: "(256) 351-4825",
        state: "AL",
        source_id: "al-mnso",
        zip_code: "35601",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Pickens County Sheriff’s Office",
        state_full: "Alabama",
        address1: null,
        source_url: "http://pickenscountyalsheriff.org/",
        county: "Pickens County",
        phone: null,
        state: "AL",
        source_id: "al-pcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Pike County Sheriff’s Office",
        state_full: "Alabama",
        address1: null,
        source_url: "http://pikecountyalsheriff.com/",
        county: "Pike County",
        phone: null,
        state: "AL",
        source_id: "al-pike",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Columbiana",
        name: "Shelby County Sheriff's Office",
        state_full: "Alabama",
        address1: "380 McDow Road",
        source_url: "http://www.shelbyso.com/",
        county: "Shelby County",
        phone: "(205) 669-3938",
        state: "AL",
        source_id: "al-scso",
        zip_code: "35051",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Trussville",
        name: "Trussville Police Department",
        state_full: "Alabama",
        address1: "131 Main Street",
        source_url: "http://www.trussvillepd.org/jail/",
        county: "Jefferson County - Trussville",
        phone: "(205) 655-2101",
        state: "AL",
        source_id: "al-tpd",
        zip_code: "35173",
        email: "jbridges@Trussville.org",
        has_mugshots: false,
    },
    {
        city: "Tuscaloosa",
        name: "Tuscaloosa County Sheriff's Office",
        state_full: "Alabama",
        address1: "1600 26th Ave",
        source_url: "http://www.tcsoal.org",
        county: "Tuscaloosa County",
        phone: "(205) 752-0616",
        state: "AL",
        source_id: "al-tcso",
        zip_code: "35401",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Anchorage",
        name: "Anchorage Police Department",
        state_full: "Alaska",
        address1: "1400 East 4th Avenue",
        source_url: "http://www.muni.org/departments/police/pages/default.aspx",
        county: "Anchorage",
        phone: "(907) 269-4100",
        state: "AK",
        source_id: "ak-apd",
        zip_code: "99501",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Bisbee",
        name: "Cochise County Sheriff's Office",
        state_full: "Arizona",
        address1: "203 North Judd Drive",
        source_url: "http://cochise.az.gov/cochise_sheriff.aspx?id=176",
        county: "Cochise County",
        phone: "(520) 432-7540",
        state: "AZ",
        source_id: "az-ccso",
        zip_code: "85603",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Phoenix",
        name: "Maricopa County Sheriff's Office",
        state_full: "Arizona",
        address1: "550 West Jackson Street",
        source_url: "http://www.mcso.org",
        county: "Maricopa County",
        phone: "(602) 876-1801",
        state: "AZ",
        source_id: "az-mcso",
        zip_code: "85003",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Kingman",
        name: "Mohave County Sheriff's Office",
        state_full: "Arizona",
        address1: "501 S. Highway 66",
        source_url: "http://www.mohavecounty.us/ContentPage.aspx?id=131&cid=81",
        county: "Mohave County",
        phone: "(928) 753-0759",
        state: "AZ",
        source_id: "az-meso",
        zip_code: "86401",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Holbrook",
        name: "Navajo County Sheriff's Office",
        state_full: "Arizona",
        address1: "100 E Code Talkers Dr",
        source_url: "http://www.navajocountyaz.gov/sheriff/",
        county: "Navajo County",
        phone: "(928) 524-4050",
        state: "AZ",
        source_id: "az-ncso",
        zip_code: "86025",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Tucson",
        name: "Pima County Sheriff's Office",
        state_full: "Arizona",
        address1: "1270 W. Silverlake Road",
        source_url: "http://pimasheriff.org/",
        county: "Pima County",
        phone: "(520) 351-8111",
        state: "AZ",
        source_id: "az-pcso",
        zip_code: "85713",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Florence",
        name: "Pinal County Sheriff's Office",
        state_full: "Arizona",
        address1: "971 Jason Lopez Circle",
        source_url: "http://www.pinalcountyaz.gov/sheriff",
        county: "Pinal County",
        phone: "(520) 866-5180",
        state: "AZ",
        source_id: "az-plso",
        zip_code: "85132",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Scottsdale",
        name: "Scottsdale Police Department",
        state_full: "Arizona",
        address1: "3700 N 75th Street",
        source_url:
            "http://www.scottsdaleaz.gov/Police/about/chief/Public_Information_Office/arrests_convictions",
        county: "Scottsdale",
        phone: "(480) 312-5419",
        state: "AZ",
        source_id: "az-spd",
        zip_code: "85251",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Camp Verde",
        name: "Yavapai County Sheriff's Office",
        state_full: "Arizona",
        address1: "2830 Commonwealth Dr",
        source_url: "http://www.ycsoaz.gov",
        county: "Yavapai County",
        phone: "(928) 771-3260",
        state: "AZ",
        source_id: "az-ycso",
        zip_code: "86322",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Yuma County Sheriff's Office",
        state_full: "Arizona",
        address1: null,
        source_url: "http://yumacountysheriff.org/",
        county: "Yuma County",
        phone: null,
        state: "AZ",
        source_id: "az-yuma",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "De Witt",
        name: "Arkansas Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "1000 Rice Belt Ave",
        source_url: "http://vinelink.com",
        county: "Arkansas County",
        phone: "(870) 946-3161",
        state: "AR",
        source_id: "ar-acso",
        zip_code: "72042",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mountain Home",
        name: "Baxter County Sheriff's Office",
        state_full: "Arkansas",
        address1: "904 Hwy 62 W",
        source_url: "http://www.baxtercountysheriff.com/index.php",
        county: "Baxter County",
        phone: "(870) 425-7000",
        state: "AR",
        source_id: "ar-bcso",
        zip_code: "72653",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Bentonville",
        name: "Benton County Sheriff's Office",
        state_full: "Arkansas",
        address1: "1300 SW 14th St",
        source_url: "http://www.bentoncountysheriff.org/JailInfo.aspx",
        county: "Benton County",
        phone: "(479) 271-1011",
        state: "AR",
        source_id: "ar-bnso",
        zip_code: "72712",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Harrison",
        name: "Boone County Sheriff's Office",
        state_full: "Arkansas",
        address1: "5800 Law Dr",
        source_url: "http://boonesheriff.com/page.php?id=6",
        county: "Boone County",
        phone: "(870) 741-8404",
        state: "AR",
        source_id: "ar-beso",
        zip_code: "72601",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Cabot",
        name: "Cabot Police Department",
        state_full: "Arkansas",
        address1: "101 North Second Street",
        source_url: "http://www.cabotar.gov",
        county: "Cabot Police",
        phone: "(501) 843-6526",
        state: "AR",
        source_id: "ar-cbd",
        zip_code: "72023",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hampton",
        name: "Calhoun Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "PO Box 1174",
        source_url: "http://vinelink.com",
        county: "Calhoun County",
        phone: "(870) 798-2323",
        state: "AR",
        source_id: "ar-chso",
        zip_code: "71744",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Carlisle",
        name: "Carlisle Police Department",
        state_full: "Arkansas",
        address1: "100 W. Main St.",
        source_url: "http://www.carlislear.org/police/police.htm",
        county: "Carlisle Police",
        phone: "(870) 552-3431",
        state: "AR",
        source_id: "ar-cpd",
        zip_code: "72024",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Berryville",
        name: "Carroll County Jail",
        state_full: "Arkansas",
        address1: "205 Hailey Rd",
        source_url: "http://co.carroll.ar.us/sheriff/",
        county: "Carroll County",
        phone: "(870) 423-2901",
        state: "AR",
        source_id: "ar-clcj",
        zip_code: "72616",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Arkadelphia",
        name: "Clark County Sheriff's Office",
        state_full: "Arkansas",
        address1: "406 S 5th Street",
        source_url: "http://vinelink.com",
        county: "Clark County",
        phone: "(870) 246-2222",
        state: "AR",
        source_id: "ar-ckso",
        zip_code: "71923",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Piggott",
        name: "Clay Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "268 S 2nd Ave",
        source_url: "http://vinelink.com",
        county: "Clay County",
        phone: "(501) 598-2270",
        state: "AR",
        source_id: "ar-cyso",
        zip_code: "72454",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Heber Springs",
        name: "Cleburne County Jail",
        state_full: "Arkansas",
        address1: "914 South 9th Street",
        source_url: "http://www.cleburnecountysheriff.com/",
        county: "Cleburne County",
        phone: "(501) 362-2596",
        state: "AR",
        source_id: "ar-cbcj",
        zip_code: "72543",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Rison",
        name: "Cleveland Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "PO Box 427",
        source_url: "http://vinelink.com",
        county: "Cleveland County",
        phone: "(870) 325-6222",
        state: "AR",
        source_id: "ar-clso",
        zip_code: "71665",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Magnolia",
        name: "Columbia County Sheriff's Office",
        state_full: "Arkansas",
        address1: "82 Columbia Road 300",
        source_url: "http://www.columbiacountysheriffar.org/index.php",
        county: "Columbia County",
        phone: "(870) 234-5331",
        state: "AR",
        source_id: "ar-cbso",
        zip_code: "71753",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Morrilton",
        name: "Conway County Jail",
        state_full: "Arkansas",
        address1: "30 Southern Valley Dr",
        source_url: "http://www.conwaycountysheriff.org/",
        county: "Conway County",
        phone: "(501) 354-9627",
        state: "AR",
        source_id: "ar-cycj",
        zip_code: "72110",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Van Buren",
        name: "Crawford Co Sheriff's Dept",
        state_full: "Arkansas",
        address1: "317 Main St",
        source_url: "http://vinelink.com",
        county: "Crawford County",
        phone: "(479) 474-2261",
        state: "AR",
        source_id: "ar-ccsd",
        zip_code: "72956",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Crittenden County Sheriff's Office",
        state_full: "Arkansas",
        address1: "350 Afco Rd",
        source_url: "https://vinelink.com",
        county: "Crittenden County",
        phone: "870-702-2000",
        state: "AR",
        source_id: "ar-crit",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wynne",
        name: "Cross County Sheriff's Office",
        state_full: "Arkansas",
        address1: "704 E Canal Ave",
        source_url: "http://crosscountysheriff.org/",
        county: "Cross County",
        phone: "(870) 238-5700",
        state: "AR",
        source_id: "ar-ccso",
        zip_code: "72396",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Crossett",
        name: "Crossett Police Department",
        state_full: "Arkansas",
        address1: "PO Box 560",
        source_url: "http://vinelink.com",
        county: "Ashley County",
        phone: "(870) 364-4131",
        state: "AR",
        source_id: "ar-crd",
        zip_code: "71635",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Monticello",
        name: "Drew Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "210 S Main St",
        source_url: "http://vinelink.com",
        county: "Drew County",
        phone: "(870) 367-6211",
        state: "AR",
        source_id: "ar-dcso",
        zip_code: "71655",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Dumas",
        name: "Dumas City Jail",
        state_full: "Arkansas",
        address1: "149 E Waterman St",
        source_url: "http://vinelink.com",
        county: "Desha County",
        phone: "(870) 382-8703",
        state: "AR",
        source_id: "ar-dcj",
        zip_code: "71639",
        email: null,
        has_mugshots: true,
    },
    {
        city: "England",
        name: "England Police Department",
        state_full: "Arkansas",
        address1: "PO Box 249",
        source_url: "http://vinelink.com",
        county: "England Police",
        phone: "(501) 842-2311",
        state: "AR",
        source_id: "ar-epd",
        zip_code: "72046",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Conway",
        name: "Faulkner County Jail",
        state_full: "Arkansas",
        address1: "801 Locust Street",
        source_url: "http://www.faulknercounty.org/sheriff",
        county: "Faulkner County",
        phone: "(501) 450-4914",
        state: "AR",
        source_id: "ar-facj",
        zip_code: "72032",
        email: "john.randall@fcso.ar.gov",
        has_mugshots: true,
    },
    {
        city: "Ozark",
        name: "Franklin County Jail",
        state_full: "Arkansas",
        address1: "101 W Main St",
        source_url:
            "https://www.facebook.com/pages/Franklin-County-Sheriffs-Office-AR/166540380032995",
        county: "Franklin County",
        phone: "(479) 667-4127",
        state: "AR",
        source_id: "ar-fncj",
        zip_code: "72949",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Salem",
        name: "Fulton Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "PO Box 436",
        source_url: "http://vinelink.com",
        county: "Fulton County",
        phone: "(870) 895-2601",
        state: "AR",
        source_id: "ar-fcso",
        zip_code: "72576",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hot Springs",
        name: "Garland County Sheriff's Office",
        state_full: "Arkansas",
        address1: "525 Ouachita Avenue",
        source_url: "http://www.garlandcountysheriff.com",
        county: "Garland County",
        phone: "(501) 622-3660",
        state: "AR",
        source_id: "ar-gcso",
        zip_code: "71901",
        email: "lbanks@garlandcounty.org",
        has_mugshots: true,
    },
    {
        city: "Sheridan",
        name: "Grant Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "101 W Pine St",
        source_url: "http://vinelink.com",
        county: "Grant County",
        phone: "(870) 942-5039",
        state: "AR",
        source_id: "ar-gtso",
        zip_code: "72150",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Paragould",
        name: "Greene Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "1809 N Rockingchair Rd",
        source_url: "http://vinelink.com",
        county: "Greene County",
        phone: "(870) 236-7612",
        state: "AR",
        source_id: "ar-grso",
        zip_code: "72450",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hope",
        name: "Hempstead County Jail",
        state_full: "Arkansas",
        address1: "312 South Washington Street",
        source_url: "http://hempsteadcountysheriff.com/",
        county: "Hempstead County",
        phone: "(870) 777-1903",
        state: "AR",
        source_id: "ar-hecj",
        zip_code: "71801",
        email: "sheriff@hempsteadcountysheriff.com",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hot Spring County Sheriff's Office",
        state_full: "Arkansas",
        address1: "215 East Highland Avenue",
        source_url: "http://www.hotspringcountysheriff.com",
        county: "Hot Spring County",
        phone: "(501) 332-3671",
        state: "AR",
        source_id: "ar-hscso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Nashville",
        name: "Howard County Sheriff's Office",
        state_full: "Arkansas",
        address1: "101 Isaac Perkins Blvd",
        source_url: "http://howardcountysheriffar.com/index.php",
        county: "Howard County",
        phone: "(870) 845-2626",
        state: "AR",
        source_id: "ar-hcso",
        zip_code: "71852",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Batesville",
        name: "Independence Co Sheriff's Dept",
        state_full: "Arkansas",
        address1: "PO Box 4236",
        source_url: "http://vinelink.com",
        county: "Independence County",
        phone: "(870) 793-8838",
        state: "AR",
        source_id: "ar-icsd",
        zip_code: "72503",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Melbourne",
        name: "Izard Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "PO Box 370",
        source_url: "http://vinelink.com",
        county: "Izard County",
        phone: "(870) 368-4203",
        state: "AR",
        source_id: "ar-icso",
        zip_code: "72556",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Newport",
        name: "Jackson County Sheriff's Office",
        state_full: "Arkansas",
        address1: "617 Second St",
        source_url: "http://jacksonsheriff.org/index.php",
        county: "Jackson County",
        phone: "(870) 523-5842",
        state: "AR",
        source_id: "ar-jkso",
        zip_code: "72112",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pine Bluff",
        name: "Jefferson County Sheriff's Office",
        state_full: "Arkansas",
        address1: "101 East Barraque St",
        source_url: "www.jeffcoso.org/index.php",
        county: "Jefferson County",
        phone: "(870) 541-4620",
        state: "AR",
        source_id: "ar-jnso",
        zip_code: "71611",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Clarksville",
        name: "Johnson County Sheriff's Office",
        state_full: "Arkansas",
        address1: "301 Porter Industrial Road",
        source_url: "http://www.johnsoncosheriff.com/index.php",
        county: "Johnson County",
        phone: "(479) 754-2200",
        state: "AR",
        source_id: "ar-jcso",
        zip_code: "72830",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Walnut Ridge",
        name: "Lawrence County Jail",
        state_full: "Arkansas",
        address1: "315 West Main",
        source_url: "http://www.lawrencecountysheriffsoffice.com/",
        county: "Lawrence County",
        phone: "(870) 886-2525",
        state: "AR",
        source_id: "ar-lwcj",
        zip_code: "72476",
        email: "jail@lawrencecountysheriffsoffice.com",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lee County Sheriff's Office",
        state_full: "Arkansas",
        address1: "510 N Commerce",
        source_url: "https://vinelink.com",
        county: "Lee County",
        phone: "662-841-9040",
        state: "AR",
        source_id: "ar-leec",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Star City",
        name: "Lincoln Co Sheriff's Dept",
        state_full: "Arkansas",
        address1: "300 S Drew St",
        source_url: "http://vinelink.com",
        county: "Lincoln County",
        phone: "(870) 628-4271",
        state: "AR",
        source_id: "ar-lcsd",
        zip_code: "71667",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Ashdown",
        name: "Little River Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "351 N 2nd St",
        source_url: "http://vinelink.com",
        county: "Little County",
        phone: "(870) 898-5115",
        state: "AR",
        source_id: "ar-lrcso",
        zip_code: "71822",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Paris",
        name: "Logan County Jail",
        state_full: "Arkansas",
        address1: "508 West Grober Street",
        source_url: "http://www.loganso.com/",
        county: "Logan County",
        phone: "(479) 963-3271",
        state: "AR",
        source_id: "ar-lgcj",
        zip_code: "72855",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Lonoke",
        name: "Lonoke Police Department",
        state_full: "Arkansas",
        address1: "203 W Front St",
        source_url: "http://vinelink.com",
        county: "Lonoke County",
        phone: "(501) 676-6953",
        state: "AR",
        source_id: "ar-lpd",
        zip_code: "72086",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Huntsville",
        name: "Madison County Jail",
        state_full: "Arkansas",
        address1: "201 West Main Street",
        source_url: "http://www.mcso.ar.gov/",
        county: "Madison County",
        phone: "(479) 738-9420",
        state: "AR",
        source_id: "ar-mdcj",
        zip_code: "72740",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Yellville",
        name: "Marion County Sheriff's Office",
        state_full: "Arkansas",
        address1: "491 Highway 62 West",
        source_url: "http://www.marioncountysheriffar.com/index.php",
        county: "Marion County",
        phone: "(870) 449-4236",
        state: "AR",
        source_id: "ar-mcso",
        zip_code: "72687",
        email: "",
        has_mugshots: true,
    },
    {
        city: "McGehee",
        name: "McGehee Police Department",
        state_full: "Arkansas",
        address1: "2412 Hwy 65 N.",
        source_url: "http://www.cityofmcgehee.com/mpd.htm",
        county: "McGehee Police",
        phone: "(870) 222-3636",
        state: "AR",
        source_id: "ar-mpd",
        zip_code: "71654",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Miller County Sheriff's Office",
        state_full: "Arkansas",
        address1: "2300 East Street",
        source_url: "http://vinelink.com",
        county: "Miller County",
        phone: "(870) 774-3001",
        state: "AR",
        source_id: "ar-mlso",
        zip_code: null,
        email: "infocenter@millercountyso.us",
        has_mugshots: true,
    },
    {
        city: "Luxora",
        name: "Mississippi County Sheriff's Office",
        state_full: "Arkansas",
        address1: "685 North County Road 599",
        source_url: "http://www.mcagov.com/offices/county-sheriff",
        county: "Mississippi County",
        phone: "(870) 658-2242",
        state: "AR",
        source_id: "ar-msso",
        zip_code: "72358",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clarendon",
        name: "Monroe Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "200 S Main St",
        source_url: "http://vinelink.com",
        county: "Monroe County",
        phone: "(870) 747-3623",
        state: "AR",
        source_id: "ar-mnso",
        zip_code: "72029",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mount Ida",
        name: "Montgomery Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "105 Highway 270 E",
        source_url: "http://vinelink.com",
        county: "Montgomery County",
        phone: "(870) 867-3151",
        state: "AR",
        source_id: "ar-mtso",
        zip_code: "71957",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jasper",
        name: "Newton Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "PO Box 312",
        source_url: "http://vinelink.com",
        county: "Newton County",
        phone: "(870) 446-5124",
        state: "AR",
        source_id: "ar-ncso",
        zip_code: "72641",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Osceola",
        name: "Osceola Police Department",
        state_full: "Arkansas",
        address1: "401 W Keiser Ave",
        source_url: "http://cityofosceolaar.com/works_police.php",
        county: "Osceola Police",
        phone: "(870) 563-5213",
        state: "AR",
        source_id: "ar-opd",
        zip_code: "72370",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Camden",
        name: "Ouachita Co Sheriff's Dept",
        state_full: "Arkansas",
        address1: "145 Scott Aly SW",
        source_url: "http://vinelink.com",
        county: "Ouachita County",
        phone: "(870) 837-2200",
        state: "AR",
        source_id: "ar-ocsd",
        zip_code: "71701",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Perryville",
        name: "Perry Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "PO Box 146",
        source_url: "http://vinelink.com",
        county: "Perry County",
        phone: "(501) 889-2333",
        state: "AR",
        source_id: "ar-prso",
        zip_code: "72126",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Harrisburg",
        name: "Poinsett County Jail",
        state_full: "Arkansas",
        address1: "1500 Justice Drive",
        source_url: "http://www.poinsettcountysheriff.org/",
        county: "Poinsett County",
        phone: "(870) 578-5411",
        state: "AR",
        source_id: "ar-pocj",
        zip_code: "72432",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Russellville",
        name: "Pope Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "3 Emergency Ln",
        source_url: "http://vinelink.com",
        county: "Pope County",
        phone: "(479) 968-2558",
        state: "AR",
        source_id: "ar-pcso",
        zip_code: "72802",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Little Rock",
        name: "Pulaski County Sheriff's Office",
        state_full: "Arkansas",
        address1: "3201 West Roosevelt Road",
        source_url: "http://www.pcso.org/InmateRoster.shtml",
        county: "Pulaski",
        phone: "(501) 340-7001",
        state: "AR",
        source_id: "ar-pula",
        zip_code: "72204",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Pocahontas",
        name: "Randolph Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "1500 Pace Rd",
        source_url: "http://vinelink.com",
        county: "Randolph County",
        phone: "(870) 892-8888",
        state: "AR",
        source_id: "ar-rcso",
        zip_code: "72455",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Benton",
        name: "Saline County Jail",
        state_full: "Arkansas",
        address1: "735 South Neeley St",
        source_url: "http://www.scsosheriff.org/",
        county: "Saline County",
        phone: "(501) 303-5609",
        state: "AR",
        source_id: "ar-sacj",
        zip_code: "72015",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Scott County Sheriff's Office",
        state_full: "Arkansas",
        address1: "100 First St, Waldron",
        source_url: "http://vinelink.com",
        county: "Scott County",
        phone: "(479) 637-4156",
        state: "AR",
        source_id: "ar-scso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Marshall",
        name: "Searcy Co Sheriff's Dept",
        state_full: "Arkansas",
        address1: "PO Box 812",
        source_url: "http://vinelink.com",
        county: "Searcy County",
        phone: "(870) 448-2340",
        state: "AR",
        source_id: "ar-scsd",
        zip_code: "72650",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Sebastian County Sheriff's Office",
        state_full: "Arkansas",
        address1: null,
        source_url: "http://www.sebastiancountyar.gov/Sheriff",
        county: "Sebastian County",
        phone: null,
        state: "AR",
        source_id: "ar-sbstn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "De Queen",
        name: "Sevier Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "115 N 3rd St",
        source_url: "http://vinelink.com",
        county: "Sevier County",
        phone: "(870) 642-2125",
        state: "AR",
        source_id: "ar-svso",
        zip_code: "71832",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Ash Flat",
        name: "Sharp County Sheriff's Office",
        state_full: "Arkansas",
        address1: "30A Court Drive",
        source_url: "http://sharpcountysheriff.com/index.php",
        county: "Sharp County",
        phone: "(870) 994-7329",
        state: "AR",
        source_id: "ar-shso",
        zip_code: "72513",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Forrest City",
        name: "St. Francis County Sheriff's Office",
        state_full: "Arkansas",
        address1: "313 South Izard",
        source_url: "http://www.stfranciscountysheriff.org/index.php",
        county: "St. Francis County",
        phone: "(870) 633-2611",
        state: "AR",
        source_id: "ar-sfso",
        zip_code: "72336",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Mountain View",
        name: "Stone County Sheriff's Office",
        state_full: "Arkansas",
        address1: "1009 Sheriff's Drive",
        source_url: "www.stonecountysheriff.com",
        county: "Stone County",
        phone: "(870) 269-3825",
        state: "AR",
        source_id: "ar-stso",
        zip_code: "72560",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clinton",
        name: "Van Buren Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "PO Box 451",
        source_url: "http://vinelink.com",
        county: "Van County",
        phone: "(501) 745-2112",
        state: "AR",
        source_id: "ar-vbcso",
        zip_code: "72031",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Fayetteville",
        name: "Washington County Jail",
        state_full: "Arkansas",
        address1: "1155 W. Clydesdale Dr",
        source_url: "http://www.co.washington.ar.us/index.aspx?page=105",
        county: "Washington County Jail",
        phone: "(479) 444-5830",
        state: "AR",
        source_id: "ar-wacj",
        zip_code: "72701",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Fayetteville",
        name: "Washington County Sheriff's Office",
        state_full: "Arkansas",
        address1: "1156 W. Clydesdale Dr.",
        source_url: "http://www.co.washington.ar.us/index.aspx?page=108",
        county: "Washington County",
        phone: "(479) 444-5831",
        state: "AR",
        source_id: "ar-wash",
        zip_code: "72701",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Searcy",
        name: "White Co Sheriff's Dept",
        state_full: "Arkansas",
        address1: "1600 E Booth Rd",
        source_url: "http://vinelink.com",
        county: "White County",
        phone: "(501) 279-6279",
        state: "AR",
        source_id: "ar-wcsd",
        zip_code: "72143",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Augusta",
        name: "Woodruff Co Sheriff's Office",
        state_full: "Arkansas",
        address1: "500 N 3rd St",
        source_url: "http://vinelink.com",
        county: "Woodruff County",
        phone: "(870) 347-2583",
        state: "AR",
        source_id: "ar-wcso",
        zip_code: "72006",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Dardanelle",
        name: "Yell County Jail #2",
        state_full: "Arkansas",
        address1: "PO Box 99",
        source_url: "http://vinelink.com",
        county: "Yell County",
        phone: "(479) 229-4175",
        state: "AR",
        source_id: "ar-ycj",
        zip_code: "72834",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Alameda County Sheriff's Office",
        state_full: "California",
        address1: "15555 Oak Street",
        source_url: "http://vinelink.com",
        county: "Alameda County",
        phone: "510-272-6878",
        state: "CA",
        source_id: "ca-acso",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Fresno County Sheriff's Office",
        state_full: "California",
        address1: "1225 ‘M’ Street\nP.O. Box 872",
        source_url: "http://www.fresnosheriff.org",
        county: "Fresno County",
        phone: "Inmate Info: 888-373-7011\nFresno Police: 559-621-7",
        state: "CA",
        source_id: "ca-fcso",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Kern Co Sheriff's Dept",
        state_full: "California",
        address1: "  1415 Truxtun Ave",
        source_url: "http://www.kernsheriff.com",
        county: "Kern County",
        phone: "(661) 868-6850",
        state: "CA",
        source_id: "ca-kcso",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Kings County Sheriff's Office",
        state_full: "California",
        address1: "1444 W. Lacey Blvd.\nHanford, CA 93230",
        source_url:
            "http://www.countyofkings.com/departments/public-safety/sheriff",
        county: "Kings",
        phone: "Kings County Jail: 559-584-1431 ext. 2812\nJail Adm",
        state: "CA",
        source_id: "ca-king",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Monterey Park",
        name: "Los Angeles County Sheriff's Department",
        state_full: "California",
        address1: "4700 Ramona Blvd",
        source_url: "http://sheriff.lacounty.gov/wps/portal/lasd",
        county: "Los Angeles County",
        phone: "(323) 267-4800",
        state: "CA",
        source_id: "ca-lcso",
        zip_code: "91754",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Mendocino County Sheriff's Office",
        state_full: "California",
        address1: null,
        source_url: "http://www.mendocinosheriff.com/",
        county: "Mendocino County",
        phone: null,
        state: "CA",
        source_id: "ca-mend",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Monterey Co Sheriff's Office",
        state_full: "California",
        address1: "1414 Natividad Rd",
        source_url: "https://www.montereysheriff.org/",
        county: "Monterey County",
        phone: "(831) 647-7702",
        state: "CA",
        source_id: "ca-mcso",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Santa Ana",
        name: "Orange County Sheriff's Department",
        state_full: "California",
        address1: "550 N. Flower St",
        source_url:
            "http://egov.ocgov.com/ocgov/Sheriff-Coroner%20-%20Sandra%20Hutchens",
        county: "Orange County",
        phone: "(949) 770-6011",
        state: "CA",
        source_id: "ca-ocsd",
        zip_code: "92702",
        email: "cjx@ocsd.org",
        has_mugshots: false,
    },
    {
        city: "Riverside",
        name: "Riverside County Sheriff's Department",
        state_full: "California",
        address1: "4095 Lemon Street",
        source_url: "http://www.riversidesheriff.org/",
        county: "Riverside County",
        phone: "(951) 955-2400",
        state: "CA",
        source_id: "ca-rcsd",
        zip_code: "92501",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "San Bernardino County Sheriff's Office",
        state_full: "California",
        address1: "655 East Third Street",
        source_url: "https://vinelink.com",
        county: "San Bernardino County",
        phone: " 479-474-1721",
        state: "CA",
        source_id: "ca-sber",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "San Diego",
        name: "San Diego County Sheriff's Department",
        state_full: "California",
        address1: "1173 Front St",
        source_url: "http://www.sdsheriff.net/about_dsb.html",
        county: "San Diego County",
        phone: "(619) 610-1647",
        state: "CA",
        source_id: "ca-sdsd",
        zip_code: "92101",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "San Francisco County Sheriff's Office",
        state_full: "California",
        address1: "850 Bryant Street, 7th Floor",
        source_url: "http://www.sfsheriff.com/",
        county: "San Francisco County",
        phone: "(415) 553-1430",
        state: "CA",
        source_id: "ca-sfso",
        zip_code: "94103",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "San Joaquin Co Sheriff's Dept",
        state_full: "California",
        address1: "999 W. Mathews Rd",
        source_url: "http://www.sjgov.org/sheriff",
        county: "San Joaquin County",
        phone: "TEL\n209-468-4562\n209-468-4570",
        state: "CA",
        source_id: "ca-sjsd",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Solano County Sheriff's Office",
        state_full: "California",
        address1: "2500 Claybank Rd",
        source_url: "http://www.solanocounty.com/sheriff",
        county: "Solano County",
        phone: "Main Phone: 707-421-7180",
        state: "CA",
        source_id: "ca-slso",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Sonoma County Sheriff's Office",
        state_full: "California",
        address1: "2777 Ventura Avenue",
        source_url: "http://www.sonomasheriff.org",
        county: "Sonoma County",
        phone: "TEL\n707-565-1400",
        state: "CA",
        source_id: "ca-snso",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Stanislaus County Sheriff's Department",
        state_full: "California",
        address1: "250 E Hackett Rd",
        source_url: "https://www.scsdonline.com/",
        county: "Stanislaus County",
        phone: "209-525-7114",
        state: "CA",
        source_id: "ca-scsd",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Tulare County Sheriff's Office",
        state_full: "California",
        address1: "2404 W Burrel Avenue",
        source_url: "http://www.tularecounty.ca.gov/sheriff/",
        county: "Tulare County",
        phone: "Main Phone: 559-735-1931",
        state: "CA",
        source_id: "ca-tcso",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Ventura County Sheriff's Office",
        state_full: "California",
        address1: "2101 E Olsen Rd",
        source_url: "http://www.vcsd.org",
        county: "Ventura County",
        phone: "805-494-8242",
        state: "CA",
        source_id: "ca-vcso",
        zip_code: "",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Adams County Sheriff's Office",
        state_full: "Colorado",
        address1: "150 North 19th Avenue\nPO Box 5001",
        source_url: "http://www.adamssheriff.org/",
        county: "Adams",
        phone: "Sheriff's Office: 303-654-1850\nJail's Division Chi",
        state: "CO",
        source_id: "co-adms",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Arapahoe County Government",
        state_full: "Colorado",
        address1: "7375 S. Potomac St",
        source_url: "http://www.co.arapahoe.co.us/",
        county: "Arapahoe County",
        phone: "720-874-3500",
        state: "CO",
        source_id: "co-acg",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pagosa Springs",
        name: "Archuleta County Sheriff's Office",
        state_full: "Colorado",
        address1: "449 San Juan St",
        source_url: "http://www.archuletacounty.org/index.aspx?NID=95",
        county: "Archuleta County",
        phone: "(970) 264-2131",
        state: "CO",
        source_id: "co-acso",
        zip_code: "81147",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Las Animas",
        name: "Bent County Sheriff's Office",
        state_full: "Colorado",
        address1: "11100 County Road GG.5",
        source_url:
            "http://bentcounty.org/2010/11/bent-county-sheriffs-office/",
        county: "Bent County",
        phone: "(719) 456-0796",
        state: "CO",
        source_id: "co-bcso",
        zip_code: "81054",
        email: "bent.sheriff@bentcounty.net",
        has_mugshots: true,
    },
    {
        city: "Georgetown",
        name: "Clear Creek Co Sheriff's Office",
        state_full: "Colorado",
        address1: "PO Box 2000",
        source_url: "http://vinelink.com",
        county: "Clear County",
        phone: "(303) 569-3251",
        state: "CO",
        source_id: "co-cccso",
        zip_code: "80444",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Conejos",
        name: "Conejos Co Sheriff's Office",
        state_full: "Colorado",
        address1: "PO Box 37",
        source_url: "http://vinelink.com",
        county: "Conejos County",
        phone: "(719) 376-6778",
        state: "CO",
        source_id: "co-ccso",
        zip_code: "81129",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Westcliffe",
        name: "Custer Co Sheriff's  Office",
        state_full: "Colorado",
        address1: "PO Box 1489",
        source_url: "http://vinelink.com",
        county: "Custer County",
        phone: "(719) 783-2270",
        state: "CO",
        source_id: "co-csso",
        zip_code: "81252",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Douglas County Sheriff's Office",
        state_full: "Colorado",
        address1: "4000 Justice Way\nSuite 2630",
        source_url: "http://www.dcsheriff.net",
        county: "Douglas County",
        phone: "(303) 660-7505",
        state: "CO",
        source_id: "co-dcso",
        zip_code: null,
        email: "DCSO@DCSheriff.net",
        has_mugshots: true,
    },
    {
        city: "Colorado Springs",
        name: "El Paso County Sheriff's Office",
        state_full: "Colorado",
        address1: "27 East Vermijo Avenue",
        source_url: "http://shr.elpasoco.com/",
        county: "El Paso County",
        phone: "(719) 520-7100",
        state: "CO",
        source_id: "co-epco",
        zip_code: "80903",
        email: null,
        has_mugshots: false,
    },
    {
        city: "Kiowa",
        name: "Elbert County Sheriff's Office",
        state_full: "Colorado",
        address1: "751 Ute Ave",
        source_url: "http://www.elbertcountysheriff.com/",
        county: "Elbert County",
        phone: "(303) 621-2027",
        state: "CO",
        source_id: "co-ecso",
        zip_code: "80117",
        email: "shayne.heap@elbertcounty-co.gov",
        has_mugshots: true,
    },
    {
        city: "Cañon City",
        name: "Fremont Co Sheriff's Office",
        state_full: "Colorado",
        address1: "100 Justice Center Rd",
        source_url: "http://vinelink.com",
        county: "Fremont County",
        phone: "(719) 276-5555",
        state: "CO",
        source_id: "co-fcso",
        zip_code: "81212",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Glenwood Springs",
        name: "Garfield Co Sheriff's Office",
        state_full: "Colorado",
        address1: "107 8th St",
        source_url: "http://vinelink.com",
        county: "Garfield County",
        phone: "(970) 945-0453",
        state: "CO",
        source_id: "co-gcso",
        zip_code: "81601",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Black Hawk",
        name: "Gilpin Co Sheriff's Office",
        state_full: "Colorado",
        address1: "2960 Dory Hill Rd",
        source_url: "http://vinelink.com",
        county: "Gilpin County",
        phone: "(303) 582-1060",
        state: "CO",
        source_id: "co-glso",
        zip_code: "80422",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hot Sulphur Springs",
        name: "Grand County Sheriff's Office",
        state_full: "Colorado",
        address1: "670 Spring St.",
        source_url: "http://co.grand.co.us/sheriff.html",
        county: "Grand County",
        phone: "(970) 725-3343",
        state: "CO",
        source_id: "co-grso",
        zip_code: "80451",
        email: "SO_info@co.grand.co.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Jefferson County Sheriff's Office",
        state_full: "Colorado",
        address1: "200 Jefferson County Parkway",
        source_url: "http://jeffco.us/",
        county: "Jefferson",
        phone: "303-271-5444",
        state: "CO",
        source_id: "co-jcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Burlington",
        name: "Kit Carson County Sheriff's Office",
        state_full: "Colorado",
        address1: "251 16th Street, Suite 103 ",
        source_url: "http://vinelink.com",
        county: "Kit Carson County",
        phone: "(719) 346-8934",
        state: "CO",
        source_id: "co-kccso",
        zip_code: "80807",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Durango",
        name: "La Plata Sheriff's Office",
        state_full: "Colorado",
        address1: "742 Turner Dr",
        source_url: "http://vinelink.com",
        county: "La Plata County",
        phone: "(970) 247-1157",
        state: "CO",
        source_id: "co-lpso",
        zip_code: "81303",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Leadville",
        name: "Lake County Sheriff's Office",
        state_full: "Colorado",
        address1: "PO Box 255",
        source_url: "http://vinelink.com",
        county: "Lake County",
        phone: "(719) 486-1249",
        state: "CO",
        source_id: "co-lkso",
        zip_code: "80461",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Fort Collins",
        name: "Larimer Co Sheriff's Office",
        state_full: "Colorado",
        address1: "2501 Midpoint Dr",
        source_url: "http://vinelink.com",
        county: "Larimer County",
        phone: "(970) 498-5101",
        state: "CO",
        source_id: "co-lrso",
        zip_code: "80525",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hugo",
        name: "Lincoln Co Sheriff's Office",
        state_full: "Colorado",
        address1: "PO Box 10",
        source_url: "http://vinelink.com",
        county: "Lincoln County",
        phone: "(719) 743-2426",
        state: "CO",
        source_id: "co-lcso",
        zip_code: "80821",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Craig",
        name: "Moffat County Sheriff's Office",
        state_full: "Colorado",
        address1: "800 W 1st Street",
        source_url: "http://www.moffatcountysheriff.com/",
        county: "Moffat County",
        phone: "(970) 824-4495",
        state: "CO",
        source_id: "co-mcso",
        zip_code: "81625",
        email: "khume@sheriff.moffat.co.us",
        has_mugshots: true,
    },
    {
        city: "Cortez",
        name: "Montezuma Co Sheriff's Office",
        state_full: "Colorado",
        address1: "730 E Driscoll St",
        source_url: "http://vinelink.com",
        county: "Montezuma County",
        phone: "(970) 565-0583",
        state: "CO",
        source_id: "co-mnso",
        zip_code: "81321",
        email: null,
        has_mugshots: true,
    },
    {
        city: "La Junta",
        name: "Otero Co Sheriff's Office",
        state_full: "Colorado",
        address1: "222 E 2nd St",
        source_url: "http://vinelink.com",
        county: "Otero County",
        phone: "(719) 384-5941",
        state: "CO",
        source_id: "co-ocso",
        zip_code: "81050",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Aspen",
        name: "Pitkin Co Sheriff's Dept",
        state_full: "Colorado",
        address1: "506 E Main St",
        source_url: "http://vinelink.com",
        county: "Pitkin County",
        phone: "(970) 920-5300",
        state: "CO",
        source_id: "co-pcsd",
        zip_code: "81611",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pueblo",
        name: "Pueblo Co Sheriff's Office",
        state_full: "Colorado",
        address1: "909 Court St",
        source_url: "http://vinelink.com",
        county: "Pueblo County",
        phone: "(719) 583-6129",
        state: "CO",
        source_id: "co-pcso",
        zip_code: "81003",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Steamboat Springs",
        name: "Routt Co Sheriff's Office",
        state_full: "Colorado",
        address1: "PO Box 773087",
        source_url: "http://vinelink.com",
        county: "Routt County",
        phone: "(970) 870-1090",
        state: "CO",
        source_id: "co-rcso",
        zip_code: "80477",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wray",
        name: "Yuma Co Sheriff's Dept",
        state_full: "Colorado",
        address1: "310 Ash St",
        source_url: "http://vinelink.com",
        county: "Yuma County",
        phone: "(970) 332-4805",
        state: "CO",
        source_id: "co-ycsd",
        zip_code: "80758",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wethersfield",
        name: "Connecticut Dept of Corrections",
        state_full: "Connecticut",
        address1: "24 Wolcott Hill Rd",
        source_url: "http://vinelink.com",
        county: "Tolland County",
        phone: "(860) 692-7780",
        state: "CT",
        source_id: "ct-cdc",
        zip_code: "06109",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Gainesville",
        name: "Alachua County Sheriff's Office",
        state_full: "Florida",
        address1: "3333 NE 39th Ave",
        source_url: "http://oldweb.circuit8.org/inmate.html",
        county: "Alachua County",
        phone: "(352) 367-4050",
        state: "FL",
        source_id: "fl-alac",
        zip_code: "32609",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Bradford County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://bradfordsheriff.org/",
        county: "Bradford County",
        phone: null,
        state: "FL",
        source_id: "fl-brad",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Cocoa",
        name: "Brevard County Sheriff's Office",
        state_full: "Florida",
        address1: "860 Camp Rd",
        source_url: "http://www.brevardsheriff.com/",
        county: "Brevard County",
        phone: "(321) 690-1500",
        state: "FL",
        source_id: "fl-bcso",
        zip_code: "32927",
        email: "jailinfo@bcso.us",
        has_mugshots: true,
    },
    {
        city: "Fort Lauderdale",
        name: "Broward Sheriff's Office",
        state_full: "Florida",
        address1: "555 SE 1st Ave",
        source_url: "http://www.sheriff.org/index.cfm",
        county: "Broward County",
        phone: "(954) 831-5900",
        state: "FL",
        source_id: "fl-bso",
        zip_code: "33301",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Punta Gorda",
        name: "Charlotte Co Sheriff's Office",
        state_full: "Florida",
        address1: "7474 Utilities Rd",
        source_url: "http://vinelink.com",
        county: "Charlotte County",
        phone: "(941) 575-5211",
        state: "FL",
        source_id: "fl-chso",
        zip_code: "33982",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Collier County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://www2.colliersheriff.org/",
        county: "Collier County",
        phone: null,
        state: "FL",
        source_id: "fl-coll",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Columbia County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://www.columbiasheriff.org/",
        county: "Columbia County",
        phone: null,
        state: "FL",
        source_id: "fl-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Arcadia",
        name: "Desoto County Sheriff's Office",
        state_full: "Florida",
        address1: "208 E Cypress St",
        source_url: "https://www.tipsubmit.com/WebTips.aspx?AgencyID=875",
        county: "Desoto County",
        phone: "(863) 491-6767",
        state: "FL",
        source_id: "fl-dcso",
        zip_code: "34266",
        email: "tips@desotosheriff.org",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Escambia County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://myescambia.com/",
        county: "Escambia",
        phone: null,
        state: "FL",
        source_id: "fl-ecso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Flagler County Sheriff's Office",
        state_full: "Florida",
        address1: "1002 Justice Lane",
        source_url: "http://www.myfcso.us/",
        county: "Flagler County",
        phone: "Jail (Main): 386-586-4860\nAdministration: 386-586-",
        state: "FL",
        source_id: "fl-flag",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Eastpoint",
        name: "Franklin Co Sheriff's Office",
        state_full: "Florida",
        address1: "PO Box 638",
        source_url: "http://vinelink.com",
        county: "Franklin County",
        phone: "(850) 670-8519",
        state: "FL",
        source_id: "fl-fcso",
        zip_code: "32328",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Quincy",
        name: "Gadsden County Sheriff's Office",
        state_full: "Florida",
        address1: "339 East Jefferson Street",
        source_url: "http://vinelink.com",
        county: "Gadsden County",
        phone: "(850) 627-9233",
        state: "FL",
        source_id: "fl-gcso",
        zip_code: "32351",
        email: "gadsdensheriff@tds.net",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Gilchrist County Sheriff’s Office",
        state_full: "Florida",
        address1: "9239 S US 129",
        source_url: "http://www.gcso.us/",
        county: "Gilchrist County",
        phone: "Office: 352-463-3410\n\nFax: 352-463-3183\n\nJail: 352",
        state: "FL",
        source_id: "fl-giso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Glades County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://www.gladessheriff.org/",
        county: "Glades County",
        phone: null,
        state: "FL",
        source_id: "fl-glds",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hamilton County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "",
        county: "Hamilton County",
        phone: null,
        state: "FL",
        source_id: "fl-hmltn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hardee County Sheriff's Office",
        state_full: "Florida",
        address1: "900 E. Summit St",
        source_url: "http://vinelink.com",
        county: "Hardee County",
        phone: "(863) 773-0304",
        state: "FL",
        source_id: "fl-hrso",
        zip_code: null,
        email: "alanier@hardeeso.com",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hendry County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://www.hendrysheriff.org/",
        county: "Hendry County",
        phone: null,
        state: "FL",
        source_id: "fl-hndr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hernando County Sheriff's Office",
        state_full: "Florida",
        address1: "16425 Spring Hill Dr",
        source_url: "http://www.hernandosheriff.org/",
        county: "Hernando",
        phone: "(352) 544-2334",
        state: "FL",
        source_id: "fl-hern",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Tampa",
        name: "Hillsborough County Sheriff's Office",
        state_full: "Florida",
        address1: "520 Falkenburg Road",
        source_url: "http://www.hcso.tampa.fl.us/Home.aspx",
        county: "Hillsborough County",
        phone: "(813) 247-8000",
        state: "FL",
        source_id: "fl-hcso",
        zip_code: "33619",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Holmes County Sheriff's Office",
        state_full: "Florida",
        address1: "3207 Lonny Lindsey Dr",
        source_url: "http://vinelink.com",
        county: "Holmes County",
        phone: "(850) 547-4421",
        state: "FL",
        source_id: "fl-hlso",
        zip_code: null,
        email: "information@holmescosheriff.org",
        has_mugshots: true,
    },
    {
        city: "Jacksonville",
        name: "Jacksonville Sheriff's Office",
        state_full: "Florida",
        address1: "500 E Adams St",
        source_url:
            "http://www.coj.net/Departments/Sheriffs+Office/Default.htm",
        county: "Duval County",
        phone: "(904) 630-5760",
        state: "FL",
        source_id: "fl-jso",
        zip_code: "32202",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Tavares",
        name: "Lake County Sheriff's Office",
        state_full: "Florida",
        address1: "551 West Main St",
        source_url: "https://www.lcso.org/inmates/index.php",
        county: "Lake County",
        phone: "(352) 742-4054",
        state: "FL",
        source_id: "fl-lake",
        zip_code: "32778",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Fort Myers",
        name: "Lee County Sheriff's Office",
        state_full: "Florida",
        address1: "2115 Dr Martin Luther King Jr Blvd",
        source_url: "http://www.sheriffleefl.org/",
        county: "Lee County",
        phone: "(239) 477-1500",
        state: "FL",
        source_id: "fl-lcso",
        zip_code: "33912",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Levy County Sheriff's Office",
        state_full: "Florida",
        address1: "9150 NE 80th Ave",
        source_url: "http://www.levyso.com/",
        county: "Levy County",
        phone: "(352) 486-5121",
        state: "FL",
        source_id: "fl-levy",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Palmetto",
        name: "Manatee County Sheriff's Office",
        state_full: "Florida",
        address1: "14470 Harlee Road",
        source_url:
            "http://www.manateesheriff.com/Public%20Interest/ArrestInquiry.aspx",
        county: "Manatee",
        phone: "(941) 747-3011",
        state: "FL",
        source_id: "fl-mana",
        zip_code: "34221",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Ocala",
        name: "Marion County Sheriff's Office",
        state_full: "Florida",
        address1: "700 NW 30th Avenue",
        source_url: "http://marionso.com/",
        county: "Marion County",
        phone: "(352) 351-8077",
        state: "FL",
        source_id: "fl-mcso",
        zip_code: "34475",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Martin County Sheriff's Office",
        state_full: "Florida",
        address1: "800 SE Monterey RoadStuart",
        source_url: "http://www.sheriff.martin.fl.us/",
        county: "Martin",
        phone: "772-220-7200",
        state: "FL",
        source_id: "fl-mart",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Miami",
        name: "Miami-Dade County Corrections",
        state_full: "Florida",
        address1: "1321 NW 13th Street",
        source_url: "http://www.miamidade.gov/corrections/",
        county: "Miami Dade County",
        phone: "(786) 263-4100",
        state: "FL",
        source_id: "fl-mdc",
        zip_code: "33125",
        email: "mdcrinfo@miamidade.gov",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Monroe County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://www.keysso.net/",
        county: "Monroe County",
        phone: null,
        state: "FL",
        source_id: "fl-monr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Nassau County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://nassauso.com/",
        county: "Nassau County",
        phone: null,
        state: "FL",
        source_id: "fl-ncso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Okeechobee",
        name: "Okeechobee County Jail",
        state_full: "Florida",
        address1: "PO Box 1397",
        source_url: "http://vinelink.com",
        county: "Okeechobee County",
        phone: "(863) 763-3117",
        state: "FL",
        source_id: "fl-ocj",
        zip_code: "34973",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Orlando",
        name: "Orange County Corrections",
        state_full: "Florida",
        address1: "3855 South John Young PKWY",
        source_url: "http://www.orangecountyfl.net/JailInmateServices.aspx",
        county: "Orange County",
        phone: "(407) 836-3400",
        state: "FL",
        source_id: "fl-occ",
        zip_code: "32839",
        email: "ccd@ocfl.net",
        has_mugshots: true,
    },
    {
        city: "Kissimmee",
        name: "Osceola County Corrections Department",
        state_full: "Florida",
        address1: "402 Simpson Rd",
        source_url:
            "http://www.osceola.org/correctionsdep/161-2522-0/corrections.cfm",
        county: "Osceola County",
        phone: "(407) 742-4444",
        state: "FL",
        source_id: "fl-occd",
        zip_code: "34744",
        email: "",
        has_mugshots: true,
    },
    {
        city: "West Palm Beach",
        name: "Palm Beach County Sheriff's Office",
        state_full: "Florida",
        address1: "3228 Gun Club Road",
        source_url: "http://www.pbso.org/",
        county: "Palm Beach County",
        phone: "(561) 688-4400",
        state: "FL",
        source_id: "fl-pbso",
        zip_code: "33406",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Land O' Lakes",
        name: "Pasco Sheriff's Office",
        state_full: "Florida",
        address1: "20101 Central Blvd",
        source_url: "http://www.pascosheriff.com",
        county: "Pasco County",
        phone: "(813) 996-6982",
        state: "FL",
        source_id: "fl-pso",
        zip_code: "34637",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Clearwater",
        name: "Pinellas County Sheriff's Office",
        state_full: "Florida",
        address1: "14400 49th Street North",
        source_url: "http://www.pcsoweb.com/",
        county: "Pinellas County",
        phone: "(727) 464-6415",
        state: "FL",
        source_id: "fl-pcso",
        zip_code: "33762",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Bartow",
        name: "Polk County Sheriff's Office",
        state_full: "Florida",
        address1: "2390 Bob Phillips Rd",
        source_url: "http://www.polksheriff.org",
        county: "Polk County",
        phone: "(863) 534-6123",
        state: "FL",
        source_id: "fl-pkso",
        zip_code: "33830",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Putnam County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://pcso.us/",
        county: "Putnam County",
        phone: null,
        state: "FL",
        source_id: "fl-putn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Santa Rosa County Sheriff's Office",
        state_full: "Florida",
        address1: "5850 E Milton Rd",
        source_url: "http://vinelink.com",
        county: "Santa Rosa County",
        phone: "(850) 983-1100",
        state: "FL",
        source_id: "fl-srcso",
        zip_code: null,
        email: "lskinner@srso.net",
        has_mugshots: true,
    },
    {
        city: "Sarasota",
        name: "Sarasota County Sheriff's Office",
        state_full: "Florida",
        address1: "2020 Main St",
        source_url: "http://www.sarasotasheriff.org/",
        county: "Sarasota County",
        phone: "(941) 861-4601",
        state: "FL",
        source_id: "fl-sara",
        zip_code: "34237",
        email: "srqsher@scgov.net",
        has_mugshots: true,
    },
    {
        city: "",
        name: "St. Johns County Sheriff's Office",
        state_full: "Florida",
        address1: "3955 Lewis Speedway",
        source_url: "http://www.sjso.org/",
        county: "St. Johns",
        phone: "904-824-8304",
        state: "FL",
        source_id: "fl-sjcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Fort Pierce",
        name: "St. Lucie County Sheriff's Office",
        state_full: "Florida",
        address1: "900 N. Rock Rd",
        source_url: "http://www.stluciesheriff.com/index.php",
        county: "St. Lucie County",
        phone: "(772) 462-3450",
        state: "FL",
        source_id: "fl-slcs",
        zip_code: "34945",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Sumter County Sheriff’s Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://sumtercountysheriff.org/",
        county: "Sumter County",
        phone: null,
        state: "FL",
        source_id: "fl-smtr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Suwannee County Sheriff's Office",
        state_full: "Florida",
        address1: null,
        source_url: "http://suwanneesheriff.com/",
        county: "Suwannee County",
        phone: null,
        state: "FL",
        source_id: "fl-suwa",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Daytona Beach",
        name: "Volusia County Corrections",
        state_full: "Florida",
        address1: "1300 Red John Road",
        source_url: "http://www.volusia.org/corrections/",
        county: "Volusia County",
        phone: "(386) 254-1555",
        state: "FL",
        source_id: "fl-vcc",
        zip_code: "32120",
        email: "",
        has_mugshots: true,
    },
    {
        city: "DeFuniak Springs",
        name: "Walton County Sheriff's Office",
        state_full: "Florida",
        address1: "752 Triple G Road",
        source_url: "http://www.waltonso.org",
        county: "Walton County",
        phone: "(850) 892-8186",
        state: "FL",
        source_id: "fl-wcso",
        zip_code: "32433",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Chatham County Sheriff's Office",
        state_full: "Georgia",
        address1: "28959 N Bermuda StPO Box 211",
        source_url: "http://www.chathamsheriff.org/",
        county: "Chatham",
        phone: "Office (229) 849-2555\nJail (229) 849-2480",
        state: "GA",
        source_id: "ga-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Marietta",
        name: "Cobb County Adult Detention Facilities",
        state_full: "Georgia",
        address1: "1825 County Services Parkway",
        source_url: "http://www.cobbsheriff.org/Inmate/jms_admit_review.asp",
        county: "Cobb County",
        phone: "(770) 499-4200",
        state: "GA",
        source_id: "ga-cobb",
        zip_code: "30008",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Columbia County Sheriff's Office",
        state_full: "Georgia",
        address1: "2273 County Camp Rd\nP. O. Box 310",
        source_url: "http://www.columbiacountyso.org/",
        county: "Columbia",
        phone: "(706) 541-1042",
        state: "GA",
        source_id: "ga-colum",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Coweta County Sheriff’s Office",
        state_full: "Georgia",
        address1: null,
        source_url: "http://www.coweta.ga.us/",
        county: "Coweta County",
        phone: null,
        state: "GA",
        source_id: "ga-cwt",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Dawsonville",
        name: "Dawson County Sheriff's Office",
        state_full: "Georgia",
        address1: "19 Tucker Ave",
        source_url: "http://www.dawsoncountysheriff.org/",
        county: "Dawson County",
        phone: "(706) 344-3535",
        state: "GA",
        source_id: "ga-dcso",
        zip_code: "30534",
        email: "tallen@dawsoncountysheriff.org",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Douglas County Sheriff's Office",
        state_full: "Georgia",
        address1: null,
        source_url: "https://www.sheriff.douglas.ga.us/",
        county: "Douglas County",
        phone: null,
        state: "GA",
        source_id: "ga-dgls",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Fayette County Sheriff's Office",
        state_full: "Georgia",
        address1: null,
        source_url: "http://www.fayettesheriff.org/",
        county: "Fayette County",
        phone: null,
        state: "GA",
        source_id: "ga-ftt",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Floyd County Sheriff's Office",
        state_full: "Georgia",
        address1: null,
        source_url: "http://www.floydsheriff.com/",
        county: "Floyd County",
        phone: null,
        state: "GA",
        source_id: "ga-fld",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Atlanta",
        name: "Fulton County Sheriff's Office",
        state_full: "Georgia",
        address1: "901 Rice St NW",
        source_url: "http://www.fultonsheriff.org/Inmate_Information.html",
        county: "Fulton County",
        phone: "(404) 613-2000",
        state: "GA",
        source_id: "ga-fcso",
        zip_code: "30318",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Lawrenceville",
        name: "Gwinnett County Sheriff's Department",
        state_full: "Georgia",
        address1: "2900 University Parkway",
        source_url: "http://www.gwinnettcountysheriff.com/",
        county: "Gwinnett County",
        phone: "(770) 619-6500",
        state: "GA",
        source_id: "ga-gcsd",
        zip_code: "30043",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Hall County Sheriff's Office",
        state_full: "Georgia",
        address1: "1700 Barber Rd",
        source_url: "http://www.hallcounty.org/",
        county: "Hall",
        phone: "Ph: 770-531-6904",
        state: "GA",
        source_id: "ga-hcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lumpkin County Sheriff's Office",
        state_full: "Georgia",
        address1: null,
        source_url: "http://lumpkincosheriff.com/",
        county: "Lumpkin County",
        phone: null,
        state: "GA",
        source_id: "ga-lcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Oconee County Sheriff's Office",
        state_full: "Georgia",
        address1: "1110 Experiment Station RoadBox 563",
        source_url: "http://www.oconeesheriff.org/",
        county: "Oconee County",
        phone: "Main Phone: 706-769-3930",
        state: "GA",
        source_id: "ga-ocso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Paulding County Sheriff's Office",
        state_full: "Georgia",
        address1: "25 Industrial Way North",
        source_url: "http://paulding.gov/index.aspx?NID=69",
        county: "Paulding",
        phone: "Jail (Main): 770-443-3030\nInmate Info: 770-443-303",
        state: "GA",
        source_id: "ga-pcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Walton County Sheriff’s Office",
        state_full: "Georgia",
        address1: null,
        source_url: "http://www.waltonsheriff.com/",
        county: "Walton County",
        phone: null,
        state: "GA",
        source_id: "ga-walt",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "White County Sheriff's Office",
        state_full: "Georgia",
        address1: "1210 Hulsey Rd",
        source_url: "http://whitecountysheriffsoffice.com/",
        county: "White County",
        phone: "(706)865-6370\n(706)865-5177",
        state: "GA",
        source_id: "ga-whte",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Whitfield County Sheriff's Office",
        state_full: "Georgia",
        address1: "805 Professional Blvd.",
        source_url: "http://www.whitfieldcountyga.com/",
        county: "Whitfield",
        phone: "(706)278-1233",
        state: "GA",
        source_id: "ga-wcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Boise",
        name: "Ada County Sheriff's Office",
        state_full: "Idaho",
        address1: "7200 Barrister Dr",
        source_url: "http://www.adasheriff.org/",
        county: "Ada County",
        phone: "(208) 577-3000",
        state: "ID",
        source_id: "id-acso",
        zip_code: "83704",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Council",
        name: "Adams Co Sheriff's Office",
        state_full: "Idaho",
        address1: "PO Box 64",
        source_url: "http://vinelink.com",
        county: "Adams County",
        phone: "(208) 253-4227",
        state: "ID",
        source_id: "id-amso",
        zip_code: "83612",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Bannock County Sheriff’s Office",
        state_full: "Idaho",
        address1: null,
        source_url: "http://www.bannockcounty.us/",
        county: "Bannock County",
        phone: null,
        state: "ID",
        source_id: "id-bann",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Saint Maries",
        name: "Benewah County Sheriff's Office",
        state_full: "Idaho",
        address1: "701 College Ave",
        source_url: "http://vinelink.com",
        county: "Benewah County",
        phone: "(208) 245-2555",
        state: "ID",
        source_id: "id-bnso",
        zip_code: "83861",
        email: "tmorris@benewahcounty.org",
        has_mugshots: true,
    },
    {
        city: "Blackfoot",
        name: "Bingham Co Sheriff's Office",
        state_full: "Idaho",
        address1: "501 N Maple St",
        source_url: "http://vinelink.com",
        county: "Bingham County",
        phone: "(208) 785-4440",
        state: "ID",
        source_id: "id-bcso",
        zip_code: "83221",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Blaine County Sheriff's Office",
        state_full: "Idaho",
        address1: "1650 Aviation DriveHailey,",
        source_url: "http://www.blainesheriff.com/default.asp",
        county: "Blaine County",
        phone: "(208) 788-5555",
        state: "ID",
        source_id: "id-blso",
        zip_code: null,
        email: "sheriff@blainecounty.org",
        has_mugshots: true,
    },
    {
        city: "Arco",
        name: "Butte Co Sheriff's Office",
        state_full: "Idaho",
        address1: "PO Box 130",
        source_url: "http://vinelink.com",
        county: "Butte County",
        phone: "(208) 527-8553",
        state: "ID",
        source_id: "id-btso",
        zip_code: "83213",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Canyon County Sheriff's Office",
        state_full: "Idaho",
        address1: "1115 Albany St Rm.137",
        source_url: "http://www.canyonco.org/",
        county: "Canyon",
        phone: "P 208-454-7510",
        state: "ID",
        source_id: "id-cany",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Burley",
        name: "Cassia Co Sheriff's Office",
        state_full: "Idaho",
        address1: "129 E 14th St",
        source_url: "http://vinelink.com",
        county: "Cassia County",
        phone: "(208) 878-1107",
        state: "ID",
        source_id: "id-ccso",
        zip_code: "83318",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mountain Home",
        name: "Elmore Co Sheriff's Office",
        state_full: "Idaho",
        address1: "125 S 5th E",
        source_url: "http://vinelink.com",
        county: "Elmore County",
        phone: "(208) 587-2121",
        state: "ID",
        source_id: "id-ecso",
        zip_code: "83647",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Saint Anthony",
        name: "Fremont Co Sheriff's Office",
        state_full: "Idaho",
        address1: "146 N 2nd W",
        source_url: "http://vinelink.com",
        county: "Fremont County",
        phone: "(208) 624-4482",
        state: "ID",
        source_id: "id-fcso",
        zip_code: "83445",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Gooding",
        name: "Gooding Co Sheriff's Office",
        state_full: "Idaho",
        address1: "624 Main St",
        source_url: "http://vinelink.com",
        county: "Gooding County",
        phone: "(208) 934-4421",
        state: "ID",
        source_id: "id-gcso",
        zip_code: "83330",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Rigby",
        name: "Jefferson Co Sheriff's Office",
        state_full: "Idaho",
        address1: "200 Courthouse Way",
        source_url: "http://vinelink.com",
        county: "Jefferson County",
        phone: "(208) 745-9210",
        state: "ID",
        source_id: "id-jfso",
        zip_code: "83442",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jerome",
        name: "Jerome County Sheriff's Office ",
        state_full: "Idaho",
        address1: "300 N Lincoln Ave",
        source_url: "http://vinelink.com",
        county: "Jerome County",
        phone: "(208) 324-7583",
        state: "ID",
        source_id: "id-jcso",
        zip_code: "83338",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Nezperce",
        name: "Lewis Co Sheriff's Office",
        state_full: "Idaho",
        address1: "PO Box 206",
        source_url: "http://vinelink.com",
        county: "Lewis County",
        phone: "(208) 937-2447",
        state: "ID",
        source_id: "id-lcso",
        zip_code: "83543",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Rexburg",
        name: "Madison County Sheriff's Office",
        state_full: "Idaho",
        address1: "145 E Main St",
        source_url: "http://www.madisonsheriff.com",
        county: "Madison County",
        phone: "(208) 356-5426",
        state: "ID",
        source_id: "id-mcso",
        zip_code: "83440",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Murphy",
        name: "Owyhee Co Sheriff's Office",
        state_full: "Idaho",
        address1: "PO Box 128",
        source_url: "http://vinelink.com",
        county: "Owyhee County",
        phone: "(208) 495-1154",
        state: "ID",
        source_id: "id-ocso",
        zip_code: "83650",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Payette",
        name: "Payette Co Sheriff's Office",
        state_full: "Idaho",
        address1: "1130 3rd Ave N",
        source_url: "http://vinelink.com",
        county: "Payette County",
        phone: "(208) 642-6008",
        state: "ID",
        source_id: "id-pyso",
        zip_code: "83661",
        email: "",
        has_mugshots: true,
    },
    {
        city: "American Falls",
        name: "Power Co Sheriff's Office",
        state_full: "Idaho",
        address1: "550 Gifford Ave",
        source_url: "http://vinelink.com",
        county: "Power County",
        phone: "(208) 226-2311",
        state: "ID",
        source_id: "id-pcso",
        zip_code: "83211",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wallace",
        name: "Shoshone County Sheriff's Office",
        state_full: "Idaho",
        address1: "717 Bank St.",
        source_url: "http://www.shoshoneso.com/",
        county: "Shoshone County",
        phone: "(208) 556-1114",
        state: "ID",
        source_id: "id-scso",
        zip_code: "83873",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Twin Falls",
        name: "Twin Falls Co Sheriff's Office",
        state_full: "Idaho",
        address1: "425 Shoshone St N",
        source_url: "http://vinelink.com",
        county: "Twin County",
        phone: "(208) 736-4177",
        state: "ID",
        source_id: "id-tfcso",
        zip_code: "83301",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Cascade",
        name: "Valley Co Sheriff's Office",
        state_full: "Idaho",
        address1: "PO Box 1350",
        source_url: "http://vinelink.com",
        county: "Valley County",
        phone: "(208) 382-7150",
        state: "ID",
        source_id: "id-vcso",
        zip_code: "83611",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Weiser",
        name: "Washington County Sheriff's Office",
        state_full: "Idaho",
        address1: "262 E Court St",
        source_url: "http://vinelink.com",
        county: "Washington County",
        phone: "(208) 414-2121",
        state: "ID",
        source_id: "id-wcso",
        zip_code: "83672",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Carlyle",
        name: "Clinton County Sheriff's Department",
        state_full: "Illinois",
        address1: "810 Franklin Street",
        source_url: "https://www.clintonco.illinois.gov/county_sheriff.htm",
        county: "Clinton County",
        phone: "(618) 594-4555",
        state: "IL",
        source_id: "il-ccsd",
        zip_code: "62231",
        email: "sheriff@clintonco.illinois.gov",
        has_mugshots: true,
    },
    {
        city: "Charleston",
        name: "Coles Co Sheriff's Office",
        state_full: "Illinois",
        address1: "701 7th St",
        source_url: "http://vinelink.com",
        county: "Coles County",
        phone: "(217) 348-0585",
        state: "IL",
        source_id: "il-clso",
        zip_code: "61920",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Chicago",
        name: "Cook County Sheriff's Office",
        state_full: "Illinois",
        address1: "2700 S California Ave",
        source_url: "http://www.cookcountysheriff.org/",
        county: "Cook County",
        phone: "(773) 674-7100",
        state: "IL",
        source_id: "il-ccso",
        zip_code: "60608",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Sycamore",
        name: "DeKalb County Sheriff's Office",
        state_full: "Illinois",
        address1: "150 North Main Street",
        source_url: "http://dekalbcounty.org/sheriff/",
        county: "DeKalb County",
        phone: "(815) 895-7260",
        state: "IL",
        source_id: "il-dcso",
        zip_code: "60178",
        email: "rscott@dekalbcounty.org",
        has_mugshots: true,
    },
    {
        city: "Wheaton",
        name: "Dupage County Sheriff's Office",
        state_full: "Illinois",
        address1: "501 N County Farm Rd",
        source_url: "http://inmatesearch.dupagesheriff.org/SIRIS_home.asp",
        county: "Dupage County",
        phone: "(630) 407-2201",
        state: "IL",
        source_id: "il-dupa",
        zip_code: "60187",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Morris",
        name: "Grundy County Sheriff's Police",
        state_full: "Illinois",
        address1: "111 E. Illinois Ave",
        source_url: "http://vinelink.com",
        county: "Grundy County",
        phone: "(815) 942-0336",
        state: "IL",
        source_id: "il-gcsp",
        zip_code: "60450",
        email: "reportacrime@sheriff1.com",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Kane County Sheriff's Office",
        state_full: "Illinois",
        address1: "37W755 Illinois Route 38\nSuite B",
        source_url: "http://www.kanesheriff.com/",
        county: "Kane",
        phone: "1-888-506-8407",
        state: "IL",
        source_id: "il-kcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Waukegan",
        name: "Lake County Sheriff's Office",
        state_full: "Illinois",
        address1: "25 S. Martin Luther King Jr Ave",
        source_url: "http://www.lakecountyil.gov/sheriff",
        county: "Lake County",
        phone: "(847) 377-4000",
        state: "IL",
        source_id: "il-lcso",
        zip_code: "60085",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Dixon",
        name: "Lee Co Sheriff's Office",
        state_full: "Illinois",
        address1: "316 S Hennepin Ave",
        source_url: "http://vinelink.com",
        county: "Lee County",
        phone: "(815) 284-5217",
        state: "IL",
        source_id: "il-leso",
        zip_code: "61021",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Woodstock",
        name: "McHenry Co Sheriff's Office",
        state_full: "Illinois",
        address1: "2200 N Seminary Ave",
        source_url: "http://vinelink.com",
        county: "McHenry County",
        phone: "(815) 338-2144",
        state: "IL",
        source_id: "il-mcso",
        zip_code: "60098",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Peoria County Sheriff's Office",
        state_full: "Illinois",
        address1: null,
        source_url: "http://www.peoriacounty.org/sheriff",
        county: "Peoria County",
        phone: null,
        state: "IL",
        source_id: "il-pcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pekin",
        name: "Tazewell County Sheriff's Office",
        state_full: "Illinois",
        address1: "101 S. Capitol St. ",
        source_url: "www.tazewell.com",
        county: "Tazewell County",
        phone: "(309) 478-5600",
        state: "IL",
        source_id: "il-tcso",
        zip_code: "61554",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Will County Sheriff's Office",
        state_full: "Illinois",
        address1: null,
        source_url: "https://www.willcosheriff.org/",
        county: "Will County",
        phone: null,
        state: "IL",
        source_id: "il-will",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Rockford",
        name: "Winnebago County Sheriff's Office",
        state_full: "Illinois",
        address1: "650 W State St",
        source_url:
            "http://winnebagosheriff.com/index.php?option=com_content&view=article&id=131&Itemid=150",
        county: "Winnebago County",
        phone: "(815) 319-6000",
        state: "IL",
        source_id: "il-wcso",
        zip_code: "61102",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Fort Wayne",
        name: "Allen County Sheriff's Office",
        state_full: "Indiana",
        address1: "715 S Calhoun St #101",
        source_url: "http://www.allencountysheriff.org/",
        county: "Allen County",
        phone: "(260) 449-7535",
        state: "IN",
        source_id: "in-acso",
        zip_code: "46802",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Boone County Sheriff's Office",
        state_full: "Indiana",
        address1: "1905 Indianapolis Ave",
        source_url: "http://www.boonecountyindianasheriff.com/",
        county: "Boone County",
        phone: "TEL\n765-482-1412\nFAX\n765-483-3370",
        state: "IN",
        source_id: "in-bcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Brown County Sheriff's Office",
        state_full: "Indiana",
        address1: "55 IN-46, Nashville",
        source_url:
            "http://browncounty-in.gov/Departments/SheriffsDepartment.aspx",
        county: "Brown County",
        phone: "(812) 988-6655",
        state: "IN",
        source_id: "in-brwn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jeffersonville",
        name: "Clark County Sheriff's Office",
        state_full: "Indiana",
        address1: "501 East Court Ave",
        source_url: "http://vinelink.com",
        county: "Clark County",
        phone: "(812) 283-4471",
        state: "IN",
        source_id: "in-clso",
        zip_code: "47130",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Clay County Sheriff's Department",
        state_full: "Indiana",
        address1: "20 S. Alabama St.Brazil",
        source_url: "http://www.claycountyinsheriff.com/",
        county: "Clay County",
        phone: "812-446-2535",
        state: "IN",
        source_id: "in-ccsd",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "English",
        name: "Crawford County Sheriff's Office",
        state_full: "Indiana",
        address1: "715 Judicial Plaza Dr. ",
        source_url: "http://vinelink.com",
        county: "Crawford County",
        phone: "(812) 338-2802",
        state: "IN",
        source_id: "in-ccso",
        zip_code: "47118 ",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lawrenceburg",
        name: "Dearborn County Sheriff's Office",
        state_full: "Indiana",
        address1: "301 W High St",
        source_url: "http://www.dearborncountysheriff.org/default.html",
        county: "Dearborn County",
        phone: "(812) 537-8730",
        state: "IN",
        source_id: "in-dcso",
        zip_code: "47025",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "DeKalb County Sheriff's Office",
        state_full: "Indiana",
        address1: "215 E 8th\nAuburn,",
        source_url: "http://www.co.dekalb.in.us/department/?fDD=33-0",
        county: "DeKalb County",
        phone: "(260) 925-3365",
        state: "IN",
        source_id: "in-dKso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Delaware County Sheriff’s Office",
        state_full: "Indiana",
        address1: "Address: 100 W Washington St, Muncie,",
        source_url: "http://www.delawarecountysheriff.org/",
        county: "Delaware County",
        phone: "(765) 747-7870",
        state: "IN",
        source_id: "in-dela",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jasper",
        name: "Dubois County Sheriff's Office",
        state_full: "Indiana",
        address1: "255 Brucke Strasse ",
        source_url: "http://www.indianasheriffs.org/ns/counties/Dubois.php",
        county: "Dubois County",
        phone: "(812) 482-3522",
        state: "IN",
        source_id: "in-dbso",
        zip_code: "47546",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Princeton",
        name: "Gibson County Jail",
        state_full: "Indiana",
        address1: "112 E Emerson St",
        source_url: "http://www.gibsoncountysheriff.com/",
        county: "Gibson County",
        phone: "(812) 385-3496",
        state: "IN",
        source_id: "in-gbcj",
        zip_code: "47670",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hamilton County Sheriff's Office",
        state_full: "Indiana",
        address1: "18100 Cumberland Rd, Noblesville",
        source_url: "http://www.hamiltoncounty.in.gov/",
        county: "Hamilton",
        phone: " (317) 773-1872",
        state: "IN",
        source_id: "in-hc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hancock County Sheriff's Office",
        state_full: "Indiana",
        address1: "123 E Main St, Greenfield",
        source_url: "http://sheriffweb.hancockcoingov.org/",
        county: "Hancock County",
        phone: " (317) 477-1147",
        state: "IN",
        source_id: "in-hcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hendricks County Sheriff's Office",
        state_full: "Indiana",
        address1: "925 E Main St",
        source_url:
            "http://www.co.hendricks.in.us/department/index.php?structureid=59",
        county: "Hendricks County",
        phone: "(317) 745-6269",
        state: "IN",
        source_id: "in-hend",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Howard County Sheriff's Office",
        state_full: "Indiana",
        address1: "1800 W Markland Ave, Kokomo",
        source_url:
            "http://www.howardcountyin.gov/justice-system/sheriffs-department/",
        county: "Howard County",
        phone: "(765) 456-2020",
        state: "IN",
        source_id: "in-howa",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Jackson County Sheriff’s Office",
        state_full: "Indiana",
        address1: null,
        source_url: "http://jacksoncountysheriffin.org/",
        county: "Jackson County",
        phone: null,
        state: "IN",
        source_id: "in-jack",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Jasper County Sheriff's Office",
        state_full: "Indiana",
        address1: "2171 N McKinley Ave, Rensselaer",
        source_url: "http://vinelink.com",
        county: "Jasper County",
        phone: "(219) 866-7344",
        state: "IN",
        source_id: "in-jcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Knox County Sheriff's Office",
        state_full: "Indiana",
        address1: "2375 South Old Decker Rd, Vincennes",
        source_url:
            "https://www.facebook.com/pages/Knox-County-Sheriff's-Department/205787206159263",
        county: "Knox County",
        phone: " (812) 882-7660",
        state: "IN",
        source_id: "in-kcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "La Porte County Sheriff's Office",
        state_full: "Indiana",
        address1: "809 State Suite 201a",
        source_url: "http://www.laportecountysheriff.com/",
        county: "La Porte County",
        phone: "(219) 326-7700",
        state: "IN",
        source_id: "in-lpc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Madison County Sheriff's Office",
        state_full: "Indiana",
        address1: null,
        source_url: "http://www.sheriffofmadisoncounty.com/",
        county: "Madison County",
        phone: null,
        state: "IN",
        source_id: "in-mdsn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Indianapolis",
        name: "Marion County Sheriff's Office",
        state_full: "Indiana",
        address1: "40 S Alabama St",
        source_url:
            "http://www.indy.gov/eGov/County/MCSD/Pages/Marion-County-Jail.aspx",
        county: "Marion County",
        phone: "(317) 327-1574",
        state: "IN",
        source_id: "in-mcso",
        zip_code: "46204",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Peru",
        name: "Miami County Sheriff's Office",
        state_full: "Indiana",
        address1: "1104 W 200 N",
        source_url: "http://www.miamicountyin.gov/Sheriff",
        county: "Miami County",
        phone: "(765) 472-1322",
        state: "IN",
        source_id: "in-mmso",
        zip_code: "46970",
        email: "tmiller@miamicountyin.gov",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Montgomery County Sheriff's Office",
        state_full: "Indiana",
        address1: " 600 Memorial Dr, Crawfordsville",
        source_url: "http://www.montgomerycosheriff.net/",
        county: "Montgomery County",
        phone: "(765) 362-3740",
        state: "IN",
        source_id: "in-mont",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Kentland",
        name: "Newton County Jail",
        state_full: "Indiana",
        address1: "304 E Seymour St",
        source_url: "http://www.newtoncountysheriff.com/",
        county: "Newton County",
        phone: "(219) 474-3999",
        state: "IN",
        source_id: "in-ntcj",
        zip_code: "47951",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Perry County Sheriff's Office",
        state_full: "Indiana",
        address1: "2211 Herrman Street, Tell City",
        source_url: "http://perry.indianasheriffs.net/",
        county: "Perry County",
        phone: "(812) 547-2441",
        state: "IN",
        source_id: "in-perr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Pulaski County Sheriff's Office",
        state_full: "Indiana",
        address1: "110 E Meridian St, Winamac",
        source_url: "http://gov.pulaskionline.org/sheriff/",
        county: "Pulaski County",
        phone: "(574) 946-6655",
        state: "IN",
        source_id: "in-pcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Putnam County Sheriff's Office",
        state_full: "Indiana",
        address1: "13 Keightly Rd, Greencastle",
        source_url: "http://www.putnam-sheriff.org/",
        county: "Putnam County",
        phone: " (765) 653-1810",
        state: "IN",
        source_id: "in-putn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Versailles",
        name: "Ripley County Sheriff's Office",
        state_full: "Indiana",
        address1: "210 N Monroe St",
        source_url: "http://www.ripleycounty.com/sheriff/",
        county: "Ripley County",
        phone: "(812) 689-3437",
        state: "IN",
        source_id: "in-rcso",
        zip_code: "47042",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Scott County Sheriff's Office",
        state_full: "Indiana",
        address1: "111 S 1st St, Scottsburg",
        source_url: "http://www.scottcountysheriff.org/",
        county: "Scott County Office",
        phone: "(812) 752-8400",
        state: "IN",
        source_id: "in-scot",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Scott County Sheriff's Office",
        state_full: "Indiana",
        address1: "111 S 1st St, Scottsburg",
        source_url: "http://www.scottcountysheriff.org/",
        county: "Scott County",
        phone: "(812) 752-8400",
        state: "IN",
        source_id: "in-scso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "St. Joseph County Police Department",
        state_full: "Indiana",
        address1: "401 W Sample St",
        source_url: "http://www.sjcpd.org/",
        county: "St. Joseph",
        phone: "(574) 245-6500",
        state: "IN",
        source_id: "in-sjc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Starke County Sheriff’s Office",
        state_full: "Indiana",
        address1: null,
        source_url: "http://www.co.starke.in.us/",
        county: "Starke County",
        phone: null,
        state: "IN",
        source_id: "in-strk",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Steuben County Sheriff's Office",
        state_full: "Indiana",
        address1: "206 E Gale St, Angola",
        source_url: "http://vinelink.com",
        county: "Steuben County",
        phone: "(260) 668-1000",
        state: "IN",
        source_id: "in-stso",
        zip_code: null,
        email: "sheriff@co.steuben.in.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Tippecanoe County Sheriff's Office",
        state_full: "Indiana",
        address1: "2640 Duncan Rd\nLafayette",
        source_url: "http://www.tippecanoe.in.gov/359/Sheriffs-Department",
        county: "Tippecanoe",
        phone: "765-423-9388",
        state: "IN",
        source_id: "in-tcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Evansville",
        name: "Vanderburgh County Sheriff's Office",
        state_full: "Indiana",
        address1: "3500 N. Harlan Ave",
        source_url: "http://www.vanderburghsheriff.com/Default.htm",
        county: "Vanderburgh County",
        phone: "(812) 421-6200",
        state: "IN",
        source_id: "in-vnso",
        zip_code: "47711",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hillsdale",
        name: "Vermillion County Sheriff's Office",
        state_full: "Indiana",
        address1: "1888 S State Road 63",
        source_url: "http://www.vcsheriff.com",
        county: "Vermillion County",
        phone: "(765) 492-3838",
        state: "IN",
        source_id: "in-vrso",
        zip_code: "47854",
        email: "sheriff@vcsheriff.com",
        has_mugshots: true,
    },
    {
        city: "Terre Haute",
        name: "Vigo County Sheriff's Office",
        state_full: "Indiana",
        address1: "201 Cherry St. ",
        source_url: "http://sheriff.vigocounty.in.gov",
        county: "Vigo County",
        phone: "(812) 462-3226",
        state: "IN",
        source_id: "in-vcso",
        zip_code: "47807",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Columbia City",
        name: "Whitley County Sheriff's Office",
        state_full: "Indiana",
        address1: "101 W Market St",
        source_url: "http://www.whitleygov.com/department/?fDD=22-0",
        county: "Whitley County",
        phone: "(260) 244-7119",
        state: "IN",
        source_id: "in-wcso",
        zip_code: "46725",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Corning",
        name: "Adams Co Sheriff's Office",
        state_full: "Iowa",
        address1: "901 Davis Ave",
        source_url: "http://vinelink.com",
        county: "Adams County",
        phone: "(641) 322-4444",
        state: "IA",
        source_id: "ia-amso",
        zip_code: "50841",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Centerville",
        name: "Appanoose County Jail",
        state_full: "Iowa",
        address1: "1125 W. Van Buren St",
        source_url: "http://www.appanoosecosheriff.org/",
        county: "Appanoose County",
        phone: "(641) 437-7106",
        state: "IA",
        source_id: "ia-apcj",
        zip_code: "52544",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Audubon",
        name: "Audubon Co Sheriff's Office",
        state_full: "Iowa",
        address1: "318 Leroy St",
        source_url: "http://vinelink.com",
        county: "Audubon County",
        phone: "(712) 563-2631",
        state: "IA",
        source_id: "ia-acso",
        zip_code: "50025",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Waterloo",
        name: "Black Hawk Co Sheriff's Office",
        state_full: "Iowa",
        address1: "225 E 6th St",
        source_url: "http://vinelink.com",
        county: "Black County",
        phone: "(319) 291-2587",
        state: "IA",
        source_id: "ia-bhcso",
        zip_code: "50703",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Boone",
        name: "Boone Co Sheriff's Office",
        state_full: "Iowa",
        address1: "1019 W Mamie Eisenhower Ave",
        source_url: "http://vinelink.com",
        county: "Boone County",
        phone: "(515) 433-0524",
        state: "IA",
        source_id: "ia-bnso",
        zip_code: "50036",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Waverly",
        name: "Bremer County Sheriff's Office",
        state_full: "Iowa",
        address1: "111 4th St NE",
        source_url: "http://www.bremercountysheriff.org/homecontactus.html",
        county: "Bremer County",
        phone: "(319) 352-5400",
        state: "IA",
        source_id: "ia-brso",
        zip_code: "50677",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Independence",
        name: "Buchanan Co Sheriff's Office",
        state_full: "Iowa",
        address1: "210 5th Ave NE",
        source_url: "http://vinelink.com",
        county: "Buchanan County",
        phone: "(319) 334-2568",
        state: "IA",
        source_id: "ia-bcso",
        zip_code: "50644",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Storm Lake",
        name: "Buena Vista Co Sheriff's Office",
        state_full: "Iowa",
        address1: "215 E 5th St",
        source_url: "http://vinelink.com",
        county: "Buena County",
        phone: "(712) 749-2530",
        state: "IA",
        source_id: "ia-bvcso",
        zip_code: "50588",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Allison",
        name: "Butler Co Sheriff's Office",
        state_full: "Iowa",
        address1: "PO Box 345",
        source_url: "http://vinelink.com",
        county: "Butler County",
        phone: "(319) 267-2410",
        state: "IA",
        source_id: "ia-btso",
        zip_code: "50602",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Carroll",
        name: "Carroll Co Sheriff's Office",
        state_full: "Iowa",
        address1: "114 E 6th St",
        source_url: "http://vinelink.com",
        county: "Carroll County",
        phone: "(712) 792-4393",
        state: "IA",
        source_id: "ia-ccso",
        zip_code: "51401",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Atlantic",
        name: "Cass County Sheriff's Office",
        state_full: "Iowa",
        address1: "5 W 7th St",
        source_url: "http://www.casscountyiowa.us/offices/sheriff/",
        county: "Cass County",
        phone: "(712) 243-2206",
        state: "IA",
        source_id: "ia-csso",
        zip_code: "50022",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Tipton",
        name: "Cedar Co Sheriff's Office",
        state_full: "Iowa",
        address1: "400 Cedar St",
        source_url: "http://vinelink.com",
        county: "Cedar County",
        phone: "(563) 886-2121",
        state: "IA",
        source_id: "ia-cdso",
        zip_code: "52772",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mason City",
        name: "Cerro Gordo Co Sheriff's Office",
        state_full: "Iowa",
        address1: "17262 Lark Ave",
        source_url: "http://vinelink.com",
        county: "Cerro County",
        phone: "(641) 421-3000",
        state: "IA",
        source_id: "ia-cgcso",
        zip_code: "50401",
        email: null,
        has_mugshots: true,
    },
    {
        city: "New Hampton",
        name: "Chickasaw Co Sheriff's Office",
        state_full: "Iowa",
        address1: "116 N Chestnut Ave",
        source_url: "http://vinelink.com",
        county: "Chickasaw County",
        phone: "(641) 394-3121",
        state: "IA",
        source_id: "ia-chso",
        zip_code: "50659",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Osceola",
        name: "Clarke Co Sheriff's Office",
        state_full: "Iowa",
        address1: "220 Town Line Rd",
        source_url: "http://vinelink.com",
        county: "Clarke County",
        phone: "(641) 342-2914",
        state: "IA",
        source_id: "ia-ckso",
        zip_code: "50213",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Clay County Sheriff's Office",
        state_full: "Iowa",
        address1: "3121 W 4th St",
        source_url: "http://vinelink.com",
        county: "Clay County",
        phone: "(712) 262-3221",
        state: "IA",
        source_id: "ia-clyso",
        zip_code: null,
        email: "clayshrf@co.clay.ia.us",
        has_mugshots: true,
    },
    {
        city: "Saint Olaf",
        name: "Clayton Co Sheriff's Office",
        state_full: "Iowa",
        address1: "22680 230th St",
        source_url: "http://vinelink.com",
        county: "Clayton County",
        phone: "(563) 245-2422",
        state: "IA",
        source_id: "ia-cyso",
        zip_code: "52072",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Denison",
        name: "Crawford Co Sheriff's Dept",
        state_full: "Iowa",
        address1: "1202 Broadway",
        source_url: "http://vinelink.com",
        county: "Crawford County",
        phone: "(712) 263-2146",
        state: "IA",
        source_id: "ia-ccsd",
        zip_code: "51442",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Adel",
        name: "Dallas Co Sheriff's Dept",
        state_full: "Iowa",
        address1: "PO Box 187",
        source_url: "http://vinelink.com",
        county: "Dallas County",
        phone: "(515) 993-4771",
        state: "IA",
        source_id: "ia-dcsd",
        zip_code: "50003",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bloomfield",
        name: "Davis Co Sheriff's Office",
        state_full: "Iowa",
        address1: "110 W Franklin St",
        source_url: "http://vinelink.com",
        county: "Davis County",
        phone: "(641) 664-2385",
        state: "IA",
        source_id: "ia-dcso",
        zip_code: "52537",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Leon",
        name: "Decatur County Jail",
        state_full: "Iowa",
        address1: "207 N Main St",
        source_url: "http://vinelink.com",
        county: "Decatur County",
        phone: "TEL\n641-446-4111\nFAX\n641-446-6990",
        state: "IA",
        source_id: "ia-dcj",
        zip_code: "50144",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Manchester",
        name: "Delaware Co Sheriff's Office",
        state_full: "Iowa",
        address1: "PO Box 116",
        source_url: "http://vinelink.com",
        county: "Delaware County",
        phone: "(563) 927-3135",
        state: "IA",
        source_id: "ia-dlso",
        zip_code: "52057",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Burlington",
        name: "Des Moines Co Sheriff's Office",
        state_full: "Iowa",
        address1: "512 N Main St",
        source_url: "http://vinelink.com",
        county: "Des County",
        phone: "(319) 753-8212",
        state: "IA",
        source_id: "ia-dmcso",
        zip_code: "52601",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Dubuque County Sheriff’s Office",
        state_full: "Iowa",
        address1: null,
        source_url: "http://cityofdubuque.org/",
        county: "Dubuque County",
        phone: null,
        state: "IA",
        source_id: "ia-dubu",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Estherville",
        name: "Emmett Co Sheriff's Office",
        state_full: "Iowa",
        address1: "114 N 6th St",
        source_url: "http://vinelink.com",
        county: "Emmett County",
        phone: "(712) 362-2639",
        state: "IA",
        source_id: "ia-ecso",
        zip_code: "51334",
        email: null,
        has_mugshots: true,
    },
    {
        city: "West Union",
        name: "Fayette County Sheriff's Office",
        state_full: "Iowa",
        address1: "220 N Industrial Pkwy",
        source_url: "http://vinelink.com",
        county: "Fayette County",
        phone: "563) 422-6067",
        state: "IA",
        source_id: "ia-fcso",
        zip_code: "52175",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Grundy Center",
        name: "Grundy Co Sheriff's Office",
        state_full: "Iowa",
        address1: "705 8th St",
        source_url: "http://vinelink.com",
        county: "Grundy County",
        phone: "(319) 824-6933",
        state: "IA",
        source_id: "ia-gcso",
        zip_code: "50638",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Hardin County Sheriff's Office",
        state_full: "Iowa",
        address1: "1116 14th Ave\nEldora, IA 5062",
        source_url: "http://www.hardincountyia.gov",
        county: "Hardin County",
        phone: "(641) 939-8189",
        state: "IA",
        source_id: "ia-hrso",
        zip_code: null,
        email: "sheriff@hardincountyia.gov",
        has_mugshots: true,
    },
    {
        city: "Logan",
        name: "Harrison Co Sheriff's Office",
        state_full: "Iowa",
        address1: "111 S 1st Ave",
        source_url: "http://vinelink.com",
        county: "Harrison County",
        phone: "(712) 644-2244",
        state: "IA",
        source_id: "ia-hcso",
        zip_code: "51546",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mount Pleasant",
        name: "Henry Co Sheriff's Dept",
        state_full: "Iowa",
        address1: "106 E Clay St",
        source_url: "http://vinelink.com",
        county: "Henry County",
        phone: "(319) 385-2712",
        state: "IA",
        source_id: "ia-hcsd",
        zip_code: "52641",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Humboldt",
        name: "Humboldt Co Sheriff's Office",
        state_full: "Iowa",
        address1: "430 Sumner Ave",
        source_url: "http://vinelink.com",
        county: "Humboldt County",
        phone: "(515) 332-2471",
        state: "IA",
        source_id: "ia-hmso",
        zip_code: "50548",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Des Moines",
        name: "Iowa Department of Corrections",
        state_full: "Iowa",
        address1: "510 E 12th St",
        source_url: "http://vinelink.com",
        county: "Iowa Department",
        phone: "(319) 753-8212",
        state: "IA",
        source_id: "ia-idc",
        zip_code: "50319",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Maquoketa",
        name: "Jackson Co Sheriff's Office",
        state_full: "Iowa",
        address1: "104 S Niagara St",
        source_url: "http://vinelink.com",
        county: "Jackson County",
        phone: "(563) 652-3312",
        state: "IA",
        source_id: "ia-jcso",
        zip_code: "52060",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Newton",
        name: "Jasper Co Sheriff's Office",
        state_full: "Iowa",
        address1: "2300 Law Center Dr",
        source_url: "http://vinelink.com",
        county: "Jasper County",
        phone: "(641) 792-5912",
        state: "IA",
        source_id: "ia-jsso",
        zip_code: "50208",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Fairfield",
        name: "Jefferson Co Sheriff's Office",
        state_full: "Iowa",
        address1: "1200 W Grimes Ave",
        source_url: "http://vinelink.com",
        county: "Jefferson County",
        phone: "(641) 472-4146",
        state: "IA",
        source_id: "ia-jfso",
        zip_code: "52556",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Iowa City",
        name: "Johnson Co Sheriff's Office",
        state_full: "Iowa",
        address1: "511 S Capitol St",
        source_url: "http://vinelink.com",
        county: "Johnson County",
        phone: "(319) 356-6020",
        state: "IA",
        source_id: "ia-jhso",
        zip_code: "52240",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Sigourney",
        name: "Keokuk Co Sheriff's Office",
        state_full: "Iowa",
        address1: "204 S Stone St",
        source_url: "http://vinelink.com",
        county: "Keokuk County",
        phone: "(641) 622-2727",
        state: "IA",
        source_id: "ia-kcso",
        zip_code: "52591",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Montrose",
        name: "Lee Co Sheriff's Office",
        state_full: "Iowa",
        address1: "2530 255th St",
        source_url: "http://vinelink.com",
        county: "Lee County",
        phone: "(319) 372-1152",
        state: "IA",
        source_id: "ia-leso",
        zip_code: "52639",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Cedar Rapids",
        name: "Linn Co Sheriff's Office",
        state_full: "Iowa",
        address1: "310 2nd Ave SW",
        source_url: "http://vinelink.com",
        county: "Linn County",
        phone: "(319) 398-3521",
        state: "IA",
        source_id: "ia-lnso",
        zip_code: "52404",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wapello",
        name: "Louisa Co Sheriff's Office",
        state_full: "Iowa",
        address1: "503 Franklin St",
        source_url: "http://vinelink.com",
        county: "Louisa County",
        phone: "(319) 523-3511",
        state: "IA",
        source_id: "ia-lcso",
        zip_code: "52653",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Chariton",
        name: "Lucas Co Sheriff's Office",
        state_full: "Iowa",
        address1: "1023 Linden Ave",
        source_url: "http://vinelink.com",
        county: "Lucas County",
        phone: "(641) 774-5083",
        state: "IA",
        source_id: "ia-lsso",
        zip_code: "50049",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Winterset",
        name: "Madison Co Sheriff's Office",
        state_full: "Iowa",
        address1: "1012 N 1st St",
        source_url: "http://vinelink.com",
        county: "Madison County",
        phone: "(515) 462-3575",
        state: "IA",
        source_id: "ia-mdso",
        zip_code: "50273",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Knoxville",
        name: "Marion County Sheriff's Office",
        state_full: "Iowa",
        address1: "211 N Godfrey Ln",
        source_url: "http://co.marion.ia.us/offices/sheriff/",
        county: "Marion County",
        phone: "(641) 828-2220",
        state: "IA",
        source_id: "ia-mrso",
        zip_code: "50138",
        email: "mciasheriff@co.marion.ia.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Marshall County Sheriff's Office",
        state_full: "Iowa",
        address1: null,
        source_url: "http://www.marshallcountysheriff.com/",
        county: "Marshall County",
        phone: null,
        state: "IA",
        source_id: "ia-mrshll",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Glenwood",
        name: "Mills Co Sheriff's Office",
        state_full: "Iowa",
        address1: "23 N Vine St",
        source_url: "http://vinelink.com",
        county: "Mills County",
        phone: "(712) 527-4337",
        state: "IA",
        source_id: "ia-mlso",
        zip_code: "51534",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Osage",
        name: "Mitchell Co Sheriff's Office",
        state_full: "Iowa",
        address1: "211 S 6th St",
        source_url: "http://vinelink.com",
        county: "Mitchell County",
        phone: "(641) 732-4740",
        state: "IA",
        source_id: "ia-mtso",
        zip_code: "50461",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Onawa",
        name: "Monona Co Sheriff's Office",
        state_full: "Iowa",
        address1: "909 7th St",
        source_url: "http://vinelink.com",
        county: "Monona County",
        phone: "(712) 433-1414",
        state: "IA",
        source_id: "ia-mnso",
        zip_code: "51040",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Albia",
        name: "Monroe Co Sheriff's Office",
        state_full: "Iowa",
        address1: "103 2nd Ave W",
        source_url: "http://vinelink.com",
        county: "Monroe County",
        phone: "(641) 932-7815",
        state: "IA",
        source_id: "ia-mcso",
        zip_code: "52531",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Red Oak",
        name: "Montgomery Co Sheriff's Office",
        state_full: "Iowa",
        address1: "106 W Coolbaugh St",
        source_url: "http://vinelink.com",
        county: "Montgomery County",
        phone: "(712) 623-5107",
        state: "IA",
        source_id: "ia-mgso",
        zip_code: "51566",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Muscatine",
        name: "Muscatine Co Sheriff's Office",
        state_full: "Iowa",
        address1: "400 Walnut St",
        source_url: "http://vinelink.com",
        county: "Muscatine County",
        phone: "(563) 262-4190",
        state: "IA",
        source_id: "ia-msso",
        zip_code: "52761",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Primghar",
        name: "O'Brien Co Sheriff's Office",
        state_full: "Iowa",
        address1: "PO Box 260",
        source_url: "http://vinelink.com",
        county: "O'Brien County",
        phone: "(712) 757-3415",
        state: "IA",
        source_id: "ia-ocso",
        zip_code: "51245",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clarinda",
        name: "Page Co Sheriff's Office",
        state_full: "Iowa",
        address1: "323 N 15th St",
        source_url: "http://vinelink.com",
        county: "Page County",
        phone: "(712) 542-5193",
        state: "IA",
        source_id: "ia-pgso",
        zip_code: "51632",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Le Mars",
        name: "Plymouth Co Sheriff's Office",
        state_full: "Iowa",
        address1: "451 14th Ave NE",
        source_url: "http://vinelink.com",
        county: "Plymouth County",
        phone: "(712) 546-8191",
        state: "IA",
        source_id: "ia-plso",
        zip_code: "51031",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pocahontas",
        name: "Pocahontas Co Sheriff's Office",
        state_full: "Iowa",
        address1: "99 Court Sq",
        source_url: "http://vinelink.com",
        county: "Pocahontas County",
        phone: "(712) 335-3308",
        state: "IA",
        source_id: "ia-phso",
        zip_code: "50574",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Des Moines",
        name: "Polk County Sheriff's Office",
        state_full: "Iowa",
        address1: "1985 NE 51st Pl",
        source_url: "http://www.polkcountyiowa.gov/sheriff/",
        county: "Polk County",
        phone: "(515) 323-5400",
        state: "IA",
        source_id: "ia-pcso",
        zip_code: "50313",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Council Bluffs",
        name: "Pottawattamie Co Sheriff's Office",
        state_full: "Iowa",
        address1: "1400 Big Lake Rd",
        source_url: "http://vinelink.com",
        county: "Pottawattamie County",
        phone: "(712) 890-2200",
        state: "IA",
        source_id: "ia-ptso",
        zip_code: "51501",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Montezuma",
        name: "Poweshiek Co Sheriff's Office",
        state_full: "Iowa",
        address1: "201 S 3rd St",
        source_url: "http://vinelink.com",
        county: "Poweshiek County",
        phone: "(641) 623-5679",
        state: "IA",
        source_id: "ia-pwso",
        zip_code: "50171",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Davenport",
        name: "Scott County Jail",
        state_full: "Iowa",
        address1: "00 W 4th St",
        source_url: "http://www.scottcountyiowa.com/sheriff/inmates.php",
        county: "Scott County",
        phone: "(563) 326-8750",
        state: "IA",
        source_id: "ia-sccj",
        zip_code: "52801",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Harlan",
        name: "Shelby Co Sheriff's Office",
        state_full: "Iowa",
        address1: "612 Court St",
        source_url: "http://vinelink.com",
        county: "Shelby County",
        phone: "(712) 755-5026",
        state: "IA",
        source_id: "ia-scso",
        zip_code: "51537",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Nevada",
        name: "Story County Jail",
        state_full: "Iowa",
        address1: "900 Sixth St",
        source_url: "http://www.storycountyiowa.gov/index.aspx?NID=211",
        county: "Story County",
        phone: "(515) 382-6581",
        state: "IA",
        source_id: "ia-stcj",
        zip_code: "50201",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Toledo",
        name: "Tama Co Sheriff's Office",
        state_full: "Iowa",
        address1: "100 N Main St",
        source_url: "http://vinelink.com",
        county: "Tama County",
        phone: "(641) 484-3760",
        state: "IA",
        source_id: "ia-tcso",
        zip_code: "52342",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Keosauqua",
        name: "Van Buren Co Sheriff's Office",
        state_full: "Iowa",
        address1: "PO Box 474",
        source_url: "http://vinelink.com",
        county: "Van County",
        phone: "(319) 293-3426",
        state: "IA",
        source_id: "ia-vbcso",
        zip_code: "52565",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Ottumwa",
        name: "Wapello Co Sheriff's Office",
        state_full: "Iowa",
        address1: "330 W 2nd St",
        source_url: "http://vinelink.com",
        county: "Wapello County",
        phone: "(641) 684-4350",
        state: "IA",
        source_id: "ia-wpso",
        zip_code: "52501",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Corydon",
        name: "Wayne County Sheriff's Office",
        state_full: "Iowa",
        address1: "207 N. LaFayette St",
        source_url: "http://vinelink.com",
        county: "Wayne County",
        phone: "(641) 872-1566",
        state: "IA",
        source_id: "ia-wyso",
        zip_code: "50060",
        email: "mlewis@wayneso.org",
        has_mugshots: true,
    },
    {
        city: "Decorah",
        name: "Winneshiek Co Sheriff's Office",
        state_full: "Iowa",
        address1: "400 W Claiborne Dr",
        source_url: "http://vinelink.com",
        county: "Winneshiek County",
        phone: "(563) 382-4268",
        state: "IA",
        source_id: "ia-wcso",
        zip_code: "52101",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Woodbury County Sheriff's Office",
        state_full: "Iowa",
        address1: "407 7th St",
        source_url: "http://www.woodburycountyiowa.gov/departments/sheriff",
        county: "Woodbury",
        phone: "(712) 279-6040",
        state: "IA",
        source_id: "ia-wood",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clarion",
        name: "Wright Co Sheriff's Office",
        state_full: "Iowa",
        address1: "719 2nd St SW",
        source_url: "http://vinelink.com",
        county: "Wright County",
        phone: "(515) 532-3722",
        state: "IA",
        source_id: "ia-wrso",
        zip_code: "50525",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Cherokee County Sheriff’s Office",
        state_full: "Kansas",
        address1: null,
        source_url: "http://cherokeecountykssheriff.com/",
        county: "Cherokee County",
        phone: null,
        state: "KS",
        source_id: "ks-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Pratt County Sheriff’s Office",
        state_full: "Kansas",
        address1: "303 S. Oak Street",
        source_url: "http://prattcountysheriff.com/",
        county: "Pratt County",
        phone: "620/672-4133",
        state: "KS",
        source_id: "ks-pcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Sedgwick County Sheriff's Office",
        state_full: "Kansas",
        address1: "141 West ElmWichita",
        source_url: "https://ssc.sedgwickcounty.org/",
        county: "Sedgwick",
        phone: "316-383-7205",
        state: "KS",
        source_id: "ks-scso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Columbia",
        name: "Adair County Sheriff's Office",
        state_full: "Kentucky",
        address1: "424 Public Square",
        source_url:
            "http://www.adaircounty.ky.gov/cogov/departments/sheriff.htm",
        county: "Adair County",
        phone: "(270) 384-4703",
        state: "KY",
        source_id: "ky-acso",
        zip_code: "42728",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Scottsville",
        name: "Allen County Detention Center",
        state_full: "Kentucky",
        address1: "196 Wood Street",
        source_url: "http://www.allencountydetention.com/index2.html",
        county: "Allen County",
        phone: "(270) 237-3226",
        state: "KY",
        source_id: "ky-acdc",
        zip_code: "42164",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Wickliffe",
        name: "Ballard County Detention Center",
        state_full: "Kentucky",
        address1: "132 Mayolo Ave",
        source_url: "http://www.ballardcountydetention.com/",
        county: "Ballard County",
        phone: "(270) 335-3691",
        state: "KY",
        source_id: "ky-bcdc",
        zip_code: "42087",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Glasgow",
        name: "Barren County Detention Center",
        state_full: "Kentucky",
        address1: "201 Samson Street",
        source_url: "http://www.barrencountykyjail.com/",
        county: "Barren County",
        phone: "(270) 651-8806",
        state: "KY",
        source_id: "ky-bndc",
        zip_code: "42141",
        email: "bcjmatt@glasgow-ky.com",
        has_mugshots: true,
    },
    {
        city: "Pineville",
        name: "Bell County Jail",
        state_full: "Kentucky",
        address1: "PO Box 478",
        source_url: "http://vinelink.com",
        county: "Bell County",
        phone: "(606) 337-2170",
        state: "KY",
        source_id: "ky-bcj",
        zip_code: "40977",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Paintsville",
        name: "Big Sandy Regional Detention Center",
        state_full: "Kentucky",
        address1: "904 3rd St",
        source_url: "http://www.bsrdc.com/",
        county: "Big Sandy Regional",
        phone: "(606) 297-5124",
        state: "KY",
        source_id: "ky-bsdc",
        zip_code: "41240",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Paris",
        name: "Bourbon County Detention Center",
        state_full: "Kentucky",
        address1: "101 Legion Drive",
        source_url: "http://www.bourboncountydetention.com/",
        county: "Bourbon County",
        phone: "(859) 987-2137",
        state: "KY",
        source_id: "ky-budc",
        zip_code: "40361",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Catlettsburg",
        name: "Boyd County Detention Center",
        state_full: "Kentucky",
        address1: "2714 Panol Street",
        source_url: "http://www.boydcountydetention.com/",
        county: "Boyd County",
        phone: "(606) 739-4224",
        state: "KY",
        source_id: "ky-bydc",
        zip_code: "41129",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Danville",
        name: "Boyle County Detention Center",
        state_full: "Kentucky",
        address1: "1862 S Danville Bypass",
        source_url: "http://www.boylecountydetention.com/",
        county: "Boyle County",
        phone: "(859) 238-1127",
        state: "KY",
        source_id: "ky-bldc",
        zip_code: "40422",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Hardinsburg",
        name: "Breckenridge County Sheriff's Office",
        state_full: "Kentucky",
        address1: "208 S Main Street",
        source_url: "http://breckinridgecountysheriff.net",
        county: "Breckenridge County",
        phone: "(270) 756-2361",
        state: "KY",
        source_id: "ky-bcso",
        zip_code: "40143",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hardinsburg",
        name: "Breckinridge County Detention Center",
        state_full: "Kentucky",
        address1: "500 Glen Nash Ln",
        source_url: "http://www.breckinridgecountydetention.com/",
        county: "Breckinridge County",
        phone: "(270) 756-6244",
        state: "KY",
        source_id: "ky-bddc",
        zip_code: "40143",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Shepherdsville",
        name: "Bullitt County Sheriff's Office",
        state_full: "Kentucky",
        address1: "300 S. Buckman St",
        source_url: "http://bullitt.kysheriff.org",
        county: "Bullitt County",
        phone: "(502) 543-2514",
        state: "KY",
        source_id: "ky-blso",
        zip_code: "40165",
        email: "crimetip@bcky.org",
        has_mugshots: true,
    },
    {
        city: "Murray",
        name: "Calloway County Jail",
        state_full: "Kentucky",
        address1: "310 North 4th Street",
        source_url: "http://www.callowaycountyjail.com/",
        county: "Calloway County",
        phone: "(270) 753-6150",
        state: "KY",
        source_id: "ky-ccj",
        zip_code: "42071",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Newport",
        name: "Campbell County Detention Center",
        state_full: "Kentucky",
        address1: "601 Central Avenue",
        source_url: "http://ccdc.campbellcounty.ky.gov/",
        county: "Campbell County",
        phone: "(859) 431-4611",
        state: "KY",
        source_id: "ky-cpdc",
        zip_code: "41071",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Carrollton",
        name: "Carroll County Detention Center",
        state_full: "Kentucky",
        address1: "800 Clay Street",
        source_url: "http://www.carrolldetention.com/index2.html",
        county: "Carroll County",
        phone: "(502) 732-9499",
        state: "KY",
        source_id: "ky-ccdc",
        zip_code: "41008",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Grayson",
        name: "Carter County Detention Center",
        state_full: "Kentucky",
        address1: "13 Crossbar Road",
        source_url: "http://www.cartercountydetention.com/",
        county: "Carter County",
        phone: "(606) 475-1606",
        state: "KY",
        source_id: "ky-cadc",
        zip_code: "41143",
        email: "r.boggs@cartercountydetention.com",
        has_mugshots: true,
    },
    {
        city: "Liberty",
        name: "Casey County Jail",
        state_full: "Kentucky",
        address1: "169 Courthouse Square",
        source_url: "http://www.caseycountyjail.com/",
        county: "Casey County",
        phone: "(606) 787-9097",
        state: "KY",
        source_id: "ky-cyj",
        zip_code: "42539",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Hopkinsville",
        name: "Christian County Sheriff's Department",
        state_full: "Kentucky",
        address1: "216 West 7th Street",
        source_url: "http://vinelink.com",
        county: "Christian County",
        phone: "(270) 887-4143",
        state: "KY",
        source_id: "ky-ccsd",
        zip_code: "42240",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Winchester",
        name: "Clark County Detention Center",
        state_full: "Kentucky",
        address1: "30 Wall St",
        source_url: "http://www.clarkdetentioncenter.com/",
        county: "Clark County",
        phone: "(859) 745-0270",
        state: "KY",
        source_id: "ky-ckdc",
        zip_code: "40391",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Manchester",
        name: "Clay County Detention Center",
        state_full: "Kentucky",
        address1: "67 Shamrock Road",
        source_url: "http://www.claycountydetention.com/",
        county: "Clay County",
        phone: "(606) 598-2133",
        state: "KY",
        source_id: "ky-cldc",
        zip_code: "40962",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Marion",
        name: "Crittenden County Detention Center",
        state_full: "Kentucky",
        address1: "208 W Carlisle St",
        source_url: "http://www.crittendencountydetention.com/",
        county: "Crittenden County",
        phone: "(270) 965-3185",
        state: "KY",
        source_id: "ky-ctdc",
        zip_code: "42064",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Owensboro",
        name: "Daviess County Detention Center",
        state_full: "Kentucky",
        address1: "3337 Hwy 60 E",
        source_url: "http://www.daviesscojail.org/",
        county: "Daviess County",
        phone: "(270) 685-8466",
        state: "KY",
        source_id: "ky-dcdc",
        zip_code: "42303",
        email: "dosborne@daviesscojail.org",
        has_mugshots: true,
    },
    {
        city: "Frankfort",
        name: "Franklin County Regional Jail",
        state_full: "Kentucky",
        address1: "400 Coffee Tree Road",
        source_url: "http://www.franklincountyregionaljail.com/",
        county: "Franklin County",
        phone: "(502) 875-7398",
        state: "KY",
        source_id: "ky-fcrj",
        zip_code: "40604",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Hickman",
        name: "Fulton County Detention Center",
        state_full: "Kentucky",
        address1: "2010 South Seventh Street",
        source_url: "http://www.fultonkyjail.com/",
        county: "Fulton County",
        phone: "(270) 236-2405",
        state: "KY",
        source_id: "ky-fcdc",
        zip_code: "42050",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Williamstown",
        name: "Grant County Jail",
        state_full: "Kentucky",
        address1: "212 Barnes Rd",
        source_url:
            "http://grantcounty.ky.gov/departments_agencies/detention_center.htm",
        county: "Grant County",
        phone: "(859) 824-5191",
        state: "KY",
        source_id: "ky-gcj",
        zip_code: "41097",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Leitchfield",
        name: "Grayson County Sheriff's Department",
        state_full: "Kentucky",
        address1: "44 Public Square ",
        source_url: "http://www.gcso.net",
        county: "Grayson County",
        phone: "(270) 259-3024",
        state: "KY",
        source_id: "ky-gcsd",
        zip_code: "42754",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Greenup",
        name: "Greenup County Sheriff's Department",
        state_full: "Kentucky",
        address1: "303 Main St",
        source_url: "http://greenupsheriffdept.4t.com/",
        county: "Greenup County",
        phone: "(606) 473-7037",
        state: "KY",
        source_id: "ky-grsd",
        zip_code: "41144",
        email: "gcsoso1@yahoo.com",
        has_mugshots: true,
    },
    {
        city: "Elizabethtown",
        name: "Hardin County Detention Center",
        state_full: "Kentucky",
        address1: "100 Lawson Blvd",
        source_url: "http://www.hcdetention.com/",
        county: "Hardin County",
        phone: "(270) 765-7717",
        state: "KY",
        source_id: "ky-hcdc",
        zip_code: "42701",
        email: "Jhall.hcdc@hcky.org",
        has_mugshots: true,
    },
    {
        city: "Evarts",
        name: "Harlan County Detention Center",
        state_full: "Kentucky",
        address1: "6000 Hwy 38",
        source_url: "http://www.harlandc.com/",
        county: "Harlan County",
        phone: "(606) 837- 0096",
        state: "KY",
        source_id: "ky-hndc",
        zip_code: "40828",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Munfordville",
        name: "Hart County Jail",
        state_full: "Kentucky",
        address1: "520 AA Whitman Lane",
        source_url: "http://www.hartcountykyjail.com/",
        county: "Hart County",
        phone: "(270) 524-2571",
        state: "KY",
        source_id: "ky-hcj",
        zip_code: "42765",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Henderson",
        name: "Henderson County Detention Center",
        state_full: "Kentucky",
        address1: "380 Borax Drive",
        source_url: "http://www.hendersoncountydetention.com/index2.html",
        county: "Henderson County",
        phone: "(270) 827- 5560",
        state: "KY",
        source_id: "ky-hedc",
        zip_code: "42419",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Clinton",
        name: "Hickman County Sheriff's Office",
        state_full: "Kentucky",
        address1: "110 East Clay Street",
        source_url: "http://www.hickmancounty.ky.gov/elected/sheriff.htm",
        county: "Hickman County",
        phone: "(270) 653-2241",
        state: "KY",
        source_id: "ky-hcso",
        zip_code: "42031",
        email: "hcso2006@hotmail.com",
        has_mugshots: true,
    },
    {
        city: "Madisonville",
        name: "Hopkins County Jail",
        state_full: "Kentucky",
        address1: "2250 Laffoon Trail",
        source_url: "http://www.hopkinscountyjail.com/",
        county: "Hopkins County",
        phone: "(270) 821- 6704",
        state: "KY",
        source_id: "ky-hocj",
        zip_code: "42431",
        email: "",
        has_mugshots: true,
    },
    {
        city: "McKee",
        name: "Jackson County Detention Center",
        state_full: "Kentucky",
        address1: "1893 McCammon Ridge Rd",
        source_url: "http://www.jacksoncountydetentioncenter.com/",
        county: "Jackson County",
        phone: "(606) 287-4551",
        state: "KY",
        source_id: "ky-jcdc",
        zip_code: "40447",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Nicholasville",
        name: "Jessamine County Sheriff's Office",
        state_full: "Kentucky",
        address1: "101 S 2nd St",
        source_url: "http://jessaminetax.org/",
        county: "Jessamine County",
        phone: "(859) 885-4139",
        state: "KY",
        source_id: "ky-jcso",
        zip_code: "40356",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Covington",
        name: "Kenton County Detention Center",
        state_full: "Kentucky",
        address1: "3000 Decker Crane Lane",
        source_url: "http://www.kentoncountyjail.org/",
        county: "Kenton County",
        phone: "(859) 392-1400",
        state: "KY",
        source_id: "ky-kcdc",
        zip_code: "41017",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Barbourville",
        name: "Knox County Sheriff's Office",
        state_full: "Kentucky",
        address1: "401 Court Square",
        source_url: "http://www.knoxcounty-sheriff.com/index.html",
        county: "Knox County",
        phone: "(606) 546-3181",
        state: "KY",
        source_id: "ky-kcso",
        zip_code: "40906",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hodgenville",
        name: "Larue County Detention Center",
        state_full: "Kentucky",
        address1: "209 W. High St",
        source_url: "http://www.laruecountydetention.com/",
        county: "Larue County",
        phone: "(270) 358-9876",
        state: "KY",
        source_id: "ky-lcdc",
        zip_code: "42748",
        email: "",
        has_mugshots: true,
    },
    {
        city: "London",
        name: "Laurel County Correctional Center",
        state_full: "Kentucky",
        address1: "204 W 4th St",
        source_url: "http://www.laurelcountycorrections.com/",
        county: "Laurel County",
        phone: "(606) 878-9431",
        state: "KY",
        source_id: "ky-lccc",
        zip_code: "40741",
        email: "info@laurelcorrections.com",
        has_mugshots: true,
    },
    {
        city: "Hyden",
        name: "Leslie County Detention Center",
        state_full: "Kentucky",
        address1: "2125 Highway 118",
        source_url: "http://www.lesliecountydetention.com/",
        county: "Leslie County",
        phone: "(606) 672-3548",
        state: "KY",
        source_id: "ky-ledc",
        zip_code: "41749",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Whitesburg",
        name: "Letcher County Sheriff's Office",
        state_full: "Kentucky",
        address1: "6 Broadway St",
        source_url: "http://www.letcherso.com/",
        county: "Letcher County",
        phone: "(606) 633-2293",
        state: "KY",
        source_id: "ky-lcso",
        zip_code: "41858",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Vanceburg",
        name: "Lewis County Detention Center",
        state_full: "Kentucky",
        address1: "26 Railroad St",
        source_url: "http://www.lewiscountydetention.com/",
        county: "Lewis County",
        phone: "(606) 796-2312",
        state: "KY",
        source_id: "ky-lsdc",
        zip_code: "41179",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Stanford",
        name: "Lincoln County Regional Jail",
        state_full: "Kentucky",
        address1: "100 Lancaster Street",
        source_url: "http://www.lincolnregionaljail.com/",
        county: "Lincoln County",
        phone: "(606) 365-4580",
        state: "KY",
        source_id: "ky-lnrj",
        zip_code: "40484",
        email: "wdgooch@bellsouth.net",
        has_mugshots: true,
    },
    {
        city: "Russellville",
        name: "Logan County Jail",
        state_full: "Kentucky",
        address1: "304 W 3rd St",
        source_url: "http://vinelink.com",
        county: "Logan County",
        phone: "(270) 726-3696",
        state: "KY",
        source_id: "ky-lcj",
        zip_code: "42276",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Richmond",
        name: "Madison County Detention Center",
        state_full: "Kentucky",
        address1: "107 W Irvine St",
        source_url: "http://www.madisoncountydetention.com/",
        county: "Madison County",
        phone: "(859) 624-4710",
        state: "KY",
        source_id: "ky-mcdc",
        zip_code: "40475",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Lebanon",
        name: "Marion County Detention Center",
        state_full: "Kentucky",
        address1: "201 Warehouse Rd",
        source_url: "http://www.marioncountydetention.com/",
        county: "Marion County",
        phone: "(270) 692-5802",
        state: "KY",
        source_id: "ky-mndc",
        zip_code: "40033",
        email: "brady@mcjail.org",
        has_mugshots: true,
    },
    {
        city: "Benton",
        name: "Marshall County Sheriff's Office",
        state_full: "Kentucky",
        address1: "52 Judicial Drive",
        source_url:
            "http://marshallcounty.ky.gov/Departments/Sheriff/Sheriff.html",
        county: "Marshall County",
        phone: "(270) 527-3112",
        state: "KY",
        source_id: "ky-mcso",
        zip_code: "42025",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Maysville",
        name: "Mason County Detention Center",
        state_full: "Kentucky",
        address1: "702 U.S. 68 Business",
        source_url: "http://www.masoncountydetention.com/",
        county: "Mason County",
        phone: "(606) 564-3621",
        state: "KY",
        source_id: "ky-madc",
        zip_code: "41056",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Paducah",
        name: "McCracken County Jail",
        state_full: "Kentucky",
        address1: "400 South 7th Street",
        source_url: "http://www.mccrackencountyjailer.com/inmates.htm",
        county: "McCracken County",
        phone: "(270) 444-4730",
        state: "KY",
        source_id: "ky-mccj",
        zip_code: "42003",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Pine Knot",
        name: "McCreary County Dention Center",
        state_full: "Kentucky",
        address1: "330 Federal Way",
        source_url: "http://www.mccrearycountydetention.com/",
        county: "McCreary County",
        phone: "(606) 354-7000",
        state: "KY",
        source_id: "ky-mrdc",
        zip_code: "42635",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Brandenburg",
        name: "Meade County Sheriff's Department",
        state_full: "Kentucky",
        address1: "516 Hillcrest Dr. Ste. 14",
        source_url: "http://www.meadecountyso.com/index.html",
        county: "Meade County",
        phone: "(270) 422-4937",
        state: "KY",
        source_id: "ky-mcsd",
        zip_code: "40108",
        email: "msheriff@bbtel.com",
        has_mugshots: true,
    },
    {
        city: "Tompkinsville",
        name: "Monroe County Correctional Center",
        state_full: "Kentucky",
        address1: "204 N Crawford St",
        source_url: "http://www.monroecountyjail.net/",
        county: "Monroe County",
        phone: "(270) 487.5594",
        state: "KY",
        source_id: "ky-mecc",
        zip_code: "42167",
        email: "dfox@monroecountyjail.net",
        has_mugshots: true,
    },
    {
        city: "Mount Sterling",
        name: "Montgomery County Regional Jail",
        state_full: "Kentucky",
        address1: "751 Chenault Ln",
        source_url: "http://www.montgomerycountyregionaljail.com/",
        county: "Montgomery County",
        phone: "(859) 498-8747",
        state: "KY",
        source_id: "ky-mgrj",
        zip_code: "40353",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Greenville",
        name: "Muhlenberg County Sheriff's Department",
        state_full: "Kentucky",
        address1: "109 E Main Cross St",
        source_url: "http://www.muhlenbergcountysheriff.com/index.html",
        county: "Muhlenberg County",
        phone: "(270) 338-3345",
        state: "KY",
        source_id: "ky-mhsd",
        zip_code: "42345",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bardstown",
        name: "Nelson County Sheriff's Office",
        state_full: "Kentucky",
        address1: "210 Plaza Dr",
        source_url: "http://www.nelsoncountysheriff.com/",
        county: "Nelson County",
        phone: "(502) 348-1840",
        state: "KY",
        source_id: "ky-ncso",
        zip_code: "40004",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Ohio County Detention Center",
        state_full: "Kentucky",
        address1: "108 E. Washington Street",
        source_url: "http://www.ohiocountydetention.com/",
        county: "Ohio County",
        phone: "jail: 270-298-4455\nsheriff: 270-298-4444\npolice: 2",
        state: "KY",
        source_id: "ky-ocdc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "La Grange",
        name: "Oldham County Jail",
        state_full: "Kentucky",
        address1: "102 W Main St",
        source_url: "http://www.oldhamcountyjail.com/",
        county: "Oldham County",
        phone: "(502) 222-3500",
        state: "KY",
        source_id: "ky-ocj",
        zip_code: "40031",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Hazard",
        name: "Perry County Sheriff's Office",
        state_full: "Kentucky",
        address1: "481 Main St ",
        source_url: "http://www.perrysheriff.org/",
        county: "Perry County",
        phone: "(606) 439-4523",
        state: "KY",
        source_id: "ky-prso",
        zip_code: "41701",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pikeville",
        name: "Pike County Detention Center",
        state_full: "Kentucky",
        address1: "172 Division St",
        source_url: "http://www.pikecountydetention.com/",
        county: "Pike County",
        phone: "(606) 432-6232",
        state: "KY",
        source_id: "ky-pcdc",
        zip_code: "41501",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Stanton",
        name: "Powell County Sheriff's Office",
        state_full: "Kentucky",
        address1: "524 Washington St",
        source_url: "http://www.powellcountyky.us/sheriff.asp",
        county: "Powell County",
        phone: "(606) 663-2226",
        state: "KY",
        source_id: "ky-pcso",
        zip_code: "40380",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Somerset",
        name: "Pulaski County Detention Center",
        state_full: "Kentucky",
        address1: "300 Hail Knob Rd",
        source_url: "http://www.pulaskijail.com/",
        county: "Pulaski County",
        phone: "(606) 678-4315",
        state: "KY",
        source_id: "ky-pudc",
        zip_code: "42503",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Mount Vernon",
        name: "Rockcastle County Detention Center",
        state_full: "Kentucky",
        address1: "205 East Main Street",
        source_url: "http://www.rockcastlecountydetention.com/",
        county: "Rockcastle County",
        phone: "(606) 256-2178",
        state: "KY",
        source_id: "ky-rcdc",
        zip_code: "40456",
        email: "rcdc.jmiller@windstream.net",
        has_mugshots: true,
    },
    {
        city: "Morehead",
        name: "Rowan County Sheriff's Department",
        state_full: "Kentucky",
        address1: "600 West Main Street",
        source_url: "http://www.rowancountysheriff.net",
        county: "Rowan County",
        phone: "(606) 784-5446",
        state: "KY",
        source_id: "ky-rcsd",
        zip_code: "40351",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Russell Springs",
        name: "Russell County Detention Center",
        state_full: "Kentucky",
        address1: "228 Brian Walters Dr",
        source_url: "http://www.russellcountydetention.com/",
        county: "Russell County",
        phone: "(270) 858-6900",
        state: "KY",
        source_id: "ky-rsdc",
        zip_code: "42642",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Georgetown",
        name: "Scott County Sheriff's Department",
        state_full: "Kentucky",
        address1: "120 N. Hamilton",
        source_url: "http://www.scottsheriffky.com/",
        county: "Scott County",
        phone: "(502) 863-7855",
        state: "KY",
        source_id: "ky-stsd",
        zip_code: "40324",
        email: "info@kysheriff.org",
        has_mugshots: true,
    },
    {
        city: "Shelbyville",
        name: "Shelby County Sheriff's Department",
        state_full: "Kentucky",
        address1: "501 Main Street",
        source_url: "http://www.sckyso.com/index.html",
        county: "Shelby County",
        phone: "(502) 633-4324",
        state: "KY",
        source_id: "ky-scsd",
        zip_code: "40065",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Franklin",
        name: "Simpson County Detention Center",
        state_full: "Kentucky",
        address1: "217 E. Kentucky St",
        source_url: "http://www.simpsoncojail.org/",
        county: "Simpson County",
        phone: "(270) 586-5351",
        state: "KY",
        source_id: "ky-scdc",
        zip_code: "42134",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Campbellsville",
        name: "Taylor County Detention Center",
        state_full: "Kentucky",
        address1: "120 South Central Ave",
        source_url: "http://www.taylorcountydetention.com/",
        county: "Taylor County",
        phone: "(270) 789-1909",
        state: "KY",
        source_id: "ky-tcdc",
        zip_code: "42718",
        email: "captainburress@taylorcounty.us",
        has_mugshots: true,
    },
    {
        city: "Beattyville",
        name: "Three Forks Regional Jail",
        state_full: "Kentucky",
        address1: "2475 Center St",
        source_url: "http://www.threeforksregionaljail.com/",
        county: "Three Forks",
        phone: "(606) 464-2598",
        state: "KY",
        source_id: "ky-tfrj",
        zip_code: "41311",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Morganfield",
        name: "Union County Jail",
        state_full: "Kentucky",
        address1: "120 S Court Street",
        source_url: "http://www.unioncojail.com/",
        county: "Union County",
        phone: "(270) 389-1581",
        state: "KY",
        source_id: "ky-ucj",
        zip_code: "42437",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Bowling Green",
        name: "Warren County Sheriff's Office",
        state_full: "Kentucky",
        address1: "429 East 10th Street, Suite 102",
        source_url: "http://www.warrencountykysheriff.com",
        county: "Warren County",
        phone: "(270) 842-1633",
        state: "KY",
        source_id: "ky-wcso",
        zip_code: "42101",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Wayne County Sheriff’s Office",
        state_full: "Kentucky",
        address1: "55 N. Main Street\nSuite 104",
        source_url: "http://www.waynecountydetentionky.com/",
        county: "Wayne County",
        phone: "(606) 348-5416 - Office\n(606) 348-3284 - Fax",
        state: "KY",
        source_id: "ky-wayne",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Dixon",
        name: "Webster County Jail",
        state_full: "Kentucky",
        address1: "141 Stegal Street",
        source_url: "http://www.webstercountyjail.org/",
        county: "Webster County",
        phone: "(270) 639-7020",
        state: "KY",
        source_id: "ky-wcj",
        zip_code: "42409",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Williamsburg",
        name: "Whitley County Detention Center",
        state_full: "Kentucky",
        address1: "1439 Kentucky 92",
        source_url: "http://www.whitleycountydetention.com/",
        county: "Whitley County",
        phone: "(606) 549-6013",
        state: "KY",
        source_id: "ky-wcdc",
        zip_code: "40769",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Versailles",
        name: "Woodford County Detention Center",
        state_full: "Kentucky",
        address1: "204 Beasly Drive",
        source_url: "http://www.woodfordcountydetention.com/",
        county: "Woodford County",
        phone: "(859) 873-3196",
        state: "KY",
        source_id: "ky-wddc",
        zip_code: "40383",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Allen Parish County Sheriff’s Office",
        state_full: "Louisiana",
        address1: null,
        source_url: "http://allenparishso.org/",
        county: "Allen Parish County",
        phone: null,
        state: "LA",
        source_id: "la-acso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Ascension Parish County Sheriff's Office",
        state_full: "Louisiana",
        address1: null,
        source_url: "http://www.ascensionsheriff.com/",
        county: "Ascension Parish County",
        phone: null,
        state: "LA",
        source_id: "la-apso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Caddo Parish Sheriff's Office",
        state_full: "Louisiana",
        address1: "PHYSICAL ADDRESS\n1101 Forum Dr",
        source_url: "http://www.caddosheriff.org/",
        county: "Caddo Parish",
        phone: "Correctional Center: 318-677-5254\nShreveport City ",
        state: "LA",
        source_id: "la-cadd",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lafourche Parish County Sheriff's Office",
        state_full: "Louisiana",
        address1: null,
        source_url: "http://www.lpso.net/",
        county: "Lafourche Parish County",
        phone: null,
        state: "LA",
        source_id: "la-lpcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Ouachita Parish County Sheriff's Office",
        state_full: "Louisiana",
        address1: null,
        source_url: "http://www.opso.net/",
        county: "Ouachita Parish County",
        phone: null,
        state: "LA",
        source_id: "la-opso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Rapides Parish County Sheriff's Office",
        state_full: "Louisiana",
        address1: null,
        source_url: "http://www.rpso.org/",
        county: "Rapides Parish County",
        phone: null,
        state: "LA",
        source_id: "la-rpso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "St. James Parish County Sheriff's Office",
        state_full: "Louisiana",
        address1: null,
        source_url: "http://lumpkincosheriff.com/",
        county: "St. James Parish County",
        phone: null,
        state: "LA",
        source_id: "la-sjpso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "St. Mary Parish County Sheriff’s Office",
        state_full: "Louisiana",
        address1: null,
        source_url: "http://stmaryso.com/",
        county: "St. Mary Parish County",
        phone: null,
        state: "LA",
        source_id: "la-smpso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Baltimore County Sheriff's Office",
        state_full: "Maryland",
        address1: "720 Bosley Avenue",
        source_url: "http://www.baltimorecountymd.gov/Agencies/corrections/",
        county: "Baltimore County",
        phone: "410-512-3200",
        state: "MD",
        source_id: "md-bcso",
        zip_code: "21204",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Dorchester County Sheriff's Office",
        state_full: "Maryland",
        address1: "829 Fieldcrest Rd",
        source_url: "http://www.docosheriff.com/",
        county: "Dorchester County",
        phone: "(410) 228-8101",
        state: "MD",
        source_id: "md-dcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Montgomery County Sheriff's Office",
        state_full: "Maryland",
        address1: "22880 Whelan Lane",
        source_url: "https://vinelink.com",
        county: "Montgomery County",
        phone: "Phone: (240) 773-9700",
        state: "MD",
        source_id: "md-mont",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "",
        name: "Prince George's County",
        state_full: "Maryland",
        address1: "13400 Dille Drive",
        source_url: "http://inmatelookup.princegeorgescountymd.gov/IML",
        county: "Prince Georges County",
        phone: "301-952-4800",
        state: "MD",
        source_id: "md-pg",
        zip_code: "20772",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Alpena",
        name: "Alpena Co Sheriff's Dept",
        state_full: "Michigan",
        address1: "320 Johnson St",
        source_url: "http://vinelink.com",
        county: "Alpena County",
        phone: "(989) 354-9830",
        state: "MI",
        source_id: "mi-acsd",
        zip_code: "49707",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hastings",
        name: "Barry Co Sheriff's Dept",
        state_full: "Michigan",
        address1: "1212 W State St",
        source_url: "http://vinelink.com",
        county: "Barry County",
        phone: "(269) 948-4805",
        state: "MI",
        source_id: "mi-bcsd",
        zip_code: "49058",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Saint Johns",
        name: "Clinton Co Sheriff's Dept",
        state_full: "Michigan",
        address1: "1347 E Townsend Rd",
        source_url: "http://vinelink.com",
        county: "Clinton County",
        phone: "(989) 224-5200",
        state: "MI",
        source_id: "mi-ccsd",
        zip_code: "48879",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Eaton County Sheriff's Office",
        state_full: "Michigan",
        address1: "1025 Independence Blvd.",
        source_url: "http://vinelink.com",
        county: "Eaton County",
        phone: "(517) 543-5433",
        state: "MI",
        source_id: "mi-ecso",
        zip_code: null,
        email: "crimetips@eatoncounty.org",
        has_mugshots: true,
    },
    {
        city: "Mount Pleasant",
        name: "Isabella Co Sheriff's Dept",
        state_full: "Michigan",
        address1: "207 Court St",
        source_url: "http://vinelink.com",
        county: "Isabella County",
        phone: "(989) 772-5911",
        state: "MI",
        source_id: "mi-icsd",
        zip_code: "48858",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Jackson County Sheriff's Office",
        state_full: "Michigan",
        address1: "1995 Chanter Rd",
        source_url: "http://www.co.jackson.ms.us/officials/sheriff/",
        county: "Jackson",
        phone: "TEL\n517-768-1609\nFAX\n517-768-1606",
        state: "MI",
        source_id: "mi-jcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Grand Rapids",
        name: "Kent County Sheriff's Department",
        state_full: "Michigan",
        address1: "703 Ball Avenue NE",
        source_url: "http://www.accesskent.com/Sheriff/",
        county: "Kent County",
        phone: "(616) 632-6400",
        state: "MI",
        source_id: "mi-kcsd",
        zip_code: "49503",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lake County Sheriff's Office",
        state_full: "Michigan",
        address1: "1153 Michigan Ave.",
        source_url: "https://www.facebook.com/lakecomichsheriff",
        county: "Lake County",
        phone: "(231) 745-2712",
        state: "MI",
        source_id: "mi-lcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mount Clemens",
        name: "Macomb County Sheriff's Office",
        state_full: "Michigan",
        address1: "43565 Elizabeth Rd",
        source_url: "http://www.macomb-sheriff.com/",
        county: "Macomb County",
        phone: "(586)-307-9612",
        state: "MI",
        source_id: "mi-mcso",
        zip_code: "48043",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Big Rapids",
        name: "Mecosta Co Sheriff's Office",
        state_full: "Michigan",
        address1: "225 S Stewart Ave",
        source_url: "http://vinelink.com",
        county: "Mecosta County",
        phone: "(231) 592-0150",
        state: "MI",
        source_id: "mi-mtso",
        zip_code: "49307",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Menominee",
        name: "Menominee Co Sheriff's Dept",
        state_full: "Michigan",
        address1: "831 10th Ave",
        source_url: "http://vinelink.com",
        county: "Menominee County",
        phone: "(906) 863-4441",
        state: "MI",
        source_id: "mi-mnsd",
        zip_code: "49858",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Midland",
        name: "Midland Co Sheriff's Dept",
        state_full: "Michigan",
        address1: "2727 Rodd St",
        source_url: "http://vinelink.com",
        county: "Midland County",
        phone: "(989) 839-4626",
        state: "MI",
        source_id: "mi-mcsd",
        zip_code: "48640",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Monroe County Sheriff's Office",
        state_full: "Michigan",
        address1: "100 E. Second Street",
        source_url: "https://vinelink.com",
        county: "Monroe County",
        phone: "734-240-7401",
        state: "MI",
        source_id: "mi-monr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Stanton",
        name: "Montcalm Co Sheriff's Office",
        state_full: "Michigan",
        address1: "659 N State St",
        source_url: "http://vinelink.com",
        county: "Montcalm County",
        phone: "(989) 831-7590",
        state: "MI",
        source_id: "mi-mnso",
        zip_code: "48888",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Muskegon County Sheriff's Office",
        state_full: "Michigan",
        address1: "25 W Walton Ave",
        source_url: "http://www.muskegonsheriff.com/",
        county: "Muskegon",
        phone: "231-724-6289",
        state: "MI",
        source_id: "mi-musk",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Oakland Sheriff's Office",
        state_full: "Michigan",
        address1: "1201 North Telegraph",
        source_url: "https://vinelink.com",
        county: "Oakland County",
        phone: "248-858-5000​​",
        state: "MI",
        source_id: "mi-oakl",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Wayne County Sheriff's Office",
        state_full: "Michigan",
        address1: "570 Clinton St.",
        source_url: "http://someplace.com",
        county: "Wayne County",
        phone: "Main Phone: 313-224-2222",
        state: "MI",
        source_id: "mi-wayn",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "",
        name: "Anoka County Sheriff's Office",
        state_full: "Minnesota",
        address1: "13301 Hanson Blvd NW",
        source_url: "http://www.anokacounty.us/",
        county: "Anoka",
        phone: "Ph: 763-323-5000\nFax: 763-422-7503",
        state: "MN",
        source_id: "mn-acso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Minneapolis",
        name: "Hennepin County Sheriff's Office",
        state_full: "Minnesota",
        address1: "401 S. 4th Ave",
        source_url: "http://www.hennepinsheriff.org/",
        county: "Hennepin County",
        phone: "(612) 348-5112",
        state: "MN",
        source_id: "mn-hcso",
        zip_code: "55415",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Mower County Sheriff’s Office",
        state_full: "Minnesota",
        address1: null,
        source_url: "http://www.co.mower.mn.us/",
        county: "Mower County",
        phone: null,
        state: "MN",
        source_id: "mn-mcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Shakopee",
        name: "Scott County Jail",
        state_full: "Minnesota",
        address1: "301 Fuller Street South",
        source_url:
            "http://www.co.scott.mn.us/PublicSafetyJustice/CountyJail/Pages/AbouttheJail.aspx",
        county: "Scott County",
        phone: "(952) 496-8314",
        state: "MN",
        source_id: "mn-scj",
        zip_code: "55379",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Buffalo",
        name: "Wright County Jail",
        state_full: "Minnesota",
        address1: "3800 Braddock Ave. NE",
        source_url: "http://www.co.wright.mn.us/department/sheriff/index.asp",
        county: "Wright County",
        phone: "(763) 684-2381",
        state: "MN",
        source_id: "mn-wrcj",
        zip_code: "55313-3662",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Natchez",
        name: "Adams County Sheriff's Office",
        state_full: "Mississippi",
        address1: "306 State Street",
        source_url: "http://www.adamscosheriff.org/index.php",
        county: "Adams County",
        phone: "(601) 442-4199",
        state: "MS",
        source_id: "ms-acso",
        zip_code: "39120",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Cleveland",
        name: "Bolivar County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "2792 Highway 8",
        source_url: "http://vinelink.com",
        county: "Bolivar County",
        phone: "(662) 843-5378",
        state: "MS",
        source_id: "ms-bcsd",
        zip_code: "38732",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pittsboro",
        name: "Calhoun County Sheriff's Department",
        state_full: "Mississippi",
        address1: "178 S. Murphree St",
        source_url: "https://www.facebook.com/CalhounCountySheriffsDept",
        county: "Calhoun County",
        phone: "(662) 412-3149",
        state: "MS",
        source_id: "ms-cnsd",
        zip_code: "38951",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Ackerman",
        name: "Choctaw County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "122 Jailhouse Rd",
        source_url: "http://vinelink.com",
        county: "Choctaw County",
        phone: "(662) 285-6129",
        state: "MS",
        source_id: "ms-ccsd",
        zip_code: "39735",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Quitman",
        name: "Clarke County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "444 W Donald St",
        source_url: "http://vinelink.com",
        county: "Clarke County",
        phone: "(601) 776-5252",
        state: "MS",
        source_id: "ms-clsd",
        zip_code: "39355",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Clay County Sheriff's Office",
        state_full: "Mississippi",
        address1: "330 W Broad St",
        source_url: "http://www.claysheriffms.org/",
        county: "Clay County",
        phone: "Phone for Sheriff's Department: (662) 494-2896\nFax",
        state: "MS",
        source_id: "ms-clay",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Collins",
        name: "Covington County Sheriff's Office",
        state_full: "Mississippi",
        address1: "PO Box 545",
        source_url: "http://vinelink.com",
        county: "Covington County",
        phone: "(601) 765-8281",
        state: "MS",
        source_id: "ms-ccso",
        zip_code: "39428",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "DeSoto County Sheriff's Office",
        state_full: "Mississippi",
        address1: "3425 Industrail Dr. West",
        source_url: "https://vinelink.com",
        county: "Desoto County",
        phone: "Ph: (662) 469-8568\nFx: (662) 469-8532",
        state: "MS",
        source_id: "ms-deso",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "",
        name: "Forrest County Sheriff's Office",
        state_full: "Mississippi",
        address1: "P.O. Box 747Hattiesburg",
        source_url: "http://www.forrestcountysheriffsoffice.com/",
        county: "Forrest County",
        phone: "Main Phone: (601) 544-9770",
        state: "MS",
        source_id: "ms-fcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lucedale",
        name: "George County Sheriff's Office",
        state_full: "Mississippi",
        address1: "355 Cox St",
        source_url: "http://vinelink.com",
        county: "George County",
        phone: "(601) 947-4811",
        state: "MS",
        source_id: "ms-gcso",
        zip_code: "39452",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hancock County Sheriff's Office",
        state_full: "Mississippi",
        address1: "PHYSICAL ADDRESS\n21100 Hwy. 603",
        source_url: "https://vinelink.com",
        county: "Hancock County",
        phone: "TEL\n228-466-6900\n228-467-5101",
        state: "MS",
        source_id: "ms-hanc",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "Gulfport",
        name: "Harrison County Sheriff's Office",
        state_full: "Mississippi",
        address1: "10451 Larkin Smith Dr",
        source_url: "http://www.harrisoncountysheriff.com/",
        county: "Harrison County",
        phone: "(228) 896-3000",
        state: "MS",
        source_id: "ms-hcso",
        zip_code: "39503",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Jackson",
        name: "Hinds County Sheriff's Office",
        state_full: "Mississippi",
        address1: "327 East Pascagoula Street",
        source_url:
            "http://www.co.hinds.ms.us/pgs/apps/inmate/inmate_query.asp",
        county: "Hinds County",
        phone: "(601) 857-8781",
        state: "MS",
        source_id: "ms-hind",
        zip_code: "39201",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Lexington",
        name: "Holmes County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "PO Box 120",
        source_url: "http://vinelink.com",
        county: "Holmes County",
        phone: "(662) 834-1511",
        state: "MS",
        source_id: "ms-hlsd",
        zip_code: "39095",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Belzoni",
        name: "Humphreys County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "106 Castleman St",
        source_url: "http://vinelink.com",
        county: "Humphreys County",
        phone: "(601) 247-2551",
        state: "MS",
        source_id: "ms-hcsd",
        zip_code: "39038",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pascagoula",
        name: "Jackson County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "PO Box 998",
        source_url: "http://vinelink.com",
        county: "Jackson County",
        phone: "(228) 769-3024",
        state: "MS",
        source_id: "ms-jcsd",
        zip_code: "39568",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bay Springs",
        name: "Jasper County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "PO Box 464",
        source_url: "http://vinelink.com",
        county: "Jasper County",
        phone: "(601) 764-2588",
        state: "MS",
        source_id: "ms-jssd",
        zip_code: "39422",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Fayette",
        name: "Jefferson County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "PO Box 218",
        source_url: "http://vinelink.com",
        county: "Jefferson County",
        phone: "(601) 786-3403",
        state: "MS",
        source_id: "ms-jfsd",
        zip_code: "39069",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Ellisville",
        name: "Jones County Sheriff's Office",
        state_full: "Mississippi",
        address1: "5178 U.S. 11",
        source_url: "http://www.jonesso.com/index.php",
        county: "Jones County",
        phone: "(601) 649-7502",
        state: "MS",
        source_id: "ms-jcso",
        zip_code: "39437",
        email: "",
        has_mugshots: true,
    },
    {
        city: "De Kalb",
        name: "Kemper County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "330 Stennis Industrial Park Rd",
        source_url: "http://vinelink.com",
        county: "Kemper County",
        phone: "(601) 743-2255",
        state: "MS",
        source_id: "ms-kcsd",
        zip_code: "39328",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lamar County Sheriff's Office",
        state_full: "Mississippi",
        address1: "TEL\n(601) 794-1088\n(601) 794-1005",
        source_url: "http://sheriff.lamarcounty.com/",
        county: "Lamar County",
        phone: "TEL\n(601) 794-1088\n(601) 794-1005",
        state: "MS",
        source_id: "ms-lama",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Meridian",
        name: "Lauderdale County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "2001 5th St",
        source_url: "http://vinelink.com",
        county: "Lauderdale County",
        phone: "(601) 482-9806",
        state: "MS",
        source_id: "ms-lcsd",
        zip_code: "39301",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Monticello",
        name: "Lawrence County Sheriff's Office",
        state_full: "Mississippi",
        address1: "PO Box 816",
        source_url: "http://vinelink.com",
        county: "Lawrence County",
        phone: "(601) 587-2961",
        state: "MS",
        source_id: "ms-lwso",
        zip_code: "39654",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Carthage",
        name: "Leake County Sheriff's Office",
        state_full: "Mississippi",
        address1: "PO Box 129",
        source_url: "http://vinelink.com",
        county: "Leake County",
        phone: "(601) 267-7361",
        state: "MS",
        source_id: "ms-lcso",
        zip_code: "39051",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lee County Sheriff’s Office",
        state_full: "Mississippi",
        address1: null,
        source_url: "http://www.leecosheriff.com/",
        county: "Lee County",
        phone: null,
        state: "MS",
        source_id: "ms-lee",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Columbus",
        name: "Lowndes County Sheriff's Office",
        state_full: "Mississippi",
        address1: "527 Martin Luther King Jr Dr S",
        source_url: "http://vinelink.com",
        county: "Lowndes County",
        phone: "(662) 329-5853",
        state: "MS",
        source_id: "ms-ldso",
        zip_code: "39701",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Aberdeen",
        name: "Monroe County Sheriff's Office",
        state_full: "Mississippi",
        address1: "PO Box 683",
        source_url: "http://vinelink.com",
        county: "Monroe County",
        phone: "(662) 369-2468",
        state: "MS",
        source_id: "ms-mcso",
        zip_code: "39730",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Perry County Sheriff's Office",
        state_full: "Mississippi",
        address1: "P.O. Box 228",
        source_url: "http://www.perrycountysheriff.com/",
        county: "Perry County",
        phone: "Main Phone: 601) 964-8461",
        state: "MS",
        source_id: "ms-pcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pontotoc",
        name: "Pontotoc County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "490 Highway 6 W",
        source_url: "http://vinelink.com",
        county: "Pontotoc County",
        phone: "(662) 489-3915",
        state: "MS",
        source_id: "ms-pcsd",
        zip_code: "38863",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Booneville",
        name: "Prentiss County Sheriff's Department",
        state_full: "Mississippi",
        address1: "1901 E Chambers Dr",
        source_url: "http://prentisscountymssheriff.com/",
        county: "Prentiss County",
        phone: "(662) 728-2016",
        state: "MS",
        source_id: "ms-prsd",
        zip_code: "38829",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Brandon",
        name: "Rankin County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "221 Timber St",
        source_url: "http://vinelink.com",
        county: "Rankin County",
        phone: "(601) 825-9232",
        state: "MS",
        source_id: "ms-rcsd",
        zip_code: "39042",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Forest",
        name: "Scott County Sheriff's Office",
        state_full: "Mississippi",
        address1: "530 Airport Rd",
        source_url: "http://vinelink.com",
        county: "Scott County",
        phone: "(601) 469-1511",
        state: "MS",
        source_id: "ms-scso",
        zip_code: "39074",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Raleigh",
        name: "Smith County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "PO Box 307",
        source_url: "http://vinelink.com",
        county: "Smith County",
        phone: "(601) 782-4531",
        state: "MS",
        source_id: "ms-scsd",
        zip_code: "39153",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wiggins",
        name: "Stone County Sheriff's  Dept",
        state_full: "Mississippi",
        address1: "1420 Industrial Park Rd",
        source_url: "http://vinelink.com",
        county: "Stone County",
        phone: "(601) 928-3191",
        state: "MS",
        source_id: "ms-stsd",
        zip_code: "39577",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Indianola",
        name: "Sunflower County Sheriff's Office",
        state_full: "Mississippi",
        address1: "1300 Allen Rd",
        source_url: "http://vinelink.com",
        county: "Sunflower County",
        phone: "(662) 887-2121",
        state: "MS",
        source_id: "ms-snso",
        zip_code: "38751",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Iuka",
        name: "Tishomingo County Sheriff's Department",
        state_full: "Mississippi",
        address1: "1208 Bettydale Drive",
        source_url: "http://tishomingocountysheriff.com/",
        county: "Tishomingo County",
        phone: "(662) 423-6185",
        state: "MS",
        source_id: "ms-tgsd",
        zip_code: "38852",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Tunica",
        name: "Tunica County Sheriff's Office",
        state_full: "Mississippi",
        address1: "456 S Court Street",
        source_url: "http://www.tunicamssheriff.com/index.php",
        county: "Tunica County",
        phone: "(662) 363-1411",
        state: "MS",
        source_id: "ms-tcso",
        zip_code: "38676",
        email: "reginald.boykin@tunicagov.com",
        has_mugshots: true,
    },
    {
        city: "New Albany",
        name: "Union County Sheriff's Office",
        state_full: "Mississippi",
        address1: "300 Carter Ave.",
        source_url: "http://vinelink.com",
        county: "Union County",
        phone: "(662) 534-1941",
        state: "MS",
        source_id: "ms-ucso",
        zip_code: "38652",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Greenville",
        name: "Washington County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "903 W Alexander St",
        source_url: "http://vinelink.com",
        county: "Washington County",
        phone: "(662) 334-4523",
        state: "MS",
        source_id: "ms-wssd",
        zip_code: "38701",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Waynesboro",
        name: "Wayne County Sheriff's Dept",
        state_full: "Mississippi",
        address1: "613 Court St",
        source_url: "http://vinelink.com",
        county: "Wayne County",
        phone: "(601) 735-2323",
        state: "MS",
        source_id: "ms-wcsd",
        zip_code: "39367",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Eupora",
        name: "Webster County Sheriff's Office",
        state_full: "Mississippi",
        address1: "87 Government Ave",
        source_url: "http://vinelink.com",
        county: "Webster County",
        phone: "(662) 258-7701",
        state: "MS",
        source_id: "ms-wbso",
        zip_code: "39744",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Woodville",
        name: "Wilkinson County Sheriff's Office",
        state_full: "Mississippi",
        address1: "PO Box 980",
        source_url: "http://vinelink.com",
        county: "Wilkinson County",
        phone: "(601) 888-3511",
        state: "MS",
        source_id: "ms-wcso",
        zip_code: "39669",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Water Valley",
        name: "Yalobusha County Sheriff's Department",
        state_full: "Mississippi",
        address1: "109 Calhoun Street",
        source_url:
            "https://www.facebook.com/pages/YALOBUSHA-COUNTY-SHERIFF-DEPARTMENT/475496560532",
        county: "Yalobusha County",
        phone: "(662) 473-2722",
        state: "MS",
        source_id: "ms-yasd",
        zip_code: "38965",
        email: "So1@yalobushacounty.net",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Yazoo County Sheriff's Office",
        state_full: "Mississippi",
        address1: "154 Roosevelt Hudson Dr",
        source_url: "http://www.yazoorcf.com/",
        county: "Yazoo County",
        phone: "Main Phone: 662-751-8484",
        state: "MS",
        source_id: "ms-ycso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Kirksville",
        name: "Adair County Sheriff's Office",
        state_full: "Missouri",
        address1: "215 N Franklin St",
        source_url: "http://vinelink.com",
        county: "Adair County",
        phone: "(660) 665-4644",
        state: "MO",
        source_id: "mo-acso",
        zip_code: "63501",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Rock Port",
        name: "Atchison County Sheriff's Office",
        state_full: "Missouri",
        address1: "511 W Clay St",
        source_url: "http://vinelink.com",
        county: "Atchison County",
        phone: "(660) 744-6271",
        state: "MO",
        source_id: "mo-asso",
        zip_code: "64482",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Cassville",
        name: "Barry County Sheriff's Office",
        state_full: "Missouri",
        address1: "505 East St",
        source_url: "http://www.barrycountysheriff.com/index.php",
        county: "Barry County",
        phone: "(417) 847-3461",
        state: "MO",
        source_id: "mo-bcso",
        zip_code: "65625",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Lamar",
        name: "Barton County Sheriff's Office",
        state_full: "Missouri",
        address1: "1010 cherry Street",
        source_url:
            "https://www.facebook.com/pages/Barton-County-Sheriffs-Office/296977876995031",
        county: "Barton County",
        phone: "(417) 682-5515",
        state: "MO",
        source_id: "mo-brso",
        zip_code: "64759",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Butler",
        name: "Bates County Sheriff's Office",
        state_full: "Missouri",
        address1: "6 W Fort Scott St",
        source_url: "http://vinelink.com",
        county: "Bates County",
        phone: "(660) 679-3232",
        state: "MO",
        source_id: "mo-btso",
        zip_code: "64730",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Warren",
        name: "Benton County Jail",
        state_full: "Missouri",
        address1: "174 W. Washington Street",
        source_url: "http://www.bentoncomo.com/offices/sheriff.html",
        county: "Benton County",
        phone: "(660) 438-6135",
        state: "MO",
        source_id: "mo-bncj",
        zip_code: "65355",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Bollinger County Sheriff's Office",
        state_full: "Missouri",
        address1: "202 High Street",
        source_url: "http://bollingercountysheriffsoffice.com/",
        county: "Bollinger County",
        phone: "Phone:  (573) 238-2633\nFax:  (573)238-3095",
        state: "MO",
        source_id: "mo-boll",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Columbia",
        name: "Boone County Sheriff's Dept",
        state_full: "Missouri",
        address1: "2121 E County Dr",
        source_url: "http://vinelink.com",
        county: "Boone County",
        phone: "(573) 875-1111",
        state: "MO",
        source_id: "mo-bcsd",
        zip_code: "65202",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Branson",
        name: "Branson Police Dept",
        state_full: "Missouri",
        address1: "110 W Maddux St",
        source_url: "http://vinelink.com",
        county: "Branson County",
        phone: "(417) 334-3300",
        state: "MO",
        source_id: "mo-bpd",
        zip_code: "65616",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Buchanan County Sheriff's Office",
        state_full: "Missouri",
        address1: "411 Jules",
        source_url: "http://vinelink.com",
        county: "Buchanan County",
        phone: "(816) 271-4777",
        state: "MO",
        source_id: "mo-bhso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Poplar Bluff",
        name: "Butler County Sheriff's dept",
        state_full: "Missouri",
        address1: "200 N Oak St",
        source_url: "http://vinelink.com",
        county: "Butler County",
        phone: "(573) 686-8072",
        state: "MO",
        source_id: "mo-bcs",
        zip_code: "63901",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Kingston",
        name: "Caldwell County Sheriff's Office",
        state_full: "Missouri",
        address1: "95 N Franklin St",
        source_url: "http://vinelink.com",
        county: "Caldwell County",
        phone: "(816) 586-2681",
        state: "MO",
        source_id: "mo-ccso",
        zip_code: "64650",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Fulton",
        name: "Callaway County Sheriff's Office",
        state_full: "Missouri",
        address1: "1201 State Road O",
        source_url: "http://www.callawaysheriff.org/",
        county: "Callaway County",
        phone: "(573) 642-7291",
        state: "MO",
        source_id: "mo-clso",
        zip_code: "65251",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Camden County Sheriff's Office",
        state_full: "Missouri",
        address1: "1 Court Circle Suite 13",
        source_url: "http://www.camdencountymosheriff.org/",
        county: "Camden County",
        phone: "PHONE 573-346-2243 (24 HOURS)",
        state: "MO",
        source_id: "mo-camd",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jackson",
        name: "Cape Girardeau County Jail",
        state_full: "Missouri",
        address1: "216 N. Missouri",
        source_url: "http://www.capecountysheriff.org/",
        county: "Cape Girardeau County",
        phone: "(573) 243-3551",
        state: "MO",
        source_id: "mo-cgcj",
        zip_code: "63755",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Cedar County Sheriff's Office",
        state_full: "Missouri",
        address1: "113 South Stree",
        source_url: "http://cedarcountymo.gov/ccso.html",
        county: "Cedar County",
        phone: "(417) 276-5133",
        state: "MO",
        source_id: "mo-cdso",
        zip_code: null,
        email: "LDwerlkotte@cedarcountysheriff.com",
        has_mugshots: true,
    },
    {
        city: "Kahoka",
        name: "Clark County Sheriff's Office",
        state_full: "Missouri",
        address1: "518 N Lincoln St",
        source_url: "http://vinelink.com",
        county: "Clark County",
        phone: "(660) 727-2915",
        state: "MO",
        source_id: "mo-ckso",
        zip_code: "63445",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Liberty",
        name: "Clay County Sheriff's Office",
        state_full: "Missouri",
        address1: "12 S. Water St.",
        source_url: "https://www.claycountymo.gov/Sheriff",
        county: "Clay County",
        phone: "(816) 407-3750",
        state: "MO",
        source_id: "mo-cyso",
        zip_code: "64068",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Cole County Sheriff's Office",
        state_full: "Missouri",
        address1: "301 E. High Street",
        source_url: "http://vinelink.com",
        county: "Cole County",
        phone: "(573) 634-9160",
        state: "MO",
        source_id: "mo-coso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Boonville",
        name: "Cooper County Jail",
        state_full: "Missouri",
        address1: "200 Main Street, Room #3",
        source_url: "http://www.coopercountymo.org/ccsheriff.htm",
        county: "Cooper County",
        phone: "(660) 882-6985",
        state: "MO",
        source_id: "mo-cocj",
        zip_code: "65233",
        email: "ccsd590@sbcglobal.net",
        has_mugshots: true,
    },
    {
        city: "Steelville",
        name: "Crawford County Jail",
        state_full: "Missouri",
        address1: "212 Third Street",
        source_url: "http://www.crawfordcountysheriffsdepartment.com/",
        county: "Crawford County",
        phone: "(573) 775-2125",
        state: "MO",
        source_id: "mo-cwcj",
        zip_code: "65565",
        email: "ccsd@misn.com",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Dunklin County Sheriff's Office",
        state_full: "Missouri",
        address1: "200 Cedar St.",
        source_url: "http://vinelink.com",
        county: "Dunklin County",
        phone: "(573) 888-2424",
        state: "MO",
        source_id: "mo-dcso",
        zip_code: null,
        email: "dccourthouse@yahoo.com",
        has_mugshots: true,
    },
    {
        city: "Florissant",
        name: "Florissant Police Department",
        state_full: "Missouri",
        address1: "1700 N Us Highway 67",
        source_url: "http://www.florissantmo.com/Police",
        county: "Florissant Police",
        phone: "(314) 831-7000",
        state: "MO",
        source_id: "mo-fpd",
        zip_code: "63033",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Union",
        name: "Franklin County Adult Detention Center",
        state_full: "Missouri",
        address1: "#1 Bruns Lane",
        source_url: "http://www.franklinmo.org/Sheriff/jail.htm",
        county: "Franklin County",
        phone: "(636) 583-2560",
        state: "MO",
        source_id: "mo-fcdc",
        zip_code: "63084",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Springfield",
        name: "Greene County Sheriff's Office",
        state_full: "Missouri",
        address1: "1000 N Boonville Ave",
        source_url: "http://www.greenecountymo.org/sheriff/list.php",
        county: "Greene County",
        phone: "(417) 868-4048",
        state: "MO",
        source_id: "mo-gree",
        zip_code: "65802",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Grundy County Sheriff's Office",
        state_full: "Missouri",
        address1: "115 E. 8th St.",
        source_url: "http://vinelink.com",
        county: "Grundy County",
        phone: "(660) 359-2828",
        state: "MO",
        source_id: "mo-gcso",
        zip_code: null,
        email: "sheriff@grundycountymo.com",
        has_mugshots: true,
    },
    {
        city: "Hazelwood",
        name: "Hazelwood Police Department",
        state_full: "Missouri",
        address1: "415 Elm Grove Lane ",
        source_url: "http://www.hazelwoodpd.org",
        county: "Hazelwood Police",
        phone: "(314) 838-5000",
        state: "MO",
        source_id: "mo-hpd",
        zip_code: "63042",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clinton",
        name: "Henry County Jail",
        state_full: "Missouri",
        address1: "220 South Washington Street",
        source_url: "http://hcsomo.com/",
        county: "Henry County",
        phone: "(660) 885-5587",
        state: "MO",
        source_id: "mo-hycj",
        zip_code: "64735",
        email: "hcsojailmail@gmail.com",
        has_mugshots: false,
    },
    {
        city: "Oregon",
        name: "Holt County Sheriff's Office",
        state_full: "Missouri",
        address1: "PO Box 229",
        source_url: "http://vinelink.com",
        county: "Holt County",
        phone: "(660) 446-3300",
        state: "MO",
        source_id: "mo-hlso",
        zip_code: "64473",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Howard County Sheriff's Office",
        state_full: "Missouri",
        address1: "100 N Mulberry St",
        source_url: "http://hocomosheriff.com/",
        county: "Howard County",
        phone: "Main Phone: 660-248-2490",
        state: "MO",
        source_id: "mo-hoco",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "West Plains",
        name: "Howell County Sheriff's Office",
        state_full: "Missouri",
        address1: "1106 Missouri Ave",
        source_url: "http://vinelink.com",
        county: "Howell County",
        phone: "(417) 256-2544",
        state: "MO",
        source_id: "mo-hcso",
        zip_code: "65775",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Kansas City",
        name: "Jackson County Detention Center",
        state_full: "Missouri",
        address1: "1300 Cherry Street",
        source_url: "https://www.jacksongov.org/corrections/",
        county: "Jackson County",
        phone: "(816) 881-4200",
        state: "MO",
        source_id: "mo-jkdc",
        zip_code: "64106",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Carthage",
        name: "Jasper County Sheriff's Office",
        state_full: "Missouri",
        address1: "405 East 5th Street",
        source_url: "http://www.jaspercountysheriff.org/",
        county: "Jasper County",
        phone: "(417) 358-8177",
        state: "MO",
        source_id: "mo-jpso",
        zip_code: "64836",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Hillsboro",
        name: "Jefferson County Jail",
        state_full: "Missouri",
        address1: "510 1st St",
        source_url: "http://www.jcsd.org/jail.htm",
        county: "Jefferson County",
        phone: "(636) 797-5301",
        state: "MO",
        source_id: "mo-jecj",
        zip_code: "63050",
        email: "KCarle@jeffcomo.org",
        has_mugshots: true,
    },
    {
        city: "Warrensburg",
        name: "Johnson County Sheriff's Office",
        state_full: "Missouri",
        address1: "135 W. Market St., Ste. 1",
        source_url: "http://jocomosheriff.org/index.php",
        county: "Johnson County",
        phone: "(660) 747-2265",
        state: "MO",
        source_id: "mo-jcso",
        zip_code: "64093",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Lebanon",
        name: "Laclede County Jail",
        state_full: "Missouri",
        address1: "240 N Adams St",
        source_url: "http://www.lacledecountymissouri.org/sheriff/",
        county: "Laclede County",
        phone: "(417) 532-2311",
        state: "MO",
        source_id: "mo-lacj",
        zip_code: "65536",
        email: "sheriff265@lacledecountymissouri.org",
        has_mugshots: true,
    },
    {
        city: "Lexington",
        name: "Lafayette County Sheriff's Dept",
        state_full: "Missouri",
        address1: "107 S 11th St",
        source_url: "http://vinelink.com",
        county: "Lafayette County",
        phone: "(660) 259-3622",
        state: "MO",
        source_id: "mo-lcsd",
        zip_code: "64067",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mount Vernon",
        name: "Lawrence County Sheriff's Office",
        state_full: "Missouri",
        address1: "300 E Water St",
        source_url: "http://www.lawrencecosheriff.com/index.php",
        county: "Lawrence County",
        phone: "(417) 466-2131",
        state: "MO",
        source_id: "mo-lcso",
        zip_code: "65712",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Pattonsburg",
        name: "Livingston County Sheriff's Office",
        state_full: "Missouri",
        address1: "102 N. Meadows Lane",
        source_url: "http://www.livcoso.org/index.php",
        county: "Livingston County",
        phone: "(660) 367-2200",
        state: "MO",
        source_id: "mo-lnso",
        zip_code: "64670",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Macon County Sheriff's Office",
        state_full: "Missouri",
        address1: "101 W. Sheridan St",
        source_url: "http://www.maconcountymo.com/sheriffs-department.html",
        county: "Macon County",
        phone: "Main Phone: 660-385-2062",
        state: "MO",
        source_id: "mo-maco",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Maryland Heights",
        name: "Maryland Heights Police Department",
        state_full: "Missouri",
        address1: "11911 Dorsett",
        source_url: "http://www.marylandheights.com/index.aspx?page=132",
        county: "Maryland Heights Police",
        phone: "(314) 298-8700",
        state: "MO",
        source_id: "mo-mhpd",
        zip_code: "63043",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "McDonald County Sheriff's Office",
        state_full: "Missouri",
        address1: "c/o McDonald County\nPO Box 68",
        source_url: "http://www.mcdonaldcountysheriff.com/",
        county: "McDonald County",
        phone: "Main phone 417-223-4319\nMain fax 417-223-2331",
        state: "MO",
        source_id: "mo-mcdc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Tuscumbia",
        name: "Miller County Sheriff's Office",
        state_full: "Missouri",
        address1: "1999 MO-52",
        source_url: "http://www.millercountysheriffmo.org/",
        county: "Miller County",
        phone: "573-369-2341",
        state: "MO",
        source_id: "mo-mcso",
        zip_code: "65082",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Charleston",
        name: "Mississippi County Sheriff's dept",
        state_full: "Missouri",
        address1: "200 W Commercial St",
        source_url: "http://vinelink.com",
        county: "Mississippi County",
        phone: "(573) 683-2111",
        state: "MO",
        source_id: "mo-mcs",
        zip_code: "63834",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jefferson City",
        name: "Missouri Dept of Correction",
        state_full: "Missouri",
        address1: "2729 Plaza Dr",
        source_url: "http://vinelink.com",
        county: "Missouri Dept",
        phone: "(573) 526-6516",
        state: "MO",
        source_id: "mo-mdc",
        zip_code: "65109",
        email: null,
        has_mugshots: true,
    },
    {
        city: "California",
        name: "Moniteau County Jail",
        state_full: "Missouri",
        address1: "102 E North St",
        source_url: "http://vinelink.com",
        county: "Moniteau County",
        phone: "(573) 796-2525",
        state: "MO",
        source_id: "mo-mcj",
        zip_code: "65018",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Monroe County Sheriff's Office",
        state_full: "Missouri",
        address1: "300 N. Main S",
        source_url: "http://monroecountysheriffsoffice.com/",
        county: "Monroe County",
        phone: "Main Phone: 660-327-4060",
        state: "MO",
        source_id: "mo-monr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Montgomery City",
        name: "Montgomery County Sheriff's dept",
        state_full: "Missouri",
        address1: "211 E 3rd St",
        source_url: "http://vinelink.com",
        county: "Montgomery County",
        phone: "(573) 564-8084",
        state: "MO",
        source_id: "mo-mns",
        zip_code: "63361",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Morgan County Sheriff's Office",
        state_full: "Missouri",
        address1: "211 E. Newton",
        source_url: "http://vinelink.com",
        county: "Morgan County",
        phone: "(573) 378-5481",
        state: "MO",
        source_id: "mo-mrso",
        zip_code: null,
        email: "tharlin@morgan-county.org",
        has_mugshots: true,
    },
    {
        city: "",
        name: "New Madrid County Sheriff's Office",
        state_full: "Missouri",
        address1: "#2 Courthouse Square",
        source_url: "http://vinelink.com",
        county: "New Madrid County",
        phone: "(573) 748-2516",
        state: "MO",
        source_id: "mo-nmcso",
        zip_code: null,
        email: "tstevens@newmadridco.org",
        has_mugshots: true,
    },
    {
        city: "Maryville",
        name: "Nodaway County Sheriff's Office",
        state_full: "Missouri",
        address1: "404 N Vine St",
        source_url: "http://vinelink.com",
        county: "Nodaway County",
        phone: "(660) 582-7451",
        state: "MO",
        source_id: "mo-ncso",
        zip_code: "64468",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Linn",
        name: "Osage County Sheriff's Office",
        state_full: "Missouri",
        address1: "106 East Main Street",
        source_url: "http://vinelink.com",
        county: "Osage County",
        phone: "(573) 897-3927",
        state: "MO",
        source_id: "mo-ocso",
        zip_code: "65051",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Caruthersville",
        name: "Pemiscot County Sheriff's Office",
        state_full: "Missouri",
        address1: "800 Ward Ave",
        source_url: "http://vinelink.com",
        county: "Pemiscot County",
        phone: "(573) 333-4101",
        state: "MO",
        source_id: "mo-pmso",
        zip_code: "63830",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Perryville",
        name: "Perry County Jail",
        state_full: "Missouri",
        address1: "710 S Kingshighway St",
        source_url: "http://perrycountymo.us/index.aspx?nid=92",
        county: "Perry County",
        phone: "(573) 547-4071",
        state: "MO",
        source_id: "mo-pycj",
        zip_code: "63775",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Sedalia",
        name: "Pettis County Sheriff's Office",
        state_full: "Missouri",
        address1: "319 S Lamine Ave",
        source_url: "http://vinelink.com",
        county: "Pettis County",
        phone: "(660) 827-0052",
        state: "MO",
        source_id: "mo-ptso",
        zip_code: "65301",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bowling Green",
        name: "Pike County Sheriff's Office",
        state_full: "Missouri",
        address1: "1600 Business Highway 54 W",
        source_url: "http://vinelink.com",
        county: "Pike County",
        phone: "(573) 324-3202",
        state: "MO",
        source_id: "mo-pkso",
        zip_code: "63334",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Platte City",
        name: "Platte County Sheriff's Office",
        state_full: "Missouri",
        address1: "415 3rd St",
        source_url: "http://vinelink.com",
        county: "Platte County",
        phone: "(816) 858-2424",
        state: "MO",
        source_id: "mo-pcso",
        zip_code: "64079",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bolivar",
        name: "Polk County Jail",
        state_full: "Missouri",
        address1: "113 E. Jefferson",
        source_url: "http://www.polkcountymosheriff.org/",
        county: "Polk County",
        phone: "(417) 777-9020",
        state: "MO",
        source_id: "mo-pkcj",
        zip_code: "65613",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Waynesville",
        name: "Pulaski County Jail",
        state_full: "Missouri",
        address1: "301 Historic 66 East Suite 136",
        source_url: "http://www.pcsheriff2.com/",
        county: "Pulaski County",
        phone: "(573) 774-4793",
        state: "MO",
        source_id: "mo-pscj",
        zip_code: "65583",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Huntsville",
        name: "Randolph County Sheriff's Dept",
        state_full: "Missouri",
        address1: "372 Highway Jj",
        source_url: "http://vinelink.com",
        county: "Randolph County",
        phone: "(660) 277-5095",
        state: "MO",
        source_id: "mo-rcsd",
        zip_code: "65259",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Reynolds County Sheriff's Office",
        state_full: "Missouri",
        address1: "Courthouse\nP. O. Box 16",
        source_url: "http://www.reynoldsso.org/",
        county: "Reynolds County",
        phone: "TEL\n573-648-2491\nFAX\n573-648-2296",
        state: "MO",
        source_id: "mo-rcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Marshall",
        name: "Saline County Sheriff's Office",
        state_full: "Missouri",
        address1: "1915 W Arrow St",
        source_url: "http://vinelink.com",
        county: "Saline County",
        phone: "(660) 886-5511",
        state: "MO",
        source_id: "mo-slso",
        zip_code: "65340",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Benton",
        name: "Scott County Jail",
        state_full: "Missouri",
        address1: "131 S New Madrid St",
        source_url: "http://www.scottcountymo.com/sheriff/",
        county: "Scott County",
        phone: "(573) 471-3530",
        state: "MO",
        source_id: "mo-sccj",
        zip_code: "63736",
        email: "",
        has_mugshots: true,
    },
    {
        city: "St. Charles",
        name: "St. Charles Police Department",
        state_full: "Missouri",
        address1: "1781 Zumbehl Road",
        source_url:
            "http://www.stcharlescitymo.gov/departments/police/tabid/258/default.aspx",
        county: "St. Charles County",
        phone: "(636) 949-3300",
        state: "MO",
        source_id: "mo-scpd",
        zip_code: "63303",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "St. Francois County Sheriff's Office",
        state_full: "Missouri",
        address1: "1550 Doubet Rd",
        source_url: "http://vinelink.com",
        county: "St. Francois County",
        phone: "(573) 756-3252",
        state: "MO",
        source_id: "mo-sfcso",
        zip_code: null,
        email: "sheriff@sfcsd.org",
        has_mugshots: true,
    },
    {
        city: "St. Louis",
        name: "St. Louis County Police Department",
        state_full: "Missouri",
        address1: "7900 Forsyth Boulevard",
        source_url:
            "http://www.stlouisco.com/LawandPublicSafety/PoliceDepartment",
        county: "St. Louis County Police",
        phone: "(314) 889-2341",
        state: "MO",
        source_id: "mo-slcpd",
        zip_code: "63105",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clayton",
        name: "St. Louis County Sheriff's Office",
        state_full: "Missouri",
        address1: "7900 Carondelet Avenue",
        source_url:
            "http://www.stlouisco.com/YourGovernment/CountyDepartments/SheriffsOffice",
        county: "St. Louis County",
        phone: "(314) 615-4724",
        state: "MO",
        source_id: "mo-slcso",
        zip_code: "63105",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bloomfield",
        name: "Stoddard County Sheriff's Dept",
        state_full: "Missouri",
        address1: "PO Box 319",
        source_url: "http://vinelink.com",
        county: "Stoddard County",
        phone: "(573) 568-4654",
        state: "MO",
        source_id: "mo-scsd",
        zip_code: "63825",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Galena",
        name: "Stone County Sheriff's Office",
        state_full: "Missouri",
        address1: "110 S Maple St",
        source_url: "http://www.stonecountymosheriff.com/index.php",
        county: "Stone County",
        phone: "(417) 357-6652",
        state: "MO",
        source_id: "mo-scso",
        zip_code: "65656",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Forsyth",
        name: "Taney County Jail",
        state_full: "Missouri",
        address1: "266 Main Street",
        source_url:
            "http://www.taneycounty.org/cgi-bin/County/index.cgi?department=21",
        county: "Taney County",
        phone: "(417) 546-7250",
        state: "MO",
        source_id: "mo-tacj",
        zip_code: "65653",
        email: "Sheriff@co.taney.mo.us",
        has_mugshots: false,
    },
    {
        city: "University City",
        name: "University City Police Department",
        state_full: "Missouri",
        address1: "6801 Delmar Blvd",
        source_url: "http://www.ucitymo.org/index.aspx?NID=78",
        county: "University City Police",
        phone: "(314) 505-8652",
        state: "MO",
        source_id: "mo-ucpd",
        zip_code: "63130",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Nevada",
        name: "Vernon County Sheriff's Office",
        state_full: "Missouri",
        address1: "230 W Cherry St",
        source_url: "http://vinelink.com",
        county: "Vernon County",
        phone: "(417) 667-6042",
        state: "MO",
        source_id: "mo-vcso",
        zip_code: "64772",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Warrenton",
        name: "Warren County Jail",
        state_full: "Missouri",
        address1: "104 W Booneslick Rd",
        source_url: "http://www.wcsdmo.net/jail.html",
        county: "Warren County",
        phone: "(636) 456-4333",
        state: "MO",
        source_id: "mo-wrcj",
        zip_code: "63383",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Ravalli County Sheriff's Office",
        state_full: "Montana",
        address1: null,
        source_url: "http://ravalli.us/",
        county: "Ravalli County",
        phone: null,
        state: "MT",
        source_id: "mt-rcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Douglas County Sheriff's Office",
        state_full: "Nebraska",
        address1: "710 S. 17th St.",
        source_url: "http://corrections.dccorr.com/",
        county: "Douglas",
        phone: "402-444-7400",
        state: "NE",
        source_id: "ne-dcdc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Las Vegas",
        name: "City of Las Vegas",
        state_full: "Nevada",
        address1: "3300 Stewart Ave",
        source_url: "http://www.lasvegasnevada.gov/Government/detention.htm",
        county: "Las Vegas",
        phone: "(702) 671-3900",
        state: "NV",
        source_id: "nv-clv",
        zip_code: "89101",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Las Vegas",
        name: "Clark County Detention Center",
        state_full: "Nevada",
        address1: "330 South Casino Center",
        source_url:
            "http://www.clarkcountynv.gov/depts/ccdc/Pages/default.aspx",
        county: "Clark County",
        phone: "(702) 671-3900",
        state: "NV",
        source_id: "nv-ccdc",
        zip_code: "89101",
        email: "CCDC@lvmpd.com",
        has_mugshots: false,
    },
    {
        city: "Henderson",
        name: "Henderson Detention Center",
        state_full: "Nevada",
        address1: "18 S Water St",
        source_url: "http://cityofhenderson.com/police/detention.php",
        county: "Henderson",
        phone: "(702) 267-5245",
        state: "NV",
        source_id: "nv-chn",
        zip_code: "89015",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Lincoln County Sheriff's Office",
        state_full: "Nevada",
        address1: null,
        source_url: "http://www.lincolncountynv.org/sheriff/",
        county: "Lincoln County",
        phone: null,
        state: "NV",
        source_id: "nv-lcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lyon County Sheriff's Department",
        state_full: "Nevada",
        address1: null,
        source_url: "http://www.lyon-county.org/17/Sheriff",
        county: "Lyon County",
        phone: null,
        state: "NV",
        source_id: "nv-lcsd",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Pershing County Sheriff's Office",
        state_full: "Nevada",
        address1: null,
        source_url:
            "http://www.pershingcounty.net/government/sheriff_s_office/index.php",
        county: "Pershing County",
        phone: null,
        state: "NV",
        source_id: "nv-pcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Storey County Sheriff's Office",
        state_full: "Nevada",
        address1: null,
        source_url: "http://www.storeycounty.org/313/Sheriff",
        county: "Storey County",
        phone: null,
        state: "NV",
        source_id: "nv-scso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Reno",
        name: "Washoe County Sheriff's Office",
        state_full: "Nevada",
        address1: "911 E Parr Blvd, Reno",
        source_url: "http://www.washoesheriff.com/",
        county: "Washoe County",
        phone: "(775) 328-3001",
        state: "NV",
        source_id: "nv-wcso",
        zip_code: "89512",
        email: "sheriffweb@washoecounty.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "White Pine County Sheriff's Office",
        state_full: "Nevada",
        address1: null,
        source_url: "https://www.whitepinecounty.net/",
        county: "White Pine County",
        phone: null,
        state: "NV",
        source_id: "nv-wpcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hackensack",
        name: "Bergen County Sheriff's Office",
        state_full: "New Jersey",
        address1: "160 S River St",
        source_url: "http://www.bcsd.us/Default.aspx",
        county: "Bergen County",
        phone: "(201) 336-3500",
        state: "NJ",
        source_id: "nj-bcso",
        zip_code: "7601",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Newark",
        name: "Essex County Dept of Corrections",
        state_full: "New Jersey",
        address1: "354 Doremus Ave",
        source_url:
            "http://www.essex-countynj.org/index.php?section=dept/ps/fi",
        county: "Essex County",
        phone: "(973) 274-7500",
        state: "NJ",
        source_id: "nj-ecdc",
        zip_code: "7105",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Hudson County Sheriff's Office",
        state_full: "New Jersey",
        address1: "35 Hackensack Avenue",
        source_url: "http://www.hudsoncountynj.org/",
        county: "Hudson County",
        phone: " 201-395-5600",
        state: "NJ",
        source_id: "nj-hcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Mercer County Sheriff's Office",
        state_full: "New Jersey",
        address1: "1750 Route 29",
        source_url: "http://mercercountysheriff.org/",
        county: "Mercer",
        phone: "609-583-3545",
        state: "NJ",
        source_id: "nj-mcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Monmouth County Sheriff's Office",
        state_full: "New Jersey",
        address1: "1 Waterworks Rd",
        source_url: "http://www.monmouthsheriff.org",
        county: "Monmouth County",
        phone: "Main Phone: 732-431-7860",
        state: "NJ",
        source_id: "nj-monm",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "",
        name: "Ocean County Sheriff's Office",
        state_full: "New Jersey",
        address1: "120 Hooper Avenue",
        source_url: "http://omse.co.ocean.nj.us:8082/IML",
        county: "Ocean County",
        phone: "732-929-2043",
        state: "NJ",
        source_id: "nj-ocea",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Passaic County Jail",
        state_full: "New Jersey",
        address1: "11 Marshall StreetPaterson",
        source_url:
            "http://www.pcsheriff.org/index.php?option=com_wrapper&view=wrapper&Itemid=118",
        county: "Passaic County",
        phone: "Main Phone: 973-881-4620",
        state: "NJ",
        source_id: "nj-pass",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "Albuquerque",
        name: "Bernalillo County Sheriff’s Department",
        state_full: "New Mexico",
        address1: "100 Deputy Dean Miera Dr SW",
        source_url: "http://www.bernalillocountysheriff.com/services.html",
        county: "Bernalillo County",
        phone: "(505) 839-8700",
        state: "NM",
        source_id: "nm-bcsd",
        zip_code: "87151",
        email: "mdc@bernco.gov",
        has_mugshots: true,
    },
    {
        city: "Reserve",
        name: "Catron County Sheriff's Dept",
        state_full: "New Mexico",
        address1: "PO Box 467",
        source_url: "http://vinelink.com",
        county: "Catron County",
        phone: "(505) 533-6222",
        state: "NM",
        source_id: "nm-ccsd",
        zip_code: "87830",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Roswell",
        name: "Chaves County Sheriff's Dept",
        state_full: "New Mexico",
        address1: "PO Box 1396",
        source_url: "http://vinelink.com",
        county: "Chaves County",
        phone: "(505) 624-6500",
        state: "NM",
        source_id: "nm-chsd",
        zip_code: "88202",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Grants",
        name: "Cibola County Sheriff's Dept",
        state_full: "New Mexico",
        address1: "515 W High St",
        source_url: "http://vinelink.com",
        county: "Cibola County",
        phone: "(505) 876-2040",
        state: "NM",
        source_id: "nm-cbsd",
        zip_code: "87020",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clovis",
        name: "Curry Co Detention Center",
        state_full: "New Mexico",
        address1: "801 Mitchell St",
        source_url: "http://vinelink.com",
        county: "Curry County",
        phone: "(575) 762-3819",
        state: "NM",
        source_id: "nm-ccdc",
        zip_code: "88101",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Las Cruces",
        name: "Dona Ana County Sheriff's Office",
        state_full: "New Mexico",
        address1: "750 N Motel Blvd",
        source_url: "http://vinelink.com",
        county: "Dona Ana County",
        phone: "(505) 647-7700",
        state: "NM",
        source_id: "nm-dacso",
        zip_code: "88007",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Silver City",
        name: "Grant County Detention Center",
        state_full: "New Mexico",
        address1: "209 N Black St",
        source_url: "http://vinelink.com",
        county: "Grant County",
        phone: "(575) 534-3803",
        state: "NM",
        source_id: "nm-gcdc",
        zip_code: "88061",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lordsburg",
        name: "Hidalgo County Sheriff's Office",
        state_full: "New Mexico",
        address1: "305 Pyramid St",
        source_url: "http://vinelink.com",
        county: "Hidalgo County",
        phone: "(575) 542-8827",
        state: "NM",
        source_id: "nm-hcso",
        zip_code: "88045",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Los Alamos",
        name: "Los Alamos Co Detention Center",
        state_full: "New Mexico",
        address1: "2500 Trinity Dr",
        source_url: "http://vinelink.com",
        county: "Los County",
        phone: "(505) 662-8279",
        state: "NM",
        source_id: "nm-lacdc",
        zip_code: "87544",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Tucumcari",
        name: "Quay County Detention Center",
        state_full: "New Mexico",
        address1: "223 W High",
        source_url: "http://vinelink.com",
        county: "Quay County",
        phone: "(575) 461-4664",
        state: "NM",
        source_id: "nm-qcdc",
        zip_code: "88401",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Tierra Amerilla",
        name: "Rio Arriba Co Detention Center",
        state_full: "New Mexico",
        address1: "#2 Main St.",
        source_url: "http://vinelink.com",
        county: "Rio County",
        phone: "(575) 588-7350",
        state: "NM",
        source_id: "nm-racdc",
        zip_code: "87575",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Portales",
        name: "Roosevelt Co Detention Center",
        state_full: "New Mexico",
        address1: "1700 N Boston Ave",
        source_url: "http://vinelink.com",
        county: "Roosevelt County",
        phone: "(575) 356-6871",
        state: "NM",
        source_id: "nm-rcdc",
        zip_code: "88130",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Las Vegas",
        name: "San Miguel Co Detention Center",
        state_full: "New Mexico",
        address1: "26 Nm 283",
        source_url: "http://vinelink.com",
        county: "San County",
        phone: "(505) 454-7403",
        state: "NM",
        source_id: "nm-smcdc",
        zip_code: "87701",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bernalillo",
        name: "Sandoval Co Dentention Center",
        state_full: "New Mexico",
        address1: "1100 Montoya Rd",
        source_url: "http://vinelink.com",
        county: "Sandoval County",
        phone: "(505) 867-5339",
        state: "NM",
        source_id: "nm-scdc",
        zip_code: "87004",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Santa Fe",
        name: "Santa Fe County Corrections Department",
        state_full: "New Mexico",
        address1: "4001 Office Court Drive Suite 906",
        source_url: "http://vinelink.com",
        county: "Santa Fe County",
        phone: "(505) 428-3111",
        state: "NM",
        source_id: "nm-sfccd",
        zip_code: "87507",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Estancia",
        name: "Torrance Co Detention Center",
        state_full: "New Mexico",
        address1: "209 East Alan Ayers Rd",
        source_url: "http://vinelink.com",
        county: "Torrance County",
        phone: "(505) 384-2711",
        state: "NM",
        source_id: "nm-tcdc",
        zip_code: "87016",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clayton",
        name: "Union County Detention Facililty",
        state_full: "New Mexico",
        address1: "185 Dr Michael Jenkins Rd",
        source_url: "http://vinelink.com",
        county: "Union County",
        phone: "(575) 374-4005",
        state: "NM",
        source_id: "nm-ucdf",
        zip_code: "88415",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Raton",
        name: "Vigil Maldonado Detention Center",
        state_full: "New Mexico",
        address1: "444 Hereford Ave",
        source_url: "http://vinelink.com",
        county: "Colfax County",
        phone: "(575) 445-3691",
        state: "NM",
        source_id: "nm-vmdc",
        zip_code: "87740",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Belmont",
        name: "Allegany County Sheriff's Office",
        state_full: "New York",
        address1: "4884 State Route 19 S",
        source_url: "http://vinelink.com",
        county: "Allegany County",
        phone: "(585) 268-9872",
        state: "NY",
        source_id: "ny-acso",
        zip_code: "14813",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Binghamton",
        name: "Broome County Jail",
        state_full: "New York",
        address1: "155 Lt Vanwinkle Dr",
        source_url: "http://www.gobroomecounty.com/sheriff/corrections",
        county: "Broome County",
        phone: "(607) 778.1911",
        state: "NY",
        source_id: "ny-bcj",
        zip_code: "13905",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Little Valley",
        name: "Cattaraugus County Sheriff's Office",
        state_full: "New York",
        address1: "301 Court St",
        source_url: "http://vinelink.com",
        county: "Cattaraugus County",
        phone: "(716) 938-9191",
        state: "NY",
        source_id: "ny-ctso",
        zip_code: "14755",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Auburn",
        name: "Cayuga County Sheriff's Office",
        state_full: "New York",
        address1: "7445 County House Rd",
        source_url:
            "http://www.cayugacounty.us/LivingWorking/SheriffsOffice.aspx",
        county: "Cayuga County",
        phone: "(315) 253-1222",
        state: "NY",
        source_id: "ny-cyso",
        zip_code: "13021",
        email: "Tips@cayugacounty.us",
        has_mugshots: true,
    },
    {
        city: "Elmira",
        name: "Chemung County Sheriff's Office",
        state_full: "New York",
        address1: "203-209 William St.",
        source_url: "http://www.chemungsheriff.net/index.asp?pageId=6",
        county: "Chemung County",
        phone: "(607) 737-2933",
        state: "NY",
        source_id: "ny-chso",
        zip_code: "14901",
        email: "sheriff@co.chemung.ny.us",
        has_mugshots: true,
    },
    {
        city: "Norwich",
        name: "Chenango",
        state_full: "New York",
        address1: "279 County Route 46",
        source_url: "http://www.chenangosheriff.us",
        county: "Chenango County ",
        phone: "(607) 334-2000",
        state: "NY",
        source_id: "ny-c",
        zip_code: "13815",
        email: "milescs@co.chenango.ny.us",
        has_mugshots: true,
    },
    {
        city: "Plattsburgh",
        name: "Clinton County Jail",
        state_full: "New York",
        address1: "25 McCarthy Dr",
        source_url:
            "http://www.clintoncountygov.com/departments/sheriff/sheriffhome.html",
        county: "Clinton County",
        phone: "(518) 565-4300",
        state: "NY",
        source_id: "ny-clcj",
        zip_code: "12901",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Columbia County Sheriff's Office",
        state_full: "New York",
        address1: "110 Miain",
        source_url: "http://www.columbiacountysheriff.us/index.html",
        county: "Columbia County",
        phone: "(518) 822-8477",
        state: "NY",
        source_id: "ny-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Cortland",
        name: "Cortland County Sheriff's Office",
        state_full: "New York",
        address1: "54 Greenbush St",
        source_url: "http://vinelink.com",
        county: "Cortland County",
        phone: "(607) 753-3311",
        state: "NY",
        source_id: "ny-crso",
        zip_code: "13045",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Delhi",
        name: "Delaware County Jail",
        state_full: "New York",
        address1: "280 Phoebe Ln",
        source_url: "http://www.co.delaware.ny.us/departments/shrf/shrf.htm",
        county: "Delaware County",
        phone: "(607) 746-2336",
        state: "NY",
        source_id: "ny-dwcj",
        zip_code: "13753",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Lewis",
        name: "Essex County Jail",
        state_full: "New York",
        address1: "702 Stowersville Rd",
        source_url: "http://www.co.essex.ny.us/Sheriffs/index.htm",
        county: "Essex County",
        phone: "(518) 873-6974",
        state: "NY",
        source_id: "ny-excj",
        zip_code: "12950",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Malone",
        name: "Franklin County Jail",
        state_full: "New York",
        address1: "45 Bare Hill Rd",
        source_url: "http://franklincony.org/content/Departments/View/10",
        county: "Franklin County",
        phone: "(518) 483-6795",
        state: "NY",
        source_id: "ny-frcj",
        zip_code: "12953",
        email: "kmulverhill@co.franklin.ny.us",
        has_mugshots: true,
    },
    {
        city: "Batavia",
        name: "Genesee County Sheriff's Office",
        state_full: "New York",
        address1: "14 W Main St",
        source_url: "http://vinelink.com",
        county: "Genesee County",
        phone: "(315) 786-2700",
        state: "NY",
        source_id: "ny-gnso",
        zip_code: "14020",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Catskill",
        name: "Greene County Sheriff's Office",
        state_full: "New York",
        address1: "80 Bridge St",
        source_url: "http://vinelink.com",
        county: "Greene County",
        phone: "(518) 943-3302",
        state: "NY",
        source_id: "ny-gcso",
        zip_code: "12414",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Herkimer",
        name: "Herkimer County Sheriff's Office",
        state_full: "New York",
        address1: "320 N Main St",
        source_url: "http://vinelink.com",
        county: "Herkimer County",
        phone: "(315) 867-1167",
        state: "NY",
        source_id: "ny-hcso",
        zip_code: "13350",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Jefferson County Sheriff's Office",
        state_full: "New York",
        address1: "753 Waterman Dr",
        source_url: "http://vinelink.com",
        county: "Jefferson County",
        phone: "(315) 786-2700",
        state: "NY",
        source_id: "ny-jcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lowville",
        name: "Lewis County Sheriff's Office",
        state_full: "New York",
        address1: "PO Box 233",
        source_url: "http://vinelink.com",
        county: "Lewis County",
        phone: "(315) 376-3511",
        state: "NY",
        source_id: "ny-lwso",
        zip_code: "13367",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Geneseo",
        name: "Livingston County Sheriff's Office",
        state_full: "New York",
        address1: "4 Court St",
        source_url: "http://www.co.livingston.state.ny.us/Sheriff/sheriff.htm",
        county: "Livingston County",
        phone: "(585) 243-7100",
        state: "NY",
        source_id: "ny-lcso",
        zip_code: "14454",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wampsville",
        name: "Madison County Sheriff's Office",
        state_full: "New York",
        address1: "138 N Court St # 7",
        source_url: "http://www.madisoncountysheriff.us/home",
        county: "Madison County",
        phone: "(315) 366-2318",
        state: "NY",
        source_id: "ny-mcso",
        zip_code: "13163",
        email: null,
        has_mugshots: true,
    },
    {
        city: "New York",
        name: "New York City Department of Corrections",
        state_full: "New York",
        address1: "15-00 Hazen St",
        source_url: "http://www.nyc.gov/html/doc/html/home/home.shtml",
        county: "New York City",
        phone: "(718) 546-1500",
        state: "NY",
        source_id: "ny-nyc",
        zip_code: "11370",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Lockport",
        name: "Niagara County Jail",
        state_full: "New York",
        address1: "5526 Niagara Street Ext",
        source_url: "http://www.niagarasheriff.com/JailMain.aspx",
        county: "Niagara County",
        phone: "(716) 438-3436",
        state: "NY",
        source_id: "ny-nicj",
        zip_code: "14094",
        email: "ncsd@niagaracounty.com",
        has_mugshots: true,
    },
    {
        city: "Albion",
        name: "Orleans County Sheriff's Office",
        state_full: "New York",
        address1: "13925 State Route 31",
        source_url:
            "http://www.orleansny.com/Departments/PublicSafety/Sheriff.aspx",
        county: "Orleans County",
        phone: "(585) 590-4142",
        state: "NY",
        source_id: "ny-olso",
        zip_code: "14411",
        email: "scott.hess@orleansny.com",
        has_mugshots: true,
    },
    {
        city: "Oswego",
        name: "Oswego County Sheriff's Office",
        state_full: "New York",
        address1: "39 Churchill Rd",
        source_url: "http://vinelink.com",
        county: "Oswego County",
        phone: "(315) 349-3307",
        state: "NY",
        source_id: "ny-ocso",
        zip_code: "13126",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Cooperstown",
        name: "Otsego County Jail",
        state_full: "New York",
        address1: "172 County Highway 33 W",
        source_url: "http://www.otsegocounty.com/depts/shf/Corrections.htm",
        county: "Otsego County",
        phone: "(607) 547-4252",
        state: "NY",
        source_id: "ny-otcj",
        zip_code: "13326",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Carmel",
        name: "Putnam County Sheriff's Office",
        state_full: "New York",
        address1: "3 County Center",
        source_url: "http://putnamsheriff.com",
        county: "Putnam County",
        phone: "(845) 225-4300",
        state: "NY",
        source_id: "ny-pcso",
        zip_code: "10512",
        email: null,
        has_mugshots: true,
    },
    {
        city: "New City",
        name: "Rockland County Sheriff's Office",
        state_full: "New York",
        address1: "55 New Hemsptead Rd",
        source_url: "http://www.rocklandcountysheriffoffice.com",
        county: "Rockland County",
        phone: "(845) 638-5400",
        state: "NY",
        source_id: "ny-rcso",
        zip_code: "10956",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Watkins Glen",
        name: "Schuyler County Sheriff's Office",
        state_full: "New York",
        address1: "106 10th St",
        source_url: "http://vinelink.com",
        county: "Schuyler County",
        phone: "(607) 535-8222",
        state: "NY",
        source_id: "ny-slco",
        zip_code: "14891",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Romulus",
        name: "Seneca County Jail",
        state_full: "New York",
        address1: "6150 New York 96",
        source_url: "http://sheriff.co.seneca.ny.us/",
        county: "Seneca County",
        phone: "(315) 539-9241",
        state: "NY",
        source_id: "ny-sncj",
        zip_code: "14541",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Canton",
        name: "St. Lawrence County Sheriff's Office",
        state_full: "New York",
        address1: "17 Commerce Ln",
        source_url: "http://vinelink.com",
        county: "St. Lawrence County",
        phone: "(315) 379-2367",
        state: "NY",
        source_id: "ny-slcso",
        zip_code: "13617",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bath",
        name: "Steuben County Sheriff's Office",
        state_full: "New York",
        address1: "7007 Rumsey St.Ext.",
        source_url: "http://www.steubencony.org/Pages.asp?PGID=43",
        county: "Steuben County",
        phone: "(607) 622-3901",
        state: "NY",
        source_id: "ny-stso",
        zip_code: "14810",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Monticello",
        name: "Sullivan County Sheriff's Office",
        state_full: "New York",
        address1: "4 Bushnell Ave",
        source_url: "http://vinelink.com",
        county: "Sullivan County",
        phone: "(845) 794-7100",
        state: "NY",
        source_id: "ny-scso",
        zip_code: "12701",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Owego",
        name: "Tioga County Sheriff's Office",
        state_full: "New York",
        address1: "103 Corporate Dr",
        source_url: "http://www.tiogacountysheriff.com/index.asp?pageId=6",
        county: "Tioga County",
        phone: "(607) 687-1010",
        state: "NY",
        source_id: "ny-tcso",
        zip_code: "13827",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Ithaca",
        name: "Tompkins County Sheriff's Office",
        state_full: "New York",
        address1: "779 Warren Rd",
        source_url: "http://vinelink.com",
        county: "Tompkins County",
        phone: "(607) 257-1345",
        state: "NY",
        source_id: "ny-tmso",
        zip_code: "14850",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lake George",
        name: "Warren County Sheriff's Office",
        state_full: "New York",
        address1: "1400 State Route 9",
        source_url: "http://vinelink.com",
        county: "Warren County",
        phone: "(518) 743-2500",
        state: "NY",
        source_id: "ny-wrso",
        zip_code: "12845",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Fort Edward",
        name: "Washington County Sheriff's Office",
        state_full: "New York",
        address1: "399 Broadway",
        source_url: "http://washingtoncountysheriffny.com/",
        county: "Washington County",
        phone: "(518) 746-2475",
        state: "NY",
        source_id: "ny-wcso",
        zip_code: "12828",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lyons",
        name: "Wayne County Jail",
        state_full: "New York",
        address1: "7368 Route 31",
        source_url: "http://www.waynecosheriff.org/divcorr.htm",
        county: "Wayne County",
        phone: "(315) 946-9711",
        state: "NY",
        source_id: "ny-wacj",
        zip_code: "14489",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Penn Yan",
        name: "Yates County Sheriff's Office",
        state_full: "New York",
        address1: "227 Main Street",
        source_url: "http://www.yatescounty.org/display_page.asp?pID=86",
        county: "Yates County",
        phone: "(315) 536-4438",
        state: "NY",
        source_id: "ny-ycso",
        zip_code: "14527",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Graham",
        name: "Alamance County Sheriff's Office",
        state_full: "North Carolina",
        address1: "109 S Maple St",
        source_url: "http://vinelink.com",
        county: "Alamance County",
        phone: "(336) 570-6399",
        state: "NC",
        source_id: "nc-amso",
        zip_code: "27253",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Sparta",
        name: "Alleghany County Sheriff's Office",
        state_full: "North Carolina",
        address1: "40 Alleghany Street",
        source_url: "http://www.alleghanycounty-nc.gov/sheriff.php",
        county: "Alleghany County",
        phone: "(336) 372-4455",
        state: "NC",
        source_id: "nc-also",
        zip_code: "28675",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wadesboro",
        name: "Anson County Sheriff's Office",
        state_full: "North Carolina",
        address1: "119 N. Washington Street",
        source_url: "http://www.ansonsheriff.com/",
        county: "Anson County",
        phone: "(704) 694-4188",
        state: "NC",
        source_id: "nc-asso",
        zip_code: "28170",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jefferson",
        name: "Ashe County Sheriff's Office",
        state_full: "North Carolina",
        address1: "140 Government Circle",
        source_url: "http://www.ashecountysheriff.com",
        county: "Ashe County",
        phone: "(336) 846-5633",
        state: "NC",
        source_id: "nc-acso",
        zip_code: "28640",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Newland",
        name: "Avery County Sheriff's Office",
        state_full: "North Carolina",
        address1: "300 Shultz Circle",
        source_url: "http://www.averysheriff.org/",
        county: "Avery County",
        phone: "(828) 733-2071",
        state: "NC",
        source_id: "nc-arso",
        zip_code: "28657",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Windsor",
        name: "Bertie County Sheriff's Office",
        state_full: "North Carolina",
        address1: "104 Dundee St.",
        source_url:
            "http://www.co.bertie.nc.us/departments/sheriff/sheriff.html",
        county: "Bertie County",
        phone: "(252) 794-5330",
        state: "NC",
        source_id: "nc-bcso",
        zip_code: "27983",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Elizabethtown",
        name: "Bladen County Sheriff's Office",
        state_full: "North Carolina",
        address1: "201 King St.",
        source_url:
            "http://www.bladennc.govoffice3.com/index.asp?SEC=CAF22648-0FEC-44E6-BB41-27DD43E0C4E8&Type=B_BASIC",
        county: "Bladen County",
        phone: "(910) 862-6960",
        state: "NC",
        source_id: "nc-blso",
        zip_code: "28337",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bolivia",
        name: "Brunswick County Sheriff's Department",
        state_full: "North Carolina",
        address1: "70 Stamp Act Dr",
        source_url: "http://brunswicksheriff.com/",
        county: "Brunswick County",
        phone: "(910) 253-2760",
        state: "NC",
        source_id: "nc-brsd",
        zip_code: "28422",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Asheville",
        name: "Buncombe County Sheriff's Office",
        state_full: "North Carolina",
        address1: "202 Haywood St",
        source_url: "http://vinelink.com",
        county: "Buncombe County",
        phone: "(828) 250-4474",
        state: "NC",
        source_id: "nc-bnso",
        zip_code: "28801",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Morganton",
        name: "Burke County Sheriff's Office",
        state_full: "North Carolina",
        address1: "150 Government Dr",
        source_url: "http://www.burkesheriff.org",
        county: "Burke County",
        phone: "(828) 438-5500",
        state: "NC",
        source_id: "nc-brso",
        zip_code: "28655",
        email: "admin@burkesheriff.org",
        has_mugshots: true,
    },
    {
        city: "Concord",
        name: "Cabarrus County Sheriff's Office",
        state_full: "North Carolina",
        address1: "30 Corban Ave SE",
        source_url: "http://www.cabarruslaw.us",
        county: "Cabarrus County",
        phone: "(704) 920-3000",
        state: "NC",
        source_id: "nc-cbso",
        zip_code: "28025",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lenoir",
        name: "Caldwell County Sheriff's Office",
        state_full: "North Carolina",
        address1: "2351 Morganton Boulevard SW",
        source_url: "http://caldwellsheriff.org/",
        county: "Caldwell County",
        phone: "(828) 759-2324",
        state: "NC",
        source_id: "nc-cwso",
        zip_code: "28645",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Beaufort",
        name: "Carteret County Sheriff's Office",
        state_full: "North Carolina",
        address1: "304 Craven Street",
        source_url: "http://www.carteretsheriff.com",
        county: "Carteret County",
        phone: "(252) 728-8400",
        state: "NC",
        source_id: "nc-ctso",
        zip_code: "28516",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Yanceyville",
        name: "Caswell County Sheriff's Office",
        state_full: "North Carolina",
        address1: "231 County Park Rd",
        source_url: "http://www.caswellcountysheriffsoffice.com/index.htm",
        county: "Caswell County",
        phone: "(336) 694-9311",
        state: "NC",
        source_id: "nc-csso",
        zip_code: "27379",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Murphy",
        name: "Cherokee County Sheriff's Office",
        state_full: "North Carolina",
        address1: "577 Regal Street",
        source_url: "http://tinyurl.com/28owo5g",
        county: "Cherokee County",
        phone: "(828) 837-2589",
        state: "NC",
        source_id: "nc-chso",
        zip_code: "28906",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hayesville",
        name: "Clay County Sheriff's Office",
        state_full: "North Carolina",
        address1: "295 Courthouse Dr",
        source_url: "http://www.claycountyso.org",
        county: "Clay County",
        phone: "(828) 389-6354",
        state: "NC",
        source_id: "nc-cyso",
        zip_code: "28904",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Shelby",
        name: "Cleveland County Sheriff's Office",
        state_full: "North Carolina",
        address1: "100 Justice Place",
        source_url: "http://www.sheriffclevelandcounty.com",
        county: "Cleveland County",
        phone: "(704) 484-4888",
        state: "NC",
        source_id: "nc-ccso",
        zip_code: "28150",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Whiteville",
        name: "Columbus County Sheriff's Office",
        state_full: "North Carolina",
        address1: "805 Washington Street ",
        source_url:
            "http://www.columbusco.org/dotnetnuke_2/sheriff/SheriffHome/tabid/574/Default.aspx",
        county: "Columbus County",
        phone: "(910) 642-6551",
        state: "NC",
        source_id: "nc-clso",
        zip_code: "28472",
        email: "lhatcher@columbusco.org",
        has_mugshots: true,
    },
    {
        city: "Fayetteville",
        name: "Cumberland County Sheriff's Office",
        state_full: "North Carolina",
        address1: "131 Dick St",
        source_url: "http://www.ccsonc.org/sheriff.htm",
        county: "Cumberland County",
        phone: "(910) 323-1500",
        state: "NC",
        source_id: "nc-cmso",
        zip_code: "28301",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Maple",
        name: "Currituck County Sheriff's Office",
        state_full: "North Carolina",
        address1: "407 Maple Road",
        source_url: "http://www.co.currituck.nc.us/Sheriffs-Office.cfm",
        county: "Currituck County",
        phone: "(252) 232-2216",
        state: "NC",
        source_id: "nc-crso",
        zip_code: "27956",
        email: "Susan.Johnson@CurrituckCountyNC.gov",
        has_mugshots: false,
    },
    {
        city: "Manteo",
        name: "Dare County Sheriff's Office",
        state_full: "North Carolina",
        address1: "962 Marshall C. Collins Drive",
        source_url: "http://www.darecountysheriff.com",
        county: "Dare County",
        phone: "(252) 475-5980",
        state: "NC",
        source_id: "nc-daso",
        zip_code: "27954",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lexington",
        name: "Davidson County Sheriff's Office",
        state_full: "North Carolina",
        address1: "110 W. Center Street",
        source_url: "http://www.co.davidson.nc.us/sheriff/",
        county: "Davidson County",
        phone: "(336) 242-2100",
        state: "NC",
        source_id: "nc-dvso",
        zip_code: "27292",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mocksville",
        name: "Davie County Sheriff's Office",
        state_full: "North Carolina",
        address1: "140 S. Main St",
        source_url: "http://www.dcsonc.com",
        county: "Davie County",
        phone: "(336) 751-6238",
        state: "NC",
        source_id: "nc-dcso",
        zip_code: "27028",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Kenansville",
        name: "Duplin County Sheriff's Office",
        state_full: "North Carolina",
        address1: "112 W. Hill St.  PO Box 908",
        source_url:
            "http://www.duplincountync.com/governmentOffices/sheriff.html",
        county: "Duplin County",
        phone: "(910) 296-2150",
        state: "NC",
        source_id: "nc-dpso",
        zip_code: "28349",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Durham",
        name: "Durham County Sheriff's Office",
        state_full: "North Carolina",
        address1: "510 S Dillard St",
        source_url: "http://dconc.gov/index.aspx?page=475",
        county: "Durham County",
        phone: "(919) 560-0897",
        state: "NC",
        source_id: "nc-drso",
        zip_code: "27701",
        email: "sheriff@durhamsheriff.org",
        has_mugshots: true,
    },
    {
        city: "Tarboro",
        name: "Edgecombe County Sheriff's Office",
        state_full: "North Carolina",
        address1: "3005 Anaconda Road",
        source_url: "http://www.edgecombecountync.gov/sheriff/sheriff.aspx",
        county: "Edgecombe County",
        phone: "(252) 641-7915",
        state: "NC",
        source_id: "nc-ecso",
        zip_code: "27886",
        email: "sheriff.knight@co.edgecombe.nc.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Forsyth County Sheriff's Office",
        state_full: "North Carolina",
        address1: "301 North Church Street ",
        source_url: "http://p2c.fcso.us/",
        county: "Forsyth",
        phone: "Phone: 336 917 7001 \nFax: 336 727 8070 ",
        state: "NC",
        source_id: "nc-fors",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Louisburg",
        name: "Franklin County Sheriff's Office",
        state_full: "North Carolina",
        address1: "285 T-Kemp Road",
        source_url: "http://www.franklincountync.us/services/sheriff",
        county: "Franklin County",
        phone: "(919) 496-3332",
        state: "NC",
        source_id: "nc-fcso",
        zip_code: "27549",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Robbinsville",
        name: "Graham County Sheriff's Office",
        state_full: "North Carolina",
        address1: "45 South Main Street",
        source_url:
            "http://www.grahamcounty.org/grahamcounty_departments_sheriff.html",
        county: "Graham County",
        phone: "(828) 479-3352",
        state: "NC",
        source_id: "nc-ghso",
        zip_code: "28771",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Oxford",
        name: "Granville County Sheriff's Office",
        state_full: "North Carolina",
        address1: "143 Williamsboro Street",
        source_url: "http://www.granvillenc.govoffice2.com/",
        county: "Granville County",
        phone: "(919) 693-3213",
        state: "NC",
        source_id: "nc-gnso",
        zip_code: "27565",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Snow Hill",
        name: "Greene County Sheriff's Office",
        state_full: "North Carolina",
        address1: "301 N. Greene St",
        source_url:
            "http://www.co.greene.nc.us/Departments_SheriffsOffice.aspx",
        county: "Greene County",
        phone: "(252) 747-3411",
        state: "NC",
        source_id: "nc-grso",
        zip_code: "28580",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Greensboro",
        name: "Guilford County Sheriff's Office",
        state_full: "North Carolina",
        address1: "201 S Edgeworth St",
        source_url: "http://guilfordcountysheriff.com/",
        county: "Guilford County",
        phone: "(336) 641-2700",
        state: "NC",
        source_id: "nc-gcso",
        zip_code: "27401",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Halifax",
        name: "Halifax County Sheriff's Office",
        state_full: "North Carolina",
        address1: "355 Ferrell Lane",
        source_url: "http://www.halifaxsheriff.com/",
        county: "Halifax County",
        phone: "(252) 583-8201",
        state: "NC",
        source_id: "nc-hcso",
        zip_code: "27839",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Waynesville",
        name: "Haywood County Sheriff's Office",
        state_full: "North Carolina",
        address1: "1620 Brown Ave",
        source_url:
            "http://www.haywoodnc.net/index.php?option=com_content&view=article&id=183:sheriff&catid=61:Sheriff&Itemid=194",
        county: "Haywood County",
        phone: "(828) 452-6666",
        state: "NC",
        source_id: "nc-hyso",
        zip_code: "28786",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hendersonville",
        name: "Henderson County Sheriff's Office",
        state_full: "North Carolina",
        address1: "100 North Grove Street",
        source_url: "http://www.hendersoncountync.org/sheriff/index.html",
        county: "Henderson County",
        phone: "(828) 697-4596",
        state: "NC",
        source_id: "nc-hnso",
        zip_code: "28792",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Winton",
        name: "Hertford County Sheriff's Office",
        state_full: "North Carolina",
        address1: "701 North Taylor Street",
        source_url: "http://www.hertfordcountync.gov/?page_id=123",
        county: "Hertford County",
        phone: "(252) 358-7800",
        state: "NC",
        source_id: "nc-hrso",
        zip_code: "27986",
        email: "hfdcounty.sheriff@hertfordcountync.gov",
        has_mugshots: true,
    },
    {
        city: "Raeford",
        name: "Hoke County Sheriff's Office",
        state_full: "North Carolina",
        address1: "429 E. Central Avenue",
        source_url: "http://www.hokecountysheriff.org/",
        county: "Hoke County",
        phone: "(910) 875-5111",
        state: "NC",
        source_id: "nc-hkso",
        zip_code: "28376",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Statesville",
        name: "Iredell County Sheriff's Office",
        state_full: "North Carolina",
        address1: "230 N. Tradd St.",
        source_url: "http://www.iredellsheriff.com/",
        county: "Iredell County",
        phone: "(704) 878-3180",
        state: "NC",
        source_id: "nc-icso",
        zip_code: "28687",
        email: "icsoinfo@co.iredell.nc.us",
        has_mugshots: true,
    },
    {
        city: "Sylva",
        name: "Jackson County Sheriff's Office",
        state_full: "North Carolina",
        address1: "399 Grindstaff Cove Road",
        source_url: "http://www.sheriff.jacksonnc.org/",
        county: "Jackson County",
        phone: "(828) 586-4355",
        state: "NC",
        source_id: "nc-jkso",
        zip_code: "28779",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Trenton",
        name: "Jones County Sheriff's Office",
        state_full: "North Carolina",
        address1: "101 Market Street",
        source_url: "http://vinelink.com",
        county: "Jones County",
        phone: "(252) 448-7091",
        state: "NC",
        source_id: "nc-jcso",
        zip_code: "28525",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Sanford",
        name: "Lee County Sheriff's Office",
        state_full: "North Carolina",
        address1: "1401 Elm Street",
        source_url:
            "http://www.leecountync.gov/Departments/SheriffsOffice/tabid/116/Default.aspx",
        county: "Lee County",
        phone: "(919) 775-5531",
        state: "NC",
        source_id: "nc-lcso",
        zip_code: "27331",
        email: "report-a-crime@leecountync.gov",
        has_mugshots: true,
    },
    {
        city: "Kinston",
        name: "Lenoir County Sheriff's Office",
        state_full: "North Carolina",
        address1: "PO Box 3289",
        source_url: "http://vinelink.com",
        county: "Lenoir County",
        phone: "(252) 559-6100",
        state: "NC",
        source_id: "nc-lnso",
        zip_code: "28502",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lincoln County Sheriff's Office",
        state_full: "North Carolina",
        address1: "700 John Howell Memorial DrivePO Box 506",
        source_url: "http://www.lincolnsheriff.org/",
        county: "Lincoln",
        phone: "(704) 735-8202",
        state: "NC",
        source_id: "nc-linc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Franklin",
        name: "Macon County Sheriff's Office",
        state_full: "North Carolina",
        address1: "1820 Lakeside Drive",
        source_url: "www.maconnc.org/sheriffs-office.html",
        county: "Macon County",
        phone: "(828) 349-2104",
        state: "NC",
        source_id: "nc-mcnso",
        zip_code: "28734",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Marshall",
        name: "Madison County Sheriff's Office",
        state_full: "North Carolina",
        address1: "348 Medical Park Dr",
        source_url: "http://www.madisoncountync.org/",
        county: "Madison County",
        phone: "(828) 649-2721",
        state: "NC",
        source_id: "nc-mdso",
        zip_code: "28753",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Marion",
        name: "McDowell County Sheriff's Office",
        state_full: "North Carolina",
        address1: "593 Spaulding Road",
        source_url: "http://www.mcdowellsheriff.org/",
        county: "McDowell County",
        phone: "(828) 652-2235",
        state: "NC",
        source_id: "nc-dwso",
        zip_code: "28752",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Charlotte",
        name: "Mecklenburg County Sheriff's Office",
        state_full: "North Carolina",
        address1: "801 East Fourth Street",
        source_url:
            "http://charmeck.org/mecklenburg/county/MCSO/Pages/default.aspx",
        county: "Mecklenburg County",
        phone: "(704) 336-8100",
        state: "NC",
        source_id: "nc-mcso",
        zip_code: "28202",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Troy",
        name: "Montgomery County Sheriff's Office",
        state_full: "North Carolina",
        address1: "111 West Main ST.",
        source_url: "http://www.montgomerycountync.com/",
        county: "Montgomery County",
        phone: "(910) 572-1313",
        state: "NC",
        source_id: "nc-mnso",
        zip_code: "27371",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Carthage",
        name: "Moore County Sheriff's Office",
        state_full: "North Carolina",
        address1: "302 South McNeill St.",
        source_url: "http://www.moorecountync.gov/index.php/en/sheriff",
        county: "Moore County",
        phone: "(910) 947-2931",
        state: "NC",
        source_id: "nc-mrso",
        zip_code: "28327",
        email: "sheriff@moorecountync.gov",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Morganton County Sheriff’s Office",
        state_full: "North Carolina",
        address1: null,
        source_url: "http://www.morgantonnc.gov/",
        county: "Morganton County",
        phone: null,
        state: "NC",
        source_id: "nc-morg",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Nashville",
        name: "Nash County Sheriff's Department",
        state_full: "North Carolina",
        address1: "120 W Washington St",
        source_url: "http://www.co.nash.nc.us/index.aspx?NID=252",
        county: "Nash County",
        phone: "(252) 459-1536",
        state: "NC",
        source_id: "nc-nasd",
        zip_code: "27856",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "New Hanover County Sheriff's Office",
        state_full: "North Carolina",
        address1: "3950 Juvenile Center Rd, Castle Hayne",
        source_url: "http://p2c.nhcgov.com/p2c/",
        county: "New Hanover",
        phone: "Main Phone: 910-798-4161",
        state: "NC",
        source_id: "nc-nhcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jackson",
        name: "Northampton County Sheriff's Office",
        state_full: "North Carolina",
        address1: "105 W Jefferson St",
        source_url: "http://www.northamptonnc.com/sheriff.asp",
        county: "Northampton County",
        phone: "(252) 534-2611",
        state: "NC",
        source_id: "nc-ncso",
        zip_code: "27845",
        email: "Jack.Smith@nhcnc.net",
        has_mugshots: true,
    },
    {
        city: "Jacksonville",
        name: "Onslow County Sheriff's Office",
        state_full: "North Carolina",
        address1: "717 Court Street",
        source_url: "http://sheriff.onslowcountync.gov/",
        county: "Onslow County",
        phone: "(910) 455-3113",
        state: "NC",
        source_id: "nc-ocso",
        zip_code: "28540",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hillsborough",
        name: "Orange County Sheriff's Office",
        state_full: "North Carolina",
        address1: "144 E Margaret Ln",
        source_url: "http://vinelink.com",
        county: "Orange County",
        phone: "(919) 644-3050",
        state: "NC",
        source_id: "nc-onso",
        zip_code: "27278",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bayboro",
        name: "Pamlico County Sheriff's Office",
        state_full: "North Carolina",
        address1: "200 Main St",
        source_url: "http://www.co.pamlico.nc.us/sheriff.aspx",
        county: "Pamlico County",
        phone: "(252) 745-3101",
        state: "NC",
        source_id: "nc-pmso",
        zip_code: "28515",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Burgaw",
        name: "Pender County Sheriff's Office",
        state_full: "North Carolina",
        address1: "605 E Fremont St.",
        source_url: "http://www.pendersheriff.com/",
        county: "Pender County",
        phone: "(910) 259-1212",
        state: "NC",
        source_id: "nc-pnso",
        zip_code: "28425",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Roxboro",
        name: "Person County Sheriff's Office",
        state_full: "North Carolina",
        address1: "120 Court St. ",
        source_url: "http://www.personcounty.net/index.aspx?page=183",
        county: "Person County",
        phone: "(336) 597-0500",
        state: "NC",
        source_id: "nc-prso",
        zip_code: "27573",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Greenville",
        name: "Pitt County Sheriff's Office",
        state_full: "North Carolina",
        address1: "100 West 3rd Street",
        source_url: "http://www.pittcountysheriff.com",
        county: "Pitt County",
        phone: "(252) 902-2800",
        state: "NC",
        source_id: "nc-ptso",
        zip_code: "27834",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Columbus",
        name: "Polk County Sheriff's Office",
        state_full: "North Carolina",
        address1: "40 Ward St",
        source_url: "http://www.polkcosheriff.org",
        county: "Polk County",
        phone: "(828) 894-3001",
        state: "NC",
        source_id: "nc-pcso",
        zip_code: "28722",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Randolph County Sheriff's Office",
        state_full: "North Carolina",
        address1: " 790 New Century Dr",
        source_url: "http://www.co.randolph.nc.us/sheriff/",
        county: "Randolph",
        phone: "Phone - 336.318.6625.",
        state: "NC",
        source_id: "nc-rand",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lumberton",
        name: "Robeson County Sheriff's Office",
        state_full: "North Carolina",
        address1: "120 Legend Road",
        source_url: "http://www.robesoncoso.org/",
        county: "Robeson County",
        phone: "(910) 671-3100",
        state: "NC",
        source_id: "nc-rcso",
        zip_code: "28358",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Reidsville",
        name: "Rockingham County Sheriff's Office",
        state_full: "North Carolina",
        address1: "170 N.C. 65",
        source_url: "http://www.rockinghamsheriff.com/",
        county: "Rockingham County",
        phone: "(336) 634-3232",
        state: "NC",
        source_id: "nc-rkso",
        zip_code: "27320",
        email: "ksuthard@co.rockingham.nc.us",
        has_mugshots: true,
    },
    {
        city: "Salisbury",
        name: "Rowan County Sheriff's Office",
        state_full: "North Carolina",
        address1: "232 North Main Street",
        source_url: "http://www.rowansheriff.org",
        county: "Rowan County",
        phone: "(704) 216-8700",
        state: "NC",
        source_id: "nc-rwso",
        zip_code: "28144",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Rutherfordton",
        name: "Rutherford County Sheriff's Office",
        state_full: "North Carolina",
        address1: "198 North Washington Street",
        source_url: "http://rutherfordcountync.gov/sheriffsoffice",
        county: "Rutherford County",
        phone: "(828) 287-6247",
        state: "NC",
        source_id: "nc-rtso",
        zip_code: "28139",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Clinton",
        name: "Sampson County Sheriff's Office",
        state_full: "North Carolina",
        address1: "112 Fontana Street",
        source_url: "http://www.sampsonsheriff.com",
        county: "Sampson County",
        phone: "(910) 592-4141",
        state: "NC",
        source_id: "nc-smso",
        zip_code: "28328",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Laurinburg",
        name: "Scotland County Sheriff's Office",
        state_full: "North Carolina",
        address1: "212 BIGGS ST",
        source_url: "http://scotlandcountysheriff.org",
        county: "Scotland County",
        phone: "(910) 276-3385",
        state: "NC",
        source_id: "nc-slso",
        zip_code: "28352",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Albemarle",
        name: "Stanly County Sheriff's Office",
        state_full: "North Carolina",
        address1: "223 South 2nd Street",
        source_url: "http://www.stanlysheriff.us/index.htm",
        county: "Stanly County",
        phone: "(704) 986-3714",
        state: "NC",
        source_id: "nc-scso",
        zip_code: "28001",
        email: "clking@stanlycountync.gov",
        has_mugshots: true,
    },
    {
        city: "Danbury",
        name: "Stokes County Sheriff's Office",
        state_full: "North Carolina",
        address1: "1012 N Main St.",
        source_url: "http://www.co.stokes.nc.us/sheriff/",
        county: "Stokes County",
        phone: "(336) 593-8787",
        state: "NC",
        source_id: "nc-stso",
        zip_code: "27016",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bryson City",
        name: "Swain County Sheriff's Office",
        state_full: "North Carolina",
        address1: "95 Brendle Street",
        source_url: "http://swaincountync.gov/sheriff-home.html",
        county: "Swain County",
        phone: "(828) 488-0159",
        state: "NC",
        source_id: "nc-swso",
        zip_code: "28713",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Transylvania County Sheriff's Office",
        state_full: "North Carolina",
        address1: null,
        source_url: "http://www.transylvaniasheriff.org/",
        county: "Transylvania County",
        phone: null,
        state: "NC",
        source_id: "nc-tcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Henderson",
        name: "Vance County Sheriff's Office",
        state_full: "North Carolina",
        address1: "156 Church Street ",
        source_url: "http://www.vancesheriff.org/",
        county: "Vance County",
        phone: "(252) 738-2200",
        state: "NC",
        source_id: "nc-vcso",
        zip_code: "27536",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Wake County Sheriff's Office",
        state_full: "North Carolina",
        address1: "330 South Salisbury Street",
        source_url: "http://www.wakegov.com/sheriff",
        county: "Wake County",
        phone: "Jail: 919-856-5952",
        state: "NC",
        source_id: "nc-wcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Warrenton",
        name: "Warren County Sheriff's Office",
        state_full: "North Carolina",
        address1: "132 Rafters Lane",
        source_url: "http://www.warrencountync.com/Sheriff.aspx",
        county: "Warren County",
        phone: "(252) 257-3364",
        state: "NC",
        source_id: "nc-wrso",
        zip_code: "27589",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Plymouth",
        name: "Washington County Sheriff's Office",
        state_full: "North Carolina",
        address1: "120 Adams Street",
        source_url: "http://www.washconc.org/sheriff_department.aspx",
        county: "Washington County",
        phone: "(252) 793-2422",
        state: "NC",
        source_id: "nc-wsso",
        zip_code: "27962",
        email: "jross@washconc.org",
        has_mugshots: true,
    },
    {
        city: "Boone",
        name: "Watauga County Sheriff's Office",
        state_full: "North Carolina",
        address1: "184 Hodges Gap Road",
        source_url:
            "http://www.wataugacounty.org/main/App_Pages/Dept/Sheriff/home.aspx",
        county: "Watauga County",
        phone: "(828) 264-3761",
        state: "NC",
        source_id: "nc-wtso",
        zip_code: "28607",
        email: "len.hagaman@watgov.org",
        has_mugshots: true,
    },
    {
        city: "Goldsboro",
        name: "Wayne County Sheriff's Office",
        state_full: "North Carolina",
        address1: "207 E. Chestnut Street",
        source_url: "http://www.waynegov.com/domain/43",
        county: "Wayne County",
        phone: "(919) 731-1481",
        state: "NC",
        source_id: "nc-wyso",
        zip_code: "27533",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wilkesboro",
        name: "Wilkes County Sheriff's Office",
        state_full: "North Carolina",
        address1: "201 Curtis Bridge Rd",
        source_url: "http://wilkescountysheriff.net/",
        county: "Wilkes County",
        phone: "(336) 903-7600",
        state: "NC",
        source_id: "nc-wlso",
        zip_code: "28697",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Wilson",
        name: "Wilson County Sheriff's Office",
        state_full: "North Carolina",
        address1: "100 Green St E",
        source_url: "http://www.wilson-co.com/212/Office-of-the-Sheriff",
        county: "Wilson County",
        phone: "(252) 237-2118",
        state: "NC",
        source_id: "nc-wnso",
        zip_code: "27893",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Yadkinville",
        name: "Yadkin County Sheriff's Office",
        state_full: "North Carolina",
        address1: "210 East Hemlock St.",
        source_url: "http://nc-yadkincounty.civicplus.com/index.aspx?nid=216",
        county: "Yadkin County",
        phone: "(336) 679-4217",
        state: "NC",
        source_id: "nc-ydso",
        zip_code: "27055",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Burnsville",
        name: "Yancey County Sheriff's Office",
        state_full: "North Carolina",
        address1: "4 East Main Street",
        source_url: "http://yanceycountysheriff.org/",
        county: "Yancey County",
        phone: "(828) 682-2124",
        state: "NC",
        source_id: "nc-ycso",
        zip_code: "28714",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bismarck",
        name: "Burleigh County Sheriff's Office",
        state_full: "North Dakota",
        address1: "514 East Thayer Avenue",
        source_url: "http://www.co.burleigh.nd.us/departments/sheriff/",
        county: "Burleigh County",
        phone: "(701) 222-6651",
        state: "ND",
        source_id: "nd-bcso",
        zip_code: "58501",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Cass County Sheriff's Office",
        state_full: "North Dakota",
        address1: "450 34th Street South",
        source_url:
            "https://www.casscountynd.gov/county/depts/sheriff/Pages/default.aspx",
        county: "Cass County",
        phone: "TEL\n(701) 271-2900\n(701) 271-2918",
        state: "ND",
        source_id: "nd-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Williams County Sheriff's Office",
        state_full: "North Dakota",
        address1: null,
        source_url: "http://www.williamsnd.com/",
        county: "Williams County",
        phone: null,
        state: "ND",
        source_id: "nd-wcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Adams County Sheriff's Office",
        state_full: "Ohio",
        address1: "110 West Main Street ",
        source_url: "http://www.adamscountyso.com/",
        county: "Adams County",
        phone: "Phone: 937-544-2314\nFax: 937-544-0000",
        state: "OH",
        source_id: "oh-acso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Georgetown",
        name: "Brown County Sheriff's Office",
        state_full: "Ohio",
        address1: "755 Mt Orab Pike",
        source_url: "http://www.browncountyohiosheriff.us/",
        county: "Brown County",
        phone: "(315) 946-5714",
        state: "OH",
        source_id: "oh-bcso",
        zip_code: "45121",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Hamilton",
        name: "Butler County Sheriff's Office",
        state_full: "Ohio",
        address1: "705 Hanover St",
        source_url: "http://www.butlersheriff.org/index.html",
        county: "Butler County",
        phone: "(513) 785-1000",
        state: "OH",
        source_id: "oh-btso",
        zip_code: "45011",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Springfield",
        name: "Clark County Sheriff's Office",
        state_full: "Ohio",
        address1: "120 N Fountain Ave",
        source_url: "http://www.clarkcountyohio.gov/sheriff/index.htm",
        county: "Clark County",
        phone: "(937) 521-2050",
        state: "OH",
        source_id: "oh-ccso",
        zip_code: "45502",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Batavia",
        name: "Clermont County Sheriff's Office",
        state_full: "Ohio",
        address1: "4700 East Filager Road",
        source_url: "http://www.clermontsheriff.org/",
        county: "Clermont County",
        phone: "(513) 732-7540",
        state: "OH",
        source_id: "oh-clso",
        zip_code: "45103",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Clinton County Sheriff’s Office",
        state_full: "Ohio",
        address1: null,
        source_url: "http://clintonsheriff.com/",
        county: "Clinton County",
        phone: null,
        state: "OH",
        source_id: "oh-cltn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Coshocton",
        name: "Coshocton County Sheriff's Office",
        state_full: "Ohio",
        address1: "328 Chestnut Street",
        source_url: "http://www.coshoctoncounty.net/sheriff/",
        county: "Coshocton County",
        phone: "(740) 622-2411",
        state: "OH",
        source_id: "oh-csso",
        zip_code: "43812",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Cuyahoga County Sheriff's Office",
        state_full: "Ohio",
        address1: "1215 W. 3rd Street",
        source_url: "https://vinelink.com",
        county: "Cuyahoga County",
        phone: "Visitation Phone: 216-698-2717",
        state: "OH",
        source_id: "oh-cuya",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "Greenville",
        name: "Darke County Sheriff's Office",
        state_full: "Ohio",
        address1: "5185 County Home Rd",
        source_url: "http://www.darkecountysheriff.org",
        county: "Darke County",
        phone: "(937) 548-3399",
        state: "OH",
        source_id: "oh-dcso",
        zip_code: "45331",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Fairfield County Sheriff's Office",
        state_full: "Ohio",
        address1: "221 East Main Street",
        source_url: "http://vinelink.com",
        county: "Fairfield County",
        phone: "(740) 652-7252",
        state: "OH",
        source_id: "oh-frso",
        zip_code: null,
        email: "prenko@sheriff.fairfield.oh.us",
        has_mugshots: true,
    },
    {
        city: "Washington Court House",
        name: "Fayette County Sheriff's Office",
        state_full: "Ohio",
        address1: "113 E Market St",
        source_url: "http://www.faycoso.com/",
        county: "Fayette County",
        phone: "(740) 333-3783",
        state: "OH",
        source_id: "oh-fcso",
        zip_code: "43160",
        email: "matt.weidman@fayette-co-oh.com",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Franklin County Sheriff's Office",
        state_full: "Ohio",
        address1:
            "Columbus, OH 43215\n\n(614) 525-3333\n\nDial 911 for Emergencies\n\nInmate Information\nZero Tolerance Policy (PDF)\n\nFCCC I\n\n370 S. Front Street",
        source_url: "https://vinelink.com",
        county: "Franklin County",
        phone: "614-525-3368",
        state: "OH",
        source_id: "oh-fran",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "Xenia",
        name: "Green County Sheriff's Office",
        state_full: "Ohio",
        address1: "77 E Market St",
        source_url: "http://www.co.greene.oh.us/sheriff/",
        county: "Green County",
        phone: "(937) 562-4840",
        state: "OH",
        source_id: "oh-gcso",
        zip_code: "45385",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Cincinnati",
        name: "Hamilton County Sheriff's Office",
        state_full: "Ohio",
        address1: "1000 Sycamore Street",
        source_url: "http://www.hcso.org/",
        county: "Hamilton County",
        phone: "(513) 946-6400",
        state: "OH",
        source_id: "oh-hcso",
        zip_code: "45202",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Hillsboro",
        name: "Highland County Sheriff's Office",
        state_full: "Ohio",
        address1: "110 Homestead Drive",
        source_url: "http://www.highlandcoso.com/",
        county: "Highland County",
        phone: "(937) 840-6240",
        state: "OH",
        source_id: "oh-hiso",
        zip_code: "45133",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Licking County Sheriff's Office",
        state_full: "Ohio",
        address1: "155 E. Main Street",
        source_url: "http://www.lcounty.com/Sheriff/default.aspx",
        county: "Licking",
        phone: "Main Phone: (740) 670-5501",
        state: "OH",
        source_id: "oh-lick",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bellefontaine",
        name: "Logan County Sheriff's Office",
        state_full: "Ohio",
        address1: "284 County Road 32 S",
        source_url: "http://www.logansheriff.com",
        county: "Logan County",
        phone: "(937) 592-8111",
        state: "OH",
        source_id: "oh-lcso",
        zip_code: "43311",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lorain County Sheriff's Office",
        state_full: "Ohio",
        address1: "100 West Erie Avenue",
        source_url: "http://www.loraincountysheriff.com/",
        county: "Lorain County",
        phone: "Main Phone: 440-329-377",
        state: "OH",
        source_id: "oh-lc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lucas County Sheriff's Office",
        state_full: "Ohio",
        address1: "03151 CR 2425",
        source_url: "https://vinelink.com",
        county: "Lucas County",
        phone: "TEL\n(419) 428-3800\nFAX\n(419) 428-2119",
        state: "OH",
        source_id: "oh-luca",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "Dayton",
        name: "Montgomery County Kettering Jail",
        state_full: "Ohio",
        address1: "330 West Second Street",
        source_url: "http://www.mcohio.org/sheriff/",
        county: "Montgomery County Kettering",
        phone: "(937) 225-3492",
        state: "OH",
        source_id: "oh-mckj",
        zip_code: "45422",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Dayton",
        name: "Montgomery County Sheriff's Office",
        state_full: "Ohio",
        address1: "330 W 2nd St",
        source_url: "http://www.co.montgomery.oh.us/sheriff/",
        county: "Montgomery County",
        phone: "(937) 225-4160",
        state: "OH",
        source_id: "oh-mcso",
        zip_code: "45402",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Eaton",
        name: "Preble County Sheriff's Office",
        state_full: "Ohio",
        address1: "1139 Preble Drive",
        source_url: "http://www.preblecountysheriff.org/",
        county: "Preble County",
        phone: "(937) 456-6323",
        state: "OH",
        source_id: "oh-pcso",
        zip_code: "45320",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Sidney",
        name: "Shelby County Sheriff's Office",
        state_full: "Ohio",
        address1: "555 Gearhart Rd",
        source_url: "http://www.shelbycountysheriff.com/",
        county: "Shelby County",
        phone: "(937) 498-7831",
        state: "OH",
        source_id: "oh-shso",
        zip_code: "45365",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Akron",
        name: "Summit County Sheriff's Office",
        state_full: "Ohio",
        address1: "205 East Crosier Street",
        source_url: "http://www.co.summit.oh.us/sheriff/",
        county: "Summit County",
        phone: "(330) 643-2171",
        state: "OH",
        source_id: "oh-scso",
        zip_code: "",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Suwannee County Sheriff's Office",
        state_full: "Ohio",
        address1: null,
        source_url: "http://suwanneesheriff.com/",
        county: "Suwannee County",
        phone: null,
        state: "OH",
        source_id: "oh-suwa",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mechanicsburg",
        name: "Tri-County Regional Jail",
        state_full: "Ohio",
        address1: "4099 State Route 559",
        source_url: "http://www.madisonsheriff.org/tri-county_jail.htm",
        county: "Madison County",
        phone: "(937) 834-5000",
        state: "OH",
        source_id: "oh-tcrj",
        zip_code: "43044",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Lebanon",
        name: "Warren County Sheriff's Office",
        state_full: "Ohio",
        address1: "550 Justice Dr",
        source_url: "http://www.wcsooh.org/",
        county: "Warren County",
        phone: "(513) 695-1280",
        state: "OH",
        source_id: "oh-wcso",
        zip_code: "45036",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Wooster",
        name: "Wayne County Sheriff's Office",
        state_full: "Ohio",
        address1: "201 W. North Street",
        source_url: "http://www.waynecountysheriff.com/",
        county: "Wayne County",
        phone: "(330) 287-5750",
        state: "OH",
        source_id: "oh-wyso",
        zip_code: "44691",
        email: "wcso@wcjustice-center.org",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Wood County Sheriff's Office",
        state_full: "Ohio",
        address1: "1960 E. Gypsy Lane Rd",
        source_url: "http://72.240.31.242/p2c/main.aspx",
        county: "Wood",
        phone: "Main Phone: 419-354-7744",
        state: "OH",
        source_id: "oh-wood",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Cleveland County Sheriff's Office",
        state_full: "Oklahoma",
        address1: "2550 West Franklin Rd.Norman",
        source_url: "http://ccso-ok.us/",
        county: "Cleveland",
        phone: "Visitation Phone: 405-701-7701",
        state: "OK",
        source_id: "ok-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Chandler ",
        name: "Lincoln County Sheriff's Office",
        state_full: "Oklahoma",
        address1: "811 Manvel Ave  #14 ",
        source_url: "http://www.lincolncountysheriffok.com/",
        county: "Lincoln County",
        phone: "(405) 258-1191",
        state: "OK",
        source_id: "ok-lcso",
        zip_code: "74834",
        email: "lincolncounty@outlook.com",
        has_mugshots: false,
    },
    {
        city: "Oklahoma City",
        name: "Oklahoma County Sheriff's Office",
        state_full: "Oklahoma",
        address1: "201 N Shartel Ave",
        source_url: "http://www.oklahomacounty.org/sheriff/",
        county: "Oklahoma County",
        phone: "(405) 713-1000",
        state: "OK",
        source_id: "ok-ocso",
        zip_code: "73102",
        email: "lisa.dickerson@wcsooh.org",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Rogers County Sheriff’s Office",
        state_full: "Oklahoma",
        address1: "201 South Cherokee Street",
        source_url: "http://rcsheriff.org/",
        county: "Rogers County",
        phone: "Main Phone: (918) 342-9797",
        state: "OK",
        source_id: "ok-rcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Tulsa",
        name: "Tulsa County Sheriff's Office",
        state_full: "Oklahoma",
        address1: "500 S Denver Ave",
        source_url: "http://www.tcso.org/tcsoweb/GlanzBio.aspx",
        county: "Tulsa County",
        phone: "(918) 596-5633",
        state: "OK",
        source_id: "ok-tcso",
        zip_code: "74103",
        email: "sglanz@tcso.org",
        has_mugshots: true,
    },
    {
        city: "Alva",
        name: "Woods County Sheriff's Office",
        state_full: "Oklahoma",
        address1: "407 Government St",
        source_url: "http://vinelink.com",
        county: "Woods County",
        phone: "(580) 327-3434",
        state: "OK",
        source_id: "ok-wcso",
        zip_code: "73717",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Oregon City",
        name: "Clackamas County Sheriff's Office",
        state_full: "Oregon",
        address1: "2206 Kaen Rd.",
        source_url: "http://www.clackamas.us/safetyapp/inmates/",
        county: "Clackamas",
        phone: "(503) 722-6777",
        state: "OR",
        source_id: "or-clac",
        zip_code: "97045",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Columbia County Sheriff's Office",
        state_full: "Oregon",
        address1: "901 Port Avenue",
        source_url: "http://www.co.columbia.or.us/sheriff/",
        county: "Columbia County",
        phone: "586--997-2332",
        state: "OR",
        source_id: "or-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bend",
        name: "Deschutes County Sheriff's Office",
        state_full: "Oregon",
        address1: "63333 Highway 20",
        source_url: "http://sheriff.deschutes.org",
        county: "Deschutes County",
        phone: "(541) 388-6661",
        state: "OR",
        source_id: "or-dcso",
        zip_code: "97701",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Jackson County Sheriff's Office",
        state_full: "Oregon",
        address1: "5179 CRATER LAKE HWY",
        source_url: "http://jacksoncountyor.org/",
        county: "Jackson",
        phone: "(541) 774-6800",
        state: "OR",
        source_id: "or-jcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Newport",
        name: "Lincoln County Sheriff's Office",
        state_full: "Oregon",
        address1: "225 W Olive St.",
        source_url: "http://vinelink.com",
        county: "Lincoln County",
        phone: "(541) 265-4277",
        state: "OR",
        source_id: "or-lcso",
        zip_code: "97365",
        email: "lcsheriff@co.lincoln.or.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Linn County Sheriff's Office",
        state_full: "Oregon",
        address1: "1115 Jackson ST SE",
        source_url: "https://www.linnsheriff.org/",
        county: "Linn County",
        phone: "Main Phone: 541-967-3901",
        state: "OR",
        source_id: "or-linn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Salem",
        name: "Marion County Sheriff's Office",
        state_full: "Oregon",
        address1: "4000 Aumsville Hwy SE",
        source_url: "http://www.co.marion.or.us/SO/",
        county: "Marion County",
        phone: "(503) 581-1183",
        state: "OR",
        source_id: "or-mcso",
        zip_code: "97317",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Multnomah County Sheriff's Office",
        state_full: "Oregon",
        address1: "1120 SW Third Ave.",
        source_url: "http://www.mcso.us/",
        county: "Multnomah",
        phone: "Main Phone: 503-988-3689",
        state: "OR",
        source_id: "or-mucso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Washington County Sheriff’s Office",
        state_full: "Oregon",
        address1: "215 SW Adams, MS 32",
        source_url: "http://www.co.washington.or.us/sheriff/",
        county: "Washington County",
        phone: "Jail (Main): 503-846-6826\nAdministration: 503-846-",
        state: "OR",
        source_id: "or-wcsc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Yamhill County Sheriff's Office",
        state_full: "Oregon",
        address1: "535 NE 5th Street",
        source_url: "http://www.co.yamhill.or.us/",
        county: "Yamhill County",
        phone: "Main Phone: (503) 434-7507",
        state: "OR",
        source_id: "or-ycso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Butler",
        name: "Butler County Sheriff's Office",
        state_full: "Pennsylvania",
        address1: "300 South Main Street",
        source_url: "http://vinelink.com",
        county: "Butler County",
        phone: "(724) 431-2474",
        state: "PA",
        source_id: "pa-bcso",
        zip_code: "16001",
        email: "wanted@co.butler.pa.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Centre County Sheriff's Office",
        state_full: "Pennsylvania",
        address1: "700 Rishel Hill Rd",
        source_url: "http://centrecountypa.gov/index.aspx?NID=401",
        county: "Centre",
        phone: "Ph: (814) 355-6794",
        state: "PA",
        source_id: "pa-cent",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "George W. Hill Correctional Facility",
        state_full: "Pennsylvania",
        address1: "500 Cheyney Rd",
        source_url: "https://vinelink.com",
        county: "Philadelphia County",
        phone: "Phone:\n610-361-3200",
        state: "PA",
        source_id: "pa-phil",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "Greensburg",
        name: "Westmoreland County Prison",
        state_full: "Pennsylvania",
        address1: "3000 S Grande Blvd",
        source_url:
            "http://apps.co.westmoreland.pa.us/prison/PrisonInmates/Prison_inmates.asp",
        county: "Westmoreland County",
        phone: "(724) 830-6000",
        state: "PA",
        source_id: "pa-wmlc",
        zip_code: "15601",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Abbeville",
        name: "Abbeville County Sheriff's Office",
        state_full: "South Carolina",
        address1: "PO Box 9",
        source_url: "http://vinelink.com",
        county: "Abbeville County",
        phone: "(864) 446-6000",
        state: "SC",
        source_id: "sc-abso",
        zip_code: "29620",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Aiken",
        name: "Aiken County Sheriff's Office",
        state_full: "South Carolina",
        address1: "420 Hampton Ave NE",
        source_url: "http://vinelink.com",
        county: "Aiken County",
        phone: "(803) 642-1761",
        state: "SC",
        source_id: "sc-acso",
        zip_code: "29801",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Anderson",
        name: "Anderson County Sheriff's Office",
        state_full: "South Carolina",
        address1: "305 Camson Rd",
        source_url: "http://vinelink.com",
        county: "Anderson County",
        phone: "(864) 260-4400",
        state: "SC",
        source_id: "sc-adso",
        zip_code: "29625",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bamberg",
        name: "Bamberg County Sheriff's Office",
        state_full: "South Carolina",
        address1: "PO Box 928",
        source_url: "http://vinelink.com",
        county: "Bamberg County",
        phone: "(803) 245-3020",
        state: "SC",
        source_id: "sc-bcso",
        zip_code: "29003",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Beaufort County Sheriff's Office",
        state_full: "South Carolina",
        address1: "2001 Duke Street\nPO Drawer 1228",
        source_url: "http://www.bcgov.net/",
        county: "Beaufort",
        phone: "TEL\n843-470-5715",
        state: "SC",
        source_id: "sc-beauf",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Moncks Corner",
        name: "Berkeley County Sheriff's Office",
        state_full: "South Carolina",
        address1: "223 N Live Oak Dr",
        source_url: "http://vinelink.com",
        county: "Berkeley County",
        phone: "(843) 719-4424",
        state: "SC",
        source_id: "sc-brso",
        zip_code: "29461",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Charleston",
        name: "Charleston County Sheriff's Office",
        state_full: "South Carolina",
        address1: "3505 Pinehaven Drive",
        source_url: "http://www.ccso.charlestoncounty.org",
        county: "Charleston County",
        phone: "(843) 202-1700",
        state: "SC",
        source_id: "sc-chso",
        zip_code: "29405",
        email: "alcannon@charlestoncounty.org",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Cherokee County Sheriff's Office",
        state_full: "South Carolina",
        address1: null,
        source_url: "http://www.cherokeecountysheriff.net/",
        county: "Cherokee County",
        phone: null,
        state: "SC",
        source_id: "sc-chrk",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Chester",
        name: "Chester County Sheriff's Office",
        state_full: "South Carolina",
        address1: "2740 Dawson Dr",
        source_url: "http://vinelink.com",
        county: "Chester County",
        phone: "(803) 581-5131",
        state: "SC",
        source_id: "sc-ccso",
        zip_code: "29706",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Chesterfield",
        name: "Chesterfield County Prison Camp",
        state_full: "South Carolina",
        address1: "317 Goodale Rd",
        source_url: "http://vinelink.com",
        county: "Chesterfield County",
        phone: "(843) 623-7234",
        state: "SC",
        source_id: "sc-ccpc",
        zip_code: "29709",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Walterboro",
        name: "Colleton County Sheriff's Office",
        state_full: "South Carolina",
        address1: "112 S Miller St",
        source_url: "http://vinelink.com",
        county: "Colleton County",
        phone: "(843) 549-2211",
        state: "SC",
        source_id: "sc-clso",
        zip_code: "29488",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Dillon",
        name: "Dillon County Detention Center",
        state_full: "South Carolina",
        address1: "1027 Old Latta Hwy",
        source_url: "http://vinelink.com",
        county: "Dillon County",
        phone: "(843) 774-1435",
        state: "SC",
        source_id: "sc-dcdc",
        zip_code: "29536",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Edgefield",
        name: "Edgefield County Detention Center",
        state_full: "South Carolina",
        address1: "200 Railroad St",
        source_url: "http://vinelink.com",
        county: "Edgefield County",
        phone: "(803) 637-5337",
        state: "SC",
        source_id: "sc-ecdc",
        zip_code: "29824",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Winnsboro",
        name: "Fairfield County Sheriff's Office",
        state_full: "South Carolina",
        address1: "350 Columbia Rd",
        source_url: "http://vinelink.com",
        county: "Fairfield County",
        phone: "(803) 635-4141",
        state: "SC",
        source_id: "sc-fcso",
        zip_code: "29180",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Georgetown",
        name: "Georgetown County Sheriff's Office",
        state_full: "South Carolina",
        address1: "PO Box 1292",
        source_url: "http://vinelink.com",
        county: "Georgetown County",
        phone: "(843) 546-5102",
        state: "SC",
        source_id: "sc-grso",
        zip_code: "29442",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Greenwood",
        name: "Greenwood County Sheriff's Office",
        state_full: "South Carolina",
        address1: "528 Edgefield St",
        source_url: "http://vinelink.com",
        county: "Greenwood County",
        phone: "(864) 942-8600",
        state: "SC",
        source_id: "sc-gcso",
        zip_code: "29646",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hampton",
        name: "Hampton County Sheriff's Dept",
        state_full: "South Carolina",
        address1: "PO Box 633",
        source_url: "http://vinelink.com",
        county: "Hampton County",
        phone: "(803) 914-2200",
        state: "SC",
        source_id: "sc-hcsd",
        zip_code: "29924",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Conway",
        name: "Horry County Sheriff's Office",
        state_full: "South Carolina",
        address1: "1301 2nd Ave",
        source_url: "sheriff.horrycounty.org",
        county: "Horry County",
        phone: "(843) 915-5450",
        state: "SC",
        source_id: "sc-hcso",
        zip_code: "29576",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Ridgeland",
        name: "Jasper County Sheriff's Office",
        state_full: "South Carolina",
        address1: "PO Box 986",
        source_url: "http://vinelink.com",
        county: "Jasper County",
        phone: "(803) 726-7777",
        state: "SC",
        source_id: "sc-jcso",
        zip_code: "29936",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lugoff",
        name: "Kershaw County Sheriff's Office",
        state_full: "South Carolina",
        address1: "PO Box 70",
        source_url: "http://vinelink.com",
        county: "Kershaw County",
        phone: "(803) 425-1512",
        state: "SC",
        source_id: "sc-kcso",
        zip_code: "29078",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Laurens",
        name: "Laurens County (R. Eugene Johnson) Detention Cente",
        state_full: "South Carolina",
        address1: "154 Templeton Rd",
        source_url: "http://vinelink.com",
        county: "Laurens County",
        phone: "(864) 683-4055",
        state: "SC",
        source_id: "sc-lcejdc",
        zip_code: "29360",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lexington",
        name: "Lexington County Detention Center",
        state_full: "South Carolina",
        address1: "521 Gibson Rd",
        source_url: "http://vinelink.com",
        county: "Lexington County",
        phone: "(803) 785-2700",
        state: "SC",
        source_id: "sc-lcdc",
        zip_code: "29072",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Mullins",
        name: "Marion County Jail",
        state_full: "South Carolina",
        address1: "2715 E Highway 76",
        source_url: "http://vinelink.com",
        county: "Marion County",
        phone: "(843) 431-5401",
        state: "SC",
        source_id: "sc-mcj",
        zip_code: "29574",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bennettsville",
        name: "Marlboro County Detention Center",
        state_full: "South Carolina",
        address1: "253 Throop St",
        source_url: "http://vinelink.com",
        county: "Marlboro County",
        phone: "(843) 479-5628",
        state: "SC",
        source_id: "sc-mcdc",
        zip_code: "29512",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Newberry",
        name: "Newberry County Sheriff Office",
        state_full: "South Carolina",
        address1: "520 Wilson Rd",
        source_url: "http://vinelink.com",
        county: "Newberry County",
        phone: "(803) 321-2211",
        state: "SC",
        source_id: "sc-ncso",
        zip_code: "29108",
        email: null,
        has_mugshots: true,
    },
    {
        city: "North Myrtle Beach",
        name: "North Myrtle Beach Police Department",
        state_full: "South Carolina",
        address1: "1015 2nd Ave S",
        source_url: "http://vinelink.com",
        county: "North Myrtle",
        phone: "(843) 280-5511",
        state: "SC",
        source_id: "sc-nmbpd",
        zip_code: "29582",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Oconee County Sheriff's Office",
        state_full: "South Carolina",
        address1: null,
        source_url: "http://www.oconeelaw.com/",
        county: "Oconee County",
        phone: null,
        state: "SC",
        source_id: "sc-ocon",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Orangeburg",
        name: "Orangeburg County Sheriff's Office",
        state_full: "South Carolina",
        address1: "1520 Ellis Ave",
        source_url: "http://vinelink.com",
        county: "Orangeburg County",
        phone: "(803) 533-5804",
        state: "SC",
        source_id: "sc-ocso",
        zip_code: "29118",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Pickens",
        name: "Pickens County Prison Camp",
        state_full: "South Carolina",
        address1: "186 Prison Camp Rd",
        source_url: "http://vinelink.com",
        county: "Pickens County",
        phone: "(864) 898-5500",
        state: "SC",
        source_id: "sc-pcpc",
        zip_code: "29671",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Saluda",
        name: "Saluda County Sheriff's Office",
        state_full: "South Carolina",
        address1: "100 Law Enforcement Dr",
        source_url: "http://vinelink.com",
        county: "Saluda County",
        phone: "(864) 445-2112",
        state: "SC",
        source_id: "sc-scso",
        zip_code: "29138",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Spartanburg",
        name: "Spartanburg County Sheriff's Office",
        state_full: "South Carolina",
        address1: "950 California Avenue",
        source_url:
            "http://www.spartanburgsheriff.org/bookings/jailrostera.xml",
        county: "Spartanburg County",
        phone: "(864) 596-2607",
        state: "SC",
        source_id: "sc-spar",
        zip_code: "29303",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Sumter County Sheriff's Office",
        state_full: "South Carolina",
        address1: "1250 Winkles Rd",
        source_url: "http://sumtercountysc.org/?q=department/sheriff-office",
        county: "Sumter",
        phone: "864-112-7674",
        state: "SC",
        source_id: "sc-sumt",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Union",
        name: "Union County Sheriff's Office",
        state_full: "South Carolina",
        address1: "220 W. Main Street",
        source_url: "http://vinelink.com",
        county: "Union County",
        phone: "(864) 429-1612",
        state: "SC",
        source_id: "sc-ucso",
        zip_code: "29379",
        email: "sherifftaylor@countyofunion.com",
        has_mugshots: true,
    },
    {
        city: "Kingstree",
        name: "Williamsburg County Jail",
        state_full: "South Carolina",
        address1: "207 S Jackson St",
        source_url: "http://vinelink.com",
        county: "Williamsburg County",
        phone: "(843) 355-6381",
        state: "SC",
        source_id: "sc-wcj",
        zip_code: "29556",
        email: null,
        has_mugshots: true,
    },
    {
        city: "York",
        name: "York County Detention Center/Moss Justice Center",
        state_full: "South Carolina",
        address1: "1675 York Hwy",
        source_url: "http://vinelink.com",
        county: "York County",
        phone: "(803) 628-3077",
        state: "SC",
        source_id: "sc-ycdcjc",
        zip_code: "29745",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Clay County Sheriff's Office",
        state_full: "South Dakota",
        address1: null,
        source_url: "http://www.claysheriff.org/",
        county: "Clay County",
        phone: null,
        state: "SD",
        source_id: "sd-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Minnehaha County Sheriff's Office",
        state_full: "South Dakota",
        address1: "1900 W Russell St, Sioux Falls",
        source_url: "http://www.minnehahasheriff.org/",
        county: "Minnehaha",
        phone: "(605) 367-8428",
        state: "SD",
        source_id: "sd-mcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Woodbury",
        name: "Cannon County Sheriff's Office",
        state_full: "Tennessee",
        address1: "110 Alexander Drive",
        source_url: "http://www.cannoncounty.net/public_safety/sherrif.htm",
        county: "Cannon County",
        phone: "(615) 563-1000",
        state: "TN",
        source_id: "tn-ccso",
        zip_code: "37190",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Carter County Sheriff's Office",
        state_full: "Tennessee",
        address1: "(423) 542-1845",
        source_url: "http://www.sheriff.cc/",
        county: "Carter County",
        phone: "900 East Elk Avenue\nElizabethton,",
        state: "TN",
        source_id: "tn-cart",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Celina",
        name: "Clay",
        state_full: "Tennessee",
        address1: "400 West Lake Avenue",
        source_url: "http://www.claysherifftn.com/index.html",
        county: "Clay County",
        phone: "(931) 243-3266",
        state: "TN",
        source_id: "tn-c",
        zip_code: "38551",
        email: "claycosheriff@twlakes.net",
        has_mugshots: true,
    },
    {
        city: "Alamo",
        name: "Crockett County Sheriff's Department",
        state_full: "Tennessee",
        address1: "884 Cavaller Drive",
        source_url: "http://www.crockettsd.com/CCSD/Home.html",
        county: "Crockett County",
        phone: "(731) 696-2104",
        state: "TN",
        source_id: "tn-cksd",
        zip_code: "38001",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Dyersburg",
        name: "Dyer County Sheriff's Department",
        state_full: "Tennessee",
        address1: "401 East Cedar Street",
        source_url: "http://www.dyercountysheriff.com/",
        county: "Dyer County",
        phone: "(731) 285-2802",
        state: "TN",
        source_id: "tn-dysd",
        zip_code: "38025",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Somerville",
        name: "Fayette County Sheriff's Department",
        state_full: "Tennessee",
        address1: "705 Justice Drive",
        source_url: "http://www.fayettecountysheriff.org/",
        county: "Fayette County",
        phone: "(901) 465-5247",
        state: "TN",
        source_id: "tn-fysd",
        zip_code: "38068",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Winchester",
        name: "Franklin",
        state_full: "Tennessee",
        address1: "420 Wilton Circle",
        source_url: "http://www.fcsheriff.org",
        county: "Franklin County",
        phone: "(931) 962-0123",
        state: "TN",
        source_id: "tn-f",
        zip_code: "37398",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Pulaski",
        name: "Giles County Sheriff's Office",
        state_full: "Tennessee",
        address1: "200 Thomas Gatlin Drive",
        source_url: "http://www.gilessd.com/index.html",
        county: "Giles County",
        phone: "(931) 363-3505",
        state: "TN",
        source_id: "tn-gcso",
        zip_code: "38478",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Greeneville",
        name: "Greene County Sheriff's Department",
        state_full: "Tennessee",
        address1: "116 East Depot Street",
        source_url: "http://greenecountysheriffsdepartment.org/",
        county: "Greene County",
        phone: "(423) 798-1800",
        state: "TN",
        source_id: "tn-gcsd",
        zip_code: "37743",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Altamont",
        name: "Grundy County Sheriff's Department",
        state_full: "Tennessee",
        address1: "62 Spring St",
        source_url: "http://grundysheriff.blogspot.com/",
        county: "Grundy County",
        phone: "(931) 692-3466",
        state: "TN",
        source_id: "tn-grsd",
        zip_code: "37301",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Chattanooga",
        name: "Hamilton County Sheriff’s Office",
        state_full: "Tennessee",
        address1: "601 Walnut St",
        source_url: "http://www.hcsheriff.gov/default.php",
        county: "Hamilton County",
        phone: "(423) 209-7050",
        state: "TN",
        source_id: "tn-hcso",
        zip_code: "37402",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Mountain City",
        name: "Johnson County Sheriff's Office",
        state_full: "Tennessee",
        address1: "999 Honeysuckle St.",
        source_url: "http://johnsoncountysd.org/index.htm",
        county: "Johnson County",
        phone: "(423) 727-7761",
        state: "TN",
        source_id: "tn-jcso",
        zip_code: "37683",
        email: "sheriff@johnsoncountysd.org",
        has_mugshots: true,
    },
    {
        city: "Knoxville",
        name: "Knox County Sheriff's Office",
        state_full: "Tennessee",
        address1: "400 W Main St",
        source_url: "http://www.knoxsheriff.org/",
        county: "Knox County",
        phone: "(865) 215-2476",
        state: "TN",
        source_id: "tn-kcso",
        zip_code: "37902",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Loudoun County Sheriff's Office",
        state_full: "Tennessee",
        address1: "12680 Hwy 11 W Suite 1",
        source_url: "http://www.loudonsheriff.com/",
        county: "Loudoun County",
        phone: "865-986-4823",
        state: "TN",
        source_id: "tn-lcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Marshall County Sheriff's Office",
        state_full: "Tennessee",
        address1: "209 N 1st Ave",
        source_url: "http://www.mctnsheriff.com/",
        county: "Marshall County",
        phone: "(931) 359-6122",
        state: "TN",
        source_id: "tn-mcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Carthage",
        name: "Moore County Sheriff's Department",
        state_full: "Tennessee",
        address1: "104 West Saunders Street",
        source_url:
            "https://www.facebook.com/pages/Metro-Moore-County-Sheriffs-Department/306287086431",
        county: "Moore County",
        phone: "(910) 947-2980",
        state: "TN",
        source_id: "tn-mrsd",
        zip_code: "28327",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Wartburg",
        name: "Morgan County Sheriff's Department",
        state_full: "Tennessee",
        address1: "414 Main St",
        source_url: "http://www.morgancountytn.org/service.htm",
        county: "Morgan County",
        phone: "(423) 346-6262",
        state: "TN",
        source_id: "tn-mcsd",
        zip_code: "37887",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Rhea County Sheriff's Office",
        state_full: "Tennessee",
        address1: null,
        source_url: "http://rheacountytn.gov/",
        county: "Rhea County",
        phone: null,
        state: "TN",
        source_id: "tn-rhea",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Kingston",
        name: "Roane County Sheriff's Office",
        state_full: "Tennessee",
        address1: "230 North Third Street",
        source_url: "http://www.roanesheriff.org/",
        county: "Roane County",
        phone: "(865) 717-4700",
        state: "TN",
        source_id: "tn-rcso",
        zip_code: "37763",
        email: "tipline@roanesheriff.org",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Robertson County Sheriff's Office",
        state_full: "Tennessee",
        address1: "507 S Brown St",
        source_url: "http://vinelink.com",
        county: "Robertson County",
        phone: "(615) 384-7971",
        state: "TN",
        source_id: "tn-rbso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Murfreesboro",
        name: "Rutherford County Sheriff's Department",
        state_full: "Tennessee",
        address1: "940 New Salem Rd",
        source_url: "http://rutherfordcountytn.gov/so/",
        county: "Rutherford County",
        phone: "(615) 904-3107",
        state: "TN",
        source_id: "tn-rusd",
        zip_code: "37129",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Memphis",
        name: "Shelby County Sheriff's Office",
        state_full: "Tennessee",
        address1: "201 Poplar Avenue",
        source_url: "http://www.shelby-sheriff.org/",
        county: "Shelby County",
        phone: "(901) 222-4700",
        state: "TN",
        source_id: "tn-scso",
        zip_code: "38103",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Carthage",
        name: "Smith County Sheriff's Dept",
        state_full: "Tennessee",
        address1: "205 Second Ave W",
        source_url: "http://vinelink.com",
        county: "Smith County",
        phone: "(615) 735-2626",
        state: "TN",
        source_id: "tn-scsd",
        zip_code: "37030",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Tipton County Sheriff's Office",
        state_full: "Tennessee",
        address1: "261 Tate Rd,",
        source_url: "https://vinelink.com",
        county: "Tipton County",
        phone: "(901) 835-5395",
        state: "TN",
        source_id: "tn-tipt",
        zip_code: "",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Franklin",
        name: "Williamson County Sheriff's Office",
        state_full: "Tennessee",
        address1: "408 Century Court",
        source_url: "http://www.williamsoncounty-tn.gov/index.aspx?NID=20",
        county: "Williamson County",
        phone: "(615) 790-5560",
        state: "TN",
        source_id: "tn-wlso",
        zip_code: "37064",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lebanon",
        name: "Wilson County Sheriff's Office",
        state_full: "Tennessee",
        address1: "105 East High Street",
        source_url: "http://vinelink.com",
        county: "Wilson County",
        phone: "(615) 444-1412",
        state: "TN",
        source_id: "tn-wcso",
        zip_code: "37087",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Angelina County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.angelinacounty.net/",
        county: "Angelina County",
        phone: null,
        state: "TX",
        source_id: "tx-acso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Bryan",
        name: "Brazos County Sheriff's Office",
        state_full: "Texas",
        address1: "1700 West Highway 21",
        source_url: "http://vinelink.com",
        county: "Brazos County",
        phone: "(979) 361-4900",
        state: "TX",
        source_id: "tx-brso",
        zip_code: "77803",
        email: "ckirk@co.brazos.tx.us",
        has_mugshots: true,
    },
    {
        city: "Brownwood",
        name: "Brown County Sheriff's Office",
        state_full: "Texas",
        address1: "Law Enforcement Center",
        source_url:
            "http://www.browncountytx.org/default.aspx?Brown_County/Sheriff",
        county: "Brown County",
        phone: "(325) 646-5510",
        state: "TX",
        source_id: "tx-bcso",
        zip_code: "76801",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Calhoun County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.calhouncotx.org/",
        county: "Calhoun County",
        phone: null,
        state: "TX",
        source_id: "tx-clhn",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Cameron County Sheriff's Office",
        state_full: "Texas",
        address1: "7300 Old Alice Rd,",
        source_url: "http://www.co.cameron.tx.us/",
        county: "Cameron County",
        phone: "(956) 554-6700",
        state: "TX",
        source_id: "tx-cc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Anahuac",
        name: "Chambers County Sheriff's Office",
        state_full: "Texas",
        address1: "201 North Court",
        source_url: "http://www.co.chambers.tx.us/default.aspx?name=sheriff",
        county: "Chambers County",
        phone: "(409) 267-2500",
        state: "TX",
        source_id: "tx-chso",
        zip_code: "77514",
        email: "ccso@co.chambers.tx.us",
        has_mugshots: true,
    },
    {
        city: "Rusk",
        name: "Cherokee County Jail",
        state_full: "Texas",
        address1: "272 Underwood Street",
        source_url: "http://www.cherokeecountytexas.us/index200.htm",
        county: "Cherokee County",
        phone: "(903) 683-6365",
        state: "TX",
        source_id: "tx-ckcj",
        zip_code: "75785",
        email: "ccso501@cocherokee.org",
        has_mugshots: true,
    },
    {
        city: "McKinney",
        name: "Collin County Sheriff's Office",
        state_full: "Texas",
        address1: "4300 Community Ave",
        source_url: "http://www.co.collin.tx.us/sheriff/",
        county: "Collin County",
        phone: "(972) 547-5200",
        state: "TX",
        source_id: "tx-ccso",
        zip_code: "75071",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Comal County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.co.comal.tx.us/SO/",
        county: "Comal County",
        phone: null,
        state: "TX",
        source_id: "tx-comal",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Dalhard",
        name: "Dallam County Sheriff's Office",
        state_full: "Texas",
        address1: "501 Denver Avenue",
        source_url: "http://vinelink.com",
        county: "Dallam County",
        phone: "(806) 244-2313",
        state: "TX",
        source_id: "tx-dlso",
        zip_code: "79022",
        email: "sheriff@dallam.org",
        has_mugshots: true,
    },
    {
        city: "Dallas",
        name: "Dallas County Sheriff's Department",
        state_full: "Texas",
        address1: "111 West Commerce Street",
        source_url:
            "http://www.dallascounty.org/department/sheriff/sheriff_intro.html",
        county: "Dallas County",
        phone: "(214) 962-5800",
        state: "TX",
        source_id: "tx-dc",
        zip_code: "75202",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Denton",
        name: "Denton County Sheriff's Office",
        state_full: "Texas",
        address1: "127 N Woodrow Ln",
        source_url: "http://sheriff.dentoncounty.com/",
        county: "Denton County",
        phone: "(940) 349-1700",
        state: "TX",
        source_id: "tx-dcso",
        zip_code: "76205",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Ector County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.co.ector.tx.us/",
        county: "Ector County",
        phone: null,
        state: "TX",
        source_id: "tx-ector",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Ellis County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://elliscountysheriff.com/",
        county: "Ellis County",
        phone: null,
        state: "TX",
        source_id: "tx-ellis",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Stephenville",
        name: "Erath County Sheriff's Office",
        state_full: "Texas",
        address1: "1043 Glen Rose Hwy.",
        source_url: "http://co.erath.tx.us/sheriff.html",
        county: "Erath County",
        phone: "(254) 965-3338",
        state: "TX",
        source_id: "tx-ecso",
        zip_code: "76401",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Fort Bend County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.fortbendcountytx.gov/",
        county: "Fort Bend County",
        phone: null,
        state: "TX",
        source_id: "tx-fbco",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Galena Park",
        name: "Galena Park Police Department",
        state_full: "Texas",
        address1: "2207 Clinton Drive",
        source_url: "http://cityofgalenapark-tx.gov/?/departments/index/10",
        county: "Galena Park",
        phone: "(713) 675-3471",
        state: "TX",
        source_id: "tx-gppd",
        zip_code: "77547",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Galveston County Sheriff's Office",
        state_full: "Texas",
        address1: "601 54th Street",
        source_url: "http://www.co.galveston.tx.us/sheriff/",
        county: "Galveston",
        phone: "(409) 267-2500",
        state: "TX",
        source_id: "tx-galv",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Post",
        name: "Garza County Sheriff's Office",
        state_full: "Texas",
        address1: "412 W 15th St",
        source_url: "http://www.garzacounty.net/id25.html",
        county: "Garza County",
        phone: "(806) 495-3595",
        state: "TX",
        source_id: "tx-gcso",
        zip_code: "79356",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Longview",
        name: "Gregg County Sheriff",
        state_full: "Texas",
        address1: "101 E. Methvin",
        source_url:
            "http://www.co.gregg.tx.us/government/Public_Safety/sheriff.asp",
        county: "Gregg County",
        phone: "(903) 236-1755",
        state: "TX",
        source_id: "tx-gcs",
        zip_code: "75601",
        email: "Chuck.Willeford@co.gregg.tx.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Guadalupe County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.co.guadalupe.tx.us/",
        county: "Guadalupe County",
        phone: null,
        state: "TX",
        source_id: "tx-gdlp",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Houston",
        name: "Harris County Sheriff's Office",
        state_full: "Texas",
        address1: "1200 Baker St",
        source_url: "http://www.hcso.hctx.net/inmateinfo.aspx",
        county: "Harris County",
        phone: "(713) 755-6044",
        state: "TX",
        source_id: "tx-hcso",
        zip_code: "77002",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Hays County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.co.hays.tx.us/sheriff.aspx",
        county: "Hays County",
        phone: null,
        state: "TX",
        source_id: "tx-hays",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hillsboro",
        name: "Hill County Sheriff's Office",
        state_full: "Texas",
        address1: "406 Hall Street",
        source_url: "http://vinelink.com",
        county: "Hill County",
        phone: "(254) 582-5313",
        state: "TX",
        source_id: "tx-hlso",
        zip_code: "76645",
        email: "sheriff@co.hill.tx.us",
        has_mugshots: true,
    },
    {
        city: "Granbury",
        name: "Hood County Sheriff's Office",
        state_full: "Texas",
        address1: "400 Deputy Larry Miller Dr.",
        source_url: "http://vinelink.com",
        county: "Hood County",
        phone: "(817) 579-3316",
        state: "TX",
        source_id: "tx-hdso",
        zip_code: "76048",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Howard County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url:
            "http://www.co.howard.tx.us/default.aspx?Howard_County/Sheriff",
        county: "Howard County",
        phone: null,
        state: "TX",
        source_id: "tx-hwrd",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Greenville",
        name: "Hunt County Jail",
        state_full: "Texas",
        address1: "2801 Stuart St",
        source_url: "http://www.huntcounty.net/index.aspx?nid=882",
        county: "Hunt County",
        phone: "(903) 453-6851",
        state: "TX",
        source_id: "tx-htcj",
        zip_code: "75401",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Hurst",
        name: "Hurst Police Department",
        state_full: "Texas",
        address1: "825 Thousand Oaks Dr",
        source_url: "http://www.hursttx.gov/index.aspx?page=90",
        county: "Hurst County",
        phone: "(817) 788-7146",
        state: "TX",
        source_id: "tx-hpd",
        zip_code: "76054",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Edna",
        name: "Jackson County Jail",
        state_full: "Texas",
        address1: "115 W Main St",
        source_url:
            "http://www.co.jackson.tx.us/default.aspx?Jackson_County/Sheriff",
        county: "Jackson County",
        phone: "(361) 782-5407",
        state: "TX",
        source_id: "tx-jkcj",
        zip_code: "77957",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Cleburne",
        name: "Johnson County Sheriff's Office",
        state_full: "Texas",
        address1: "1800 Ridgemar Drive",
        source_url: "http://www.jctxsheriff.org/",
        county: "Johnson County",
        phone: "(817) 558-0024",
        state: "TX",
        source_id: "tx-jcso",
        zip_code: "76031",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Kaufman",
        name: "Kaufman County Sheriff's Office",
        state_full: "Texas",
        address1: "1900 E Highway 175",
        source_url: "http://kaufmanso.com/",
        county: "Kaufman County",
        phone: "(972) 932-9736",
        state: "TX",
        source_id: "tx-kcso",
        zip_code: "75142",
        email: "chiefstone@kaufmancounty.net",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Kerr County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.co.kerr.tx.us/sheriff/",
        county: "Kerr County",
        phone: null,
        state: "TX",
        source_id: "tx-kerr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Kleberg County Sheriff's Office",
        state_full: "Texas",
        address1: "1500 E. King ",
        source_url: "http://WWW.KLEBERGCOSO.ORG",
        county: "Kleberg County",
        phone: "(361) 595-8500",
        state: "TX",
        source_id: "tx-klso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Paris",
        name: "Lamar County Sheriff's Office",
        state_full: "Texas",
        address1: "125 Brown Ave.",
        source_url:
            "http://www.co.lamar.tx.us/default.aspx?Lamar_County/Sheriff",
        county: "Lamar County",
        phone: "(903) 737-2400",
        state: "TX",
        source_id: "tx-lcso",
        zip_code: "75460",
        email: "sheriffscottcass@co.lamar.tx.us",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Mansfield County Sheriff's Office",
        state_full: "Texas",
        address1: "1200 E. Broad St",
        source_url: "https://www.mansfieldtexas.gov/police-department",
        county: "Mansfield",
        phone: "817-276-4200",
        state: "TX",
        source_id: "tx-mans",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Midland",
        name: "Midland County Jail",
        state_full: "Texas",
        address1: "400 S Main St",
        source_url:
            "http://www.co.midland.tx.us/departments/so/Pages/default.aspx",
        county: "Midland County",
        phone: "(432) 688-4745",
        state: "TX",
        source_id: "tx-mdcj",
        zip_code: "79701",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Montgomery County Sheriff's Office",
        state_full: "Texas",
        address1: "1 Criminal Justice Dr",
        source_url: "http://www.mctx.org/",
        county: "Montgomery",
        phone: "(936) 760-5871",
        state: "TX",
        source_id: "tx-mcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Carthage",
        name: "Moore County Jail",
        state_full: "Texas",
        address1: "104 Saunders Street",
        source_url: "https://www.facebook.com/MooreCountySheriffsOffice",
        county: "Moore County",
        phone: "(806) 935-4145",
        state: "TX",
        source_id: "tx-mrcj",
        zip_code: "28327",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Nacogdoches County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.nac-sheriff.com/",
        county: "Nacogdoches County",
        phone: null,
        state: "TX",
        source_id: "tx-ncso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Pearland County Sheriff’s Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.pearlandtx.gov/departments/police",
        county: "Pearland County",
        phone: null,
        state: "TX",
        source_id: "tx-pear",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Polk County Sheriff's Office",
        state_full: "Texas",
        address1: "1733 N. Washington Ave",
        source_url: "http://vinelink.com",
        county: "Polk County",
        phone: "(936) 327-6810",
        state: "TX",
        source_id: "tx-pcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Amarillo",
        name: "Potter County Jail",
        state_full: "Texas",
        address1: "13100 NE 29th",
        source_url: "http://www.pottercountysheriff.org/",
        county: "Potter County",
        phone: "(806) 335-4100",
        state: "TX",
        source_id: "tx-ptcj",
        zip_code: "79111",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Rockwall",
        name: "Rockwall County Sheriff's Office",
        state_full: "Texas",
        address1: "972 T.L. Townsend Drive",
        source_url: "http://www.rockwallcountytexas.com/index.aspx?NID=87",
        county: "Rockwall County",
        phone: "(972) 204-7001",
        state: "TX",
        source_id: "tx-rcso",
        zip_code: "75087",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Sheriff's Department of Bell County",
        state_full: "Texas",
        address1: "104 S Main St,",
        source_url: "http://nwweb.co.bell.tx.us/",
        county: "Bell",
        phone: "(254) 933-5412",
        state: "TX",
        source_id: "tx-bcsd",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Tyler",
        name: "Smith County Sheriff's Office",
        state_full: "Texas",
        address1: "206 East Elm Street",
        source_url: "http://www.smith-county.com/law/Sheriff/Jail/Default.aspx",
        county: "Smith County",
        phone: "(903) 590-2800",
        state: "TX",
        source_id: "tx-scso",
        zip_code: "75702",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Fort Worth",
        name: "Tarrant County Sheriff's Office",
        state_full: "Texas",
        address1: "1815 Cold Springs Rd",
        source_url: "http://www.tarrantcounty.com/eSheriff/site/default.asp",
        county: "Tarrant County",
        phone: "(817) 884-3116",
        state: "TX",
        source_id: "tx-tc",
        zip_code: "76102",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Taylor County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://taylorcountytexas.org/178/Sheriffs-Office",
        county: "Taylor County",
        phone: null,
        state: "TX",
        source_id: "tx-tlr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Titus County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.tcsoinfo.com/",
        county: "Titus County",
        phone: null,
        state: "TX",
        source_id: "tx-tts",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Tom Green County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.tomgreencountysheriff.org/",
        county: "Tom Green County",
        phone: null,
        state: "TX",
        source_id: "tx-green",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Travis County Sheriff's Office",
        state_full: "Texas",
        address1: "500 W. 10th St.   ",
        source_url: "https://public.co.travis.tx.us/SIPS/DEFAULT.ASPX",
        county: "Travis County",
        phone: "(512) 854-9770",
        state: "TX",
        source_id: "tx-trso",
        zip_code: "78701",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Groveton",
        name: "Trinity County Sheriff's Office",
        state_full: "Texas",
        address1: "214 W First St",
        source_url: "http://vinelink.com",
        county: "Trinity County",
        phone: "(936) 642-1424",
        state: "TX",
        source_id: "tx-tcso",
        zip_code: "75845",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Canton",
        name: "Van Zandt County Sheriff's Office",
        state_full: "Texas",
        address1: "1220 West Dallas Street",
        source_url:
            "http://www.vanzandtcounty.org/default.aspx?Van-Zandt_County/Sheriff",
        county: "Van Zandt County",
        phone: "(903) 567-4133",
        state: "TX",
        source_id: "tx-vzcso",
        zip_code: "75103",
        email: "vzsoadmin@vanzandtcounty.org",
        has_mugshots: true,
    },
    {
        city: "Hempstead",
        name: "Waller County Sheriff's Office",
        state_full: "Texas",
        address1: "701 Calvit St",
        source_url: "http://www.wallercountytexassheriff.org",
        county: "Waller County",
        phone: "(979) 826-8282",
        state: "TX",
        source_id: "tx-wcso",
        zip_code: "77445",
        email: "scottgreen@wallercounty.us",
        has_mugshots: true,
    },
    {
        city: "Wharton",
        name: "Wharton County Jail",
        state_full: "Texas",
        address1: "315 East Elm St",
        source_url: "http://whartoncountysheriff.org/",
        county: "Wharton County",
        phone: "(979) 282- 9555",
        state: "TX",
        source_id: "tx-chcj",
        zip_code: "77488",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Williamson County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.wilco.org/Elected-Officials/Sheriffs-Office",
        county: "Williamson County",
        phone: null,
        state: "TX",
        source_id: "tx-wilco",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Wise County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.co.wise.tx.us/",
        county: "Wise County",
        phone: null,
        state: "TX",
        source_id: "tx-wise",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Wood County Sheriff's Office",
        state_full: "Texas",
        address1: null,
        source_url: "http://www.mywoodcounty.com/",
        county: "Wood County",
        phone: null,
        state: "TX",
        source_id: "tx-wood",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Davis County Sheriff's Office",
        state_full: "Utah",
        address1: "800 West State Street",
        source_url: "http://www.daviscountyutah.gov/",
        county: "Davis",
        phone: "(801) 451-4100",
        state: "UT",
        source_id: "ut-dcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Salt Lake City",
        name: "Salt Lake County Sheriff's Office",
        state_full: "Utah",
        address1: "3415 S 900 W",
        source_url: "http://www.slsheriff.org/metro-jail/where-to-start",
        county: "Salt Lake County",
        phone: "(385) 468-8400",
        state: "UT",
        source_id: "ut-slcs",
        zip_code: "84119",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Manti",
        name: "Sanpete County Sheriff's Office",
        state_full: "Utah",
        address1: "PO Box 130",
        source_url: "http://vinelink.com",
        county: "Sanpete County",
        phone: "(435) 835-2191",
        state: "UT",
        source_id: "ut-scso",
        zip_code: "84642",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Spanish Fork",
        name: "Utah County Sheriff's Office Corrections Bureau",
        state_full: "Utah",
        address1: "3075 North Main",
        source_url:
            "http://www.utahcountyonline.org/Dept/Sheriff/Corrections/InmateActiveResults.asp",
        county: "Utah",
        phone: "(801) 851-4200",
        state: "UT",
        source_id: "ut-utah",
        zip_code: "84660",
        email: "nancyk@utahcounty.gov",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Weber County Sheriff's Office",
        state_full: "Utah",
        address1: "721 West 12th Street ",
        source_url: "http://www.co.weber.ut.us/sheriff/",
        county: "Weber",
        phone: "801-629-8221",
        state: "UT",
        source_id: "ut-wcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Accomac",
        name: "Accomack County Sheriff's Office",
        state_full: "Virginia",
        address1: "23323 Wise Ct",
        source_url: "http://vinelink.com",
        county: "Accomack County",
        phone: "(757) 787-1131",
        state: "VA",
        source_id: "va-acso",
        zip_code: "23301",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Charlottesville",
        name: "Albemarle County Sheriff's Office",
        state_full: "Virginia",
        address1: "410 E High St",
        source_url: "http://vinelink.com",
        county: "Albemarle County",
        phone: "(434) 972-4001",
        state: "VA",
        source_id: "va-abso",
        zip_code: "22902",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Covington",
        name: "Alleghany County Sherrif's Dept",
        state_full: "Virginia",
        address1: "268 W Main St",
        source_url: "http://vinelink.com",
        county: "Alleghany County",
        phone: "(540) 965-1770",
        state: "VA",
        source_id: "va-alsd",
        zip_code: "24426",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Verona",
        name: "Augusta County Sheriff's Dept",
        state_full: "Virginia",
        address1: "PO Box 860",
        source_url: "http://vinelink.com",
        county: "Augusta County",
        phone: "(540) 245-5333",
        state: "VA",
        source_id: "va-acsd",
        zip_code: "24482",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Halifax",
        name: "Blue Ridge Regional Jail, Halifax",
        state_full: "Virginia",
        address1: "PO Box 829",
        source_url: "http://vinelink.com",
        county: "Collin County",
        phone: "(804) 476-3334",
        state: "VA",
        source_id: "va-brrjh",
        zip_code: "24558",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Fincastle",
        name: "Botetourt County Sheriff's Office",
        state_full: "Virginia",
        address1: "PO Box 18",
        source_url: "http://vinelink.com",
        county: "Botetourt County",
        phone: "(540) 473-8230",
        state: "VA",
        source_id: "va-bcso",
        zip_code: "24090",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Bristol Sheriff's Office",
        state_full: "Virginia",
        address1: "417 Cumberland Street",
        source_url: "http://www.bvso.net/index.htm",
        county: "City Of Bristol",
        phone: "(276) 645-7430",
        state: "VA",
        source_id: "va-bso",
        zip_code: null,
        email: "bvso@bvso.net",
        has_mugshots: true,
    },
    {
        city: "Orange",
        name: "Central Virginia Regional Jail",
        state_full: "Virginia",
        address1: "13021 James Madison Hwy",
        source_url: "http://vinelink.com",
        county: "Orange County",
        phone: "(540) 672-3222",
        state: "VA",
        source_id: "va-cvrj",
        zip_code: "22960",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Englewood",
        name: "Charlotte County Jail",
        state_full: "Virginia",
        address1: "26601 Airport Road",
        source_url: "http://www.charlotteva.com/sheriff.htm",
        county: "Charlotte County",
        phone: "(941) 833-6300",
        state: "VA",
        source_id: "va-wcj",
        zip_code: "33982",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Portsmouth",
        name: "City of Portsmouth Sheriff's Office",
        state_full: "Virginia",
        address1: "701 Crawford St",
        source_url: "http://vinelink.com",
        county: "City Of Portsmouth",
        phone: "(757) 393-5461",
        state: "VA",
        source_id: "va-cpso",
        zip_code: "23704",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Williamsburg",
        name: "City of Williamsburg/James City Co Sheriff's Office",
        state_full: "Virginia",
        address1: "5201 Monticello Ave",
        source_url: "http://vinelink.com",
        county: "City County",
        phone: "(757) 564-2220",
        state: "VA",
        source_id: "va-cwccso",
        zip_code: "23188",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Culpeper",
        name: "Culpeper County Sheriff's Office",
        state_full: "Virginia",
        address1: "110 W Cameron St",
        source_url: "http://vinelink.com",
        county: "Culpeper County",
        phone: "(540) 727-7520",
        state: "VA",
        source_id: "va-ccso",
        zip_code: "22701",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Danville County Sheriff's Office",
        state_full: "Virginia",
        address1: "401 Patton Street",
        source_url: "http://danvilleva.gov/",
        county: "Danville County",
        phone: "434-799-5135",
        state: "VA",
        source_id: "va-dcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Fairfax County Sheriff's Office",
        state_full: "Virginia",
        address1: "10459 Main Street",
        source_url: "https://vinelink.com",
        county: "Fairfax County",
        phone: "(703) 246-2328",
        state: "VA",
        source_id: "va-fair",
        zip_code: null,
        email: null,
        has_mugshots: false,
    },
    {
        city: "Rocky Mount",
        name: "Franklin County Sheriff's Office",
        state_full: "Virginia",
        address1: "70 E Court St",
        source_url: "http://vinelink.com",
        county: "Franklin County",
        phone: "(540) 483-3000",
        state: "VA",
        source_id: "va-fcso",
        zip_code: "24151",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hampton",
        name: "Hampton City Jail",
        state_full: "Virginia",
        address1: "135 High Court Ln",
        source_url: "http://vinelink.com",
        county: "City Of Hampton",
        phone: "(757) 727-6203",
        state: "VA",
        source_id: "va-hcj",
        zip_code: "23669",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Martinsville",
        name: "Henry County Sheriff's Dept",
        state_full: "Virginia",
        address1: "3250 Kings Mountain Rd",
        source_url: "http://vinelink.com",
        county: "Henry County",
        phone: "(276) 656-4200",
        state: "VA",
        source_id: "va-hcsd",
        zip_code: "24112",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Leesburg",
        name: "Loudoun County Adult Det CTR",
        state_full: "Virginia",
        address1: "41975 Loudoun Center Pl",
        source_url: "http://vinelink.com",
        county: "Loudoun County",
        phone: "(703) 771-5704",
        state: "VA",
        source_id: "va-lcadc",
        zip_code: "20175",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Martinsville",
        name: "Martinsville County Sheriff's Office",
        state_full: "Virginia",
        address1: "13 S. Moss Street",
        source_url: "http://martinsville-va.gov/Sheriff-s-Office.html",
        county: "Martinsville County",
        phone: "(276) 403-5151",
        state: "VA",
        source_id: "va-mrso",
        zip_code: "24112",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Alberta",
        name: "Meherrin River Regional Jail",
        state_full: "Virginia",
        address1: "9000 Boydton Plank Rd",
        source_url: "http://vinelink.com",
        county: "Brunswick County",
        phone: "(434) 949-7064",
        state: "VA",
        source_id: "va-mrrj",
        zip_code: "23821",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Saluda",
        name: "Middle Peninsula Reg. Sec. Ctr.",
        state_full: "Virginia",
        address1: "170 Oakes Landing Road",
        source_url: "http://vinelink.com",
        county: "Middlesex County",
        phone: "(804) 758-2338",
        state: "VA",
        source_id: "va-mprsc",
        zip_code: "23149",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Christiansburg",
        name: "Montgomery County Sheriff's Office",
        state_full: "Virginia",
        address1: "16 S. Franklin Street",
        source_url:
            "http://www.montgomerycountyva.gov/content/1146/98/179/1863/default.aspx",
        county: "Montgomery County",
        phone: "(540) 382-2951",
        state: "VA",
        source_id: "va-mcso",
        zip_code: "24073",
        email: "mcso-info@montgomerycountyva.gov",
        has_mugshots: true,
    },
    {
        city: "Dublin",
        name: "New River Valley Regional Jail",
        state_full: "Virginia",
        address1: "108 Baker Rd",
        source_url: "http://vinelink.com",
        county: "Pulaski County",
        phone: "(540) 643-2000",
        state: "VA",
        source_id: "va-nrvrj",
        zip_code: "24084",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Newport News",
        name: "Newport News City Jail ",
        state_full: "Virginia",
        address1: "224 26th St",
        source_url: "http://vinelink.com",
        county: "City Of Newport News",
        phone: "(757) 926-8535",
        state: "VA",
        source_id: "va-nncj",
        zip_code: "23607",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Norfolk County Sheriff's Office",
        state_full: "Virginia",
        address1: null,
        source_url: "http://norfolk-sheriff.com/",
        county: "Norfolk County",
        phone: null,
        state: "VA",
        source_id: "va-nrflk",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Eastville",
        name: "Northampton County Sheriff's Office",
        state_full: "Virginia",
        address1: "PO Box 68",
        source_url: "http://vinelink.com",
        county: "Northampton County",
        phone: "(757) 678-0495",
        state: "VA",
        source_id: "va-ncso",
        zip_code: "23347",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Warsaw",
        name: "Northern Neck Regional Jail",
        state_full: "Virginia",
        address1: "3908 Richmond Rd",
        source_url: "http://vinelink.com",
        county: "Richmond County",
        phone: "(804) 333-6419",
        state: "VA",
        source_id: "va-nnrj",
        zip_code: "22572",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Winchester",
        name: "Northwest Regional Adult Detention Center",
        state_full: "Virginia",
        address1: "141 Fort Collier Rd",
        source_url: "http://vinelink.com",
        county: "Frederick County",
        phone: "(540) 665-6374",
        state: "VA",
        source_id: "va-nradc",
        zip_code: "22603",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Page County Sheriff's Office",
        state_full: "Virginia",
        address1: "108 S Court St",
        source_url: "http://www.pagesheriff.com",
        county: "Page County",
        phone: "(540) 843-4506",
        state: "VA",
        source_id: "va-pgso",
        zip_code: null,
        email: "grants@pagesheriff.com",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Pamunkey Regional Jail",
        state_full: "Virginia",
        address1: "7240 Courtland Farm Rd",
        source_url: "http://www.hanovercounty.gov/Pamunkey-Regional-Jail/874/",
        county: "Pamunkey County",
        phone: "(804) 365-6400",
        state: "VA",
        source_id: "va-pjms",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Stuart",
        name: "Patrick County Sheriff's Office",
        state_full: "Virginia",
        address1: "103 W Blue Ridge St",
        source_url: "http://vinelink.com",
        county: "Patrick County",
        phone: "(276) 694-3161",
        state: "VA",
        source_id: "va-pcso",
        zip_code: "24171",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Petersburg",
        name: "Petersburg City Jail",
        state_full: "Virginia",
        address1: "40 Henry St",
        source_url: "http://www.petersburg-va.org/sheriff/",
        county: "Petersburg City",
        phone: "(804) 733-2376",
        state: "VA",
        source_id: "va-pgcj",
        zip_code: "23803",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Farmville",
        name: "Piedmont Regional Jail",
        state_full: "Virginia",
        address1: "801 Industrial Road",
        source_url: "http://vinelink.com",
        county: "Prince Edward County",
        phone: "(434) 392-1601",
        state: "VA",
        source_id: "va-prj",
        zip_code: "23901",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Chatham",
        name: "Pittsylvania County Sheriff's Office",
        state_full: "Virginia",
        address1: "21 N Main St",
        source_url: "http://vinelink.com",
        county: "Pittsylvania County",
        phone: "(434) 432-7807",
        state: "VA",
        source_id: "va-ptso",
        zip_code: "24531",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Portsmouth",
        name: "Portsmouth City Jail",
        state_full: "Virginia",
        address1: "701 Crawford St",
        source_url: "http://vinelink.com",
        county: "Portsmouth City Jail",
        phone: "(757) 391-3176",
        state: "VA",
        source_id: "va-pcj",
        zip_code: "23704",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Manassas",
        name: "Prince William County Sheriff's Office",
        state_full: "Virginia",
        address1: "9311 Lee Ave",
        source_url: "http://vinelink.com",
        county: "Prince William County",
        phone: "(703) 792-6415",
        state: "VA",
        source_id: "va-pwcso",
        zip_code: "20110",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Hopewell",
        name: "Riverside Regional Jail",
        state_full: "Virginia",
        address1: "1000 River Rd",
        source_url: "http://vinelink.com",
        county: "City Of Hopewell",
        phone: "(804) 524-6600",
        state: "VA",
        source_id: "va-rrj",
        zip_code: "23860",
        email: "",
        has_mugshots: false,
    },
    {
        city: "Roanoke",
        name: "Roanoke City Sheriff's Office",
        state_full: "Virginia",
        address1: "PO Box 494",
        source_url: "http://vinelink.com",
        county: "City of Roanoke",
        phone: "(540) 853-2941",
        state: "VA",
        source_id: "va-rnso",
        zip_code: "24003",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Lexington",
        name: "Rockbridge County Sheriff's Office",
        state_full: "Virginia",
        address1: "258 Greenhouse Rd.",
        source_url: "http://vinelink.com",
        county: "Rockbridge County",
        phone: "(540) 463-7329",
        state: "VA",
        source_id: "va-rcso",
        zip_code: "24450",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Harrisonburg",
        name: "Rockingham Regional Jail",
        state_full: "Virginia",
        address1: "25 S Liberty St",
        source_url: "http://vinelink.com",
        county: "City Of Harrisonburg",
        phone: "(540) 564-3851",
        state: "VA",
        source_id: "va-rcj",
        zip_code: "22801",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Courtland",
        name: "Southampton County Jail",
        state_full: "Virginia",
        address1: "22336 Main Street",
        source_url: "http://www.southamptoncounty.org/sheriff-detention.aspx",
        county: "Southampton County",
        phone: "(757) 653-2100",
        state: "VA",
        source_id: "va-shcj",
        zip_code: "23837",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Emporia",
        name: "Southside Regional Jail",
        state_full: "Virginia",
        address1: "244 Uriah Branch Way",
        source_url: "http://vinelink.com",
        county: "City Of Emporia",
        phone: "(434) 634-2254",
        state: "VA",
        source_id: "va-srj",
        zip_code: "23847",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Stafford",
        name: "Stafford County Sheriff's Office/Rappahannock Regional",
        state_full: "Virginia",
        address1: "1225 Courthouse Rd",
        source_url: "http://vinelink.com",
        county: "Stafford County",
        phone: "(549) 658-4450",
        state: "VA",
        source_id: "va-scsor",
        zip_code: "22554",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Sussex",
        name: "Sussex County Sheriff's Dept",
        state_full: "Virginia",
        address1: "PO Box 1326",
        source_url: "http://vinelink.com",
        county: "Sussex County",
        phone: "(434) 246-5000",
        state: "VA",
        source_id: "va-scsd",
        zip_code: "23884",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Virginia Beach Sheriff's Office",
        state_full: "Virginia",
        address1: " 2501 James Madison Blvd",
        source_url: "http://www.vbso.net/",
        county: "Virginia Beach",
        phone: "(757) 385-4555",
        state: "VA",
        source_id: "va-vbso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Suffolk",
        name: "Western Tidewater Regional Jail",
        state_full: "Virginia",
        address1: "150 N Main St",
        source_url: "http://vinelink.com",
        county: "City Of Suffolk",
        phone: "(757) 514-7840",
        state: "VA",
        source_id: "va-wtrj",
        zip_code: "23434",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Salem",
        name: "Western Virginia Regional Jail",
        state_full: "Virginia",
        address1: "5885 W River Rd",
        source_url: "http://vinelink.com",
        county: "City Of Salem",
        phone: "(540) 378-3700",
        state: "VA",
        source_id: "va-wvrj",
        zip_code: "24153",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Clark County Sheriff's Office",
        state_full: "Washington",
        address1: "707 W 13th St",
        source_url: "http://www.clark.wa.gov/",
        county: "Clark County",
        phone: " (360) 397-2211",
        state: "WA",
        source_id: "wa-cc",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Cowlitz County Sheriff's Office",
        state_full: "Washington",
        address1: "312 1st Ave S #124",
        source_url: "http://www.co.cowlitz.wa.us/Index.aspx?NID=267",
        county: "Cowlitz",
        phone: "(360) 577-3092",
        state: "WA",
        source_id: "wa-ccso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Seattle",
        name: "King County Department of Detention",
        state_full: "Washington",
        address1: "500 5th Avenue",
        source_url: "http://www.kingcounty.gov/courts/detention.aspx",
        county: "King County",
        phone: "(206) 296-0100",
        state: "WA",
        source_id: "wa-kc",
        zip_code: "98104",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Kitsap County Sheriff's Office",
        state_full: "Washington",
        address1: "614 Division St Ms37",
        source_url: "http://www.kitsapgov.com/",
        county: "Kitsap",
        phone: "(360) 337-7101",
        state: "WA",
        source_id: "wa-kcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lincoln County Sheriff's Office",
        state_full: "Washington",
        address1: "404 Sinclair St, Davenport",
        source_url: "http://co.lincoln.wa.us/Sheriff/index.htm",
        county: "Lincoln County",
        phone: "(509) 725-3501",
        state: "WA",
        source_id: "wa-lcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Lynnwood County Sheriff's Office",
        state_full: "Washington",
        address1: null,
        source_url: "http://www.lynnwoodwa.gov/",
        county: "Lynnwood County",
        phone: null,
        state: "WA",
        source_id: "wa-lwso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Marysville County Sheriff's Office",
        state_full: "Washington",
        address1: null,
        source_url: "http://marysvillewa.gov/165/Jail",
        county: "Marysville County",
        phone: null,
        state: "WA",
        source_id: "wa-mcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Pierce County Jail",
        state_full: "Washington",
        address1: "910 Tacoma Ave. S",
        source_url:
            "https://linxonline.co.pierce.wa.us/linxweb/Booking/GetJailRoster.cfm",
        county: "Pierce County",
        phone: " (253)798-4590",
        state: "WA",
        source_id: "wa-pcj",
        zip_code: "98402",
        email: "",
        has_mugshots: false,
    },
    {
        city: "",
        name: "Skagit County Sheriff's Office",
        state_full: "Washington",
        address1: "600 S 3rd St, Mt Vernon",
        source_url: "http://www.skagitcounty.net/Departments/Sheriff/main.htm",
        county: "Skagit",
        phone: "(360) 336-9448",
        state: "WA",
        source_id: "wa-skag",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Snohomish County Sheriff's Office",
        state_full: "Washington",
        address1: "3025 Oakes Ave",
        source_url: "http://snohomishcountywa.gov/",
        county: "Snohomish County",
        phone: "425-388-3393 ",
        state: "WA",
        source_id: "wa-scso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Spokane County Sheriff's Office",
        state_full: "Washington",
        address1: "1100 W Mallon Avenue",
        source_url: "http://www.spokanecounty.org/",
        county: "Spokane",
        phone: "509-477-2278",
        state: "WA",
        source_id: "wa-spso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Thurston County Sheriff's Office",
        state_full: "Washington",
        address1: null,
        source_url: "http://www.co.thurston.wa.us/sheriff/",
        county: "Thurston County",
        phone: null,
        state: "WA",
        source_id: "wa-tcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Whatcom County Sheriff's Office",
        state_full: "Washington",
        address1: "311 Grand Avenue",
        source_url: "http://whatcomcounty.us/",
        county: "Whatcom",
        phone: "360-778-6600",
        state: "WA",
        source_id: "wa-wcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Sutton",
        name: "West Virginia Regional Jail",
        state_full: "West Virginia",
        address1: "1255 Dyer Hill Road",
        source_url: "http://www.wvrja.com/",
        county: "West Virginia County",
        phone: "(304) 765-7904",
        state: "WV",
        source_id: "wv-rj",
        zip_code: "26601",
        email: "",
        has_mugshots: true,
    },
    {
        city: "",
        name: "Barron County Sheriff's Office",
        state_full: "Wisconsin",
        address1: null,
        source_url: "http://barronsheriff.org/",
        county: "Barron County",
        phone: null,
        state: "WI",
        source_id: "wi-barr",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Alma",
        name: "Buffalo County Sheriff's Office",
        state_full: "Wisconsin",
        address1: "407 S 2nd St",
        source_url: "http://vinelink.com",
        county: "Buffalo County",
        phone: "(608) 685-6270",
        state: "WI",
        source_id: "wi-bcso",
        zip_code: "54610",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Douglas County Sheriff's Office",
        state_full: "Wisconsin",
        address1: "1310 N.14th Street",
        source_url: "http://www.sheriffofdouglascountywi.org/",
        county: "Douglas County",
        phone: "715-395-1375",
        state: "WI",
        source_id: "wi-dcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "Jefferson",
        name: "Jefferson County Sheriff's Dept",
        state_full: "Wisconsin",
        address1: "411 S Center Ave",
        source_url: "http://vinelink.com",
        county: "Jefferson County",
        phone: "(920) 674-7330",
        state: "WI",
        source_id: "wi-jcsd",
        zip_code: "53549",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Kenosha County Sheriff's Office",
        state_full: "Wisconsin",
        address1: null,
        source_url: "http://www.kenoshajs.org/",
        county: "Kenosha County",
        phone: null,
        state: "WI",
        source_id: "wi-knsh",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "La Crosse",
        name: "La Crosse County Sheriff's Department",
        state_full: "Wisconsin",
        address1: "333 Vine Street",
        source_url: "http://www.co.la-crosse.wi.us/Sheriff/",
        county: "La Crosse County",
        phone: "(608) 785-9630",
        state: "WI",
        source_id: "wi-lcsd",
        zip_code: "54601",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Milwaukee",
        name: "Milwaukee County Sheriff",
        state_full: "Wisconsin",
        address1: "949 N 9th Street",
        source_url: "http://www.inmatesearch.mkesheriff.org/",
        county: "Milwaukee County",
        phone: "(414) 226-7000",
        state: "WI",
        source_id: "wi-mcs",
        zip_code: "53233",
        email: "",
        has_mugshots: true,
    },
    {
        city: "Ellsworth",
        name: "Pierce County Jail",
        state_full: "Wisconsin",
        address1: "432 W Main St",
        source_url: "http://vinelink.com",
        county: "Pierce County",
        phone: "(715) 273-4627",
        state: "WI",
        source_id: "wi-pcj",
        zip_code: "54011",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Balsam Lake",
        name: "Polk County Sheriff's dept",
        state_full: "Wisconsin",
        address1: "1005 W Main St",
        source_url: "http://vinelink.com",
        county: "Polk County",
        phone: "(715) 485-8328",
        state: "WI",
        source_id: "wi-pcs",
        zip_code: "54810",
        email: null,
        has_mugshots: true,
    },
    {
        city: "Racine",
        name: "Racine County Sheriff",
        state_full: "Wisconsin",
        address1: "717 Wisconsin Avenue",
        source_url: "http://www.racineco.com/sheriff/",
        county: "Racine County",
        phone: "(262) 636-3822",
        state: "WI",
        source_id: "wi-rcs",
        zip_code: "53403",
        email: "RCSheriff@goracine.org",
        has_mugshots: true,
    },
    {
        city: "Wisconsin Rapids",
        name: "Wood County Sheriff's Dept",
        state_full: "Wisconsin",
        address1: "400 Market St",
        source_url: "http://vinelink.com",
        county: "Wood County",
        phone: "(715) 421-8715",
        state: "WI",
        source_id: "wi-wcsd",
        zip_code: "54494",
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Sweetwater County Sheriff's Office",
        state_full: "Wyoming",
        address1: "50140 Highway 191 South\nRock Springs, WY 82901",
        source_url: "http://www.sweet.wy.us/scso",
        county: "Sweetwater County",
        phone: "TEL\n(307) 352-4900\n(307) 922-4935\nFAX\n(307) 922-54",
        state: "WY",
        source_id: "wy-scso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
    {
        city: "",
        name: "Teton County Sheriff's Office",
        state_full: "Wyoming",
        address1: null,
        source_url: "http://www.tetonsheriff.org/",
        county: "Teton County",
        phone: null,
        state: "WY",
        source_id: "wy-tcso",
        zip_code: null,
        email: null,
        has_mugshots: true,
    },
];
