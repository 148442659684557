import { FetchBail } from "../../Admin/Redux/BailRequest";
import { GetBondRequestList } from "../../Redux/bondRequest";
import axios from "../../helpers/axios";
import {
    API_ACCESS_TOKEN,
    API_URL,
    catchHandler,
    dispatchError,
    dispatchLoading,
    dispatchToast,
    elseHandler,
} from "../../helpers/utils";

const BONDSMANSERVICE = `${API_URL}/bondsman/service`;
const APPLYBIDS = `${API_URL}/bond/request/bids`;
const APPROVEBIDS = `${API_URL}/bond/request/bids/approve`;
const INVOICE = `${API_URL}/bond/request/invoice`;

export const FetchBondmanService = (page, limit) => async (dispatch) => {
    dispatchLoading(dispatch, "bondsman", true);
    axios
        .get(BONDSMANSERVICE, {
            params: {
                page,
                limit,
            },
            headers: { Authorization: API_ACCESS_TOKEN },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_BONDSMAN_SERVICE_LIST",
                    payload: result?.data?.data?.data,
                });
                dispatchError(dispatch, "bondsman", undefined);
            } else elseHandler(dispatch, "bondsman", result?.data);
            dispatchLoading(dispatch, "bondsman", false);
        })
        .catch(catchHandler(dispatch, "bondsman"));
};

export const addBondmanService =
    (data, handler, page, limit, prevData, setNewData, setState, bailAmount) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .post(BONDSMANSERVICE, data, {
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    const newBid = {
                        id: result?.data?.data?._id,
                        percentage: result?.data?.data?.percentage
                            ? result?.data?.data?.percentage
                            : "-",
                        amount: result?.data?.data?.amount
                            ? result?.data?.data?.amount
                            : "-",
                        service: result?.data?.data?.service,
                    };
                    setNewData((prevTableData) => [...prevTableData, newBid]);
                    setState(false);
                    dispatch(FetchBondmanService(page, limit));
                    handler();
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const editBondmanService =
    (data, handler, page, limit, prevData, setNewData, bailAmount) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .put(BONDSMANSERVICE, data, {
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    const newBid = {
                        id: result?.data?.data?._id,
                        service: result?.data?.data?.service,
                        percentage: result?.data?.data?.percentage
                            ? result?.data?.data?.percentage
                            : "-",
                        amount: result?.data?.data?.amount
                            ? result?.data?.data?.amount
                            : "-",
                    };

                    const updatedArray = prevData?.map((item) => {
                        if (item?.id === newBid?.id) {
                            return { ...item, ...newBid };
                        }
                        return item;
                    });
                    setNewData(updatedArray);
                    handler();
                    dispatch(FetchBondmanService(page, limit));
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const DeleteBondmanService = (id, page, limit) => async (dispatch) => {
    dispatchLoading(dispatch, "bondsman", true);
    axios
        .delete(
            BONDSMANSERVICE,

            {
                data: { id },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            }
        )
        .then((result) => {
            if (result?.data?.success) {
                dispatch(FetchBondmanService(page, limit));
                dispatchError(dispatch, "bondsman", undefined);
            } else elseHandler(dispatch, "bondsman", result?.data);
            dispatchLoading(dispatch, "bondsman", false);
        })
        .catch(catchHandler(dispatch, "bondsman"));
};

export const ApplyBids =
    (request_number, bondsman_service, bids_amount, handler, bailId) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .post(
                APPLYBIDS,
                { request_number, bondsman_service, bids_amount },
                {
                    headers: { Authorization: API_ACCESS_TOKEN },
                    withCredentials: true,
                }
            )
            .then((result) => {
                if (result?.data?.success) {
                    handler();
                    dispatch(
                        FetchBail(null, null, bailId, null, null, null, null)
                    );
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const EditBids =
    (id, bondsman_service, bids_amount, handler, bailId, setTableData) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .put(
                APPLYBIDS,
                { id, bondsman_service, bids_amount },
                {
                    headers: { Authorization: API_ACCESS_TOKEN },
                    withCredentials: true,
                }
            )
            .then((result) => {
                if (result?.data?.success) {
                    handler();
                    dispatch(
                        FetchBail(null, null, bailId, null, null, null, null)
                    );
                    dispatch(GetBids(bailId));
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const GetBids =
    (bond_request_id, setState, editState, setState2) => async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .get(APPLYBIDS, {
                params: { bond_request_id },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    const directId =
                        result?.data?.data?.data?.[0]?.bondsman_service;

                    directId?.forEach((item) => {
                        item.id = item?._id;
                        delete item?._id;
                    });

                    setState2 && setState2(directId);

                    dispatch({
                        type: "GET_BONDSMAN_BID_LIST",
                        payload: result?.data?.data?.data?.[0],
                    });

                    dispatch({
                        type: "GET_BONDSMAN_ALL_BID_LIST",
                        payload: result?.data?.data?.data,
                    });

                    setState &&
                        setState(
                            result?.data?.data?.data?.[0]?.bondsman_service
                        );

                    editState && editState(result?.data?.data?.data?.[0]?._id);
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const GetBidsClient =
    (page, bond_request_id, status, limit) => async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .get(APPLYBIDS, {
                params: { page, bond_request_id, status, limit },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch({
                        type: "GET_BONDSMAN_ALL_BID_LIST",
                        payload: result?.data?.data?.data,
                    });

                    dispatch({
                        type: "GET_BONDSMAN_ALL_BID_COUNT_LIST",
                        payload: result?.data?.data?.count,
                    });

                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const GetBidFetailClient =
    (bond_request_id, status) => async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .get(APPLYBIDS, {
                params: { bond_request_id, status },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch({
                        type: "GET_BONDSMAN_SELECTED_BID_LIST",
                        payload: result?.data?.data?.data,
                    });

                    dispatch({
                        type: "GET_BONDSMAN_SELECTED_BID_COUNT_LIST",
                        payload: result?.data?.data?.data,
                    });
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const AcceptBid =
    (bond_request_id, bids_id, setModalOpen, pageId, limit) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "acceptBid", true);
        axios
            .put(
                APPROVEBIDS,
                { bond_request_id, bids_id },
                {
                    headers: { Authorization: API_ACCESS_TOKEN },
                    withCredentials: true,
                }
            )
            .then((result) => {
                if (result?.data?.success) {
                    dispatch(
                        GetBidsClient(
                            pageId,
                            bond_request_id,
                            "accepted",
                            limit
                        )
                    );
                    dispatch(
                        GetBondRequestList(
                            {
                                id: bond_request_id,
                            },
                            setModalOpen
                        )
                    );
                    dispatchError(dispatch, "acceptBid", undefined);
                    setModalOpen(true);
                } else elseHandler(dispatch, "acceptBid", result?.data);
                dispatchLoading(dispatch, "acceptBid", false);
            })
            .catch(catchHandler(dispatch, "acceptBid"));
    };

export const SelectedAcceptBid =
    (bond_request_id, bids_id, setModalOpen) => async (dispatch) => {
        dispatchLoading(dispatch, "acceptBid", true);
        axios
            .put(
                APPROVEBIDS,
                { bond_request_id, bids_id },
                {
                    headers: { Authorization: API_ACCESS_TOKEN },
                    withCredentials: true,
                }
            )
            .then((result) => {
                if (result?.data?.success) {
                    setModalOpen && setModalOpen(true);
                    dispatch({
                        type: " GET_BONDSMAN_ALL_BID_LIST",
                        payload: [],
                    });

                    dispatch({
                        type: " GET_BONDSMAN_ALL_BID_COUNT_LIST",
                        payload: 0,
                    });

                    dispatchError(dispatch, "acceptBid", undefined);
                    setModalOpen(true);
                } else elseHandler(dispatch, "acceptBid", result?.data);
                dispatchLoading(dispatch, "acceptBid", false);
            })
            .catch(catchHandler(dispatch, "acceptBid"));
    };

export const DecideBid =
    (bond_request_id, bids_id, setModalOpen, pageId, limit) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "decideBid", true);
        axios
            .delete(APPROVEBIDS, {
                data: { bond_request_id, bids_id },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    dispatch(
                        GetBidsClient(pageId, bond_request_id, null, limit)
                    );
                    dispatch(
                        GetBondRequestList({
                            id: bond_request_id,
                        })
                    );
                    dispatchError(dispatch, "decideBid", undefined);
                    setModalOpen(false);
                } else elseHandler(dispatch, "decideBid", result?.data);
                dispatchLoading(dispatch, "decideBid", false);
            })
            .catch(catchHandler(dispatch, "acceptBid"));
    };

// Get Place Bid
export const GetPlaceBid = (bond_request_id, setState) => async (dispatch) => {
    dispatchLoading(dispatch, "placeBid", true);
    axios
        .get(APPLYBIDS, {
            params: { bond_request_id },
            headers: { Authorization: API_ACCESS_TOKEN },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_PLACE_BID",
                    payload: result?.data?.data?.data?.[0],
                });

                setState &&
                    setState(result?.data?.data?.data?.[0]?.bondsman_service);

                dispatchError(dispatch, "placeBid", undefined);
            } else elseHandler(dispatch, "placeBid", result?.data);
            dispatchLoading(dispatch, "placeBid", false);
        })
        .catch(catchHandler(dispatch, "placeBid"));
};

export const AddBondmanService =
    (data, page, limit, handler, setServiceList, modalPurpose) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .post(BONDSMANSERVICE, data, {
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    const newService = {
                        _id: result?.data?.data?._id,
                        service: result?.data?.data?.service,
                        percentage: result?.data?.data?.percentage,
                        amount: result?.data?.data?.amount,
                    };

                    setServiceList((prevTableData) => {
                        let updatedData;
                        if (modalPurpose === 1) {
                            updatedData =
                                prevTableData?.filter(
                                    (item) => item?.service !== "Service Tax"
                                ) ?? [];
                            const serviceTaxLast = prevTableData?.find(
                                (item) => item?.service === "Service Tax"
                            );
                            updatedData.push(newService, serviceTaxLast);
                        } else {
                            updatedData = [...prevTableData, newService];
                        }
                        return updatedData;
                    });

                    dispatch(FetchBondmanService(page, limit));
                    handler();
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const EditBondmanService =
    (data, page, limit, handler, setServiceList, serviceList) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "bondsman", true);
        axios
            .put(BONDSMANSERVICE, data, {
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    const newService = {
                        _id: result?.data?.data?._id,
                        service: result?.data?.data?.service,
                        percentage: result?.data?.data?.percentage,
                        amount: result?.data?.data?.amount,
                    };

                    const updatedItemIndex = serviceList.findIndex(
                        (item) => item?._id === newService?._id
                    );

                    if (updatedItemIndex !== -1) {
                        setServiceList((prevState) => {
                            const newState = [...prevState];
                            newState[updatedItemIndex] = newService;
                            return newState;
                        });
                    }

                    handler();
                    dispatch(FetchBondmanService(page, limit));
                    dispatchError(dispatch, "bondsman", undefined);
                } else elseHandler(dispatch, "bondsman", result?.data);
                dispatchLoading(dispatch, "bondsman", false);
            })
            .catch(catchHandler(dispatch, "bondsman"));
    };

export const CreateInvoice = (data, navigate) => async (dispatch) => {
    dispatchLoading(dispatch, "invoice", true);
    axios
        .post(INVOICE, data, {
            headers: { Authorization: API_ACCESS_TOKEN },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch(GetInvoice(data?.bond_request_id));
                navigate(-1);
                dispatchToast(dispatch, "success", result?.data?.msg);
                dispatchError(dispatch, "invoice", undefined);
            } else elseHandler(dispatch, "invoice", result?.data);
            dispatchLoading(dispatch, "invoice", false);
        })
        .catch(catchHandler(dispatch, "invoice"));
};

export const EditInvoice = (data, navigate) => async (dispatch) => {
    dispatchLoading(dispatch, "invoice", true);
    axios
        .put(INVOICE, data, {
            headers: { Authorization: API_ACCESS_TOKEN },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch(GetInvoice(data?.bond_request_id));
                navigate(-1);
                dispatchToast(dispatch, "success", result?.data?.msg);
                dispatchError(dispatch, "invoice", undefined);
            } else elseHandler(dispatch, "invoice", result?.data);
            dispatchLoading(dispatch, "invoice", false);
        })
        .catch(catchHandler(dispatch, "invoice"));
};

export const GetInvoice = (bond_request_id) => async (dispatch) => {
    dispatchLoading(dispatch, "invoice", true);
    axios
        .get(INVOICE, {
            headers: { Authorization: API_ACCESS_TOKEN },
            params: { bond_request_id },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_INVOICE",
                    payload: result?.data?.data?.data,
                });

                dispatchError(dispatch, "invoice", undefined);
            } else elseHandler(dispatch, "invoice", result?.data);
            dispatchLoading(dispatch, "invoice", false);
        })
        .catch(catchHandler(dispatch, "invoice"));
};

export const GetSelectedInvoice =
    (bond_request_id, invoice_id, setTableData, setServiceTaxRemain) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "invoice", true);
        axios
            .get(INVOICE, {
                headers: { Authorization: API_ACCESS_TOKEN },
                params: { bond_request_id, invoice_id },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    const data2 =
                        result?.data?.data?.data?.[0]?.bondsman_service;
                    const newData = data2?.filter(
                        (item) =>
                            item?.service !== "Service Tax" && {
                                ...item,
                                amount: item?.remain_amount + item?.amount,
                            }
                    );

                    const newData2 = data2?.filter(
                        (item) =>
                            item?.service === "Service Tax" && {
                                ...item,
                                amount: item?.remain_amount + item?.amount,
                            }
                    );
                    setTableData && setTableData(newData);

                    setServiceTaxRemain && setServiceTaxRemain(newData2);

                    dispatch({
                        type: "GET_EDIT_INVOICE",
                        payload:
                            result?.data?.data?.data?.[0]?.bondsman_service,
                    });

                    dispatch({
                        type: "GET_EDIT_INVOICE_DETAIL",
                        payload: result?.data?.data?.data?.[0],
                    });
                    dispatchError(dispatch, "invoice", undefined);
                } else elseHandler(dispatch, "invoice", result?.data);
                dispatchLoading(dispatch, "invoice", false);
            })
            .catch(catchHandler(dispatch, "invoice"));
    };

export const DeleteInvoice =
    (id, bond_request_id, setState) => async (dispatch) => {
        dispatchLoading(dispatch, "invoice", true);
        axios
            .delete(INVOICE, {
                data: { id },
                headers: { Authorization: API_ACCESS_TOKEN },
                withCredentials: true,
            })
            .then((result) => {
                if (result?.data?.success) {
                    setState && setState(false);
                    dispatch(
                        FetchBail(
                            null,
                            null,
                            bond_request_id,
                            null,
                            null,
                            null,
                            null
                        )
                    );
                    dispatch(GetInvoice(bond_request_id));
                    dispatchError(dispatch, "invoice", undefined);
                } else elseHandler(dispatch, "invoice", result?.data);
                dispatchLoading(dispatch, "invoice", false);
            })
            .catch(catchHandler(dispatch, "invoice"));
    };

export const GetBailRatio = (start_date, end_date) => async (dispatch) => {
    dispatchLoading(dispatch, "bailRatio", true);
    axios
        .get(API_URL + "/bondsman/analytics-reports/bids", {
            params: { start_date, end_date },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_BAIL_RATIO",
                    payload: result?.data?.data,
                });
                dispatchError(dispatch, "bailRatio", undefined);
            } else elseHandler(dispatch, "bailRatio", result?.data);
            dispatchLoading(dispatch, "bailRatio", false);
        })
        .catch(catchHandler(dispatch, "bailRatio"));
};

export const GetCompletionRation =
    (start_date, end_date) => async (dispatch) => {
        dispatchLoading(dispatch, "bailRatio", true);
        axios
            .get(
                API_URL + "/bondsman/analytics-reports/bail-request/complete",
                {
                    params: { start_date, end_date },
                    withCredentials: true,
                }
            )
            .then((result) => {
                if (result?.data?.success) {
                    dispatch({
                        type: "GET_COMPLETION_REPORT",
                        payload: result?.data?.data,
                    });
                    dispatchError(dispatch, "bailRatio", undefined);
                } else elseHandler(dispatch, "bailRatio", result?.data);
                dispatchLoading(dispatch, "bailRatio", false);
            })
            .catch(catchHandler(dispatch, "bailRatio"));
    };

export const GetLastMonthOverview = (date) => async (dispatch) => {
    dispatchLoading(dispatch, "lastMonth", true);
    axios
        .get(API_URL + "/bondsman/analytics-reports/bids-overview", {
            params: { date },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_LAST_MONTH_REQUEST_OVERVIEW",
                    payload: result?.data?.data,
                });
                dispatchError(dispatch, "lastMonth", undefined);
            } else elseHandler(dispatch, "lastMonth", result?.data);
            dispatchLoading(dispatch, "lastMonth", false);
        })
        .catch(catchHandler(dispatch, "lastMonth"));
};

export const GetLastMonthIncome = (date) => async (dispatch) => {
    dispatchLoading(dispatch, "lastMonthIncome", true);
    axios
        .get(API_URL + "/bondsman/analytics-reports/income/month", {
            params: { date },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_LAST_MONTH_INCOME_DISTRIBUTION",
                    payload: result?.data?.data,
                });
                dispatchError(dispatch, "lastMonthIncome", undefined);
            } else elseHandler(dispatch, "lastMonthIncome", result?.data);
            dispatchLoading(dispatch, "lastMonthIncome", false);
        })
        .catch(catchHandler(dispatch, "lastMonthIncome"));
};

export const GetYearIncome = (start_date, end_date) => async (dispatch) => {
    dispatchLoading(dispatch, "yearIncome", true);
    axios
        .get(API_URL + "/bondsman/analytics-reports/income/year", {
            params: { start_date, end_date },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_INCOME_OVERVIEW",
                    payload: result?.data?.data,
                });
                dispatchError(dispatch, "yearIncome", undefined);
            } else elseHandler(dispatch, "yearIncome", result?.data);
            dispatchLoading(dispatch, "yearIncome", false);
        })
        .catch(catchHandler(dispatch, "yearIncome"));
};
