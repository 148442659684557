import axios from "../../helpers/axios";
import { API_ACCESS_TOKEN, API_URL, catchHandler, dispatchError, dispatchLoading, elseHandler } from "../../helpers/utils";

const BAIL = `${API_URL}/bond/`;

/**
 * Fetch Bail List API call
 * @param {Number} page Page number
 * @param {Number} limit Per Page Limit
 * @param {String} id _id of Request
 * @param {String} request_number Bail request ID
 * @param {Number} is_active Filter bond request by activity status
 * @param {String} status Filter bond request by status
 * @param {String} search Search string
 * @param {String} location Location string
 * @param {Date} startDate Start Date
 * @param {Date} endDate End Date
 * @returns
 */

export const FetchBail = (page, limit, id, request_number, search, is_active, status, location, startDate, endDate) => async (dispatch) => {
  dispatchLoading(dispatch, "bail", true);
  axios
    .get(BAIL + "requests", {
      params: {
        page,
        limit,
        id,
        request_number,
        search,
        is_active,
        status,
        location,
        startDate,
        endDate,
      },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "ADMIN_BAIL_LIST_REQUEST",
          payload: result?.data?.data?.data,
        });
        dispatch({
          type: "ADMIN_BAIL_LIST_COUNT",
          payload: result?.data?.data?.count,
        });
        dispatch({
          type: "ADMIN_BAIL_LIST_OF_COUNT",
          payload: result?.data?.data?.status_count,
        });
        dispatchError(dispatch, "bail", undefined);
      } else elseHandler(dispatch, "bail", result?.data);
      dispatchLoading(dispatch, "bail", false);
    })
    .catch(catchHandler(dispatch, "bail"));
};

/**
 * Fetch Bail List Of Document API call
 * @param {String} bond_request_id _id of Request
 * @returns
 */
export const FetchListDocument = (bond_request_id) => async (dispatch) => {
  dispatchLoading(dispatch, "bail", true);
  axios
    .get(BAIL + "request/document", {
      params: { bond_request_id },
      headers: { Authorization: API_ACCESS_TOKEN },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "LIST_BOND_DOCUMENT",
          payload: result?.data?.data,
        });
        dispatchError(dispatch, "bail", undefined);
      } else elseHandler(dispatch, "bail", result?.data);
      dispatchLoading(dispatch, "bail", false);
    })
    .catch(catchHandler(dispatch, "bail"));
};

// All Reqest Bail
export const AllBailRequest = (id, page, limit, search, status, location) => async (dispatch) => {
  dispatchLoading(dispatch, "bail", true);
  axios
    .get(API_URL + "/bondsman/bail-request/all", {
      params: {
        id,
        page,
        limit,
        search,
        status,
        location,
      },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "GET_ALL_BAIL_REQUEST",
          payload: result?.data?.data?.data,
        });
        dispatch({
          type: "GET_ALL_BAIL_COUNT",
          payload: result?.data?.data?.count,
        });
        dispatch({
          type: "GET_ALL_BAIL_COUNT_CATEGORY",
          payload: result?.data?.data?.status_count,
        });
        dispatchError(dispatch, "bail", undefined);
      } else elseHandler(dispatch, "bail", result?.data);
      dispatchLoading(dispatch, "bail", false);
    })
    .catch(catchHandler(dispatch, "bail"));
};

export const FetchBailClientId = (client_id, search, page, limit) => async (dispatch) => {
  dispatchLoading(dispatch, "bail", true);
  axios
    .get(API_URL + "/admin/bail/requests", {
      params: { client_id, search, page, limit },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "GET_CLIENT_BAIL_LIST",
          payload: result?.data?.data?.data,
        });
        dispatch({
          type: "GET_CLIENT_BAIL_LIST_COUNT",
          payload: result?.data?.data?.count,
        });
        dispatch({
          type: "GET_CLIENT_BAIL_LIST_STATUS_COUNT",
          payload: result?.data?.data?.status_count,
        });
        dispatchError(dispatch, "bail", undefined);
      } else elseHandler(dispatch, "bail", result?.data);
      dispatchLoading(dispatch, "bail", false);
    })
    .catch(catchHandler(dispatch, "bail"));
};
