import { FetchBail } from "../../Admin/Redux/BailRequest";
import axios from "../../helpers/axios";
import {
    API_ACCESS_TOKEN,
    API_URL,
    catchHandler,
    dispatchError,
    dispatchLoading,
    dispatchToast,
    elseHandler,
} from "../../helpers/utils";

export const EditBondsmanBailAmount = (data, close) => async (dispatch) => {
    dispatchLoading(dispatch, "bailAmount", true);
    axios
        .put(API_URL + `/bond/request/bond-amount`, data, {
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch(
                    FetchBail(
                        null,
                        null,
                        data?.bond_request_id,
                        null,
                        null,
                        null,
                        null
                    )
                );
                close();
                dispatchError(dispatch, "bailAmount", undefined);
            } else elseHandler(dispatch, "bailAmount", result?.data);
            dispatchLoading(dispatch, "bailAmount", false);
        })
        .catch(catchHandler(dispatch, "bailAmount"));
};
