import { initializeApp } from "firebase/app";
import { getMessaging, isSupported, getToken, onMessage } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { FCM_TOKEN, GetNotification } from "../Redux/Notification";
import { ALIAS, SERVICE_WORKER_REGISTER, VAPID_KEY, firebaseConfig } from "./utils";

const app = initializeApp(firebaseConfig);

const FetchNotification = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    isSupported().then((result) => {
      if (result && isLoggedIn) {
        const messaging = getMessaging(app);

        navigator?.serviceWorker
          ?.register(SERVICE_WORKER_REGISTER)
          .then((registration) => {
            getToken(messaging, {
              serviceWorkerRegistration: registration,
              vapidKey: VAPID_KEY,
            })
              .then((currentToken) => {
                if (currentToken) {
                  dispatch(FCM_TOKEN(currentToken));
                }
              })
              .catch((err) => console.error(err));
          })
          .catch((error) => {
            console.error("ERR__ERR__ERR:", error);
          });

        // on Message get
        onMessage(messaging, function (payload) {
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            icon: `${ALIAS}/favicon.ico`,
          };
          dispatch(GetNotification(1, 10));
          try {
            if (!("Notification" in window)) {
            } else if (Notification?.permission === "granted") {
              var notification = new Notification(notificationTitle, notificationOptions);
              const JsonParse = payload && JSON?.parse(payload?.data?.data);
              const notificationId = JsonParse?.notification_id;
              console.log("payload", payload, payload && JSON?.parse(payload?.data?.data), "notificationId", notificationId);
              notification.onclick = function (event) {
                if (notificationId) {
                  dispatch(GetNotification(1, 10, notificationId));
                }
                event.preventDefault();
                window.open(payload?.data?.link, "_blank");
                notification.close();
              };
            }
          } catch (err) {
            console.error(err);
          }
        });
      }
    });
  }, []);
};

export { FetchNotification };
