import axios from "../helpers/axios";
import { API_URL, catchHandler, dispatchError, dispatchLoading, dispatchToast, elseHandler } from "../helpers/utils";

const BONDSMAN_URL = API_URL + "/bondsman";
const REVIEW_URL = BONDSMAN_URL + "/review";

export const CreateBondManReview = (data, modalClose, params) => (dispatch) => {
  dispatchLoading(dispatch, "review", true);
  axios
    .post(REVIEW_URL, data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatchToast(dispatch, "success", result?.data?.msg);
        dispatchError(dispatch, "auth", undefined);
        modalClose();
        params && dispatch(FetchBondmanReview(params?.page, params?.limit, params?.bondsman_id, null, params?.bond_request_id));
      } else elseHandler(dispatch, "review", result?.data);
      dispatchLoading(dispatch, "review", false);
    })
    .catch(catchHandler(dispatch, "review"));
};

export const UpdateBondmanReview = (data, modalClose, reloadFalse, params) => (dispatch) => {
  dispatchLoading(dispatch, "review", true);
  axios
    .put(REVIEW_URL, data, { withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        if (reloadFalse === true) {
          window.location.reload(false);
        } else {
          dispatch(FetchBondmanReview(params?.page, params?.limit, params?.bondsman_id, null, params?.bond_request_id));
        }
        dispatchToast(dispatch, "success", result?.data?.msg);
        dispatchError(dispatch, "auth", undefined);
        modalClose();
      } else elseHandler(dispatch, "review", result?.data);
      dispatchLoading(dispatch, "review", false);
    })
    .catch(catchHandler(dispatch, "review"));
};

export const FetchBondmanReview =
  (page = 1, limit = 10, bondsmanId, setData, baidId) =>
  (dispatch) => {
    dispatchLoading(dispatch, "review", true);
    axios
      .get(REVIEW_URL, {
        params: baidId ? { page: page, limit: limit, bondsman_id: bondsmanId, bond_request_id: baidId } : { page: page, limit: limit, bondsman_id: bondsmanId },
        withCredentials: true,
      })
      .then((result) => {
        if (result?.data?.success) {
          dispatch({
            type: "GET_REVIEW",
            payload: result?.data?.data,
          });
          dispatch({
            type: "GET_REVIEW_COUNT",
            payload: result?.data?.data?.length,
          });
          setData && setData(result?.data?.data);
          dispatchError(dispatch, "auth", undefined);
        } else elseHandler(dispatch, "review", result?.data);
        dispatchLoading(dispatch, "review", false);
      })
      .catch(catchHandler(dispatch, "review"));
    dispatchLoading(dispatch, "review", false);
  };

export const DeleteBondmanReview = (review_id, modalClose, params) => (dispatch) => {
  dispatchLoading(dispatch, "review", true);
  axios
    .delete(REVIEW_URL, { data: { review_id }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatchToast(dispatch, "success", result?.data?.msg);
        dispatchError(dispatch, "auth", undefined);
        modalClose();
        params && dispatch(FetchBondmanReview(params?.page, params?.limit, params?.bondsman_id));
      } else elseHandler(dispatch, "review", result?.data);
      dispatchLoading(dispatch, "review", false);
    })
    .catch(catchHandler(dispatch, "review"));
};
