import { DatePicker, Select, Space, Tooltip } from "antd";
import { Option } from "antd/es/mentions";
import { Field } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import svg from "../Assets/svg";
import { useState } from "react";

const { RangePicker } = DatePicker;

export const InputBox = ({
    className,
    label,
    labelClassName,
    classInputMerge,
    children,
    type,
    value,
    setValue,
    formClassName,
    placeholder,
    notes,
    error,
    errorClassName,
    autoComplete,
    setChangeError,
    disabled,
    onKeyDown,
    maxLength,
    regex,
}) => {
    return (
        <div className={`${className} ff_rg page-form`}>
            {label && (
                <div
                    className={`${labelClassName} form-label fw-semibold fs-6 text-black`}
                >
                    {label}
                </div>
            )}
            <div className={`${classInputMerge}`}>
                <input
                    type={type}
                    value={value}
                    onChange={(e) => {
                        if (regex === undefined) {
                            setValue(e.target.value);
                        } else {
                            if (regex?.test(e.target.value)) {
                                setValue(e.target.value);
                            }
                        }
                        setChangeError && setChangeError(false);
                    }}
                    className={`form-control ${formClassName} form-control-lg`}
                    placeholder={placeholder}
                    onWheel={(e) => e.target.blur()}
                    // autoComplete={autoComplete.toString()}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                    maxLength={maxLength}
                />
                {children}
            </div>
            {notes && <div className={`notes`}>{notes}</div>}
            <div
                className={`error-message ${errorClassName} txt-error f12 mb-1 line-height-normal`}
            >
                {error} &nbsp;
            </div>
        </div>
    );
};

export const TextAreaBox = ({
    className,
    label,
    labelClassName,
    classInputMerge,
    children,
    type,
    value,
    setValue,
    formClassName,
    placeholder,
    notes,
    error,
    errorClassName,
    autoComplete,
    setChangeError,
    disabled,
    onKeyDown,
    maxLength,
    regex,
}) => {
    return (
        <div className={`${className} ff_rg page-form`}>
            {label && (
                <div
                    className={`${labelClassName} form-label fw-semibold fs-6 text-black`}
                >
                    {label}
                </div>
            )}
            <div className={`${classInputMerge}`}>
                <textarea
                    type={type}
                    value={value}
                    onChange={(e) => {
                        if (regex === undefined) {
                            setValue(e.target.value);
                        } else {
                            if (regex.test(e.target.value)) {
                                setValue(e.target.value);
                            }
                        }
                        setChangeError && setChangeError(false);
                    }}
                    className={`form-control ${formClassName} form-control-lg`}
                    placeholder={placeholder}
                    onWheel={(e) => e.target.blur()}
                    // autoComplete={autoComplete.toString()}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                    maxLength={maxLength}
                    rows={4}
                />
                {children}
            </div>
            {notes && <div className={`notes`}>{notes}</div>}
            <div
                className={`error-message ${errorClassName} txt-error f12 mb-1 line-height-normal`}
            >
                {error} &nbsp;
            </div>
        </div>
    );
};

export const FormikInput = ({
    value,
    error,
    placeholder,
    label,
    className,
    type,
    icon,
    iconClick,
    contactField,
    inputClassName,
    fieldValue,
    isDisabled,
    tooltipTitle,
    toolTipIcon,
}) => {
    return (
        <div className={className}>
            <label
                htmlFor={value}
                className="form-label fw-semibold fs-6 text-black"
            >
                {label}
                {tooltipTitle && (
                    <Tooltip title={tooltipTitle} className="ms-auto">
                        <span className="exclamation_sign position-absolute">
                            {toolTipIcon}
                        </span>
                    </Tooltip>
                )}
            </label>
            <div className="position-relative">
                {fieldValue ? (
                    <Field
                        type={type}
                        className={`
                        form-control form-control-lg 
                        ${icon && "pe-5"} 
                        ${inputClassName && inputClassName}`}
                        id={value}
                        name={value}
                        placeholder={placeholder}
                        autoComplete={"false"}
                        value={fieldValue}
                        disabled={isDisabled}
                    />
                ) : (
                    <Field
                        type={type}
                        className={`form-control form-control-lg ${
                            icon && "pe-5"
                        } ${inputClassName && inputClassName}`}
                        id={value}
                        name={value}
                        placeholder={placeholder}
                        autoComplete={"false"}
                        disabled={isDisabled}
                    />
                )}

                {contactField && contactField}
                {icon && (
                    <span
                        className="formik-input-icon cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            iconClick();
                        }}
                    >
                        {icon}
                    </span>
                )}
            </div>
            <small className="text-danger d-block">
                {error}
                &nbsp;
            </small>
        </div>
    );
};

export const FormikTextArea = ({
    value,
    error,
    placeholder,
    label,
    className,
    type,
    classNameField,
    disabled,
}) => {
    return (
        <div className={className}>
            <label
                htmlFor={(value, type)}
                className="form-label fw-semibold fs-6 text-black"
            >
                {label}
            </label>
            <div className={classNameField}>
                <Field
                    as="textarea"
                    type={type}
                    className="form-control form-control-lg"
                    id={value}
                    name={value}
                    placeholder={placeholder}
                    rows="4"
                    disabled={disabled}
                />
                <small className="text-danger d-block">
                    {error}
                    &nbsp;
                </small>
            </div>
        </div>
    );
};

export const FormikDate = ({
    value,
    error,
    placeholder,
    label,
    className,
    type,
}) => {
    <div className={className}>
        <label
            htmlFor={(value, type)}
            className="form-label fw-semibold fs-6 text-black"
        >
            {label}
        </label>
        <Field id={value} name={value}>
            {({ field, form }) => (
                <Space direction="vertical">
                    <DatePicker
                        {...field}
                        value={field.value ? moment(field.value) : null}
                        onChange={(date, dateString) =>
                            form.setFieldValue("dateField", dateString || null)
                        }
                        format="DD MM YYYY"
                        placeholder="Select date"
                    />
                </Space>
            )}
        </Field>
        <small className="text-danger d-block">
            {error}
            &nbsp;
        </small>
    </div>;
};

export const FormikCheckbox = ({
    type,
    label,
    className,
    labelClassName,
    name,
}) => {
    return (
        <div className={`${className} formik`}>
            <label className={labelClassName}>
                <Field
                    type={type}
                    name={name}
                    className="form-check-input mt-0"
                />
                &nbsp;&nbsp;{label}
            </label>
        </div>
    );
};

export const SelectDropDown = ({
    divClass,
    dataList,
    dataId,
    setFieldValue,
    labelText,
    initialValue,
    error,
}) => {
    return (
        <div className={divClass}>
            <label
                htmlFor={`${dataId}`}
                className="form-label fw-semibold fs-6 text-black"
            >
                {labelText}
            </label>
            <Select
                defaultValue={initialValue || null}
                id={`${dataId}`}
                placeholder={"Select"}
                className={`antdSelect ff w-100 ${divClass} ${
                    initialValue ? `selected` : ``
                } `}
                onChange={(e) => {
                    setFieldValue(`${dataId}`, e);
                }}
                suffixIcon={svg?.dashBoardDownArrow}
                allowClear
            >
                {dataList?.map((option) => (
                    <Option key={option?.id} value={option?.name}>
                        {option?.name}
                    </Option>
                ))}
            </Select>
            <small className="text-danger d-block">
                {error}
                &nbsp;
            </small>
        </div>
    );
};
SelectDropDown.propTypes = {
    divClass: PropTypes.string.isRequired,
    dataList: PropTypes.array.isRequired,
    dataId: PropTypes.string.isRequired,
    setFieldValue: PropTypes.any.isRequired,
    labelText: PropTypes.string.isRequired,
    initialValue: PropTypes.string || PropTypes.undefined,
    error: PropTypes.string || PropTypes.undefined,
};

export const SearchableSelect = ({
    divClass,
    dataList,
    dataId,
    labelText,
    initialValue,
    setFieldValue,
    error,
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [options, setOptions] = useState(dataList);

    const handleSearch = (value) => {
        setSearchValue(value);
        if (value) {
            const filteredOptions = dataList.filter(
                (option) =>
                    option.name.toLowerCase().startsWith(value.toLowerCase()) ||
                    option.state_full
                        .toLowerCase()
                        .startsWith(value.toLowerCase())
            );
            setOptions(filteredOptions);
        }
    };
    return (
        <div className={divClass}>
            <label
                htmlFor={`${dataId}`}
                className="form-label fw-semibold fs-6 text-black"
            >
                {labelText}
            </label>
            <Select
                showSearch
                defaultValue={initialValue || null}
                id={`${dataId}`}
                placeholder={"Select"}
                className={`antdSelect ff ${divClass} ${
                    initialValue ? `selected` : ``
                }`}
                onSearch={handleSearch}
                onChange={(e) => {
                    setFieldValue(`${dataId}`, e);
                }}
                suffixIcon={svg?.dashBoardDownArrow}
                notFoundContent={null}
                allowClear
            >
                {options.map((option) => (
                    <Option key={option.source_id} value={option.name}>
                        {`${option.name}, ${option.state_full}`}
                    </Option>
                ))}
            </Select>
            <small className="text-danger d-block">
                {error}
                &nbsp;
            </small>
        </div>
    );
};
SearchableSelect.prototype = {
    divClass: PropTypes.string.isRequired,
    dataList: PropTypes.array.isRequired,
    dataId: PropTypes.string.isRequired,
    setFieldValue: PropTypes.any.isRequired,
    labelText: PropTypes.string.isRequired,
    initialValue: PropTypes.string || PropTypes.undefined,
    error: PropTypes.string || PropTypes.undefined,
};

export const convertTimeZone = (date) => {
    const split = new Date(date)?.toString()?.split(" ");
    const timeZone = split?.slice(-3)?.join(" ")?.replace(/\(|\)/g, "");
    const finalTimeZone = timeZone
        ?.split(" ")
        ?.map((word) => word?.charAt(0))
        ?.join("");

    let utcDate = date;
    let localDate = utcDate?.toLocaleString(undefined, {
        timeZone: finalTimeZone,
    });

    return localDate;
};
