import * as Yup from "yup";
import { AMOUNT_REGEX, EMAIL_REGEX, ONLY_ALPHABETS_REGEX, PHONE_REGEX } from "../utils";
import { MAX_FILE_SIZE_IMAGE, isValidFileType } from "./authSchema";

export const profileValidation = Yup.object().shape({
  fname: Yup.string().matches(ONLY_ALPHABETS_REGEX, "Only alphabets are allowed").max(40, "The maximum length for First name is 40 characters").required("Please enter first name"),
  lname: Yup.string().matches(ONLY_ALPHABETS_REGEX, "Only alphabets are allowed").max(40, "The maximum length for Last name is 40 characters").required("Please enter last name"),
  contactNumber: Yup.string()
    .required("Please enter contact number")
    .matches(/^\d{10}$/, "Contact number must be exactly 10 digits"),
  email: Yup.string().matches(EMAIL_REGEX, "Please enter valid email").required("Please enter email"),
  zip_code: Yup.string().required("Please enter zip code"),
  // profile: Yup.mixed()
  //     .optional()
  //     .nullable()
  //     .test(
  //         "is-valid-type",
  //         "Not a valid image type",
  //         (value) =>
  //             !value ||
  //             isValidFileType(value && value?.name?.toLowerCase(), "image")
  //     )
  //     .test(
  //         "is-valid-size",
  //         "Max allowed size is 2MB",
  //         (value) => !value || (value && value.size <= MAX_FILE_SIZE_IMAGE)
  //     ),
});

export const companyTaxFormSchema = Yup.object().shape({
  companyName: Yup.string().required("Please enter company name"),
  companyTaxNumber: Yup.string().required("Please enter company tax number"),
  tax: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, "Only numbers are allowed with up to two decimal places")
    .max(7.5, "Please enter a valid platform fees")
    .test("max", "Tax percentage cannot be greater than 7.5", (value) => parseFloat(value) <= 7.5)
    .required("Please enter tax percentage"),
});

export const ZipCodeChangeSchema = Yup.object().shape({
  zip_code: Yup.string().required("Please enter zip code"),
});

export const platformFeesSchema = Yup.object().shape({
  platform_fee: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, "Only numbers are allowed with up to two decimal places")
    .max(5, "Please enter a valid platform fees")
    .test("max", "Platform fees cannot be greater than 80", (value) => parseFloat(value) <= 80)
    .required("Please enter your platform fees"),
});

export const notificationTemplateSchema = Yup.object().shape({
  title: Yup.string().required("Please enter title"),
  description: Yup.string().required("Please enter description"),
});
