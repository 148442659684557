import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import axios from "axios";
import { SearchPostalCode } from "../Redux/postalRedux";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../helpers/utils";
import svg from "../Assets/svg";

const { Option } = Select;

const PostalCode = () => {
    const [loading, setLoading] = useState(false);
    const [postalCodeData, setPostalCodeData] = useState(null);
    const [suggestions, setSuggestions] = useState([]);

    const handlePostalCodeSearch = async (value) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `http://api.geonames.org/postalCodeLookupJSON?postalcode=${value}&country=US&username=kashyap_456`
            );
            setPostalCodeData(response?.data);
        } catch (error) {
            console.error("Error fetching zip code data:", error);
        } finally {
            setLoading(false);
        }
    };
    const clearPostalCodeData = () => {
        setPostalCodeData(null);
    };
    const handleInputChange = async (value) => {
        if (value.length >= 3) {
            try {
                const response = await axios.get(
                    `${API_URL}/postalCode/coordinates?postalcode=${value}`
                );
                const suggestionsData = response?.data?.postalcodes || [];

                const filteredData = suggestionsData.filter((data) => {
                    return data?.countryCode === "US";
                });
                const formattedSuggestions = filteredData.map((data) => ({
                    key: Math.random(),
                    value: data?.postalcode,
                    label: data?.postalcode,
                    placeName: data?.placeName,
                    adminName1: data?.adminName1,
                }));
                setSuggestions(formattedSuggestions);
            } catch (error) {
                console.error("Error fetching zip code suggestions:", error);
            }
        } else {
            setSuggestions([]);
        }
    };

    return (
        <>
            <Select
                showSearch
                placeholder="Search zip codes"
                defaultActiveFirstOption={false}
                // showArrow={true}
                filterOption={false}
                onSearch={handleInputChange}
                onChange={handlePostalCodeSearch}
                onClear={clearPostalCodeData}
                notFoundContent={loading ? <Spin size="small" /> : null}
                className="antdPostCode"
            >
                {suggestions.map((option) => (
                    <Option key={Math.random()} value={option.value}>
                        {option.label}-{option.placeName}-{option.adminName1}
                    </Option>
                ))}
            </Select>
        </>
    );
};

const NewPostalCode = ({
    setAddressData,
    setFieldValue,
    defaultValue,
    placeHolder,
}) => {
    // Use Selector
    const { postalCodeLoading } = useSelector((state) => state.loading);

    // Use Dispatch
    const dispatch = useDispatch();

    // Use State
    const [postalCodes, setPostalCodes] = useState([]);
    const [postalOptions, setPostalOptions] = useState([]);
    const [selectOption, setSelectOption] = useState([]);
    const [defaultPostal, setDefaultPostal] = useState("");

    // Handle Zip Code Search
    const handleSearch = (value) => {
        if (value.length > 2) {
            dispatch(SearchPostalCode(value, setPostalCodes));
        }
    };

    // Handle Zip Code Changes
    const handleChange = (value) => {
        const selctedPostal = postalOptions.find(
            (postal) => postal.value === value
        );

        setAddressData({ ...selctedPostal });
        if (setFieldValue) {
            setFieldValue("city", selctedPostal?.city);
            setFieldValue("state", selctedPostal?.state);
            setFieldValue("zip_code", selctedPostal?.zip_code);
            setFieldValue("lat", selctedPostal?.lat);
            setFieldValue("long", selctedPostal?.lng);
        }

        selectOption.push(selctedPostal);
    };

    // Clear Code
    const clearPostalCodeData = () => {
        setPostalOptions(null);
        setSelectOption([]);
    };

    useEffect(() => {
        if (postalCodes.length) {
            const formattedSuggestions = postalCodes?.map((data, index) => ({
                key: index,
                value: `${data?.postalcode}-${index}`,
                zip_code: data?.postalcode,
                city: data?.placeName,
                state: data?.adminName1,
                lat: data?.lat,
                lng: data?.lng,
            }));
            setPostalOptions(formattedSuggestions);
        }
    }, [postalCodes]);

    useEffect(() => {
        const newValue = computeDerivedValue(defaultValue);
        setDefaultPostal(newValue);
    }, [defaultValue]);

    const computeDerivedValue = (propValue) => {
        return propValue ? propValue : "";
    };

    const condition =
        selectOption?.length === 0 || selectOption?.length === undefined;

    return (
        <>
            {defaultPostal !== "" ? (
                <>
                    <Select
                        defaultValue={defaultPostal}
                        placeholder={
                            placeHolder ? placeHolder : "Search Zip Code"
                        }
                        defaultActiveFirstOption={false}
                        className={`w-100 antdPostCode ${
                            !condition ? "item-selected" : ""
                        }`}
                        showSearch
                        filterOption={false}
                        suffixIcon={svg?.search}
                        onSearch={(e) => {
                            handleSearch(e);
                        }}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        onClear={clearPostalCodeData}
                        notFoundContent={
                            postalCodeLoading ? <Spin size="small" /> : null
                        }
                        // onBlur={() => {
                        //     setPostalOptions(null);
                        // }}
                        allowClear
                    >
                        {postalOptions &&
                            postalOptions.map((option, index) => (
                                <Option key={index} value={option.value}>
                                    {option.zip_code}-{option.city}-
                                    {option.state}
                                </Option>
                            ))}
                    </Select>
                </>
            ) : (
                <Select
                    placeholder={placeHolder ? placeHolder : "Search Zip Code"}
                    defaultActiveFirstOption={false}
                    className={`w-100 antdPostCode ${
                        !condition ? "item-selected" : ""
                    }`}
                    showSearch
                    filterOption={false}
                    suffixIcon={svg?.search}
                    onSearch={(e) => {
                        handleSearch(e);
                    }}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    onClear={clearPostalCodeData}
                    notFoundContent={
                        postalCodeLoading ? <Spin size="small" /> : null
                    }
                    // onBlur={() => {
                    //     setPostalOptions(null);
                    // }}
                    allowClear
                >
                    {postalOptions &&
                        postalOptions.map((option, index) => (
                            <Option key={index} value={option.value}>
                                {option.zip_code}-{option.city}-{option.state}
                            </Option>
                        ))}
                </Select>
            )}
        </>
    );
};

export default NewPostalCode;
