import React, { useEffect, useState } from "react";
import { GoBack } from "../../Components/Compoents";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { CreateContract } from "../Redux/Contract";
import { UploadContractModal } from "../../Components/Antd/AntdModal";
import { BtnLoader } from "../../helpers/utils";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFViewer = ({ pdfUrl, width }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setLoading(false);
    }

    function onError(error) {
        setError(error);
        setLoading(false);
    }

    return (
        <div>
            <div className="pdf-container">
                <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={"w-100"}
                    onError={onError}
                >
                    {Array?.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            scale={1}
                            className={"w-100"}
                            width={width ? width : "768"}
                        />
                    ))}
                </Document>
            </div>
        </div>
    );
};

const ReviewContract = () => {
    // Translation
    const { t } = useTranslation();

    // Use Param
    const { bailId } = useParams();

    // Dispatch
    const dispatch = useDispatch();

    // Selector
    const { previewContractFile, infoData } = useSelector(
        (state) => state.contract
    );

    const { createContractLoading } = useSelector((state) => state.loading);

    // Navigate
    const navigate = useNavigate();

    // Contract
    const [contractModal, setContractModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");

    const createContractHandler = () => {
        // Create formData
        const formData = new FormData();
        formData.append("bond_request_id", bailId);
        formData.append("file", infoData?.originFileObj);

        if (formData) {
            dispatch(CreateContract(formData, navigate));
        }
    };

    useEffect(() => {
        // Set initial pdfUrl using previewContractFile
        if (previewContractFile && previewContractFile) {
            setPdfUrl(previewContractFile);
        } else {
            setPdfUrl("");
        }
    }, [previewContractFile]);

    return (
        <div className="ReviewContract py-4">
            <div>
                <GoBack
                    title={t("button.back")}
                    path={`/bond-man/all-bails/${bailId}/request-detail`}
                />
            </div>

            <div className="d-flex justify-content-between">
                <div className="subpage-title">
                    <h2 className="fs-2 fw-bold text-black mb-0">
                        Review Contract
                    </h2>
                    <p className="fs-6 mb-0 text-black mt-3">
                        Review contract for accurate client details, terms,
                        conditions, and legality.
                    </p>
                </div>
                <div>
                    <button
                        className="btn c-error btn-lg text-uppercase theme-btn px-4 txt-white me-3"
                        onClick={() => setContractModal(true)}
                    >
                        Change
                    </button>

                    <button
                        className="btn btn-success btn-lg text-uppercase theme-btn px-4"
                        onClick={createContractHandler}
                        disabled={createContractLoading}
                    >
                        {createContractLoading && <BtnLoader />}
                        Send For E-Sign
                    </button>
                </div>
            </div>

            <PDFViewer pdfUrl={pdfUrl} className="w-100" />

            {contractModal && (
                <UploadContractModal
                    modalOpen={contractModal}
                    cancelHandler={() => setContractModal(false)}
                    bailId={bailId}
                    modalPurpose={0}
                />
            )}
        </div>
    );
};

export default ReviewContract;
