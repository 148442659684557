import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, Checkbox, Image, Progress, Rate, Tooltip, message, Avatar, Table, Tag } from "antd";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";

// Image
import svg from "../Assets/svg";
import Profile_Avatar from "../Assets/Images/Icons/Avatar.svg";
import ContractBG from "../Assets/Images/Banner/contract-bg.png";

// Helper
import {
	momentDDMMYYAMPM,
	momentDateTimeFormatAMPM,
	momentDDMMYY,
	dayjsOnlyLongDateFormat,
	PROFILE_URL,
	API_URL,
	catchHandler,
	elseHandler,
	dispatchLoading,
	dispatchToast,
	dispatchError,
	BOND_DOCS_URL,
	BtnLoader,
	formatPhoneNumber,
	dayjsFormat,
	momentHHMMAMPM,
	CLIENT_URL,
} from "../helpers/utils";

// Modal
import { AcceptBidModal, AdminBondsmanPlatFormFees, BondsmanBailAmountEditModal, DeleteModal, ReviewModal, ReviewPreview, WorkingHoursModal, WriteAReview } from "./Antd/AntdModal";

// dayjs
import dayjs from "dayjs";

// Redux
import { CancelBondRequest, DeleteUploadedDocument, DeleteUploadedDocumentRequest, GetBondRequestList, ListDocumentRequest } from "../Redux/bondRequest";
import { EditBondsmanStatusRedirect } from "../Admin/Redux/Bondsman";
import { FetchListDocument } from "../Admin/Redux/BailRequest";
import {
	AcceptBid,
	AddBondmanService,
	DecideBid,
	DeleteBondmanService,
	DeleteInvoice,
	EditBondmanService,
	FetchBondmanService,
	GetInvoice,
	SelectedAcceptBid,
} from "../BondMan/Redux/Bondman";

// Components
import { InputBox, convertTimeZone } from "./Input";
import { PrintInvoiceHandler, PrintReciptHandler } from "../View/Pages/Download/pdfPrint";
import { ConnectBankAccount, ConnectStripeAccount, CreateNewAccount, CreateStripeCustomer, FetchBondsmanStripeAccount, RemoveStripeAccount } from "../Redux/payment";
import { GetProfileAPI } from "../Redux/auth";
import { FetchBondmanReview } from "../Redux/bondsman";
import { DeleteContract, GetContractList, SignContract, ViewContract } from "../BondMan/Redux/Contract";
import { FetchInitiateBondProcess } from "../BondMan/Redux/Cases";
import { GetNotification } from "../Redux/Notification";

// Bail Relation - Request For("self","family","friend","other")
export const BondRelation = ({ setValue, className, name }) => {
	// Translation
	const { t } = useTranslation();

	// Dispatch
	const dispatch = useDispatch();

	// Selector
	const { requestFor } = useSelector((state) => state.bondRequest);

	const data = [
		{
			title: t("pages.home.bond.me"),
			value: "self",
			svg: svg?.me,
		},
		{
			title: t("pages.home.bond.friend"),
			value: "friend",
			svg: svg?.friend,
		},
		{ title: t("pages.home.bond.other"), value: "other", svg: svg?.other },
	];
	return (
		<div className={`row gy-4 d-inline-flex justify-content-center ${className}`}>
			{data &&
				data?.map((ele, index) => {
					return (
						<label
							htmlFor={ele?.value + name}
							className={`BondRelation col-md-4 col-6 ${requestFor === ele?.value ? "txt-white isTabActive" : "isTabInActive"}`}
							key={index}
						>
							<input
								type={"radio"}
								className="form-check-input"
								id={ele?.value + name}
								value={ele?.value}
								name={name}
								onChange={(e) => {
									setValue(e.target.value);
									dispatch({
										type: "REQUEST_FOR_CHANGE",
										payload: e.target.value,
									});
								}}
								checked={requestFor === ele?.value}
							/>
							<div className="card main-sec-box bg-white text-center h-100">
								<div className="card-body pt-4">
									{ele?.svg}
									<p className="mb-0 fs-6 text-black mt-4 fw-semibold">{ele?.title}</p>
								</div>
							</div>
						</label>
					);
				})}
		</div>
	);
};

// Page - Title Of Page
export const SectionTitle = ({ className, title, description }) => {
	return (
		<div className={`section-title text-center mb-40 ${className}`}>
			{title && <h2 className="fw-bolder text-black mb-0">{title}</h2>}
			{description && <p className="mb-0 fw-medium">{description}</p>}
		</div>
	);
};

// Home - Why Choose
export const WhyChooseCard = ({ className, svg, value, title }) => {
	return (
		<div className="col-xl-3 col-lg-5 col-md-6 col-sm-10 col-11">
			<div className={`card border-0 why-choose-card bondsman-card h-100 ${className} `}>
				<div className="card-body d-flex align-items-center">
					<div className="circle-box position-relative rounded-circle me-3">{svg}</div>
					<div>
						<h3 className="fw-bold fs-40 mb-0 line-height-normal">{value}</h3>
						<p className="mb-0 fs-18 text-black fw-medium text-nowrap">{title}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

// Home - Service
export const ServiceCard = ({ img, title }) => {
	return (
		<div className="col-xl-4 col-lg-5 col-md-6">
			<div className="card service-card rounded-10 mw-100 mx-auto overflow-hidden border-0">
				<div className="card-body image-body position-relative p-0">
					<img src={img} width={460} height={320} className="w-100 h-100 position-absolute start-0 top-0 object-fit-cover" alt="Service-Provide-Image" />
				</div>
				<div className="card-body">
					<div className="text-center fw-semibold fs-4 text-truncate">{title}</div>
				</div>
			</div>
		</div>
	);
};

// Home - Testimonial
export const TestimonialsCard = ({ img, description, name, citizen }) => {
	return (
		<div>
			<div className="card card-shadow rounded-10 overflow-hidden border-0">
				<div className="card-body p-0">
					<div className="row align-items-center gx-0">
						<div className="col-lg-6">
							<div className="testimonials-img position-relative">
								<img src={img} width={730} height={533} className="w-100 h-100 position-absolute start-0 top-0 object-fit-cover" alt="Image" />
							</div>
						</div>
						<div className="col-lg-6 position-relative">
							{svg?.testimonialQuote}
							<div className="px-xl-5 px-3 py-lg-3 py-sm-5 py-4 ms-lg-5 mt-lg-0 mt-3 testimonials-text customScroll overflow-y-auto">
								<p className="fs-5 fw-medium">{description}</p>
								<div className="text-info fs-6 fw-semibold sticky-bottom bg-white rounded-3 p-2 px-0 d-inline-block pt-4 need-blue-bullet position-relative">
									{name} | {citizen}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// Other Page(Privacy,Terms,About) Comman Background & Title
export const BannerBackground = ({ title, routeTitle, currentPage, className }) => {
	return (
		<div className={`${className} linearbg-banner d-flex align-items-center justify-content-center text-center`}>
			<div>
				<h1 className="mb-2 fw-bolder">{title}</h1>
				<p className="ff_md f16">
					<span className="txt-error">{routeTitle}</span> / {currentPage}
				</p>
			</div>
		</div>
	);
};

// Header - Notification
export const NotificationIcon = ({}) => {
	// Dispatch
	const dispatch = useDispatch();

	const { notificationUnread } = useSelector((state) => state.notification);

	const [pageId, setPageId] = useState(1);
	const [itemLimit, setItemLimit] = useState(10);

	useEffect(() => {
		dispatch(GetNotification(pageId, itemLimit));
	}, [pageId, itemLimit, dispatch]);

	const number = notificationUnread ? notificationUnread : 0;
	return (
		<>
			<svg width={46} height={46} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${number > 0 ? `svgIsActive` : `svgNotIsActive`}`}>
				<circle cx={23} cy={23} r={23} fill="#F2F2F2" />
				<g clipPath="url(#clip0_576_1836)">
					<path
						d="M33.2895 29.9643C30.4952 27.1328 30.4394 24.4129 30.4394 24.385V20.684C30.4378 18.7732 29.678 16.9411 28.3268 15.59C26.9757 14.2388 25.1436 13.4791 23.2328 13.4775C22.2492 13.4198 21.2641 13.5631 20.3377 13.8988C19.4114 14.2345 18.5631 14.7554 17.8448 15.4298C17.1264 16.1042 16.5531 16.9179 16.1597 17.8213C15.7662 18.7247 15.5611 19.6987 15.5566 20.684V24.385C15.5566 24.4129 15.5055 27.1328 12.7112 29.9643C12.4757 30.1972 12.3163 30.4961 12.2541 30.8214C12.1918 31.1468 12.2296 31.4834 12.3625 31.7868C12.4873 32.0969 12.7027 32.3621 12.9805 32.5479C13.2584 32.7337 13.5858 32.8314 13.92 32.8283H32.0806C33.5229 32.885 34.3509 30.955 33.2895 29.9643ZM22.7679 12.5476C23.5728 12.5176 24.3781 12.5958 25.1623 12.7801C25.2041 12.0143 25.1298 11.169 24.53 10.6181C24.2255 10.3209 23.8405 10.1196 23.4228 10.0392C23.005 9.95877 22.5728 10.0027 22.1797 10.1656C21.7867 10.3284 21.4501 10.603 21.2117 10.9554C20.9732 11.3077 20.8434 11.7223 20.8384 12.1477V12.7801C21.4699 12.6259 22.1177 12.5478 22.7679 12.5476ZM19.6202 33.7582C20.8067 36.7477 25.1948 36.7468 26.3804 33.7582H19.6202Z"
						fill="#A2A2A2"
					/>
				</g>
				<circle cx="29.5" cy="16.5" r={8} fill="#0D9AF2" stroke="white" />
				<defs>
					<clipPath id="clip0_576_1836">
						<rect width={26} height={26} fill="white" transform="translate(10 10)" />
					</clipPath>
				</defs>
			</svg>
			<span
				className={`notificationNumber ${number > 0 ? `svgIsActive` : `svgNotIsActive`}`}
				style={{
					position: "absolute",
					right: "12.8px",
					color: "#fff",
					fontSize: "8px",
					top: "10.4px",
				}}
			>
				{number > 9 ? `9+` : number}
			</span>
		</>
	);
};

// Navigation Link
export const NavigationLink = ({ path, title, svg, children, onClick, className }) => {
	const { t } = useTranslation();
	return (
		<NavLink to={path} className={`NavigationLink d-flex text-decoration-none ${className}`} data-image={t(title)} onClick={onClick}>
			{svg && <div className="NavIconBG">{svg}</div>}
			<div className="labelContainer text-dark">
				<span>{t(title)}</span> {children && children}
			</div>
		</NavLink>
	);
};

// Antd Modal - Submit Button Group
export const FooterButtonAntdModal = ({
	className,
	children,
	addTitle,
	addSuperHandler,
	backTitle,
	backSuperHandler,
	backDisable,
	loading,
	skipButton,
	addBtnClass,
	backBtnClass,
}) => {
	return (
		<div className={`text-center mb-0 submitHandler ${className}`}>
			{children}
			{addTitle && (
				<div>
					<button
						type="submit"
						disabled={loading}
						className={`btn btn-success btn-lg antdButton addHandler fw-semibold f16 ${addBtnClass} px-3 px-md-4`}
						onClick={() => {
							addSuperHandler && addSuperHandler();
						}}
						loading={loading}
					>
						{loading && <BtnLoader />}
						{addTitle}
					</button>
				</div>
			)}
			{backTitle && (
				<div className="mt-2 d-flex align-items-center justify-content-center">
					<Button
						className={`btn btn-transparent btn-lg antdButton backHandler fw-semibold f16 txt-error ${backBtnClass}`}
						onClick={(e) => {
							e.preventDefault();
							backSuperHandler();
						}}
						disabled={backDisable || loading}
						// loading={loading}
					>
						{backTitle}
					</Button>
					{skipButton && skipButton}
				</div>
			)}
		</div>
	);
};

// Get Bail Relation Title
export const RelationTitle = ({ selected }) => {
	// Translation
	const { t } = useTranslation();

	const statusOfRelation = {
		self: t("modal.bond.self"),
		family: t("modal.bond.family"),
		friend: t("modal.bond.friend"),
		other: t("modal.bond.other"),
	};

	const status = statusOfRelation?.[selected];
	return status;
};

// Get Bail Relation Selected According Title For Antd Modal
export const BailAmountTitle = ({ selected }) => {
	// Translation
	const { t } = useTranslation();

	const statusOfRelation = {
		self: t("modal.bailAmount.self"),
		family: t("modal.bailAmount.family"),
		friend: t("modal.bailAmount.friend"),
		other: t("modal.bailAmount.other"),
	};

	const status = statusOfRelation?.[selected];
	return status;
};

// Bail Privacy Imporation Tooltip Icon
export const PrivacyTooltip = ({ className }) => {
	// Translation
	const { t } = useTranslation();
	return (
		<Tooltip title={t("privacyMessage")} className={className}>
			{svg?.privacy}
		</Tooltip>
	);
};

export const PrivacyTooltip2 = ({ className }) => {
	// Translation
	const { t } = useTranslation();
	return (
		<Tooltip title={"This information will not be visible to you until your bid is accepted by client."} className={className}>
			{svg?.privacy}
		</Tooltip>
	);
};

// Main Title Of Page
export const PageTitle = ({ className, title, description, titleClass }) => {
	return (
		<div className={`mb-4 subpage-title ${className}`}>
			{title && <h2 className={`fs-2 fw-bold text-black d-flex align-items-center mb-0 ${titleClass}`}>{title}</h2>}
			{description && <p className="fs-6 mb-0 text-black mt-3">{description}</p>}
		</div>
	);
};

// Sub Title Of Page
export const SubPageTitle = ({ title }) => {
	return <h3 className="mb-0 fs-4 fw-bold">{title}</h3>;
};

// Go Back to previous page
export const GoBack = ({ title, path, className }) => {
	const navigate = useNavigate();
	return (
		<div className={`d-inline-flex align-items-center text-decoration-none txt-error mb-md-4 mb-3 cursor-pointer f16 ${className}`} onClick={() => navigate(path)}>
			{svg?.back}
			{title}
		</div>
	);
};

// Go Back to selected page
export const GoBackPage = ({ title, path, className }) => {
	const navigate = useNavigate();
	return (
		<div className={`d-inline-flex align-items-center text-decoration-none txt-error mb-md-4 mb-3 cursor-pointer ${className}`} onClick={() => navigate(path)}>
			{title}
		</div>
	);
};

// Page title with Edit Icon
export const PageTitleWithEdit = ({ title, onClick, className, titleClass }) => {
	// Translation
	const { t } = useTranslation();
	return (
		<div className={`d-flex align-items-start justify-content-md-start justify-content-between mb-2 ${className} txt-dark`}>
			<h3 className={`mb-0 fw-bold cursor-pointer-default ${titleClass ? titleClass : "fs-4"}`}>{title}</h3>
			{onClick && (
				<div className="d-inline-flex align-items-center text-decoration-none text-orange ms-md-4 ms-2 mt-md-1 edit__btn txt-error cursor-pointer" onClick={onClick}>
					{svg?.edit} <span className="f16">{t("Edit")}</span>
				</div>
			)}
		</div>
	);
};

// Case Detail Preview Card
export const ReviewRequestCard = ({ className, title, value, isSecure }) => {
	return (
		<div className={`${className} txt-dark`}>
			<label className={`fs-6 fw-normal mb-1 ${isSecure ? "d-flex align-items-center" : ""}`}>
				{title}
				{isSecure ? <PrivacyTooltip /> : ""}
			</label>
			<h4 className={`mb-0 fw-semibold fs-5 ${isSecure ? "filter-blur-5" : ""}`}>{value}</h4>
		</div>
	);
};

// Bail Success - What Happen Card
export const WhatsHappenCard = ({ svg, title, description }) => {
	return (
		<div className="col-md-6 ">
			<div className="card process-card border-0 h-100">
				<div className="card-body txt-dark">
					<div className="d-flex align-items-md-center align-items-start">
						{svg}
						<div className="text-start">
							<h4 className="fs-5 mb-2 fw-semibold">{title}</h4>
							<p className="mb-0 fs-6">{description}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// Search bar
export const SearchBar = ({ className, value, setValue, placeholder, searchHandler, inputClass, setPage }) => {
	return (
		<div className={`searchBar ${className} position-relative`}>
			<input
				type="text"
				className={`searchInput form-control focus-border py-2 border-s h38 ${inputClass}`}
				name="searchBar"
				value={value}
				placeholder={placeholder}
				onChange={searchHandler}
			/>
			{value?.length ? (
				<span
					onClick={() => {
						setValue("");
						setPage && setPage(1);
					}}
				>
					{svg?.searchClear}
				</span>
			) : (
				svg?.search
			)}
		</div>
	);
};

// Radio Button Select Any One
export const RadioButtonSelectAnyOne = ({ data, className, name, setValue, value, children, setPageId, setSearch }) => {
	return (
		<>
			{data?.map((ele, index) => (
				<>
					<label
						htmlFor={ele?.value}
						className={`RadioButtonSelectAnyOne ${className} d-flex align-items-center justify-content-center f14 ff_md text-uppercase border-s px-3 cursor-pointer min-fit-content ${
							value === ele?.value ? "c-error txt-white" : "border-1 c-white b-error txt-error"
						} ${ele?.length === 0 ? `zeroAssign` : ``}`}
						key={index}
					>
						<input
							type={"radio"}
							className="form-check-input"
							id={ele?.value}
							value={ele?.value}
							name={name}
							onChange={(e) => {
								setValue(e.target.value);
								setPageId && setPageId(1);
								setSearch && setSearch(``);
							}}
							checked={value === ele?.value}
						/>
						<span>
							{ele?.name}
							{ele?.length !== undefined && ` (${ele?.length})`}
						</span>
					</label>
				</>
			))}
			{children}
		</>
	);
};

// My Case Card
export const CaseTypeCard = ({ className, data, handleDelete }) => {
	// Use Translation
	const { t } = useTranslation();

	// Use Selector
	const { bondDetails, isStepActive } = useSelector((state) => state.bondRequest);

	// Use Dispatch
	const dispatch = useDispatch();

	const navigate = useNavigate();

	// Selector
	const { user } = useSelector((state) => state.auth);

	let redirectUrl = () => {
		let url;
		if (user?.role?.[0] === "admin") {
			url = `/admin/bail-request/${data?._id}/bail-detail`;
		} else if (user?.role?.[0] === "sub-admin") {
			url = `/sub-admin/bail-request/${data?._id}/bail-detail`;
		} else if (user?.role?.[0] === "client") {
			url = `/my-case/${data?._id}/request-detail`;
		} else {
			url = `/bondsman/all-bails/${data?._id}/request-detail`;
		}
		return url;
	};

	const detailPagePath = redirectUrl();
	// user?.role?.[0] === "admin" || user?.role?.[0] === "sub-admin"
	// ? `/admin/bail-request/${data?._id}/bail-detail`
	// : user?.role?.[0] === "client"
	// ? `/my-case/${data?._id}/request-detail`
	// : `/bondsman/all-bails/${data?._id}/request-detail`;

	// Status of Bail Case
	const status = caseTypeStatus[data?.bond_request_status?.status];

	const loadIncompleteBondRequest = () => {
		if (data?.step_completed === 6) {
			navigate(`/my-case/${data?._id}/request-form`);
		} else if (data?.step_completed === 7) {
			navigate(`/my-case/${data?._id}/review-request`);
		} else {
			dispatch(GetBondRequestList({ id: data?._id }));
		}
	};

	// Use Effect
	useEffect(() => {
		if (bondDetails && bondDetails?._id === data?._id && data?.step_completed < 6 && !isStepActive) {
			dispatch({
				type: "CHANGE_BR_STEP",
				payload: data?.step_completed + 2,
			});
			dispatch({
				type: "IS_STEPS_ACTIVE",
				payload: true,
			});
			dispatch({
				type: "EDIT_BR_STEPS",
				payload: false,
			});
		}
	}, [bondDetails]);

	const updataData = {
		title: "",
		link: "",
	};

	const getStatusMessage = () => {
		const { status, bids, approved_bondsman } = data?.bond_request_status || {};
		const detailLink = `/my-case/${data?._id}/request-detail`;

		switch (status) {
			case "unpaid":
				updataData.title = t("pages.mycase.invoice");
				updataData.link = detailLink;
				break;
			case "paid":
				updataData.title = t("pages.mycase.bondProcess");
				updataData.link = detailLink;
				break;
			case "in-progress":
				updataData.title = t("pages.mycase.bondSubmitted");
				updataData.link = detailLink;
				break;
			case "cancel":
				updataData.title = t("pages.mycase.cancelled");
				updataData.link = detailLink;
				break;
			case "pending":
				updataData.title =
					data?.sended_request_count <= 0
						? "Send This Bail Request To A Bondsman"
						: approved_bondsman === null && bids <= 0
						? "Waiting For Response"
						: `${bids} Bid${bids > 1 ? `s` : ``} Received`;

				updataData.link = data?.sended_request_count <= 0 ? `/my-case/${data?._id}/select-bondsman` : `/my-case/${data?._id}/bondsman-list`;

				break;
			default:
				updataData.title = "";
				updataData.link = detailLink;
				break;
		}

		return updataData;
	};

	const message = getStatusMessage();

	//  Use
	const acceptRef = useRef(); // Accept - CANCEL REQUEST

	// State
	const [dropdown, setDropdown] = useState(false);
	const [isConfirm, setIsConfirm] = useState(false);
	const [requestId, setRequestId] = useState(null);

	return (
		<div className={`${className} CaseTypeCard ${message?.title}`}>
			<div className="b-shadow c-white border-m position-relative cursor-pointer">
				<div
					className="p-3"
					onClick={() => {
						data?.bond_request_status?.status !== "incomplete" && navigate(detailPagePath);
					}}
				>
					<div
						className="case-title p-1 d-inline-flex align-items-center px-3 position-absolute end-0 top-0 f16"
						style={{
							backgroundColor: `rgba(${status?.color},.2)`,
							color: `rgba(${status?.color},1)`,
						}}
					>
						<svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx={6} cy={6} r={6} fill={`rgba(${status?.color},1)`} />
						</svg>
						<span className="ms-1">{t(status?.title)}</span>
					</div>
					<div className="case-details">
						<p className="txt-grey f16 case-created-time">{dayjs(convertTimeZone(data?.created_at)).format(momentDateTimeFormatAMPM)}</p>
						<p className="txt-primary fw-bolder f20 caseId">#{data?.request_number}</p>
						<p className="ff_rg f20 caseNumber">
							{t("input.case_number")}: <span className="ff_md">{data?.case_number}</span>
						</p>
						<p className="ff_rg f20 courtDate">
							{t("pages.mycase.cDate")}: <span className="ff_md">{data?.court_date_time && dayjs(data?.court_date_time).format(momentDateTimeFormatAMPM)}</span>
						</p>
					</div>
					{data?.bond_request_status?.approved_bondsman !== null && (
						<>
							<hr className="my-2" />
							<BidUser data={data} />
						</>
					)}
				</div>
				{data?.bond_request_status?.status !== "accepted" && (
					<div className="case-status-text py-2">
						{data?.bond_request_status?.status === "incomplete" ? (
							<>
								<span className="fw-bolder f16 text-center txt-error d-inline-block w-100 text-decoration-none cursor-pointer" onClick={loadIncompleteBondRequest}>
									{t("pages.mycase.completeRequest")}
								</span>

								<div ref={acceptRef} className="delete-request">
									<button className="toggleCase" onClick={() => setDropdown(!dropdown)}>
										{svg?.threeDot}
									</button>
									<ul className={`profileDropDown ${dropdown ? "d-grid" : "d-none"} position-absolute b-shadow c-white p-3 rounded-8`}>
										<NavigationLink
											title={"DELETE REQUEST"}
											onClick={(e) => {
												// console.log("eee", data?._id);
												e.preventDefault();
												setIsConfirm(true);
												setRequestId(data?._id);
												// handleDelete(data?._id, () => setIsConfirm(false));
											}}
											className={"text-uppercase"}
										/>
									</ul>
								</div>
							</>
						) : (
							<>
								<span
									onClick={() => {
										dispatch({
											type: "SENT_TO_MORE_BONDSMAN_CLEAR_ALL",
										});
										navigate(message?.link);
									}}
									className="fw-bolder f16 text-center txt-error d-inline-block w-100 text-decoration-none"
								>
									{message?.title}
								</span>
							</>
						)}
					</div>
				)}
			</div>

			<DeleteModal
				modalOpen={isConfirm}
				cancelSuperHandler={() => setIsConfirm(false)}
				deleteHandler={(data) => {
					if (requestId) {
						handleDelete(requestId, () => setIsConfirm(false));
					}else{

						setRequestId(null);
					}
				}}
				deleteTitle={t("button.delete")}
				closeTitle={t("button.close")}
				title={`Delete Request`}
				svg={svg?.deleteDocumentLarge}
				description={`Are you sure, you want to delete this bail request?`}
			/>
		</div>
	);
};

// Bid User
export const BidUser = ({ className, data }) => {
	const value = `${data?.bond_request_status?.approved_bondsman?.average_review ? `(${data?.bond_request_status?.approved_bondsman?.average_review})` : ``}` || "";
	const userData = {
		imgUrl: "",
		firstName: "",
		lastName: "",
	};
	if (data?.bond_request_status?.approved_bondsman) {
		userData.imgUrl = data?.bond_request_status?.approved_bondsman?.photo_thumb
			? data?.bond_request_status?.approved_bondsman?.photo_thumb
			: data?.bond_request_status?.approved_bondsman?.photo;
		userData.firstName = data?.bond_request_status?.approved_bondsman?.first_name;
		userData.lastName = data?.bond_request_status?.approved_bondsman?.last_name;
	} else {
		userData.imgUrl = data?.client_id?.photo_thumb ? data?.client_id?.photo_thumb : data?.client_id?.photo;
		userData.firstName = data?.client_id?.first_name;
		userData.lastName = data?.client_id?.last_name;
	}
	return (
		<div className={`${className} BidUser d-flex align-items-center justify-content-between`}>
			<div className="user d-flex align-items-center gap-2">
				<div className="user-image">
					<img
						src={userData?.imgUrl ? `${PROFILE_URL}/${userData?.imgUrl}` : Profile_Avatar}
						className="img-fluid rounded-circle object-fit-cover b-shadow"
						alt="bid-user"
					/>
				</div>
				<div className="user-detail text-truncate">
					<p className="f20 fw-bolder">
						{userData?.firstName} {userData?.lastName}
					</p>
					{value && (
						<div className="d-flex align-items-center">
							<div>
								<Rate disabled value={data?.bond_request_status?.approved_bondsman?.average_review} allowHalf style={{ color: "#FBC01B" }} />
							</div>
							<p className="f16 fw-bolder ms-2">{`${value}`}</p>
						</div>
					)}
				</div>
			</div>
			<div className="user-chat">{svg?.userChat}</div>
		</div>
	);
};

// Upload File - Document
export const UploadFile = ({ data, className, setState }) => {
	// Translation
	const { t } = useTranslation();

	// Use Dispatch
	const dispatch = useDispatch();

	// Use Selector
	const { user } = useSelector((state) => state.auth);

	// State
	const [isDocumentRemove, setIsDocumentRemove] = useState(false);
	const [docName, setDocName] = useState("");
	const [displayInput, setDisplayInput] = useState(false);
	const [onProgress, setOnProgress] = useState(false);
	const [showError, setShowError] = useState(false);
	const [key, setKey] = useState(0);
	const [uploadProgress, setUploadProgress] = useState(0);

	// Close Delete Modals
	const closeDeleteDoc = () => setIsDocumentRemove(false);

	// Delete Document request
	const deleteDocReq = () => {
		const tempData = {
			id: data?._id,
			bond_request_id: data?.bond_request_id,
		};
		dispatch(DeleteUploadedDocumentRequest(tempData, closeDeleteDoc));
	};

	const label = data?.name;

	const customRequest = async ({ file, onSuccess, onError }) => {
		try {
			setOnProgress(true);
			const formData = new FormData();
			formData.append("file", file);
			formData.append("bond_request_id", data?.bond_request_id);
			if (label) formData.append("docs_request_id", data?._id);
			if (docName) formData.append("name", docName);
			dispatchLoading(dispatch, "bondRequest", true);
			axios
				.post(`${API_URL}/bond/request/document/upload`, formData, {
					withCredentials: true,
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						setUploadProgress(percentCompleted);
					},
				})
				.then((result) => {
					if (result?.data?.success) {
						dispatchToast(dispatch, "success", result?.data?.msg);
						dispatchError(dispatch, "auth", undefined);
						dispatch(FetchListDocument(data?.bond_request_id));
						dispatch(ListDocumentRequest(data?.bond_request_id));
						onSuccess();
						setKey((prevKey) => prevKey + 1);
						setIsDocumentRemove(false);
						setDocName("");
						setDisplayInput(false);
						setShowError(false);
						setOnProgress(false);
						setState(false);
					} else {
						setOnProgress(false);
						elseHandler(dispatch, "bondRequest", result?.data);
						onError();
					}
				})
				.catch(catchHandler(dispatch, "bondRequest"));
			dispatchLoading(dispatch, "bondRequest", false);
		} catch (err) {
			elseHandler(dispatch, "bondRequest", false);
		}
	};

	const props = {
		name: "uploadDoc",
		multiple: false,
		percent: 30,
		accept: "image/jpg,image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		progress: {
			strokeColor: {
				"0%": "#108ee9",
				"100%": "#87d068",
			},
			strokeWidth: 3,
			format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
		},
	};

	const submitNewDocRequest = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (docName !== "") {
			setShowError(false);
			setDisplayInput(false);
		} else {
			setShowError(true);
		}
	};

	const userImage = data?.bondsman_id ? `${PROFILE_URL}/${data?.bondsman_id?.photo_thumb}` : user?.photo ? `${PROFILE_URL}/${user?.photo_thumb}` : Profile_Avatar;

	return (
		<>
			<div className={`txt-dark ${className} UploadFile col-12 ${onProgress ? "onProgress" : ""}`}>
				<div className="border-1 border-m p-3 b-light-grey c-white position-relative">
					{label ? (
						<>
							<p className="f24 ff_md mb-1 text-capitalize txt-dark">{label}</p>
							<div
								className="onRemoveDocument cursor-pointer d-flex"
								onClick={(e) => {
									e.preventDefault();
									setIsDocumentRemove(true);
								}}
							>
								{svg?.onRemoveDocument}
							</div>
							<div className="fileUploadUser d-flex align-items-center mb-2 3">
								<img src={userImage} className="rounded-circle" />
								<span className="ms-2 f16 txt-dark-2">
									{data?.bondsman_id == null ? "Self" : `${data?.bondsman_id?.first_name} ${data?.bondsman_id?.last_name}`}
								</span>
							</div>
							{
								<Dragger key={key} {...props} customRequest={customRequest} className="antdUpload antdDragger docUpload">
									{label && (
										<div
											className={`beforeUpload ${displayInput ? " show " : " "}`}
											onClick={(e) => {
												if (docName === "" && !label) {
													e.preventDefault();
													e.stopPropagation();
													setDisplayInput(true);
												}
											}}
										>
											{/* <div className={`${displayInput ? "d-flex" : "d-none"} flex-column justify-content-center align-items-center beforeUploadContent`}>
													<input
														className="form-control form-control-lg"
														placeholder="Enter Document Name"
														value={docName}
														onClick={(e) => {
															e.stopPropagation();
														}}
														onChange={(e) => {
															setDocName(e.target.value);
														}}
													/>
													<small className="text-danger">
														&nbsp;
														{showError && "Please enter document name"}
													</small>
													<div className="d-flex align-items-center justify-content-center">
														<button
															className="btn c-warning text-uppercase fw-semibold fs-6 px-4 position-relative text-white"
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																setDisplayInput(false);
																setDocName("");
															}}
														>
															Cancel
														</button>
														<button
															className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative ms-2 ms-md-3 text-white"
															onClick={(e) => {
																e.stopPropagation();
																submitNewDocRequest(e);
															}}
														>
															Submit
														</button>
													</div>
												</div> */}
										</div>
									)}
									<div>{svg?.fileUpload}</div>
									<p className="mt-2">
										<span className="fw-medium">{t("pages.caseDetail.DragDrop")}</span> / <span className="txt-error">{t("pages.caseDetail.click")}</span>
									</p>
									<p className="mt-0 txt-dark-2">{t("pages.caseDetail.FileSupport")}</p>
								</Dragger>
							}
						</>
					) : docName && displayInput === false ? (
						<>
							<p className="f20 ff_md mb-2">{docName}</p>
							<span
								className="onRemoveDocument cursor-pointer d-flex top-0 btn c-error text-uppercase fw-semibold fs-6 px-2 mt-3 text-white lh-1 mt-0 text-capitalize"
								onClick={(e) => {
									setDisplayInput(true);
								}}
							>
								Edit
							</span>
							<div className="fileUploadUser d-flex align-items-center mb-2 2">
								<img
									src={user?.photo_thumb ? `${PROFILE_URL}/${user?.photo_thumb}` : user?.photo ? `${PROFILE_URL}/${user?.photo}` : Profile_Avatar}
									className="rounded-circle"
								/>
								<span className="ms-2 f16">{user?.first_name + " " + user?.last_name}</span>
							</div>
						</>
					) : (
						<>
							<p className="f24 ff_md mb-2 text-capitalize">{t("pages.caseDetail.UploadFile")}</p>
							<span
								className={`${
									!displayInput ? "cursor-pointer " : "pe-none "
								} onRemoveDocument d-flex top-0 text-uppercase fw-semibold fs-6 mt-3 text-white text-uppercase gap-2`}
							>
								<span
									className="btn c-error px-2 lh-1 text-white"
									onClick={(e) => {
										if (docName === "") {
											setDisplayInput(true);
										} else {
											e.preventDefault();
										}
									}}
								>
									{" "}
									Add{" "}
								</span>
								<span className="btn c-warning px-2 lh-1 text-white" onClick={() => setState(false)}>
									{" "}
									Remove{" "}
								</span>
							</span>
						</>
					)}
					{
						<div className={`${displayInput ? "d-flex" : "d-none"} flex-column justify-content-center align-items-center beforeUploadContent`}>
							<input
								className="form-control form-control-lg"
								placeholder="Enter Document Name"
								value={docName}
								onClick={(e) => {
									e.stopPropagation();
								}}
								onChange={(e) => {
									setDocName(e.target.value);
								}}
							/>
							<small className="text-danger">
								&nbsp;
								{showError && "Please enter document name"}
							</small>
							<div className="d-flex align-items-center justify-content-center">
								<button
									className="btn c-warning text-uppercase fw-semibold fs-6 px-4 position-relative text-white"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setDisplayInput(false);
										setDocName("");
									}}
								>
									Cancel
								</button>
								<button
									className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative ms-2 ms-md-3 text-white"
									onClick={(e) => {
										e.stopPropagation();
										submitNewDocRequest(e);
									}}
								>
									Submit
								</button>
							</div>
						</div>
					}
					{!displayInput && docName?.length > 0 && (
						<Dragger key={key} {...props} customRequest={customRequest} className="antdUpload antdDragger docUpload">
							{!label && (
								<div
									className={`beforeUpload ${displayInput ? " show " : " "}`}
									onClick={(e) => {
										if (docName === "" && !label) {
											e.preventDefault();
											e.stopPropagation();
											setDisplayInput(true);
										}
									}}
								>
									{/* <div className={`${displayInput ? "d-flex" : "d-none"} flex-column justify-content-center align-items-center beforeUploadContent`}>
												<input
													className="form-control form-control-lg"
													placeholder="Enter Document Name"
													value={docName}
													onClick={(e) => {
														e.stopPropagation();
													}}
													onChange={(e) => {
														setDocName(e.target.value);
													}}
												/>
												<small className="text-danger">
													&nbsp;
													{showError && "Please enter document name"}
												</small>
												<div className="d-flex align-items-center justify-content-center">
													<button
														className="btn c-warning text-uppercase fw-semibold fs-6 px-4 position-relative text-white"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setDisplayInput(false);
															setDocName("");
														}}
													>
														Cancel
													</button>
													<button
														className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative ms-2 ms-md-3 text-white"
														onClick={(e) => {
															e.stopPropagation();
															submitNewDocRequest(e);
														}}
													>
														Submit
													</button>
												</div>
											</div> */}
								</div>
							)}
							<div>{svg?.fileUpload}</div>
							<p className="mt-2">
								<span className="fw-medium">{t("pages.caseDetail.DragDrop")}</span> / <span className="txt-error">{t("pages.caseDetail.click")}</span>
							</p>
							<p className="mt-0 txt-dark-2">{t("pages.caseDetail.FileSupport")}</p>
						</Dragger>
					)}

					{onProgress && (
						<div className="custom-ant-upload-list">
							<Progress percent={uploadProgress} />
						</div>
					)}
				</div>
			</div>
			<DeleteModal
				modalOpen={isDocumentRemove}
				cancelSuperHandler={() => setIsDocumentRemove(false)}
				deleteHandler={deleteDocReq}
				deleteTitle={t("button.delete")}
				closeTitle={t("button.close")}
				title={t("label.deleteDocument")}
				svg={svg?.deleteDocumentLarge}
				description={t("pages.caseDetail.deleteDocument")}
			/>
		</>
	);
};

// Document List Of Uploaded Document
export const UploaderPreview = ({ name, className, data }) => {
	// Translation
	const { t } = useTranslation();

	// Use Dispatch
	const dispatch = useDispatch();

	// Use Selector
	const { user } = useSelector((state) => state.auth);

	// State
	const [isDocumentRemove, setIsDocumentRemove] = useState(false);
	const [isFileRemove, setIsFileRemove] = useState(false);

	const label = name?.split(".")?.[0];

	// Close Delete Modals
	const closeDeleteDoc = () => setIsDocumentRemove(false);
	const closeDeleteFile = () => setIsFileRemove(false);

	// Delete Document request
	const deleteDocReq = () => {
		const tempData = {
			id: data?._id,
			bond_request_id: data?.bond_request_id,
		};
		dispatch(DeleteUploadedDocumentRequest(tempData, closeDeleteDoc));
	};

	// Delete Uploaded Document
	const deleteUploadedFile = () => {
		const tempData = {
			docs_request_id: data?._id,
			bond_request_id: data?.bond_request_id,
		};
		dispatch(DeleteUploadedDocument(tempData, closeDeleteFile));
	};

	return (
		<div className={`${className} UploadFile col-12  previewFile txt-dark`}>
			<div className="border-1 border-m p-3 b-light-grey c-white  position-relative">
				<p className="f24 ff_md mb-1 text-capitalize txt-dark">{label}</p>
				<div
					className="onRemoveDocument cursor-pointer d-flex"
					onClick={(e) => {
						e.preventDefault();
						setIsDocumentRemove(true);
					}}
				>
					{svg?.onRemoveDocument}
				</div>
				<div className="fileUploadUser d-flex align-items-center mb-2 1">
					<img
						src={
							data?.bondsman_id?.photo_thumb
								? `${PROFILE_URL}/${data?.bondsman_id?.photo_thumb}`
								: data?.bondsman_id?.photo
								? `${PROFILE_URL}/${data?.bondsman_id?.photo}`
								: data?.bondsman_id === null
								? `${PROFILE_URL}/${user?.photo}`
								: Profile_Avatar
						}
						className="rounded-circle"
						onError={(event) => (event.target.src = Profile_Avatar)}
					/>
					<span className="ms-2 f16 txt-dark-2">{data?.bondsman_id == null ? "Self" : `${data?.bondsman_id?.first_name} ${data?.bondsman_id?.last_name}`}</span>
				</div>
				<div className="antdDragger uploadPreviewBorder p-3 text-center justify-content-center position-relative">
					<div
						className="onRemove cursor-pointer d-flex"
						onClick={(e) => {
							e.preventDefault();
							setIsFileRemove(true);
						}}
					>
						{svg?.onRemoveFile}
					</div>
					<div className="h-100">
						<div className="uploadedFileSuccess">{svg?.uploadedFileSuccess}</div>
						<p className="txt-error mt-1 fw-bolder text-capitalize f24">
							{name}
							<span className="text-uppercase">{"." + data?.file?.name.split(".")[1]}</span>
						</p>
						<p className="f14 mt-1 txt-dark">
							{t("pages.caseDetail.uploadedAt")} {momentDDMMYYAMPM()}
						</p>
					</div>
				</div>
			</div>

			<DeleteModal
				modalOpen={isFileRemove}
				cancelSuperHandler={() => setIsFileRemove(false)}
				deleteHandler={deleteUploadedFile}
				deleteTitle={t("button.remove")}
				closeTitle={t("button.close")}
				title={t("label.ars")}
				svg={svg?.deleteDocumentLarge}
				description={t("pages.caseDetail.deleteFile")}
			/>

			<DeleteModal
				modalOpen={isDocumentRemove}
				cancelSuperHandler={() => setIsDocumentRemove(false)}
				deleteHandler={deleteDocReq}
				deleteTitle={t("button.delete")}
				closeTitle={t("button.close")}
				title={t("label.deleteDocument")}
				svg={svg?.deleteDocumentLarge}
				description={t("pages.caseDetail.deleteDocument")}
			/>
		</div>
	);
};

const baseUrl = "";
// Document List - PDF
export const UploaderPreviewPdf = ({ url, name, onClick, className }) => {
	return (
		<span className={`ant-upload-wrapper antdUpload ${className} css-dev-only-do-not-override-1ck3jst customUploader`}>
			<div className="ant-upload-list ant-upload-list-picture">
				<div className="ant-upload-list-item-container">
					<div className="ant-upload-list-item ant-upload-list-item-done">
						<a className="ant-upload-list-item-thumbnail" href={`${baseUrl}${url}`} target="_blank" rel="noopener noreferrer">
							<img src={`${baseUrl}${url}`} alt={name} className="ant-upload-list-item-image" />
						</a>
						<a target="_blank" rel="noopener noreferrer" className="ant-upload-list-item-name" title="Swat Protection Service" href={`${baseUrl}${url}`}>
							{name}
						</a>
						<span className="ant-upload-list-item-actions picture">
							<button
								title="Remove file"
								type="button"
								className="ant-btn css-dev-only-do-not-override-1ck3jst ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action"
								onClick={onClick}
							>
								<span className="ant-btn-icon">
									<span role="img" aria-label="delete" tabIndex={-1} className="anticon anticon-delete">
										<svg viewBox="64 64 896 896" focusable="false" data-icon="delete" width="1em" height="1em" fill="currentColor" aria-hidden="true">
											<path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
										</svg>
									</span>
								</span>
							</button>
						</span>
					</div>
				</div>
			</div>
		</span>
	);
};

// Case Detail - Case Progress Handler
export const DetailHandle = ({ className, type, caseId }) => {
	// Translation
	const { t } = useTranslation();

	// Use Navigate
	const navigate = useNavigate();

	// Use Dispatch
	const dispatch = useDispatch();

	const { bondRequestLoading } = useSelector((state) => state.loading);

	//  Use
	const acceptRef = useRef(); // Accept - CANCEL REQUEST

	// State
	const [dropdown, setDropdown] = useState(false);

	// Use
	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	// Handler
	const handleClickOutside = (event) => {
		const containerElement = acceptRef.current;
		if (containerElement && !containerElement.contains(event.target)) {
			setDropdown(false);
		}
	};

	const status = caseTypeStatus[type];

	const [isConfirm, setIsConfirm] = useState(false);

	const [selectIPStatus, setSelectIPStatus] = useState("Bond Processing");

	useEffect(() => {
		if (type === "in-progress") {
			dispatch(FetchInitiateBondProcess(caseId, setSelectIPStatus));
		}
	}, [type, dispatch]);

	return (
		<div className={`${className} DetailHandle position-relative`}>
			<div className={`d-flex align-items-center ${type === "accepted" && "justify-content-between"} mt-2 mt-md-0`}>
				<div
					className="DetailHandleTitle d-flex align-items-center gap-1"
					style={{
						color: `rgba(${status?.color},1)`,
					}}
				>
					<svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx={6} cy={6} r={6} fill={`rgba(${status?.color},1)`} />
					</svg>
					<span className="ff_md f20"> {t(status?.title)}</span>
				</div>

				{type === "incomplete" && (
					<div className="incomplete FooterSticky">
						<button className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative ms-sm-2 ms-md-3 text-white h46">{t("button.continue")}</button>
					</div>
				)}

				{type === "pending" && (
					<div className="pending FooterSticky">
						<button className="btn c-warning text-uppercase fw-semibold fs-6 px-4 position-relative ms-sm-2 ms-md-3 text-white h46" onClick={() => setIsConfirm(true)}>
							<span className="text-uppercase">{t("button.cancelRequest")}</span>
						</button>

						<button
							className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative ms-2 ms-md-3 text-white h46"
							onClick={(e) => {
								e.preventDefault(e);
								navigate(`/my-case/${caseId}/review-request?edit`);
							}}
						>
							<span>{t("button.edit")}</span>
						</button>
					</div>
				)}

				{type === "accepted" && (
					<div ref={acceptRef}>
						<button
							className="btn text-uppercase fw-semibold fs-6 px-3 position-relative ms-2 ms-md-3 text-white requestButton"
							style={{
								backgroundColor: "rgba(217, 217, 217, 1)",
							}}
							onClick={() => setDropdown(!dropdown)}
						>
							<span>{svg?.toggleDot}</span>
						</button>
						<ul className={`profileDropDown ${dropdown ? "d-grid" : "d-none"} position-absolute b-shadow c-white p-3 rounded-8`}>
							<NavigationLink title={t("button.cancelRequest")} onClick={(e) => setIsConfirm(true)} className={"text-uppercase"} />
						</ul>
					</div>
				)}

				{type === "in-progress" && (
					<>
						<p className="ff_md f20 ms-2 ms-lg-3 txt-BF9E61 cursor-pointer">{selectIPStatus}</p>
					</>
				)}
			</div>
			<DeleteModal
				modalOpen={isConfirm}
				cancelSuperHandler={() => setIsConfirm(false)}
				deleteHandler={() => {
					dispatch(CancelBondRequest(caseId, () => setIsConfirm(false)));
				}}
				deleteTitle={t("button.cancel")}
				closeTitle={t("button.close")}
				title={`${t("button.cancelRequest")}?`}
				svg={svg?.cancelIconModal}
				description={t("pages.caseDetail.cancelRequestDescription")}
				loader={bondRequestLoading}
			/>
		</div>
	);
};

// Case Detail - Bondsman Card
export const BondsmanCard = ({ className, biddetail, accept, chat, isToggle, chatHistory, writeReview, data, dataUpdate, setModalOpen, setData }) => {
	// Translation
	const { t } = useTranslation();

	// dispatch
	const dispatch = useDispatch();

	// Navigate
	const navigate = useNavigate();

	//  Use
	const acceptRef = useRef(); // Accept - CANCEL REQUEST

	// Selector
	const { acceptBidLoading, decideBidLoading } = useSelector((state) => state.loading);

	// State
	const [dropdown, setDropdown] = useState(false);
	const [isConfirm, setIsConfirm] = useState(false);

	// Use
	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	// Handler
	const handleClickOutside = (event) => {
		const containerElement = acceptRef.current;
		if (containerElement && !containerElement.contains(event.target)) {
			setDropdown(false);
		}
	};

	const amount = data?.bids_amount;

	const acceptBidHandler = () => {
		dispatch(AcceptBid(data?.bond_request_id, data?._id, setModalOpen, dataUpdate?.pageId, dataUpdate?.requestLimit));
		setData(data);
	};

	const removeBidHandler = () => {
		dispatch(DecideBid(data?.bond_request_id, data?._id, setIsConfirm, dataUpdate?.pageId, dataUpdate?.requestLimit));
	};

	const bidDetailPath = `/my-case/${data?.bond_request_id}/bondsman/${data?._id}`;

	return (
		<div className={`${className} BondsmanCard`}>
			<div className="border-s p-2 p-sm-3" style={{ backgroundColor: "rgba(246, 246, 246, 1)" }}>
				<div className={`BidUser d-flex align-items-center justify-content-between position-relative`}>
					<div className="user d-flex align-items-center gap-2 cursor-pointer text-truncate" onClick={() => navigate(bidDetailPath)}>
						<div className="user-image">
							{data?.bondsman_id?.photo ? (
								<img
									src={
										data?.photo_thumb
											? `${PROFILE_URL}/${data?.bondsman_id?.photo}`
											: data?.bondsman_id?.photo
											? `${PROFILE_URL}/${data?.bondsman_id?.photo}`
											: Profile_Avatar
									}
									className="img-fluid rounded-circle"
									alt="bid-user"
								/>
							) : (
								<img src={Profile_Avatar} className="img-fluid rounded-circle" alt="bid-user" />
							)}
						</div>
						<div className="user-detail text-truncate">
							<p className="f20 fw-bolder line-height-normal fullname">{FullName(data?.bondsman_id)}</p>
							<div className="d-flex align-items-center address text-truncate">
								{svg?.location}
								<p className="f14 ms-1 text-truncate">{FullAddressString(data?.bondsman_id)}</p>
							</div>
						</div>
					</div>
					<div className="BondsmanCard-Amount txt-green fw-bolder f20 d-flex align-items-center">
						<div>
							<div className="bondsman-detail-rating text-end">
								{data?.bondsman_id?.average_review && (
									<div className="d-flex align-items-center justify-content-center">
										{svg?.ratingStar}
										<span className="f14 txt-dark fw-medium ms-1">{data?.bondsman_id?.average_review}</span>
									</div>
								)}
							</div>
							<div className="Amount line-height-normal text-end">{`$${round(amount, 2)}`}</div>
						</div>
						{isToggle && (
							<div ref={acceptRef}>
								<button className="btn text-uppercase fw-semibold fs-6 px-3 pe-0 position-relative text-white" onClick={() => setDropdown(!dropdown)}>
									<span>{svg?.toggleDot2}</span>
								</button>
								<ul className={`profileDropDown ${dropdown ? "d-grid" : "d-none"} position-absolute b-shadow c-white p-3 rounded-8`}>
									<NavigationLink title={t("button.removeBondsman")} onClick={(e) => setIsConfirm(true)} />
								</ul>
							</div>
						)}
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
					{biddetail && (
						<button className="btn c-primary text-uppercase fw-semibold fs-6 px-lg-3 px-2 position-relative text-white h46" onClick={() => navigate(bidDetailPath)}>
							<span>{t("pages.bondsmanDetail.bidDetail")}</span>
						</button>
					)}

					{accept && (
						<button className="btn c-error text-uppercase fw-semibold fs-6 px-lg-3 px-2 text-white h46 acceptBidButton" onClick={acceptBidHandler}>
							{acceptBidLoading && <BtnLoader />}
							<span>{t("button.accept")}</span>
						</button>
					)}

					{chat && (
						<button className="btn c-error text-uppercase fw-semibold fs-6 px-lg-3 px-2 position-relative me-2 me-md-3 text-white h46">
							<span>
								{svg?.chating} <span className="ms-1">{t("button.chat")}</span>
							</span>
						</button>
					)}

					{chatHistory && (
						<button className="btn c-primary text-uppercase fw-semibold fs-6 px-lg-3 px-2 position-relative text-white h46">
							<span>
								{svg?.chating} <span className="ms-1"> {t("button.chatHistory")} </span>
							</span>
						</button>
					)}

					{writeReview && (
						<button className="btn c-error text-uppercase fw-semibold fs-6 px-sm-4 px-2 position-relative text-white h46">
							<span>{t("button.writeReview")}</span>
						</button>
					)}
				</div>
			</div>

			<DeleteModal
				modalOpen={isConfirm}
				cancelSuperHandler={() => setIsConfirm(false)}
				deleteHandler={removeBidHandler}
				deleteTitle={t("button.remove")}
				closeTitle={t("button.close")}
				title={`${t("pages.caseDetail.removeBondsman")}`}
				svg={svg?.deleteDocumentLarge}
				description={t("pages.caseDetail.removeBondsmanDescription")}
				loader={decideBidLoading}
			/>
		</div>
	);
};

// Case Type Status
export const caseTypeStatus = {
	incomplete: {
		title: "pages.mycase.incomplete",
		color: "159, 38, 0",
	},
	pending: {
		title: "pages.mycase.pending",
		color: "137, 137, 137",
	},
	accepted: {
		title: "pages.mycase.accepted",
		color: "0, 85, 185",
	},
	unpaid: {
		title: "pages.mycase.unpaid",
		color: "238, 64, 127",
	},
	paid: {
		title: "pages.mycase.paid",
		color: "0, 98, 120",
	},
	"in-progress": {
		title: "pages.mycase.inprogress",
		color: "38, 160, 212",
	},
	completed: {
		title: "pages.mycase.completed",
		color: "0, 120, 34",
	},
	cancel: {
		title: "pages.mycase.cancelled",
		color: "255, 0, 0",
	},
	new: {
		title: "pages.mycase.new",
		color: "133,191,97",
	},
	available: {
		title: "Available",
		color: "0, 120, 34",
	},
	busy: {
		title: "Busy",
		color: "194,194,194",
	},
	on_vacation: {
		title: "On Vacation",
		color: "255, 0, 0",
	},
};

export const SuccessFailStatus = {
	success: {
		title: "Success",
		color: "0, 120, 34",
	},
	pending: {
		title: "Pending",
		color: "120, 120, 120",
	},
	fail: {
		title: "Failed",
		color: "255, 0, 0",
	},
};

// Case Type Status
export const caseTypeForClient = {
	high: { title: "High Priority", color: "0, 120, 34" },
	reqular: { title: "Reqular", color: "157, 103, 0" },
	new: { title: "New", color: "39, 39, 39" },
};

// Max Number Decimals
export function round(value, decimals) {
	return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

// Request For Invoice Card
export const RequestForServiceCard = ({ className, primaryTitle, actionTitle, data }) => {
	const { t } = useTranslation();
	const { paymentLoading } = useSelector((state) => state.loading);
	const dispatch = useDispatch();

	const handlePayment = () => {
		const paymentData = {
			invoice_id: data?._id,
			redirectURL: `${window.location.href}#success`,
		};
		dispatch(CreateStripeCustomer(paymentData));
	};

	const paid = data?.status === "paid";

	return (
		<div className={`${className} RequestForServiceCard`}>
			<div className="border-m p-3 c-white">
				<div className={`d-sm-flex align-items-center justify-content-between flex-wrap gap-2`}>
					<div className="">
						<p className="f18 fw-semibold">{data?.invoice_number}</p>
						<p className="f14 txt-grey">{momentDDMMYYAMPM(convertTimeZone(data?.created_at))}</p>
					</div>

					<div className="mt-1 mt-sm-0">
						<p className="d-flex align-items-center f16  fw-semibold justify-content-end" style={{ color: "rgb(0, 120, 34)" }}>
							{data?.status === "paid" && svg?.successTick}{" "}
							<span className={`${data?.status !== "paid" ? "txt-pink" : "ms-1"}`}>
								{data?.status !== "paid" ? `Unpaid ($${data?.total_amount})` : `Paid ($${data?.total_amount})`}
							</span>
						</p>
						{data?.status === "paid" && <p className="f14 txt-grey">{momentDDMMYYAMPM(convertTimeZone(data?.updated_at))}</p>}
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
					{primaryTitle && (
						<button className="btn c-primary text-uppercase fw-semibold fs-6 px-4 text-white h46" onClick={() => PrintInvoiceHandler({ data })}>
							<span className="d-flex align-items-center">
								{svg?.downloadFile}
								<span className="ms-2">{primaryTitle}</span>
							</span>
						</button>
					)}

					{actionTitle && data?.status !== "paid" && (
						<button className="btn c-error text-uppercase fw-semibold fs-6 px-4 text-white h46 changeSpinFlex" disabled={paymentLoading} onClick={handlePayment}>
							{paymentLoading && <BtnLoader />}
							{`${t("button.pay")} $${round(data?.total_amount, 2)}`}
						</button>
					)}
					{data?.status === "paid" && (
						<button className="btn c-error text-uppercase fw-semibold fs-6 px-4 text-white h46" onClick={() => PrintReciptHandler({ data })}>
							{svg?.downloadFile}
							{t("button.receipt")}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

// Request For Contract View
export const RequestForContract = ({ className, data, processingContract }) => {
	const { t } = useTranslation();
	const { contractLoading } = useSelector((state) => state.loading);
	const dispatch = useDispatch();

	// Status
	const status = caseTypeForContract[data?.status];

	const contractHandler = () => {
		if (status?.title === "Pending") {
			dispatch(SignContract(data?._id));
		} else {
			dispatch(ViewContract(data?._id));
		}
	};
	return (
		<div className={`${className} RequestForServiceCard RequestForContract`}>
			<div className="border-m p-3 c-white">
				<div className={`d-sm-flex align-items-center justify-content-between flex-wrap gap-2`}>
					<div className="">
						<p className="f18 fw-semibold">{data?.contract_number}</p>
						<p className="f14 txt-grey">{momentDDMMYYAMPM(convertTimeZone(data?.created_at))}</p>
					</div>

					<div className="mt-1 mt-sm-0">
						{processingContract ? (
							<>
								<p className="d-flex align-items-center f16 fw-semibold justify-content-end gap-1" style={{ color: `rgba(${status?.color})` }}>
									Loading....
								</p>
							</>
						) : (
							<>
								{" "}
								<p className="d-flex align-items-center f16 fw-semibold justify-content-end gap-1" style={{ color: `rgba(${status?.color})` }}>
									{data?.status === "signing_complete" ? <>{svg?.successTick} E-Signed</> : <>{status?.title}</>}{" "}
								</p>
								{data?.status === "signing_complete" && <p className="f14 txt-grey">{momentDDMMYYAMPM(convertTimeZone(data?.updated_at))}</p>}
							</>
						)}
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
					{processingContract ? (
						<>
							<button className="btn c-primary text-uppercase fw-semibold fs-6 px-4 text-white h46 changeSpinFlex" disabled={processingContract}>
								<BtnLoader />
							</button>
						</>
					) : (
						<>
							<button className="btn c-primary text-uppercase fw-semibold fs-6 px-4 text-white h46" onClick={contractHandler} disabled={contractLoading}>
								<span className="d-flex align-items-center changeSpinFlex">
									<>
										{contractLoading && <BtnLoader />}
										{status?.title === "Pending" && (
											<>
												{svg?.esignSymbol}
												<span className="ms-2">{"Review & Complete E-Sign"}</span>
											</>
										)}
										{data?.status === "signing_complete" && (
											<>
												{svg?.downloadFile}
												<span className="ms-2">{"Download E-Signed Contract"}</span>
											</>
										)}
									</>
								</span>
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

// Get Tag For Status
export const TagForStatus = ({ status }) => {
	// Translation
	const { t } = useTranslation();
	return (
		<div
			className="case-title p-1 d-inline-flex align-items-center px-3 position-absolute end-0 top-0 f16"
			style={{
				backgroundColor: `rgba(${status?.color},.2)`,
				color: `rgba(${status?.color},1)`,
			}}
		>
			<svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx={6} cy={6} r={6} fill={`rgba(${status?.color},1)`} />
			</svg>
			<span className="ms-1">{t(status?.title)}</span>
		</div>
	);
};

// Get Tag For Status
export const TagForStatus2 = ({ status }) => {
	// Translation
	const { t } = useTranslation();
	return (
		<div
			className="d-inline-flex align-items-center f13"
			style={{
				color: `rgba(${status?.color},1)`,
			}}
		>
			<svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx={6} cy={6} r={6} fill={`rgba(${status?.color},1)`} />
			</svg>
			<span className="ms-1">{t(status?.title)}</span>
		</div>
	);
};

export const NoBgTag = ({ status }) => {
	const { t } = useTranslation();
	return (
		<div
			className="case-title d-inline-flex align-items-center f16"
			style={{
				color: `rgba(${status?.color},1)`,
			}}
		>
			<svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx={6} cy={6} r={6} fill={`rgba(${status?.color},1)`} />
			</svg>
			<span className="ms-1">{t(status?.title)}</span>
		</div>
	);
};

// getDistanceInMiles
export const getDistanceInMiles = (lat1, lon1, lat2, lon2) => {
	const R = 3958.8; // Earth's radius in miles
	const dLat = toRadians(lat2 - lat1);
	const dLon = toRadians(lon2 - lon1);
	const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const distance = R * c; // Distance in miles

	return distance;
};

// Radius Calculation
const toRadians = (degrees) => {
	return degrees * (Math.PI / 180);
};

// Review Card Of Bondsman
export const ReviewCard = ({ data, className }) => {
	const image_url = data?.client_id?.photo_thumb
		? `${PROFILE_URL}/${data?.client_id?.photo_thumb}`
		: data?.client_id?.photo
		? `${PROFILE_URL}/${data?.client_id?.photo}`
		: Profile_Avatar;

	const preview_url = data?.client_id?.photo ? `${PROFILE_URL}/${data?.client_id?.photo}` : Profile_Avatar;

	return (
		<div className={`${className} ReviewCard`}>
			<div className="border-s b-shadow p-3 c-white">
				<div className="d-flex text-black">
					<div className="flex-shrink-0 text-center">
						<Image
							className="img-fluid rounded-circle"
							src={image_url}
							preview={<img src={preview_url} alt="Client Preview" />}
							alt={FullName(data?.client_id)}
							style={{ height: "46px", width: "46px" }}
						/>
					</div>
					<div className="flex-grow-1 ms-2">
						<h3 className="fw-bolder mb-1 f20">{FullName(data?.client_id)}</h3>
						<p>
							<span className="txt-primary fw-medium">#{data?.bond_request_id?.request_number}</span>
							<span className="txt-grey ms-2">{momentDDMMYY(convertTimeZone(data?.created_at))}</span>
						</p>
					</div>
				</div>

				<hr />

				<div>
					<Rate value={data?.review_rate} disabled allowHalf className="antdRate f24" style={{ color: "#FBC01B" }} />
				</div>

				<p className="fw-medium f16">{data?.message}</p>
			</div>
		</div>
	);
};

// My Case Card
export const BondCard = ({ className, data, backPage, url }) => {
	// Translation
	const { t } = useTranslation();

	// Navigate
	const navigate = useNavigate();

	// Selector
	const { user } = useSelector((state) => state.auth);
	let redirectUrl = () => {
		if (user?.role?.[0] === "admin") {
			return `/admin/bail-request/${data?._id}/bail-detail?${url ? url : `dashboard`}`;
		} else if (user?.role?.[0] === "sub-admin") {
			return `/sub-admin/bail-request/${data?._id}/bail-detail?${url ? url : `dashboard`}`;
		} else {
			return `/bond-man/all-bails/${data?._id}/request-detail`;
		}
	};

	const detailPagePath = redirectUrl();
	// user?.role?.[0] === "admin" || user?.role?.[0] === "sub-admin"
	// 	? `/admin/bail-request/${data?._id}/bail-detail?${url ? url : `dashboard`}`
	// 	: `/bond-man/all-bails/${data?._id}/request-detail`;

	const isNew = data?.is_new === 1 ? "new" : "";

	const status = caseTypeStatus[isNew];

	const path = backPage ? `${detailPagePath}?cases` : detailPagePath;

	return (
		<div className={`${className} CaseTypeCard`}>
			<div className="b-shadow c-white border-m position-relative cursor-pointer">
				<div className="p-3" onClick={() => navigate(path)}>
					{data?.is_new === 1 && <TagForStatus status={status} />}
					<div className="case-details">
						<p className="txt-grey f13 case-created-time">{dayjs(convertTimeZone(data?.created_at)).format(momentDateTimeFormatAMPM)}</p>
						<p className="txt-primary fw-bolder f16 caseId">#{data?.request_number}</p>
						<p className="ff_rg f16 caseNumber">
							{t("input.case_number")}: <span className="ff_md">{data?.case_number}</span>
						</p>
						<p className="ff_rg f16 courtDate">
							{t("pages.mycase.cDate")}: <span className="ff_md">{data?.court_date_time && dayjs(data?.court_date_time).format(momentDateTimeFormatAMPM)}</span>
						</p>
					</div>
					{data?.bond_request_status?.approved_bondsman !== null && (
						<>
							<hr className="my-2" />
							<div className="row allBailCard">
								<div className="d-flex align-items-center text-black col-8">
									<div className="flex-shrink-0 text-center align-items-center">
										<img
											style={{
												height: "41px",
												width: "41px",
												objectFit: "cover",
											}}
											src={
												data?.client_id?.photo_thumb
													? `${PROFILE_URL}/${data?.client_id?.photo_thumb}`
													: data?.client_id?.photo
													? `${PROFILE_URL}/${data?.client_id?.photo}`
													: Profile_Avatar
											}
											className="img-fluid allBailAvatar rounded-circle"
										/>
									</div>
									<div className="flex-grow-1 ms-2 mt-sm-0">
										<p className="fw-bolder mb-0 f14">
											{data?.client_id?.first_name} {data?.client_id?.last_name}
										</p>
										<div className="d-flex align-items-center">
											{svg?.location}
											<div className="f12 ms-1 text-capitalize">{FullAddressString(user)}</div>
										</div>
									</div>
								</div>
								<div className="col-4">
									<div className="totalAmount p-2 border-s text-center">
										<p className="f12">{t("input.bailAmount")}:</p>
										<span className="fw-bolder f14">${data?.bail_amount}</span>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

// Request Document List
export const RequestDocumentList = ({ isDowloaded, data, isSameUser, setDeleteDocData, setIsDeleteDocModal }) => {
	const documentIcon = isDowloaded && <div className="flex-shrink-0 text-center align-items-center">{svg?.fileDoc}</div>;

	const downloadFile = () => {
		const fileUrl = `${BOND_DOCS_URL}/${data?.file?.name}`;
		const link = document.createElement("a");
		link.href = fileUrl;
		link.download = data?.name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div className="RequestDocumentList">
			<div className="border-s c-white p-3">
				<div className="row">
					<div className="d-flex align-items-center text-black col-10 FileIcon">
						{documentIcon}
						<div className={`${isDowloaded ? "flex-grow-1 ms-2" : ""} mt-sm-0 text-start`}>
							<p className="fw-bolder mb-0 f16 txt-error text-capitalize">{data?.name}</p>
							<div className="d-flex align-items-center">{isDowloaded ? momentDDMMYYAMPM(convertTimeZone(data?.updated_at)) : "Waiting for document upload"}</div>
						</div>
					</div>
					<div className="col-2 d-flex align-items-center justify-content-end">
						{!data?.file && (
							<span
								className=" cursor-pointer"
								onClick={() => {
									setDeleteDocData({
										bond_request_id: data?.bond_request_id,
										id: data?._id,
										name: data?.name,
									});
									setIsDeleteDocModal(true);
								}}
							>
								{isSameUser && svg?.onRemoveDocument}
							</span>
						)}
						{isDowloaded && (
							<span
								className="ms-1 cursor-pointer"
								onClick={() => {
									downloadFile();
								}}
							>
								{/* ['jpg', 'jpeg', 'png', 'pdf'] */}
								{svg?.downloadDocFile}
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

// Case Type For Invoice (Paid, Unpaid, In-Progress)
export const caseTypeForInvoice = {
	paid: { title: "paid", color: "0, 98, 120" },
	unpaid: { title: "unpaid", color: "238, 64, 127" },
};

export const caseTypeForContract = {
	pending: { title: "Pending", color: "137, 137, 137" },
	signing_complete: { title: "Completed", color: "0, 120, 34" },
	reject: { title: "Reject", color: "255, 0, 0" },
};

// Request Invoice List
export const RequestInvoiceList = ({ data, bailId }) => {
	const { invoiceLoading } = useSelector((state) => state.loading);

	const documentIcon = <div className="flex-shrink-0 text-center align-items-center">{svg?.fileDoc}</div>;

	// Ref
	const dropDownRef = useRef();

	// Dispatch
	const dispatch = useDispatch();

	// State
	const [toggleDropdown, setToggleDropdown] = useState(false);
	const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);

	// Use
	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	// Handle Clickoutside toggleDropdown close
	const handleClickOutside = (event) => {
		const containerElement = dropDownRef.current;

		if (containerElement && !containerElement.contains(event.target)) {
			setToggleDropdown(false);
		}
	};

	// Status
	const status = caseTypeForInvoice[data?.status];

	const deleteInvoice = () => {
		dispatch(DeleteInvoice(data?._id, bailId, setDeleteInvoiceModal));
		setToggleDropdown(false);
	};

	return (
		<div className="RequestDocumentList bondsmanInvoiceList">
			<div className="border-s c-white p-3 position-relative">
				<TagForClient status={status} className={"text-capitalize"} amount={`($${data?.total_amount})`} />
				<div className="row">
					<div className="d-flex align-items-center text-black col-9">
						<div className="flex-shrink-0 text-center align-items-center FileIcon">{documentIcon}</div>
						<div className="flex-grow-1 ms-2 mt-sm-0 text-start">
							<p className="fw-bolder mb-0 f16 txt-error text-capitalize">{data?.invoice_number}</p>
							<div className="d-flex align-items-center f14">{momentDDMMYYAMPM(convertTimeZone(data?.updated_at))}</div>
						</div>
					</div>
					<div className="col-3 d-flex align-items-end justify-content-end invoiceAction gap-2 position-relative">
						<span className="cursor-pointer" onClick={() => PrintInvoiceHandler({ data })}>
							{svg?.downloadDocFile}
						</span>

						{data?.status === "unpaid" && (
							<div className="dropdownForDownloadInvoice" ref={dropDownRef}>
								<span className="cursor-pointer" onClick={() => setToggleDropdown(!toggleDropdown)}>
									{svg?.toggleInvoice}
								</span>
								<ul className={`profileDropDown ${toggleDropdown ? "d-grid" : "d-none"} position-absolute b-shadow c-white p-3 rounded-8`}>
									<NavigationLink
										title={"Edit Invoice"}
										className={"px-2 d-flex position-relative text-decoration-none justify-content-end"}
										path={`/bond-man/all-bails/${bailId}/invoice/${data?._id}`}
									/>

									<NavigationLink
										title={"Delete Invoice"}
										className={"px-2 d-flex position-relative text-decoration-none txt-warning justify-content-end"}
										onClick={() => setDeleteInvoiceModal(true)}
									/>
								</ul>
							</div>
						)}
					</div>
				</div>
			</div>

			{deleteInvoiceModal && (
				<DeleteModal
					modalOpen={deleteInvoiceModal}
					cancelSuperHandler={() => {
						setDeleteInvoiceModal(false);
					}}
					title={"Delete Invoice?"}
					description={`After deleting the invoice, client not able to view.`}
					deleteTitle="Delete"
					deleteHandler={deleteInvoice}
					closeTitle={"Close"}
					svg={svg?.deleteDocumentLarge}
					loader={invoiceLoading}
				/>
			)}
		</div>
	);
};

// Request Invoice List
export const RequestContract = ({ data, bailId }) => {
	const documentIcon = <div className="flex-shrink-0 text-center align-items-center">{svg?.downloadESign}</div>;

	// Ref
	const dropDownRef = useRef();

	// Dispatch
	const dispatch = useDispatch();

	// State
	const [toggleDropdown, setToggleDropdown] = useState(false);

	//
	const [deleteContractModal, setDeleteContractModal] = useState(false);

	// Use
	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	// Handle Clickoutside toggleDropdown close
	const handleClickOutside = (event) => {
		const containerElement = dropDownRef.current;

		if (containerElement && !containerElement.contains(event.target)) {
			setToggleDropdown(false);
		}
	};

	// Status
	const status = caseTypeForContract[data?.status];

	const deleteContract = () => {
		dispatch(DeleteContract(data?._id, bailId, setDeleteContractModal));
	};

	const contractDownload = () => {
		dispatch(ViewContract(data?._id));
	};

	const { contractLoading } = useSelector((state) => state.loading);

	return (
		<div className="RequestDocumentList bondsmanInvoiceList">
			<div className="border-s c-white p-3 position-relative">
				<TagForClient status={status} className={"text-capitalize"} />
				<div className="row">
					<div className="d-flex align-items-center text-black col-9">
						<div className="flex-shrink-0 text-center align-items-center FileIcon">{documentIcon}</div>
						<div className="flex-grow-1 ms-2 mt-sm-0 text-start">
							<p className="fw-bolder mb-0 f16 txt-error text-capitalize">{data?.contract_number}</p>
							<div className="d-flex align-items-center f14">{momentDDMMYYAMPM(convertTimeZone(data?.updated_at))}</div>
						</div>
					</div>
					<div className="col-3 d-flex align-items-end justify-content-end invoiceAction gap-2 position-relative">
						{!contractLoading && (
							<span className="cursor-pointer" onClick={contractDownload}>
								{svg?.downloadDocFile}
							</span>
						)}

						{contractLoading && (
							<div
								className="donwloadContract d-flex changeSpinFlex align-items-center rounded-2 justify-content-center"
								style={{
									backgroundColor: `rgb(${status?.color})`,
								}}
							>
								{" "}
								<BtnLoader className={"me-0"} />
							</div>
						)}

						{!contractLoading && data?.status === "pending" && (
							<div className="dropdownForDownloadInvoice" ref={dropDownRef}>
								<span className="cursor-pointer" onClick={() => setToggleDropdown(!toggleDropdown)}>
									{svg?.toggleInvoice}
								</span>
								<ul className={`profileDropDown ${toggleDropdown ? "d-grid" : "d-none"} position-absolute b-shadow c-white p-3 rounded-8`}>
									<NavigationLink
										title={"Delete Contract"}
										className={"px-2 d-flex position-relative text-decoration-none txt-warning justify-content-end"}
										onClick={() => {
											setDeleteContractModal(true);
											setToggleDropdown(false);
										}}
									/>
								</ul>
							</div>
						)}
					</div>
				</div>
			</div>

			{deleteContractModal && (
				<DeleteModal
					modalOpen={deleteContractModal}
					cancelSuperHandler={() => {
						setDeleteContractModal(false);
					}}
					title={"Delete Contract?"}
					description={`After deleting the contract, client not able to view and e-sign it.`}
					deleteTitle="Delete"
					deleteHandler={deleteContract}
					closeTitle={"Close"}
					svg={svg?.deleteDocumentLarge}
					loader={contractLoading}
				/>
			)}
		</div>
	);
};

export default RequestDocumentList;

// Admin - Bondsman List
export const BondsmanListCard = ({ className, data, listType }) => {
	// Translation
	const { t } = useTranslation();

	// Navigate
	const navigate = useNavigate();

	// Dispatch
	const dispatch = useDispatch();

	const { role } = useSelector((state) => state.auth);
	const { user } = useSelector((state) => state.auth);
	const status = caseTypeStatus[data?.status];

	const rateStatus = data?.average_review !== undefined;

	const navigateHandler = () => {
		if (data?.status === "new") {
			dispatch(EditBondsmanStatusRedirect(data?._id, "pending", navigate, user?.role?.[0]));
		} else {
			navigate(user?.role?.[0] === "admin" ? `/admin/bondsman-list/${data?._id}/bondsman-detail` : `/sub-admin/bondsman-list/${data?._id}/bondsman-detail`);
		}
	};

	const address = `${data?.address?.zip || ""}${data?.address?.city ? ` - ${data.address.city}` : ""}${data?.address?.state ? `, ${data.address.state}` : ""}`;

	// Ref
	const dropDownRef = useRef();

	// State
	const [toggleDropdown, setToggleDropdown] = useState(false);
	const [platFormFeeModal, setPlatFormFeeModal] = useState(false);

	// Use
	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	// Handle Clickoutside toggleDropdown close
	const handleClickOutside = (event) => {
		const containerElement = dropDownRef.current;

		if (containerElement && !containerElement.contains(event.target)) {
			setToggleDropdown(false);
		}
	};

	const linkData = [
		{
			label: "Platform Fees",
			svg: svg?.platFormFees,
		},
	];

	const [platform_fee, setPlatform_fee] = useState(data?.platform_fee ? data?.platform_fee : 5);

	return (
		<div className={`${className} BondsmanListCard CaseTypeCard`}>
			<div className="b-shadow c-white border-m position-relative h-100">
				{listType === "approved" && (
					<div className="dropdownElement position-absolute" ref={dropDownRef}>
						<button
							className="btn btn-light"
							onClick={() => {
								setToggleDropdown(!toggleDropdown);
							}}
						>
							{svg?.toggleDocument}
						</button>

						<ul className={` profileDropDown ${toggleDropdown ? "d-grid" : "d-none"} position-absolute b-shadow c-white p-3 rounded-8`}>
							{linkData?.map((link, index) => (
								<NavigationLink
									key={index}
									title={link.label}
									svg={link.svg}
									className={"px-2 d-flex position-relative text-decoration-none f16"}
									onClick={() => {
										setToggleDropdown(false);
										setPlatFormFeeModal(true);
									}}
								/>
							))}
						</ul>
					</div>
				)}

				<div className="p-3 h-100 cursor-pointer" onClick={navigateHandler}>
					{data?.status === "new" && <TagForStatus status={status} />}

					<div className="row allBailCard mt-2">
						<div className="d-flex align-items-start text-black col-12 col-md-9">
							<div className="flex-shrink-0 text-center align-items-center">
								<img
									style={{
										height: "45px",
										width: "45px",
										objectFit: "cover",
									}}
									src={data?.photo_thumb ? `${PROFILE_URL}/${data?.photo_thumb}` : data?.photo ? `${PROFILE_URL}/${data?.photo}` : Profile_Avatar}
									className="img-fluid allBailAvatar rounded-circle b-shadow"
								/>
							</div>
							<div className="flex-grow-1 ms-2 mt-sm-0 text-truncate">
								<p className="fw-bolder mb-0 f16 line-height-normal text-truncate">{`${data?.first_name} ${data?.last_name}`}</p>
								<div className="d-flex align-items-start locationSvg">
									{svg?.location}
									<p className="f14 ms-1 text-truncate">{address}</p>
								</div>

								{(role === "admin" || role === "sub-admin") && (
									<>
										<div className="d-flex align-items-start locationSvg PlatFormFees">
											{svg?.platFormFees}
											<p className="f14 ms-1 text-truncate">Platform Fees : {platform_fee}%</p>
										</div>

										<p className="f12 text-truncate">Approved By :</p>

										<p className="f12 text-truncate mb-0">Created By :</p>
									</>
								)}
							</div>
						</div>
						{rateStatus && (
							<div className="align-items-center d-flex justify-content-end col-12 col-md-3">
								<div className="d-flex align-items-center justify-content-end fw-bolder gap-2 star f13">
									{svg?.star} <span>{data?.average_review}</span>
								</div>
							</div>
						)}
					</div>

					<hr />

					<div className="row fw-medium gy-2">
						<div className="col-12 col-sm-12 col-md-6">
							<p className="f10 txt-grey">{t("label.joinedDate")}:</p>
							<p className="f13">{momentDDMMYYAMPM(convertTimeZone(data?.created_at))}</p>
						</div>
						<div className="col-12 col-sm-12 col-md-6">
							<p className="f10 txt-grey">{t("label.updatedDate")}:</p>
							<p className="f13">{momentDDMMYYAMPM(convertTimeZone(data?.updated_at))}</p>
						</div>
					</div>
				</div>
			</div>
			<AdminBondsmanPlatFormFees
				modalOpen={platFormFeeModal}
				cancelHandler={() => setPlatFormFeeModal(false)}
				updateData={{
					id: data?._id,
					platform_fee: data?.platform_fee ? data?.platform_fee : 5,
				}}
				setState={setPlatform_fee}
			/>
		</div>
	);
};

// Working Day
export const WorkingDaysBox = ({ className, data }) => {
	return (
		<div className={`${className} WorkingDaysBox`}>
			<div className="border-s p-3 c-light">
				<div className="f18 fw-bolder">{data?.day}</div>
				{data?.hours?.map((ele, index) => (
					<div className="fw-medium f14" key={index}>{`${ele?.open_hour} : ${ele?.close_hour}`}</div>
				))}
			</div>
		</div>
	);
};

// Dashboard List
export const DashboardField = ({ className, path, svg, label, value }) => {
	return (
		<>
			<div className={`${className} dashboardField`}>
				<NavLink className="border-m p-3 b-shadow h-100 d-block text-decoration-none txt-dark c-white" to={path}>
					<div className="d-flex align-items-center">
						<span>{svg}</span>
						<span className="ms-2 ms-md-3 DashboardFieldLabel">
							<span className="text-dark fw-medium">{label}</span>
							<h4 className="mb-0 mt-1 f32 fw-bolder">{value}</h4>
						</span>
					</div>
				</NavLink>
			</div>
		</>
	);
};

// Comman Components
export const BailDetail = ({ isRequest, data, isAddDocModal, setIsAddDocModal, setDeleteDocData, setIsDeleteDocModal, bailId }) => {
	// Translation
	const { t } = useTranslation();

	// Use Dispatch
	const dispatch = useDispatch();

	// Use Selectors
	const { listBondDocument } = useSelector((state) => state.listBondDoc);
	const { user, role } = useSelector((state) => state.auth);
	const { bondsmanInvoiceList } = useSelector((state) => state.bondmanList);
	const { contractList } = useSelector((state) => state.contract);

	// State
	const [bailAmountModal, setBailAmountModal] = useState(false);

	// Use Effects
	useEffect(() => {
		if (data?._id) {
			dispatch(FetchListDocument(data?._id));
		}
	}, [data?._id]);

	const isSecure = false;

	const rowClass = "row gy-md-4 gy-3";
	const divClass = "col-lg-4 col-md-6 col-12";

	useEffect(() => {
		dispatch(GetInvoice(bailId));
	}, [dispatch]);

	useEffect(() => {
		dispatch(GetContractList(bailId));
	}, [dispatch]);

	const bailAmountEdit = role === "bail_bondsman" && (data?.bail_amount === 0 || data?.bail_amount === null || data?.bail_amount === undefined);

	const isTermsApproved = role === "bail_bondsman" ? (data?.information_hide ? data?.information_hide && !data?.bond_request_status?.approved_bondsman : false) : false;

	return (
		<div className="card reviewCard shadow border-0 rounded-10">
			<div className="card-body p-40">
				<div className="row">
					<div className="col-12 col-md-7 col-xl-8">
						<div className="mb-30">
							<PageTitleWithEdit
								title={
									<>
										{t("pages.caseDetail.cTitle")}
										{isTermsApproved && <PrivacyTooltip2 />}
									</>
								}
							/>
							<div className={rowClass}>
								{!isTermsApproved && (
									<ReviewRequestCard
										className={divClass}
										title={
											<>
												{t("input.defendant")} {isTermsApproved && <PrivacyTooltip2 />}
											</>
										}
										value={data?.defendant}
										isSecure={isSecure}
									/>
								)}

								<ReviewRequestCard className={divClass} title={t("input.dob")} value={dayjs(data?.dob).format(dayjsOnlyLongDateFormat)} />

								{data && data.contact_info && data.contact_info.contact_number && (
									<ReviewRequestCard
										className={divClass}
										title={t("input.contact")}
										value={`${data?.contact_info?.country_code} ${formatPhoneNumber(data?.contact_info?.contact_number)}`}
										isSecure={isSecure}
									/>
								)}

								{data?.contact_info?.email && (
									<ReviewRequestCard className={divClass} title={t("input.emailA")} value={data?.contact_info?.email} isSecure={isSecure} />
								)}

								<div className="col-12">
									<hr className="m-0" />
								</div>
							</div>
						</div>

						<div className="mb-30">
							<PageTitleWithEdit title={t("pages.caseDetail.arrestedInformation")} />
							<div className={rowClass}>
								<ReviewRequestCard className={"col-12 col-md-6"} title={t("input.police_department")} value={data?.police_department} />

								{data?.booking_number && (
									<ReviewRequestCard className={"col-12 col-md-6"} title={t("pages.reviewRequest.bookingNumber")} value={data?.booking_number} />
								)}

								<ReviewRequestCard
									className={"col-12 col-md-6"}
									title={t("pages.caseDetail.arrestedLocation")}
									value={data?.address?.address_line_1 + " " + data?.address?.address_line_2}
								/>
								<div className="col-12">
									<hr className="m-0" />
								</div>
							</div>
						</div>

						<div className="mb-30">
							<PageTitleWithEdit title={t("pages.caseDetail.courtInformation")} />

							<div className={rowClass}>
								<ReviewRequestCard className={"col-12"} title={t("input.court_name")} value={data?.court_name} />

								<ReviewRequestCard className={divClass} title={t("input.case_number")} value={data?.case_number} />

								<ReviewRequestCard
									className={divClass}
									title={
										<>
											{t("input.bailAmount")}{" "}
											{bailAmountEdit && (
												<div
													className="d-inline-flex align-items-center text-decoration-none text-orange ms-2 mt-md-1 edit__btn txt-error cursor-pointer bailAmountIcon"
													onClick={() => setBailAmountModal(true)}
												>
													{svg?.edit} <span className="f14">{t("Edit")}</span>
												</div>
											)}
										</>
									}
									value={`$${data?.bail_amount ? data?.bail_amount : 0}`}
								/>

								{data?.bail_terms && <ReviewRequestCard className={divClass} title={t("modal.bailTerms.terms")} value={data?.bail_terms ? "Yes" : "No"} />}

								<ReviewRequestCard className={"col-12 col-md-6"} title={t("modal.bailTerms.conditions")} value={data?.restrictions} />

								{data?.attorney_detail && (
									<ReviewRequestCard className={"col-12 col-md-6"} title={t("pages.reviewRequest.attorney_detail")} value={data?.attorney_detail} />
								)}

								<ReviewRequestCard
									className={"col-12 col-md-6"}
									title={t("input.court_date_time")}
									value={dayjs(data?.court_date_time).format(momentDateTimeFormatAMPM)}
								/>
								<ReviewRequestCard className={"col-12 col-md-6"} title={t("pages.reviewRequest.request_nature")} value={data?.request_nature} />

								<ReviewRequestCard className={"col-12"} title={t("input.request_reason")} value={data?.request_reason} />

								<ReviewRequestCard className={"col-12"} title={t("input.additional_comments")} value={data?.request_nature} />
								<div className="col-12">
									<hr className="m-0" />
								</div>
							</div>
						</div>

						{}

						<div className="mb-30">
							<PageTitleWithEdit
								title={
									<>
										{" "}
										{t("modal.emergency")} {isTermsApproved && <PrivacyTooltip2 />}
									</>
								}
							/>
							<div className={rowClass}>
								{data?.emergency_contact_info?.name && (
									<ReviewRequestCard className={divClass} title={t("input.ename")} value={data?.emergency_contact_info?.name} isSecure={isSecure} />
								)}

								{data?.emergency_contact_info?.relation && (
									<ReviewRequestCard
										className={divClass}
										title={t("pages.reviewRequest.relationDefendant")}
										value={data?.emergency_contact_info?.relation}
										isSecure={isSecure}
									/>
								)}

								{data?.emergency_contact_info?.contact_number && (
									<ReviewRequestCard
										className={divClass}
										title={t("input.contact")}
										value={
											<>
												{data?.emergency_contact_info?.country_code} {data?.emergency_contact_info?.contact_number}
											</>
										}
										isSecure={isSecure}
									/>
								)}

								{data?.emergency_contact_info?.additional_note && (
									<ReviewRequestCard className={divClass} title={t("input.psnotes")} value={data?.emergency_contact_info?.additional_note} isSecure={isSecure} />
								)}

								{!data?.emergency_contact_info && (
									<div className="col-12 text-orange fw-semibold">This information will be displayed when client accepts your bid.</div>
								)}

								<div className="col-12 psnotes">
									<hr className="m-0" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-5 col-xl-4">
						<div className="c-light border-m p-3">
							<div className="text-center">
								<div className="bail-detail-user-avatar mt-3">
									<Image
										style={{
											height: "150px",
											width: "150px",
											objectFit: "cover",
										}}
										toolbarRender={() => {}}
										preview={<img src={`${PROFILE_URL}/${data?.client_id?.photo}`} alt="Profile" />}
										src={
											data?.client_id?.photo_thumb
												? `${PROFILE_URL}/${data?.client_id?.photo_thumb}`
												: data?.client_id?.photo
												? `${PROFILE_URL}/${data?.client_id?.photo}`
												: Profile_Avatar
										}
										alt="Profile"
										className="img-fluid rounded-circle"
									/>

									<p className="fw-bolder f20 mt-2">{data?.client_id?.first_name + " " + data?.client_id?.last_name}</p>

									<div className="d-flex align-items-center justify-content-center">
										{svg?.location}
										<p className="f14 ms-1">{data?.client_id?.address?.city}</p>
									</div>

									{contractList && (
										<>
											<p className="mt-4"></p>
											<hr className="blue-hr" />

											<p className="fw-bolder f20 mt-2 mb-3">E-Sign Contract</p>

											<RequestContract data={contractList} bailId={bailId} />
										</>
									)}

									{bondsmanInvoiceList && bondsmanInvoiceList?.length !== 0 && (
										<>
											<hr className="blue-hr" />
											<p className="fw-bolder f20 mt-2">Payment Invoice</p>
											<div className="row gy-3 mt-0">
												{bondsmanInvoiceList && bondsmanInvoiceList?.map((ele, index) => <RequestInvoiceList key={index} data={ele} bailId={bailId} />)}
											</div>
										</>
									)}

									<hr className="blue-hr" />

									<p className="fw-bolder f20 mt-2">Case Documents</p>

									<div className="row gy-3 mt-0">
										{listBondDocument &&
											listBondDocument?.map((ele, index) => (
												<RequestDocumentList
													setDeleteDocData={setDeleteDocData}
													setIsDeleteDocModal={setIsDeleteDocModal}
													isDowloaded={ele?.file}
													key={index}
													data={ele}
													isSameUser={ele?.bondsman_id === user?._id}
												/>
											))}
									</div>

									{isRequest && !(user?.role?.[0] === "admin" || user?.role?.[0] === "sub-admin") && (
										<button
											className="btn c-error text-uppercase fw-semibold fs-6 px-4 mt-3 text-white h46"
											onClick={(e) => {
												e.preventDefault();
												setIsAddDocModal(true);
											}}
										>
											Request Documents
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<BondsmanBailAmountEditModal modalOpen={bailAmountModal} cancelHandler={() => setBailAmountModal(false)} data={data} bailId={bailId} />
		</div>
	);
};

// Get Selected Attribute
function getSelectedAttribute(event, attribute) {
	event.target.getAttribute(attribute);
	return event.target.getAttribute(attribute);
}

// Bondsman Service Toggle Event
export const BondsmanServiceToggle = ({
	data,
	page,
	limit,
	setCurrentId,
	setServiceName,
	setServicePercantage,
	setServiceAmount,
	setEdit,
	setAdd,
	setServicePercantageFlag,
	setServiceAmountFlag,
	className,
	scroll,
}) => {
	// State
	const [dropdown, setDropdown] = useState(false);

	// dispatch
	const dispatch = useDispatch();

	const id = data?._id;

	const deleteServiceHandler = () => {
		dispatch(DeleteBondmanService(id, page, limit));
		setDropdown(false);
	};
	return (
		<div>
			<div className={`toogleDot cursor-pointer ${className}`}>
				<div onClick={() => setDropdown(!dropdown)}>{svg?.toggleDot}</div>

				<ul className={`profileDropDown ${dropdown ? "d-block" : "d-none"} position-absolute b-shadow c-white p-3 rounded-8`}>
					<NavigationLink
						title={"button.edit"}
						onClick={(e) => {
							e.preventDefault();
							setDropdown(false);
							setCurrentId(data?._id);
							setServiceName(data?.service);
							setServicePercantage(data?.percentage);
							setServiceAmount(data?.amount);
							setEdit(true);
							setAdd(false);
							setServicePercantageFlag(false);
							setServiceAmountFlag(false);
							scroll && scroll();
						}}
					/>
					<NavigationLink title={"button.delete"} onClick={deleteServiceHandler} className={"txt-warning"} />
				</ul>
			</div>
		</div>
	);
};

// Bondsman Service List
export const BondmanServiceList = ({ className, serviceList, setServiceList, name, bailAmount, setIsDropdown, setCurrentService, modalPurpose }) => {
	// Translation
	const { t } = useTranslation();

	// Dispatch
	const dispatch = useDispatch();

	// Selector
	const { bondsmanService } = useSelector((state) => state.bondmanList);
	const { bondsmanLoading } = useSelector((state) => state.loading);

	// Bondman Service Param State
	const [pageId, setPageId] = useState(1);
	const [itemLimit, setItemLimit] = useState(12);

	useEffect(() => {
		dispatch(FetchBondmanService(pageId, itemLimit));
	}, [pageId, itemLimit, dispatch]);

	// State For Select Service
	const [serviceId, setServiceId] = useState();

	// Select Handler
	const selectHandler = (e) => {
		const _id = getSelectedAttribute(e, "data-currentId");
		const service = getSelectedAttribute(e, "data-title");
		const amount = Number(getSelectedAttribute(e, "data-amount"));
		const percentage = Number(getSelectedAttribute(e, "data-percentage"));
		setServiceId(_id);

		const newSelect = {
			_id: _id,
			service,
			amount,
			percentage,
		};

		const isIdDuplicate = serviceList?.some((bid) => bid?._id === _id || bid?.service?.toLowerCase() === service?.toLowerCase());

		if (!isIdDuplicate) {
			setServiceList((prevTableData) => {
				let updatedData;
				if (modalPurpose === 1) {
					updatedData = prevTableData?.filter((item) => item?.service !== "Service Tax") ?? [];
					const serviceTaxLast = prevTableData?.find((item) => item?.service === "Service Tax");
					updatedData.push(newSelect, serviceTaxLast);
				} else {
					updatedData = [...prevTableData, newSelect];
				}
				return updatedData;
			});

			setIsDropdown(false);
			setCurrentService(newSelect);
		} else {
			message.error("A duplicate service has been identified, you cannot add a bid with the same service name.");
		}
	};

	// Add & Edit Service
	const [isAdd, setIsAdd] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [serviceName, setServiceName] = useState("");
	const [servicePercantage, setServicePercantage] = useState(0);
	const [servicePercantageFlag, setServicePercantageFlag] = useState(false);
	const [serviceAmount, setServiceAmount] = useState(0);
	const [serviceAmountFlag, setServiceAmountFlag] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const clearHandler = () => {
		setError(false);
		setServiceName("");
		setServicePercantage(0);
		setServiceAmount(0);
		setIsAdd(false);
		setIsEdit(false);
		setServicePercantageFlag(false);
		setServiceAmountFlag(false);
		setIsDropdown(false);
	};

	const serviceAddHandler = () => {
		const data = {
			service: serviceName,
			amount: serviceAmount,
			percentage: servicePercantage,
		};

		const editdata = {
			id: serviceId,
			service: serviceName,
			amount: serviceAmount,
			percentage: servicePercantage,
		};
		if (serviceName === "") {
			setError(true);
			setErrorMessage("Please enter service name");
		} else {
			if (serviceAmount !== "" || servicePercantage !== "") {
				setError(true);
				if (serviceAmount === 0 || servicePercantage === 0) {
					setErrorMessage("Both service amount and percentage are 0,Please provide valid values.");
				} else {
					setErrorMessage("Please enter service amount or percantage");
				}
			}
		}

		if ((!isEdit && serviceName?.toLocaleLowerCase() === "bail processing fee") || (!isEdit && serviceName?.toLocaleLowerCase() === "service tax")) {
			setError(true);
			setErrorMessage("This service name already in use. Please add with another service name.");
		} else {
			if (serviceName && (serviceAmount > 0 || servicePercantage > 0)) {
				setError(false);

				const action = isEdit ? EditBondmanService : AddBondmanService;

				dispatch(action(isEdit ? editdata : data, pageId, itemLimit, clearHandler, setServiceList, isEdit ? serviceList : modalPurpose));
			}
		}
	};

	// Handler For Toggle Out Side Click
	const toggleRef = useRef();
	const isEditRef = useRef(null);

	// Use
	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	// Handler
	const handleClickOutside = (event) => {
		const containerElement = toggleRef.current;
		if (containerElement && !containerElement.contains(event.target)) {
		}
	};

	const scrollToDiv = () => {
		if (isEditRef.current) {
			// Scroll to the top position of the div
			isEditRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className={`${className} ServiceList w-100`}>
			<div className="border-s p-3 pt-2 b-light-grey border mt-2">
				{bondsmanService?.map((ele, index) => {
					const percentage = ele?.percentage ?? ele?.amount;
					const newPercentage = (percentage / bailAmount) * 100;

					const amount = ele?.amount ?? ele?.percentage;
					const convertAmount = (bailAmount * amount) / 100;

					const calcAmount = ele?.amount ?? convertAmount;
					const calcPercentage = ele?.percentage ?? newPercentage;

					const isMatched = serviceList?.some((item) => item?._id === ele?._id || item?.service?.toLowerCase() === ele?.service?.toLowerCase());

					const IsPercentage = ele?.percentage;
					const IsPercentageAmount = (bailAmount * ele?.percentage) / 100;

					const disAmount = IsPercentage ? IsPercentageAmount : calcAmount;

					const roundOfAmount = round(disAmount, 2);
					const roundOfPercentage = round(calcPercentage, 2);
					return (
						<div className="d-flex align-items-center gap-2">
							<div className={`selectedBid d-flex align-items-center justify-content-center ${isMatched ? `opacity-100` : `opacity-0`}`}>{svg?.selectedBid}</div>
							<label
								htmlFor={ele?._id + name}
								className={`BondRelation w-100 position-relative ${serviceId == ele?._id ? "txt-white isTabActive" : "isTabInActive"} ${
									ele?.service === "bail processing fee" ? "disable-bail-fee" : ""
								}`}
								key={index}
								ref={toggleRef}
							>
								<input
									type={"radio"}
									className="form-check-input"
									id={ele?._id + name}
									value={ele?._id}
									name={"bondsman-service"}
									checked={serviceId === ele?._id}
								/>
								<div className="d-flex gap-2">
									<div
										className="d-flex w-100 text-capitalize pointerEvent align-items-center"
										data-currentId={ele?._id}
										data-percentage={roundOfPercentage}
										data-amount={roundOfAmount}
										data-title={ele?.service}
										onClick={selectHandler}
									>
										<p className="mb-0 fs-6 text-black fw-semibold col-6">{ele?.service}</p>
										<p className="mb-0 fs-6 text-black fw-semibold col-3">{`${round(calcPercentage, 2)}%`}</p>
										<p className="mb-0 fs-6 text-black fw-semibold col-3">{`$${round(disAmount, 2)}`}</p>
									</div>
									<BondsmanServiceToggle
										data={{
											_id: ele._id,
											amount: disAmount,
											percentage: calcPercentage,
											service: ele?.service,
										}}
										limit={itemLimit}
										page={pageId}
										setCurrentId={setServiceId}
										setServiceName={setServiceName}
										setServicePercantage={setServicePercantage}
										setServiceAmount={setServiceAmount}
										setEdit={setIsEdit}
										setAdd={setIsAdd}
										setServicePercantageFlag={setServicePercantageFlag}
										setServiceAmountFlag={setServiceAmountFlag}
										scroll={scrollToDiv}
									/>
								</div>
							</label>
						</div>
					);
				})}

				<div className="" ref={isEditRef}>
					{isAdd && (
						<>
							<div className="AddService mt-2">
								<InputBox
									className={"servicePercentage w-100"}
									errorClassName={"d-none"}
									placeholder={t("table.selectService")}
									value={serviceName}
									setValue={setServiceName}
									type={"text"}
									setChangeError={setError}
								/>
								<div className="servicePercentage position-relative maxWidth ff_rg page-form">
									<div>
										<input
											type="number"
											className="form-control undefined form-control-lg"
											placeholder={t("table.percentage")}
											value={servicePercantage}
											disabled={servicePercantageFlag}
											onChange={(e) => {
												const perCalc = (bailAmount * e.target.value) / 100;

												setServicePercantage(round(e.target.value, 2));
												setServiceAmount(round(perCalc, 2));
												if (e.target.value) {
													setServiceAmountFlag(true);
												} else {
													setServiceAmountFlag(false);
												}
											}}
										/>
										<div className="position-absolute c-error txt-white percentage py-1 px-2 d-flex align-items-center justify-content-center cursor-pointer">
											<span>%</span>
										</div>
									</div>
								</div>

								<div className="servicePercentage position-relative maxWidth ff_rg page-form selectPercentageAmount">
									<div>
										<div className="position-absolute c-error txt-white percentage py-1 px-2 d-flex align-items-center justify-content-center cursor-pointer">
											<span>$</span>
										</div>
										<input
											type="number"
											className="form-control undefined form-control-lg"
											placeholder={t("table.amount")}
											value={serviceAmount}
											disabled={serviceAmountFlag}
											onChange={(e) => {
												const percentage = (e.target.value / bailAmount) * 100;
												setServiceAmount(round(e.target.value, 2));
												setServicePercantage(round(percentage, 2));
												if (e.target.value) {
													setServicePercantageFlag(true);
												} else {
													setServicePercantageFlag(false);
												}
											}}
										/>
									</div>
								</div>

								<button
									className="p-0  border-0 d-flex align-items-center justify-content-center AddServiceButton"
									onClick={serviceAddHandler}
									disabled={bondsmanLoading}
								>
									{bondsmanLoading && <BtnLoader />}
									{!bondsmanLoading && svg?.addService}
								</button>
							</div>
							<div className="txt-error f12">
								{error === true && errorMessage}
								&nbsp;
							</div>
						</>
					)}

					{isEdit && (
						<>
							<div className="AddService mt-2">
								<InputBox
									className={"servicePercentage w-100"}
									errorClassName={"d-none"}
									placeholder={t("table.selectService")}
									value={serviceName}
									setValue={setServiceName}
									type={"text"}
									setChangeError={setError}
								/>
								<div className="servicePercentage position-relative maxWidth ff_rg page-form">
									<div>
										<input
											type="number"
											className="form-control undefined form-control-lg"
											placeholder={t("table.percentage")}
											value={servicePercantage}
											disabled={servicePercantageFlag}
											onChange={(e) => {
												const perCalc = (bailAmount * e.target.value) / 100;

												setServicePercantage(round(e.target.value, 2));
												setServiceAmount(round(perCalc, 2));
												if (e.target.value) {
													setServiceAmountFlag(true);
												} else {
													setServiceAmountFlag(false);
												}
											}}
										/>
										<div className="position-absolute c-error txt-white percentage py-1 px-2 d-flex align-items-center justify-content-center cursor-pointer">
											<span>%</span>
										</div>
									</div>
								</div>

								<div className="servicePercentage position-relative maxWidth ff_rg page-form selectPercentageAmount">
									<div>
										<input
											type="number"
											className="form-control undefined form-control-lg"
											placeholder={t("table.amount")}
											value={serviceAmount}
											disabled={serviceAmountFlag}
											onChange={(e) => {
												const percentage = (e.target.value / bailAmount) * 100;
												setServiceAmount(round(e.target.value, 2));
												setServicePercantage(round(percentage, 2));
												if (e.target.value) {
													setServicePercantageFlag(true);
												} else {
													setServicePercantageFlag(false);
												}
											}}
										/>
										<div className="position-absolute c-error txt-white percentage py-1 px-2 d-flex align-items-center justify-content-center cursor-pointer">
											<span>$</span>
										</div>
									</div>
								</div>
								<button
									className="p-0  border-0 d-flex align-items-center justify-content-center AddServiceButton"
									onClick={serviceAddHandler}
									disabled={bondsmanLoading}
								>
									{bondsmanLoading && <BtnLoader />}
									{svg?.addService}
								</button>
							</div>
							<div className="txt-error f12">
								{error === true && errorMessage}
								&nbsp;
							</div>
						</>
					)}
				</div>

				{!isAdd && !isEdit && (
					<div
						className="c-orange-10 h38 border-s d-flex align-items-center justify-content-center text-uppercase txt-error fw-medium cursor-pointer mt-2"
						onClick={() => {
							setIsAdd(true);
							setError(false);
							setErrorMessage("");
						}}
					>
						<span>Add New Service</span>
					</div>
				)}
			</div>
		</div>
	);
};

// Invoice Service List
export const InvoiceList = ({ className, name, bailAmount, setIsDropdown, setCurrentService, InvoiceList, selectedList, setInvoiceAmount, hasRemainAmountKey, invoiceId }) => {
	// Translation
	const { t } = useTranslation();

	// Dispatch
	const dispatch = useDispatch();

	// State For Select Service
	const [serviceId, setServiceId] = useState();

	// Select Handler
	const selectHandler = (e) => {
		const _id = getSelectedAttribute(e, "data-currentId");
		const service = getSelectedAttribute(e, "data-title");
		const amount = getSelectedAttribute(e, "data-amount");
		const percantage = getSelectedAttribute(e, "data-percentage");
		setServiceId(_id);
		setInvoiceAmount(round(amount, 2));
		setIsDropdown(false);

		const newSelect = {
			_id: _id,
			service,
			amount,
			remain_amount: 0,
		};

		setCurrentService(newSelect);
	};

	return (
		<div className={`${className} ServiceList w-100`}>
			<div className="border-s p-3 pt-2 b-light-grey border mt-2">
				{InvoiceList?.map((ele, index) => {
					const isMatched = selectedList?.some((item) => item._id === ele._id);

					const displayAmount = ele?.remain_amount !== undefined && ele?.remain_amount !== null ? `$${ele.remain_amount}` : `$${ele.amount}`;

					const displayAmount2 = ele?.remain_amount !== undefined && ele?.remain_amount !== null ? `${ele.remain_amount}` : `${ele.amount}`;

					return (
						ele?.service !== "Service Tax" && (
							<div className="d-flex align-items-center gap-2">
								<div className={`selectedBid d-flex align-items-center justify-content-center ${isMatched ? `opacity-100` : `opacity-0`}`}>{svg?.selectedBid}</div>
								<label
									htmlFor={ele?._id + name}
									className={`BondRelation w-100 position-relative ${serviceId == ele?._id ? "txt-white isTabActive" : "isTabInActive"}`}
									key={index}
								>
									<input
										type={"radio"}
										className="form-check-input"
										id={ele?._id + name}
										value={ele?._id}
										name={"bondsman-service"}
										checked={serviceId === ele?._id}
									/>
									<div className="d-flex gap-2">
										<div
											className="d-flex w-100 text-capitalize pointerEvent align-items-center"
											data-currentId={ele?._id}
											data-amount={invoiceId ? ele?.amount : displayAmount2}
											data-title={ele?.service}
											onClick={selectHandler}
										>
											<p className="mb-0 fs-6 text-black fw-semibold col-8">{ele?.service}</p>

											<p className="mb-0 fs-6 text-black fw-semibold col-4">{invoiceId ? `$${ele?.amount}` : displayAmount}</p>
										</div>
									</div>
								</label>
							</div>
						)
					);
				})}
			</div>
		</div>
	);
};

// Pagination
export const CustomPagination = ({ pageId, countCalculation, setPageId, className, pageCount, listCount, limit }) => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, [pageId]);

	// Handler
	const nextPageHandler = () => {
		if (countCalculation > pageId) setPageId(pageId + 1);
	};

	const prevPageHandler = () => {
		if (pageId > 0) setPageId(pageId - 1);
	};

	return (
		<div className={`${className} d-flex align-items-center justify-content-between`}>
			<div className="">{`Showing ${pageId * limit - limit + 1} to ${limit * pageId > countCalculation ? countCalculation : limit * pageId} of ${countCalculation}`}</div>
			<nav>
				<ul className="pagination mb-0">
					<li className={`page-item ${pageId === 1 ? "disabled" : ""}`}>
						<button className="page-link f14 txt-grey" onClick={prevPageHandler} disabled={pageId === 1}>
							{t("button.prev")}
						</button>
					</li>
					<li className="page-item">
						<button className="page-link f14 c-error txt-white currentpage">{prefixZero(pageId)}</button>
					</li>
					<li className={`page-item ${pageId === pageCount || limit * pageId >= countCalculation ? "disabled" : ""}`}>
						<button className="page-link f14 txt-grey" onClick={nextPageHandler} disabled={limit * pageId >= countCalculation}>
							{t("button.next")}
						</button>
					</li>
				</ul>
			</nav>
		</div>
	);
};

// Return 01,02 to 09
export function prefixZero(e) {
	return Number(e) < 10 ? `0${e}` : String(e);
}

// Update Footer (Add Any Page Automatically Added Updated Footer)
export const UpdateFooter = ({ removeFooter }) => {
	const updateFooter = true;
	useEffect(() => {
		if (updateFooter === true) {
			if (removeFooter === true) {
				document.body.classList.add("FooterUpdate");
				document.body.classList.add("RemoveFooter");
			} else {
				document.body.classList.add("FooterUpdate");
			}
			return () => {
				document.body.classList.remove("FooterUpdate");
				document.body.classList.add("RemoveFooter");
			};
		}
	}, []);
	return;
};

export const useBodyClass = (className) => {
	useEffect(() => {
		// Add class to body when component mounts
		document.body.classList.add(className);

		// Remove class from body when component unmounts
		return () => {
			document.body.classList.remove(className);
		};
	}, [className]); // Re-run effect if className changes
};

// Full Address Using Function
export function FullAddressString(data) {
	const address = `${data?.address?.zip || ""}${data?.address?.city ? ` ${data?.address?.zip ? "-" : ""} ${data.address.city}` : ""}${
		data?.address?.state ? `${data.address.city ? ", " : ""}${data.address.state}` : ""
	}`;
	return address;
}

// Full Name Using Function
export function FullName(data) {
	const name = `${data?.first_name || ""}${data?.last_name ? ` ${data?.last_name}` : ""}`;

	return name;
}

// Full Contact NUmber With Coutry Coude
export function ContactNumber(data) {
	const countryCode = data?.country_code ? (String(data?.country_code)?.includes("+") ? "" : "+") + data?.country_code : "";
	const phoneNo = data?.phone_no ? ` ${data?.phone_no}` : "";

	const cleanedNumber = `${countryCode}${phoneNo}`.replace(/\D/g, "");
	var formattedPhoneNumber = cleanedNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
	return formattedPhoneNumber;
}

export function ContactNumber2(data) {
	const countryCode = data?.country_code ? (String(data?.country_code)?.includes("+") ? "" : "+") + data?.country_code : "";
	const phoneNo = data?.contact_number ? ` ${data?.contact_number}` : "";

	const cleanedNumber = `${countryCode}${phoneNo}`.replace(/\D/g, "");
	var formattedPhoneNumber = cleanedNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
	return formattedPhoneNumber;
}

// If Found No Data Then Use This Component
export const NoData = ({ className, message }) => {
	return (
		<div className={`${className ? className : ""} text-center`}>
			<div>
				<span className="noData">{svg?.noData}</span>
				<p className="f18 txt-dark fw-medium mt-2">{message}</p>
			</div>
		</div>
	);
};

// Client Card
export const ClientCard = ({ className, data }) => {
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	let redirectUrl = () => {
		if (user?.role?.[0] === "admin") {
			return `/admin/client-list/${data?._id}/client-detail`;
		} else {
			return `/sub-admin/client-list/${data?._id}/client-detail`;
		}
	};
	const navigatePath = redirectUrl();
	// `/admin/client-list/${data?._id}/client-detail`;
	return (
		<div className={`ClientCard ${className}`}>
			<div className="card border-0 rounded-10 shadow cursor-pointer" onClick={() => navigate(navigatePath)}>
				<div className="card-body">
					<div className="d-flex align-items-start">
						<Image
							toolbarRender={() => {}}
							preview={<img src={`${PROFILE_URL}/${data?.photo}`} alt="Profile" />}
							src={data?.client_id?.photo_thumb ? `${PROFILE_URL}/${data?.photo_thumb}` : data?.photo ? `${PROFILE_URL}/${data?.photo}` : Profile_Avatar}
							alt="Profile"
							className="img-fluid position-absolute start-0 top-0 w-100 h-100 rounded-circle object-fit-cover"
							rootClassName="client-picture rounded-circle position-relative w-100"
						/>

						<div className="detail-card-user">
							<h3 className="fs-5 fw-semibold text-black mb-1 lh-normal">{FullName(data)}</h3>
							<p className="mb-0 fs-6 fw-medium">
								<Link to={`tel:${ContactNumber(data)}`} className="text-decoration-none txt-dark txt-primary">
									{ContactNumber(data)}
								</Link>
							</p>
							<p className="mb-0 fs-6 fw-medium">
								<Link to={`mailto:${data?.email}`} className="text-decoration-none txt-dark txt-primary">
									{data?.email}
								</Link>
							</p>
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-6">
							<label>Created Date:</label>
							<p>{momentDDMMYYAMPM(convertTimeZone(data?.created_at))}</p>
						</div>
						<div className="col-6">
							<label>Updated Date:</label>
							<p>{momentDDMMYYAMPM(convertTimeZone(data?.updated_at))}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// Selected Client Card
export const SelectClientCard = ({ data, className }) => {
	return (
		<div className={`${className} SelectClientCard`}>
			<div className="d-md-flex align-items-center text-md-start text-center card-body">
				<Image
					toolbarRender={() => {}}
					preview={<img src={`${PROFILE_URL}/${data?.photo}`} alt="Profile" />}
					src={data?.client_id?.photo_thumb ? `${PROFILE_URL}/${data?.photo_thumb}` : data?.photo ? `${PROFILE_URL}/${data?.photo}` : Profile_Avatar}
					alt="Profile"
					className="img-fluid position-absolute start-0 top-0 w-100 h-100 rounded-circle object-fit-cover"
					rootClassName="client-picture cp__detail rounded-circle position-relative w-100"
				/>
				<div className="text-break">
					<h3 className="fs-36 fw-bold text-black mb-1 lh-normal">
						{FullName(data)} <span className="fw-medium fs-5 d-inline-block">{FullAddressString(data)}</span>
					</h3>
					<div>
						<p className="mb-0 fs-6 fw-medium d-inline-block">
							<Link to={`tel:${ContactNumber(data)}`} className="text-decoration-none txt-dark txt-primary">
								{ContactNumber(data)}
							</Link>
						</p>
						<p className="mb-0 fs-6 fw-medium d-inline-block noEmail mx-2">|</p>
						<p className="mb-0 fs-6 fw-medium d-inline-block">
							<Link to={`mailto:${data?.email}`} className="text-decoration-none txt-dark txt-primary">
								{data?.email}
							</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

// Earning Card
export const EarningsCard = ({ title, amount, trend, trendPercentage, caseRequests, status }) => {
	return (
		<div className={`card common__card border-0 rounded-10 shadow h-100`}>
			<div className="card-body">
				<div className="d-lg-flex align-items-center text-lg-start text-center">
					<div className={`overview_round ${status ? `monthly__round` : `yearly__round`} rounded-circle position-relative me-lg-4 me-auto ms-lg-0 ms-auto mb-lg-0 mb-3`}>
						<span className={`position-absolute top-50 start-50 translate-middle fw-bold fs-26 ${status ? `text-blue` : `text-orrange`}`}>{`$${round(
							amount,
							2
						)}`}</span>
					</div>
					<div>
						<h3 className="fs-5 fw-bold mb-2 lh-normal">{title}</h3>
						<p className={`fs-30 fw-bold mb-2 ${status ? `text-success` : `text-danger`}`}>
							<span className="fs-5 fw-normal d-inline-block">
								{trend} ({trendPercentage}% {title === `Monthly Earning` ? `from last month` : `from last year`})
							</span>
						</p>
						<p className="mb-0 fs-5 fw-normal text-787878">{caseRequests} Case Request</p>
					</div>
				</div>
			</div>
		</div>
	);
};

// Range Picker
export const generatePresets = (t) => {
	return [
		{ label: t("datepicker.today"), value: [dayjs().add(0, "d"), dayjs()] },
		{
			label: t("datepicker.yesterday"),
			value: [dayjs().add(-1, "d"), dayjs()],
		},
		{
			label: t("datepicker.7days"),
			value: [dayjs().add(-7, "d"), dayjs()],
		},
		{
			label: t("datepicker.30days"),
			value: [dayjs().add(-30, "d"), dayjs()],
		},
		{
			label: t("datepicker.thisMonth"),
			value: [dayjs().startOf("month"), dayjs().endOf("month")],
		},
		{
			label: t("datepicker.lastMonth"),
			value: [dayjs().add(-1, "month"), dayjs()],
		},
		{
			label: t("datepicker.thisYear"),
			value: [dayjs().startOf("year"), dayjs().endOf("year")],
		},
		{
			label: t("datepicker.lastYear"),
			value: [dayjs().add(-1, "year"), dayjs()],
		},
	];
};

// Range Picker Props
export const getRangePickerProps = (startDate, endDate, setStartDate, setEndDate, t, svg) => {
	return {
		bordered: false,
		format: dayjsFormat,
		style: { width: "100%", height: "100%" },
		suffixIcon: svg?.dashBoardDownArrow,
		className: `bondmanlistDateFilter ff ${startDate && endDate ? `selected` : ``}`,
		separator: svg?.DashboardDateRange,
		placeholder: [t("datepicker.start"), t("datepicker.end")],
		value: [startDate && dayjs(startDate), endDate && dayjs(endDate)],
		onChange: (date, dateString) => {
			if (dateString[0] !== "" && dateString[1] !== "") {
				setStartDate(dayjs(dateString[0], dayjsFormat).toISOString());
				setEndDate(dayjs(dateString[1], dayjsFormat).toISOString());
			} else {
				setStartDate("");
				setEndDate("");
			}
		},
	};
};

// Client Card
export const ClientCardBondman = ({ className, data }) => {
	// Navigation
	const navigate = useNavigate();

	// Status
	const status = caseTypeForClient["high"];

	// Navigation Path
	const navigatePath = `/admin/client-list/${data?._id}/client-detail`;
	return (
		<div className={`ClientCard ${className}`}>
			<div className="card client__card client_h_card border-0 rounded-10 shadow h-100">
				<div className="card-body">
					<TagForClient status={status} />
					<div className="d-flex align-items-start">
						<Image
							toolbarRender={() => {}}
							preview={<img src={`${PROFILE_URL}/${data?.photo}`} alt="Profile" />}
							src={data?.client_id?.photo_thumb ? `${PROFILE_URL}/${data?.photo_thumb}` : data?.photo ? `${PROFILE_URL}/${data?.photo}` : Profile_Avatar}
							alt="Profile"
							className="img-fluid position-absolute start-0 top-0 w-100 h-100 rounded-circle object-fit-cover"
							rootClassName="client-picture rounded-circle position-relative w-100"
						/>

						<div className="text-break">
							<h3 className="fs-5 fw-semibold text-black mb-1 lh-normal">{FullName(data)}</h3>
							<p className="mb-0 fs-6 fw-medium">
								<Link to={`tel:${ContactNumber(data)}`} className="text-decoration-none txt-dark txt-primary">
									{ContactNumber(data)}
								</Link>
							</p>
							<p className="mb-0 fs-6 fw-medium">
								<Link to={`mailto:${data?.email}`} className="text-decoration-none txt-dark txt-primary">
									{data?.email}
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// Tag For CLient
export const TagForClient = ({ status, className, amount }) => {
	// Translation
	const { t } = useTranslation();
	return (
		<span
			className="position-absolute end-0 top-0 statusBadge px-md-3 px-2 py-1 lh-normal d-inline-block fs-6 d-flex align-items-center gap-1"
			style={{
				backgroundColor: `rgba(${status?.color},.2)`,
				color: `rgba(${status?.color},1)`,
			}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12" fill="none" className="me-0">
				<circle cx={6} cy={6} r={6} fill={`rgba(${status?.color},1)`} />
			</svg>
			<span className={`${className}`}>
				{t(status?.title)} {amount && amount}
			</span>
		</span>
	);
};

export const CalendarAppointmentsCard = ({ className, data }) => {
	return (
		<div className={className}>
			<div className="card client__card border-0 rounded-10 shadow h-100">
				<div className="card-body">
					<div className="d-flex align-items-center">
						<Image
							toolbarRender={() => {}}
							preview={<img src={`${PROFILE_URL}/${data?.photo}`} alt="Profile" />}
							src={data?.client_id?.photo_thumb ? `${PROFILE_URL}/${data?.photo_thumb}` : data?.photo ? `${PROFILE_URL}/${data?.photo}` : Profile_Avatar}
							alt="Profile"
							className="img-fluid position-absolute start-0 top-0 w-100 h-100 rounded-circle object-fit-cover"
							rootClassName="client-picture rounded-circle position-relative w-100"
						/>
						<div className="text-break w-100 d-flex align-items-center justify-content-between">
							<div>
								<h3 className="fs-5 fw-semibold text-black mb-1 lh-normal">{FullName(data)}</h3>
								<p className="mb-0 fs-6 fw-medium noEmail">{data?.request_number}</p>
							</div>
							<button onClick={() => {}} className="btn btn-lg calebder__btn fw-semibold btn-warning c-error">
								{svg?.calenderAppointment}
								<span className="f13">
									{momentDDMMYY(convertTimeZone(data?.created_at))}
									<br />
									{momentHHMMAMPM(convertTimeZone(data?.created_at))}
								</span>
							</button>
						</div>
					</div>
					<hr className="my-2" />
					<label className="fw-bold fs-5" style={{ color: "rgb(89, 68, 49)" }}>
						{data?.category?.name}
					</label>
					<p className="mb-0 fs-18 text-black">{data?.category?.description}</p>
				</div>
			</div>
		</div>
	);
};

export const ReviewAndFeedback = ({ className, data, param }) => {
	// State
	const [isReview, setIsReview] = useState(false);
	const [isReviewPurpose, setIsReviewPose] = useState(0);
	const [isReviewData, setIsReviewData] = useState({});

	return (
		<div className={`${className} ReviewAndFeedback`}>
			<div className="card client__card client_h_card border-0 rounded-10 shadow h-100">
				<div
					className="card-body cursor-pointer"
					onClick={() => {
						setIsReview(true);
						setIsReviewData(data);
					}}
				>
					<div className="d-flex align-items-center">
						<Image
							toolbarRender={() => {}}
							preview={<img src={`${PROFILE_URL}/${data?.client_id?.photo}`} alt="Profile" />}
							src={
								data?.client_id?.photo_thumb
									? `${PROFILE_URL}/${data?.client_id?.photo_thumb}`
									: data?.client_id?.photo
									? `${PROFILE_URL}/${data?.client_id?.photo}`
									: Profile_Avatar
							}
							alt="Profile"
							className="img-fluid position-absolute start-0 top-0 w-100 h-100 rounded-circle object-fit-cover"
							rootClassName="client-picture rounded-circle position-relative w-100"
						/>
						<div className="text-break w-100 d-flex align-items-center justify-content-between">
							<div>
								<h3 className="fs-5 fw-semibold text-black mb-1 lh-normal">{FullName(data?.client_id)}</h3>
								<p className="mb-0 fs-6 fw-medium">
									<span className="txt-primary">#{data?.bond_request_id?.request_number}</span>
									<span className="txt-grey ms-2">{momentDDMMYY(convertTimeZone(data?.created_at))}</span>
								</p>
							</div>
						</div>
					</div>
					<hr className="my-3" />
					<div className="">
						<div className="d-flex align-items-center justify-content-between mb-2">
							<Rate disabled value={data?.review_rate} allowHalf className="antdRate f30" style={{ color: "#FBC01B" }} />

							{data?.bondsman_feedback && <p className="mb-0 fs-6 fw-medium txt-error cursor-pointer text-uppercase">responded</p>}
						</div>

						<p className="mb-0 f18 fw-medium line line-3">{data?.message}</p>

						<button
							className="mt-2 p-0 btn btn-transparent read_more_link fs-6 fw-semibold text-uppercase text-decoration-none d-inline-flex align-items-center txt-error txt-hover-error"
							onClick={() => {
								setIsReview(true);
								setIsReviewData(data);
							}}
						>
							Read More {svg?.readMore}
						</button>
					</div>
				</div>
			</div>
			{isReview && <ReviewModal modalOpen={isReview} modalPurpose={isReviewPurpose} updateData={isReviewData} cancelHandler={() => setIsReview(false)} param={param} />}
		</div>
	);
};

export const PayoutBalance = ({ className }) => {
	return (
		<div className={`${className}`}>
			<div className="card common__card border-0 rounded-10 shadow h-100">
				<div className="card-body d-sm-flex align-items-center justify-content-between">
					<div className="me-3">
						<h4 className="fs-4 fw-bold">Balance Amount</h4>
						<p className="fs-6 mb-0">This is the due amount which we transfer weekly.</p>
					</div>
					<div className="payoutBox position-relative fw-bold d-inline-flex align-items-center justify-content-center mt-sm-0 mt-3">$0.00</div>
				</div>
			</div>
		</div>
	);
};

export const PayoutStripe = ({ className, data, connect, inprogress, logo, isBankCard, bankType }) => {
	// Dispatch
	const dispatch = useDispatch();

	const { paymentLoading, stripeLoading, createBankLoading } = useSelector((state) => state.loading);
	const { bondsmanAccountDetail } = useSelector((state) => state.payoutAccount);

	// State
	const [isRemoveAccount, setIsRemoveAccount] = useState(false);

	const bankConnectData = {
		return_url: `${CLIENT_URL}/bond-man/payout`,
		refresh_url: `${CLIENT_URL}/bond-man/payout`,
	};

	const handleConnect = () => {
		if (isBankCard) {
			dispatch(ConnectBankAccount(bankConnectData));
		} else {
			dispatch(ConnectStripeAccount(data?.id));
		}
	};

	const accountId = bondsmanAccountDetail?.[0]?._id;

	const bank_data = bondsmanAccountDetail?.[0];

	return (
		<div className={`${className} PayoutStripe`}>
			<div className="card common__card border-0 rounded-10 shadow h-100">
				<div className="card-body">
					<div className=" d-sm-flex align-items-center justify-content-between">
						<div className={`d-flex stripe-logo ${connect ? `align-items-center` : ``}`}>
							{logo}
							<div className="me-3">
								<h4 className="fs-4 fw-bold">
									{isBankCard ? "Connect Bank Account" : !connect ? `Connect Stripe Account` : bankType ? `Bank Account` : `Stripe Account`}
								</h4>
								<p className="fs-6 mb-0">
									{isBankCard
										? "Let’s enter bank account details for your payment. Your connected bank account will be used for convenient and efficient payment processing."
										: !connect
										? `Let’s enter Stripe account details for your payment.`
										: bankType
										? `In this Bank account, your payment will be credited.`
										: `In this Stripe account, your payment will be credited.`}
								</p>
							</div>
						</div>
						{!connect || isBankCard ? (
							<div>
								<button
									className="btn btn-lg c-success connect__btn mt-sm-0 mt-3 txt-white"
									onClick={handleConnect}
									disabled={isBankCard ? paymentLoading : stripeLoading}
								>
									{isBankCard ? <>{paymentLoading && <BtnLoader />}</> : <>{stripeLoading && <BtnLoader />}</>}
									Let’s Connect
								</button>
							</div>
						) : (
							<div className="text-end">
								<div className="fs-6 mb-1 inProgress d-inline-flex align-items-center">
									{inprogress ? (
										<>
											{svg?.inProgressStripe} <span className="txt-error">Processing</span>
										</>
									) : (
										<>
											{svg?.isConnectedStripe} <span className="txt-success f20">Connected</span>
										</>
									)}
								</div>
								{bank_data?.account_type === "bank" && (
									<>
										<div className="f16 mb-1">{bank_data?.bank_details?.bank_name}</div>
										<div className="f16 mb-1">XXXX XXXX {bank_data?.bank_details?.last4}</div>
										<div className="f16 mb-1">{bank_data?.bank_details?.routing_number}</div>
									</>
								)}

								{bank_data?.account_type === "stripe" && (
									<>
										<div className="f16 mb-1">{bank_data?.stripe_email}</div>
										<div className="f16 mb-1">{bank_data?.bank_details?.routing_number}</div>
									</>
								)}

								<div className="cursor-pointer txt-warning px-0 mt-2 f16" onClick={() => setIsRemoveAccount(true)}>
									Remove Account
								</div>
							</div>
						)}
					</div>
					{!connect && !isBankCard && (
						<>
							<div className="w-100 mb-2 border-bottom my-3"></div>
							<p className="mb-0">
								If you didn’t have Stripe account?{" "}
								<a
									onClick={(e) => {
										e.preventDefault();
										dispatch(CreateNewAccount(bankConnectData));
									}}
									className="red__link text-uppercase text-decoration-none txt-hover-error"
									disabled={createBankLoading}
								>
									CREATE NEW ACCOUNT
								</a>
							</p>
						</>
					)}
				</div>
			</div>

			<DeleteModal
				modalOpen={isRemoveAccount}
				cancelSuperHandler={() => {
					setIsRemoveAccount(false);
				}}
				title={"Remove Account, Are you sure?"}
				deleteTitle="Yes, Remove"
				deleteHandler={() => {
					setIsRemoveAccount(false);
					// Add Dispatch
					dispatch(RemoveStripeAccount(accountId));
				}}
				svg={svg?.deleteDocumentLarge}
				loader={paymentLoading}
				deleteTitleClass={"f16"}
			/>
		</div>
	);
};

// Education & Resource
export const EducationCard = ({ className, data, isWebinar, classNameCard }) => {
	return (
		<div className={className}>
			<div className={`card eru__card border-0 rounded-10 overflow-hidden shadow ${classNameCard}`}>
				<div className="card-header bg-transparent border-0 p-0 position-relative">
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
						className="position-absolute start-0 top-0 w-100 h-100 object-fit-cover"
						width={480}
						height={240}
						alt="Image"
					/>
					<span className="eru__card_date position-absolute lh-normal text-white py-1 px-2 fs-14 fw-semibold">{momentDDMMYY(new Date())}</span>
				</div>
				<div className="card-body d-flex flex-column p-3">
					<div className={`flex-fill ${isWebinar && `mb-2`}`}>
						<h3 className="fs-5 fw-bold mb-2 text-black text-truncate-2">{data?.title}</h3>
						<p className="fs-18 lh-normal text-black mb-2 text-truncate-2">{data?.description}</p>
						{isWebinar && (
							<>
								<p className="fs-18 lh-normal text-black mb-1">
									Time: <span className="fw-semibold">{momentDDMMYYAMPM(new Date())}</span>
								</p>
								<p className="fs-18 lh-normal text-black mb-0">
									Speaker: <span className="fw-semibold">Amanda Jane</span>
								</p>
							</>
						)}
					</div>
					<Link
						to={`/bond-man/educational-resources-and-updates/${data?._id}`}
						className="read_more_link fs-6 fw-semibold text-uppercase text-decoration-none d-inline-flex align-items-center txt-error txt-hover-error"
					>
						Read More {svg?.readMore}
					</Link>
				</div>
			</div>
		</div>
	);
};

export const EducationDetailCard = ({ className, data }) => {
	return (
		<div className={className}>
			<div className="card common__card eru_detail__card border-0 rounded-10 shadow h-100">
				<div className="card-body">
					<div className="cru_main_img position-relative rounded-10 overflow-hidden mb-md-4 mb-3">
						<img
							src="https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg"
							className="position-absolute start-0 top-0 w-100 h-100 object-fit-cover"
							width={920}
							height={460}
							alt="Image"
						/>
					</div>
					<p className="fs-6 lh-normal text-black mb-1">
						Updated On: <span className="fw-semibold"> {momentDDMMYY(new Date())}</span>
					</p>
					<h3 className="fs-4 fw-bold mb-md-3 mb-2 text-black">{data?.title}</h3>
					<div className="fs-6 text-black">
						<p className="mb-3">{data?.description}</p>
						<p className="mb-3">{data?.description}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export const DocumentRepositoryCard = ({ className, data }) => {
	// Ref
	const dropDownRef = useRef();

	// State
	const [toggleDropdown, setToggleDropdown] = useState(false);

	// Use
	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	// Handle Clickoutside toggleDropdown close
	const handleClickOutside = (event) => {
		const containerElement = dropDownRef.current;

		if (containerElement && !containerElement.contains(event.target)) {
			setToggleDropdown(false);
		}
	};

	const linkData = [
		{
			path: "Share",
			label: "Share",
			svg: svg?.shareDocument,
		},
		{
			path: "Delete",
			label: "Delete",
			svg: svg?.deleteDocument,
		},
	];

	return (
		<div className={`col ${className} DocumentRepositoryCard`}>
			<div className="card dr__card border-0 rounded-10 shadow h-100">
				<div className="card-body position-relative text-center">
					<div className="dropdownElement position-absolute" ref={dropDownRef}>
						<button className="btn btn-light" onClick={() => setToggleDropdown(!toggleDropdown)}>
							{svg?.toggleDocument}
						</button>

						<ul className={` profileDropDown ${toggleDropdown ? "d-grid" : "d-none"} position-absolute b-shadow c-white p-3 rounded-8`}>
							{linkData?.map((link, index) => (
								<NavigationLink
									key={index}
									path={link.path || ""}
									title={link.label}
									svg={link.svg}
									className={"px-2 d-flex position-relative text-decoration-none"}
									onClick={() => setToggleDropdown(false)}
								/>
							))}
						</ul>
					</div>
					{svg?.documentFolder}
					<div>
						<h3 className="fs-5 fw-semibold text-black">{data?.request_number}</h3>
						<p className="text-truncate fw-medium text-787878 mb-0 fs-6">{FullName(data)}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export const SelectBondsman = ({ className, classNameCollection, classNameItem, handleCheckboxChange, selectedStatus, data, bidDetail, selectedOption, acceptBid, extraUrl }) => {
	// Translation
	const { t } = useTranslation();

	// Dispatch
	const navigate = useNavigate();

	// Dispatch
	const dispatch = useDispatch();

	// Selector
	const { user } = useSelector((state) => state.auth);
	// Selector
	const { acceptBidLoading, decideBidLoading } = useSelector((state) => state.loading);

	// State
	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);

	useEffect(() => {
		// Check if the browser supports geolocation
		if (navigator.geolocation) {
			// Get the current position
			navigator.geolocation.getCurrentPosition(function (position) {
				// Retrieve the latitude and longitude
				const lat = position.coords.latitude;
				const long = position.coords.longitude;

				// Update the state with the latitude
				setLatitude(lat);
				setLongitude(long);
			});
		}
	}, []);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalData, setModalData] = useState();

	const acceptBidHandler = (params, modalParams) => {
		dispatch(SelectedAcceptBid(params?.bond_request_id, params?.id, setModalOpen));
		setModalData(modalParams);
	};

	return (
		<div className={`FilterDataCheckBox ${className} `}>
			<Checkbox.Group onChange={handleCheckboxChange} value={selectedStatus} className={`antdCheckBoxGroup ${classNameCollection} ff`}>
				{data?.map((ele, index) => {
					const isSelected = selectedStatus?.includes(ele?._id);
					const clientLat = user ? user?.address?.lat : latitude;
					const ClientLong = user ? user?.address?.long : longitude;
					const bondmansLat = acceptBid ? ele?.bondsman_id?.address?.lat : ele?.address?.lat;
					const bondmansLong = acceptBid ? ele?.bondsman_id?.address?.long : ele?.address?.long;

					const miles = getDistanceInMiles(clientLat, ClientLong, bondmansLat, bondmansLong);

					const dataArrange = {
						user_image: acceptBid ? ele?.bondsman_id : ele,
						full_name: acceptBid ? ele?.bondsman_id : ele,
						address: acceptBid ? ele?.bondsman_id : ele,
						experience: acceptBid ? ele?.bondsman_id?.experience : ele?.experience,
						service_charge: acceptBid ? ele?.bondsman_id?.service_charge : ele?.service_charge,
						average_review: acceptBid ? ele?.bondsman_id?.average_review : ele?.average_review,
						bail_amount: ele?.bids_amount,
					};

					const view_profile_path = acceptBid
						? `/${ele?.bondsman_id?._id}/bondsman-profile${extraUrl ? extraUrl : ``}`
						: `/${ele?._id}/bondsman-profile${extraUrl ? extraUrl : ``}`;

					const id = acceptBid ? ele?.bondsman_id?._id : ele?._id;

					const acceptBidParams = {
						bond_request_id: acceptBid ? ele?.bond_request_id : undefined,
						id: acceptBid ? ele?._id : ele?.bondsman_id?._id,
					};

					const bondsman_bid_detail = acceptBid && `/my-case/${ele?.bond_request_id}/bondsman/${ele?._id}`;

					const review = dataArrange?.average_review && dataArrange?.average_review !== 0 ? dataArrange?.average_review : "-";

					return (
						<div className={classNameItem}>
							<div className={`card bondsManCard rounded-10 h-100 w-100 ${isSelected ? `isSelected` : ``}`}>
								<div className="card-body text-center position-relative">
									{isSelected && svg?.find_bondsman_tick}
									<UserProfileImage
										className={"object-fit-cover position-absolute w-100 h-100 top-0 start-0"}
										rootClassName={"bondsmanProfile rounded-circle overflow-hidden w-100 position-relative d-inline-block mb-3"}
										data={dataArrange?.user_image}
									/>

									<h3 className="fw-bold fs-4 mb-3">{FullName(dataArrange?.full_name)}</h3>
									<div className="serviceCharge d-inline-flex align-items-center rounded-10">
										<span className="fs-5 text-black px-3 text-nowrap">{acceptBid ? `Bid Amount :` : `Service Charge :`}</span>
										<span className="fs-4 fw-bold px-3">
											{acceptBid ? `$${dataArrange?.bail_amount}` : `${dataArrange?.service_charge ? dataArrange?.service_charge : 10}%`}

											{!acceptBid && <span className="fs-14 fw-normal d-block text-nowrap">of Bail Amount</span>}
										</span>
									</div>

									{bidDetail && (
										<p className="fw-medium  mt-3 text-uppercase txt-success f14 cursor-pointer" onClick={() => navigate(bondsman_bid_detail)}>
											BID DETAILS
										</p>
									)}

									<div className="row gx-sm-3 gx-2 mt-3">
										<div className="col-4">
											<div className="card bondsManColCard rounded-10 border-0">
												<div className="card-body d-flex justify-content-center align-items-center flex-column">
													{svg?.find_bondsman_mile} {round(miles, 0)} Miles
												</div>
											</div>
										</div>

										<div className="col-4">
											<div className="card bondsManColCard rounded-10 border-0">
												<div className="card-body d-flex justify-content-center align-items-center flex-column">
													{svg?.find_bondsman_rating}
													{review}
												</div>
											</div>
										</div>

										{dataArrange?.experience && (
											<div className="col-4">
												<div className="card bondsManColCard rounded-10 border-0">
													<div className="card-body d-flex justify-content-center align-items-center flex-column">
														{svg?.find_bondsman_year}
														{dataArrange?.experience} Years
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="card-footer text-center bg-white border-0 p-3">
									<button className="btn btn-success c-success btn-lg mx-xl-2 mx-1 fw-medium px-2 px-md-3" onClick={() => navigate(view_profile_path)}>
										VIEW PROFILE
									</button>
									{selectedOption && (
										<div className="d-inline-block mx-xl-2 mx-1">
											<Checkbox
												className={`antdCheckBox btn btn-warning select-btn-lg btn-lg d-inline-flex align-items-center fw-medium px-2 px-md-3`}
												value={id}
												key={index}
											>
												<div>
													<span className="select">Select</span>
													<span className="selected">
														{svg?.find_bondsman_selected}
														Selected
													</span>
												</div>
											</Checkbox>
										</div>
									)}
									{acceptBid && (
										<button
											className="btn btn-warning select-btn-lg btn-lg text-uppercase fw-medium px-2 px-md-3"
											onClick={() => acceptBidHandler(acceptBidParams, ele)}
										>
											{acceptBidLoading && <BtnLoader />}
											Accept Bid
										</button>
									)}
								</div>
							</div>
						</div>
					);
				})}
			</Checkbox.Group>

			<AcceptBidModal modalOpen={modalOpen} cancelSuperHandler={() => setModalOpen(false)} value={modalData} condition={true} />
		</div>
	);
};

// Select Any One Of Case
export const SelectBailCard = ({ data, className, name, setValue, value }) => {
	return (
		<>
			{data?.map((ele, index) => (
				<>
					<label htmlFor={ele?._id} className={`SelectBailCard RadioButtonSelectAnyOne ${className} d-block ${value === ele?._id ? "isSelected" : ""}`} key={index}>
						<input
							type={"radio"}
							className="form-check-input"
							id={ele?._id}
							value={ele?._id}
							name={name}
							onChange={(e) => {
								setValue(e.target.value);
							}}
							checked={value === ele?._id}
						/>
						<div className={`card se_request_Card ${value === ele?._id && "selected"} mx-auto card-shadow rounded-10 overflow-hidden mb-3`}>
							<div className="card-body position-relative">
								{value === ele?._id && svg?.find_bondsman_tick}
								<span className="fs-6 date__time mb-1 d-inline-block">{momentDDMMYYAMPM(convertTimeZone(ele?.created_at))}</span>
								<h3 className="fs-5 fw-bold text-blue mb-2">#{ele?.request_number}</h3>
								<p className="fs-5 fw-semibold">
									<span className="fw-normal text-capitalize">case number:</span> {ele?.case_number}
								</p>
								<p className="fs-5 fw-semibold mb-0">
									<span className="fw-normal text-capitalize">court date:</span> {momentDDMMYYAMPM(ele?.court_date_time)}
								</p>
							</div>
						</div>
					</label>
				</>
			))}
		</>
	);
};

export const SendBailRequest = ({ className, id }) => {
	const navigate = useNavigate();
	return (
		<div className={`card border-0 dark-card text-white ${className}`}>
			<div className="card-body p-4">
				<div className="row gy-4 align-items-center">
					<div className="col-xl-6 text-md-start text-center">
						<h3 className="fw-bold fs-4 mb-2">Send Bail Request To Bondsman</h3>
						<p className="mb-0 fs-6">Please send this bail request to a bondsman, as it has not been sent to any bondsman yet.</p>
					</div>
					<div className="col-xl-6">
						<div className="d-md-flex align-items-center justify-content-xl-end text-md-start text-center">
							<button className="btn btn-warning btn-lg page-btn mb-1" onClick={() => navigate(`/my-case/${id}/select-bondsman`)}>
								Send Request
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const SendBailRequestList = ({ className, id, data }) => {
	// Dispatch
	const dispatch = useDispatch();

	// Navigate
	const navigate = useNavigate();

	const isSent = data?.selected_bondsman_count <= 0 || data?.bids_count <= 0;

	const selected = isSent ? data?.selected_bondsman_details : data?.bids_details;
	const selectedCount = isSent ? data?.selected_bondsman_count : data?.bids_count;

	return (
		<div className={`card border-0 dark-card text-white ${className}`}>
			<div className="card-body p-4">
				<div className="row gy-4 align-items-center">
					<div className="col-xl-6 text-md-start text-center">
						<h3 className="fw-bold fs-4 mb-2">Requested Bondsman List</h3>
						<p className="mb-0 fs-6">You can find all requested bondsman and also find which bondsman placed bid on your bail request</p>
					</div>
					<div className="col-xl-6">
						<div className="d-md-flex align-items-center justify-content-xl-end text-md-start text-center">
							<span className={`fs-5 fw-bold sendCount mb-md-0 mb-3 d-inline-block me-md-4 ${isSent ? `text-blue ` : `txt-success`}`}>
								{isSent ? svg?.sentBondman : svg?.receiveBondman}
								{isSent ? `Sent to ${selectedCount} Bondsman` : `${selectedCount} Bid${selectedCount > 1 ? "s" : ""} Received`}
							</span>

							<ul className="list-unstyled profilelist_ul mb-md-0 mb-3 me-md-4">
								{selected &&
									selected?.map((ele, index) => {
										const src = isSent
											? ele?.photo
												? `${PROFILE_URL}/${ele?.photo_thumb}`
												: Profile_Avatar
											: ele?.bondsman_id?.photo
											? `${PROFILE_URL}/${ele?.bondsman_id?.photo_thumb}`
											: Profile_Avatar;

										return (
											<li className="d-inline-block" key={index}>
												<div className="profileList_li rounded-circle">
													<img src={src} width={56} height={56} alt="Image" />
													{index >= 4 && selectedCount - 5 !== 0 && <span>+{selectedCount - 5}</span>}
												</div>
											</li>
										);
									})}
							</ul>

							<div>
								<button className="btn btn-primary btn-lg page-btn me-md-3 me-2 mb-1" onClick={() => navigate(`/my-case/${id}/bondsman-list`)}>
									View All
								</button>
								<button
									className="btn btn-warning btn-lg page-btn mb-1"
									onClick={() => {
										dispatch({
											type: "SENT_TO_MORE_BONDSMAN_CLEAR_ALL",
										});
										navigate(`/my-case/${id}/select-bondsman`);
									}}
								>
									Send To More
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const BondsmanDetailAcceptBid = ({ className, data, type, anyInvoicePaid, bondDetails, caseId }) => {
	// Translation
	const { t } = useTranslation();

	// Navigate
	const navigate = useNavigate();

	// Dispatch
	const dispatch = useDispatch();

	// Selector
	const { user } = useSelector((state) => state.auth);
	const { reviewList, reviewCount } = useSelector((state) => state.review);

	const clientLat = user?.address?.lat;
	const ClientLong = user?.address?.long;
	const bondmansLat = data?.bondsman_id?.address?.lat;
	const bondmansLong = data?.bondsman_id?.address?.long;

	const miles = getDistanceInMiles(clientLat, ClientLong, bondmansLat, bondmansLong);

	const bondsman_bid_detail = `/my-case/${data?.bond_request_id}/bondsman/${data?._id}`;

	const getChatLabel = () => {
		switch (type) {
			case "accepted":
				return t("button.startChat");
			case "completed":
				return t("button.chatHistory");
			case "unpaid":
			case "paid":
			case "in-progress":
				return t("button.chat");
			default:
				return null; // Handle any other cases or provide a default value
		}
	};

	const charRedirect = () => {
		dispatch({
			type: "HANDLE_ACTIVE_REQUEST_NUMBER",
			payload: data?.request_number,
		});
		navigate(`/chat`);
	};

	// Set Review
	const [isReview, setIsReview] = useState(false);
	const [isReviewPurpose, setIsReviewPurpose] = useState(0);
	const [isReviewData, setIsReviewData] = useState({});

	// Get Bondsman Review

	const [isReviewPage, setIsReviewPage] = useState(1);
	const [isReviewLimit, setIsReviewLimit] = useState(10);

	useEffect(() => {
		dispatch(FetchBondmanReview(isReviewPage, isReviewLimit, data?.bondsman_id?._id, null, caseId));
	}, [data]);

	const [isReviewPreviewModal, setIsReviewPreviewModal] = useState(false);

	const isReviewPreview = reviewCount > 0 ? true : false;

	return (
		<div className={`card border-0 bailBondDetail_card rounded-10 card-shadow ${className} BondsmanDetailAcceptBid`}>
			<div className="card-body">
				<div className="row gy-4 align-items-center">
					<div className="col-xl-7 col-xxl-6">
						<div className="d-xl-flex align-items-center">
							<UserProfileImage
								data={data?.bondsman_id}
								className={"object-fit-cover position-absolute w-100 h-100 top-0 start-0"}
								rootClassName={"bondsmanProfile rounded-circle overflow-hidden w-100 position-relative d-inline-block mb-3 shadow"}
							/>

							<div className="bb-detail-p mt-4 mt-xl-0">
								<h3 className="fw-bold fs-4 mb-2">
									<span className="me-4">{FullName(data?.bondsman_id)}</span>
								</h3>
								<p className="fs-6 fw-semibold mb-2">
									{svg?.location}
									{FullAddressString(data?.bondsman_id)}
								</p>
								<p className="fs-6 fw-semibold mb-2">
									{svg?.find_bondsman_mile}
									Within {round(miles, 2)} Mile
									{miles > 1 ? `s` : ``}
								</p>

								{data?.bondsman_id?.average_review && (
									<p className="svg_primary fs-6 fw-semibold mb-2">
										{svg?.ratingStar}

										<span className="ms-1 fw-medium txt-dark">
											{data?.bondsman_id?.average_review && data?.bondsman_id?.average_review !== 0 ? data?.bondsman_id?.average_review : `-`}{" "}
										</span>
									</p>
								)}

								<p className="svg_primary fs-6 fw-semibold mb-2">
									{svg?.find_bondsman_year}
									Experience:
									<span className="ms-1 fw-medium txt-dark">
										{data?.bondsman_id?.experience} Year
										{data?.bondsman_id?.experience > 1 ? `s` : ``}
									</span>
								</p>
							</div>
						</div>
					</div>
					<div className="col-xl-5 col-xxl-6">
						<div className="d-sm-flex align-items-center justify-content-xl-end justify-content-between w-100">
							<div className="serviceCharge d-sm-inline-flex d-flex flex-sm-column text-center align-items-center rounded-10">
								<span className="fs-6 text-black p-3 text-nowrap">Bid Amount :</span>
								<span className="fs-5 fw-bold px-3 py-2 w-100">${round(data?.bids_amount, 2)}</span>
							</div>
							<div className="text-center ms-xl-4 mt-md-0 mt-3">
								<div className="mb-md-3 mb-2">
									<button className="btn c-primary text-uppercase fw-semibold fs-6 px-lg-3 px-2 position-relative text-white h46" onClick={charRedirect}>
										<span>
											{svg?.chating}
											<span className="ms-2">{getChatLabel()}</span>
										</span>
									</button>
								</div>

								{type !== "completed" && anyInvoicePaid !== true && (
									<p className="mb-0 txt-success text-uppercase fs-6 fw-semibold cursor-pointer" onClick={() => navigate(bondsman_bid_detail)}>
										VIEW BID DETAILS
									</p>
								)}

								{!isReviewPreview && (type === "completed" || anyInvoicePaid === true) && (
									<button
										className="btn c-error text-uppercase fw-semibold fs-6 px-sm-4 px-2 position-relative text-white h46"
										onClick={(e) => {
											e.preventDefault();
											setIsReview(true);
											setIsReviewPurpose(0);
											setIsReviewData({});
										}}
									>
										<span>{t("button.writeReview")}</span>
									</button>
								)}

								{isReviewPreview && type === "completed" && (
									<button
										className="btn c-error text-uppercase fw-semibold fs-6 px-sm-4 px-2 position-relative text-white h46"
										onClick={(e) => {
											e.preventDefault();
											setIsReviewPreviewModal(true);
										}}
									>
										<span>View Review</span>
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{isReviewPreviewModal && (
				<ReviewPreview
					modalOpen={isReviewPreviewModal}
					cancelHandler={() => setIsReviewPreviewModal(false)}
					reviewList={reviewList}
					setModalPurpose={setIsReviewPurpose}
					setState={setIsReview}
					setUpdateReviewData={setIsReviewData}
					params={{
						page: isReviewPage,
						limit: isReviewLimit,
						bondsman_id: data?.bondsman_id?._id,
					}}
				/>
			)}

			{isReview && (
				<WriteAReview
					modalOpen={isReview}
					cancelHandler={() => setIsReview(false)}
					bondDetails={bondDetails}
					modalPurpose={isReviewPurpose}
					updateData={isReviewData}
					params={{
						page: isReviewPage,
						limit: isReviewLimit,
						bondsman_id: data?.bondsman_id?._id,
						bond_request_id: caseId,
					}}
				/>
			)}
		</div>
	);
};

export const BondsmanProfileCard = ({ className, data, profile }) => {
	// Translation
	const { t } = useTranslation();

	// Selector
	const { user } = useSelector((state) => state.auth);

	// State
	const [modalOpen, setModalOpen] = useState(false);

	const clientLat = user?.address?.lat;
	const ClientLong = user?.address?.long;
	const bondmansLat = data?.bondsman_id?.address?.lat || profile?.address?.lat;
	const bondmansLong = data?.bondsman_id?.address?.long || profile?.address?.long;

	const miles = getDistanceInMiles(clientLat, ClientLong, bondmansLat, bondmansLong);

	const dataArrange = {
		user_image: data?.bondsman_id || profile,
		full_name: data?.bondsman_id || profile,
		availability_status: data?.bondsman_id?.availability_status || profile?.availability_status,
		address: data?.bondsman_id || profile,
		experience: data?.bondsman_id?.experience || profile?.experience,
		bondsman_license: data?.bondsman_id?.bondsman_license || profile?.bondsman_license,
		service_charge: data?.bondsman_id?.service_charge || profile?.service_charge,
		bio: data?.bondsman_id?.bio || profile?.bio,
		working_hours: data?.bondsman_id?.working_hours || profile?.working_hours,
		average_review: data?.bondsman_id?.average_review || profile?.average_review,
	};

	return (
		<div className={`card border-0 bailBondDetail_card rounded-10 card-shadow BondsmanProfileCard`}>
			<div className="card-body">
				<div className="row gy-4 align-items-center">
					<div className="col-lg-6">
						<div className="d-md-flex align-items-start">
							<UserProfileImage
								data={dataArrange?.user_image}
								className={"object-fit-cover position-absolute w-100 h-100 top-0 start-0"}
								rootClassName={"bondsmanProfile rounded-circle overflow-hidden w-100 position-relative d-inline-block mb-3 shadow"}
							/>
							<div className="bb-detail-p">
								<h3 className="fw-bold fs-36 mb-3">
									<span className="me-4"> {FullName(dataArrange?.full_name)}</span>
									<span className="d-inline-block fs-6 fw-semibold bb__status available">
										{dataArrange?.availability_status ? (
											<>
												{svg?.available}
												Available
											</>
										) : (
											<>
												{svg?.available}
												Not Available
											</>
										)}
									</span>
								</h3>
								<p className="fs-5 fw-semibold mb-2">
									{svg?.location}
									{FullAddressString(dataArrange?.address)}
								</p>
								<p className="fs-5 fw-semibold mb-2">
									{svg?.find_bondsman_mile}
									Within {round(miles, 2)} Mile
									{miles > 1 ? `s` : ``}
								</p>
								<p className="fs-5 fw-semibold mb-2 svg_primary">
									{svg?.find_bondsman_year}
									{dataArrange?.experience} Year
									{dataArrange?.experience > 1 ? `s` : ``}
								</p>
								<h4 className="text-333 fs-4 fw-bold mb-0">
									Bondsman License: <span className="fw-medium">{dataArrange?.bondsman_license}</span>
								</h4>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="d-sm-flex align-items-center justify-content-lg-end justify-content-between w-100">
							<div className="text-md-end text-start me-md-4 mb-md-0 mb-3">
								{dataArrange?.average_review && dataArrange?.average_review !== 0 && (
									<div className="mb-md-4 mb-2 bb_reviewStar">
										<div className="d-flex align-items-center">
											<Rate value={dataArrange?.average_review} allowHalf className="antdRate f32" disabled={true} style={{ color: "#FBC01B" }} />
											<span className="fs-4 text-333 fw-semibold d-inline-block align-middle ms-2">{dataArrange?.average_review}</span>
										</div>
									</div>
								)}
								<p className="mb-0 text-orange text-uppercase fs-6 fw-semibold cursor-pointer" onClick={() => setModalOpen(true)}>
									View Working Hours
								</p>
							</div>
							<div className="serviceCharge d-sm-inline-flex d-flex flex-sm-column text-center align-items-center rounded-10">
								<span className="fs-5 text-black p-3 text-nowrap">Service Charge :</span>
								<span className="fs-4 fw-bold px-3 py-2 w-100">
									{dataArrange?.service_charge}%<span className="fs-14 fw-normal d-block text-nowrap">of Bail Amount</span>
								</span>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<p className="fs-4 fw-medium mb-0 text-333">{dataArrange?.bio}</p>
			</div>

			<WorkingHoursModal modalOpen={modalOpen} cancelSuperHandler={() => setModalOpen(false)} data={dataArrange?.working_hours} />
		</div>
	);
};

const UserProfileImage = ({ data, className, rootClassName }) => {
	const bondsmanPhoto = data?.photo_thumb || data?.photo || "Profile_Avatar";
	const userProfilePhoto = `${PROFILE_URL}/${data?.photo}`;
	const src = data?.photo ? `${PROFILE_URL}/${bondsmanPhoto}` : Profile_Avatar;

	return <Image toolbarRender={() => {}} preview={<img src={userProfilePhoto} alt="Profile" />} src={src} alt="Profile" className={className} rootClassName={rootClassName} />;
};

export const RenderTable = ({ data, title }) => {
	const { t } = useTranslation();
	return (
		<div className="mt-2">
			<Table
				className="modalAntdTable antdThemeTable"
				dataSource={data}
				scroll={{ x: true }}
				columns={[
					{
						title: "Name",
						dataIndex: "name",
						key: "name",
						width: "",
						render: (_, data) => {
							return data?.first_name + " " + data?.last_name;
						},
					},
					{
						title: t("table.email"),
						dataIndex: "email",
						key: "email",
					},
					{
						title: "Contact Number",
						dataIndex: "number",
						key: "number",
						render: (_, data) => {
							const country_code = data?.country_code;
							const phone_no = data?.phone_no;
							return `+ ${country_code} ${phone_no}`;
						},
					},
					{
						title: "Address",
						dataIndex: "address",
						key: "address",
						render: (_, data) => {
							return data?.["address.city"] + ", " + data?.["address.state"] + ", " + data?.["address.city"] + ", " + data?.["address.zip"];
						},
					},
					{
						title: "Bondsman License",
						dataIndex: "bondsman_license",
						key: "bondsman_license",
					},
					{
						title: "Experience",
						dataIndex: "experience",
						key: "experience",
					},
					{
						title: "Availability Status",
						dataIndex: "availability_status",
						key: "availability_status",
					},
				]}
			/>
		</div>
	);
};
