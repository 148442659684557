import { FetchBail, FetchListDocument } from "../Admin/Redux/BailRequest";
import { SendBondRequestToBondsman } from "../View/Redux/NearbyBondsman";
import axios from "../helpers/axios";
import { API_URL, catchHandler, dispatchError, dispatchLoading, dispatchToast, elseHandler, elseHandler2 } from "../helpers/utils";

const BOND_URL = API_URL + "/bond";

/**
 * Create / Update Bond Request API Call
 *
 * @param {Boolean} isEdit It is a Edit Request or Create Request
 * @param {Object} data Bond Request Data
 * @param {Number} changeStep Next Step Number
 * @param {Function} navigate Function to navigate user to next page
 * @param {Object} navigate Direct sent to request bondsman data
 * @returns {}
 */
export const CreateBondRequestAPI = (isEdit, data, changeStep, closeBrModals, navigate, bondsmanData) => async (dispatch) => {
	dispatchLoading(dispatch, "bondRequest", true);
	axios
		.put(`${BOND_URL}/request`, data, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				dispatch({
					type: "UPDATE_BOND_SUCCESS",
					payload: result?.data?.data,
				});
				if (!isEdit) {
					dispatch({
						type: "CHANGE_BR_STEP",
						payload: changeStep,
					});
					if (changeStep === 0) {
						dispatch({
							type: "IS_STEPS_ACTIVE",
							payload: false,
						});
						navigate(`/my-case/${result?.data?.data?._id}/request-form`);
					} else if (changeStep === 8) {
						navigate(`/my-case/${result?.data?.data?._id}/review-request`);
					} else if (changeStep === 9) {
						const bondRequestId = result?.data?.data?._id;

						if (bondsmanData) {
							if (bondsmanData?.type === "all") {
								dispatch(
									SendBondRequestToBondsman(
										{
											bond_request_id: bondRequestId,
											all_select: true,
										},
										navigate,
										bondsmanData?.count
									)
								);
							}

							if (bondsmanData?.type === "Selected") {
								dispatch(
									SendBondRequestToBondsman(
										{
											bond_request_id: bondRequestId,
											selected_bondsman: bondsmanData?.list,
										},
										navigate,
										bondsmanData?.count
									)
								);
							}
						} else {
							navigate(`/my-case/${bondRequestId}/submit`);
						}
					}
				} else {
					closeBrModals(dispatch);
				}
				// dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
			} else elseHandler(dispatch, "bondRequest", result?.data);
			dispatchLoading(dispatch, "bondRequest", false);
		})
		.catch(catchHandler(dispatch, "bondRequest"));
};

/**
 * Get Bond request API call
 * @param {Number} page Page number
 * @param {Number} limit Per Page Limit
 * @param {Number} is_active Filter bond request by activity status
 * @param {String} request_number Bail request ID
 * @param {String} search Search string
 * @param {String} status Filter bond request by status
 * @param {String} id _id of Request
 * @returns
 */
export const GetBondRequestList =
	({ page = 1, limit = 10, is_active = 1, request_number, search, status, id }, setModalOpen) =>
	async (dispatch) => {
		dispatchLoading(dispatch, "bondRequest", true);
		const params = {};
		params["page"] = page;
		params["limit"] = limit;
		params["is_active"] = is_active;
		if (request_number) params["request_number"] = request_number;
		if (search) params["search"] = search;
		if (status) params["status"] = status;
		if (id) params["id"] = id;

		axios
			.get(`${BOND_URL}/requests`, {
				params: { ...params },
				withCredentials: true,
			})
			.then((result) => {
				if (result?.data?.success) {
					if (id !== undefined) {
						dispatch({
							type: "CREATE_BOND_SUCCESS",
							payload: result?.data?.data?.data?.[0],
						});
					} else {
						dispatch({
							type: "GET_BOND_LIST_SUCCESS",
							payload: result?.data?.data,
						});
						dispatch({
							type: "GET_BOND_LIST_COUNT",
							payload: result?.data?.data?.status_count,
						});
					}
				} else elseHandler(dispatch, "bondRequest", result?.data);
				dispatchLoading(dispatch, "bondRequest", false);
			})
			.catch(catchHandler(dispatch, "bondRequest"));
	};

/**
 * Delete Incomplete Bond request API call
 * @param {String} id _id of Request
 * @returns
 */
export const DeleteIncomplete = (id, closeModal, setState) => async (dispatch) => {
	dispatchLoading(dispatch, "bondRequest", true);

	axios
		.delete(`${BOND_URL}/request/incomplete`, {
			data: { id },
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				setState && setState();
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				closeModal();
				// dispatch(GetBondRequestList(params));
			} else elseHandler(dispatch, "bondRequest", result?.data);
			dispatchLoading(dispatch, "bondRequest", false);
		})
		.catch(catchHandler(dispatch, "bondRequest"));
};

/**
 * Create Document Requst Modal
 * @param {Object} data Object containing form data object for create request
 * @param {Function} closeModal Function to close the request Modal
 * @returns
 */
export const CreateDocumentRequest = (data, closeModal) => async (dispatch) => {
	dispatchLoading(dispatch, "bondRequest", true);
	axios
		.post(`${BOND_URL}/request/document`, data, {
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				closeModal();
				dispatch(FetchListDocument(result?.data?.data?.[0]?.bond_request_id));
			} else elseHandler(dispatch, "bondRequest", result?.data);
			dispatchLoading(dispatch, "bondRequest", false);
		})
		.catch(catchHandler(dispatch, "bondRequest"));
};

/**
 * Upload Requested Documents
 * @param {Object} Data Form Data of the document
 * @returns
 */
export const UploadDocumentRequest = (data, bond_request_id) => (dispatch) => {
	dispatchLoading(dispatch, "bondRequest", true);
	axios
		.post(`${BOND_URL}/request/document/upload`, data, {
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				dispatch(FetchListDocument(bond_request_id));
				dispatch(ListDocumentRequest(bond_request_id));
			} else elseHandler(dispatch, "bondRequest", result?.data);
			dispatchLoading(dispatch, "bondRequest", false);
		})
		.catch(catchHandler(dispatch, "bondRequest"));
};

/**
 * List Document Requests
 * @param {Object} data Form Data Obj containins id
 * @returns
 */
export const ListDocumentRequest = (data) => (dispatch) => {
	dispatchLoading(dispatch, "bondRequest", true);
	axios
		.get(`${BOND_URL}/request/document`, {
			params: { bond_request_id: data },
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				dispatch({
					type: "LIST_BOND_DOCUMENT_SUCCESS",
					payload: result?.data?.data,
				});
				// dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
			} else elseHandler(dispatch, "bondRequest", result?.data);
			dispatchLoading(dispatch, "bondRequest", false);
		})
		.catch(catchHandler(dispatch, "bondRequest"));
};

/**
 * Delete Upload Document Request
 * @param {object} data Object Containing Id of Document Request
 * @param {Function} modalClose Function to close modal popup
 * @returns {}
 */
export const DeleteUploadedDocumentRequest = (data, modalClose) => (dispatch) => {
	dispatchLoading(dispatch, "bondRequest", true);
	axios
		.delete(`${BOND_URL}/request/document`, {
			data: { id: data?.id },
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				dispatch(FetchListDocument(data?.bond_request_id));
				dispatch(ListDocumentRequest(data?.bond_request_id));
				modalClose();
			} else elseHandler(dispatch, "bondRequest", result?.data);
			dispatchLoading(dispatch, "bondRequest", false);
		})
		.catch(catchHandler(dispatch, "bondRequest"));
};

/**
 * Delete Uploaded Document
 * @param {object} data Object Containing Document & Bond Id
 * @param {Function} modalClose Function to close modal popup
 * @returns {}
 */
export const DeleteUploadedDocument = (data, modalClose) => (dispatch) => {
	dispatchLoading(dispatch, "bondRequest", true);
	axios
		.delete(`${BOND_URL}/request/document/upload`, {
			data: {
				docs_request_id: data?.docs_request_id,
				bond_request_id: data?.bond_request_id,
			},
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
				dispatch(FetchListDocument(data?.bond_request_id));
				dispatch(ListDocumentRequest(data?.bond_request_id));
				modalClose();
			} else elseHandler(dispatch, "bondRequest", result?.data);
			dispatchLoading(dispatch, "bondRequest", false);
		})
		.catch(catchHandler(dispatch, "bondRequest"));
};

/**
 * Cancel Bond Request
 * @param {string} id Id of Bond Request
 * @param {Function} modalClose Function to close Modal Popup
 * @returns {}
 */
export const CancelBondRequest = (id, modalClose) => (dispatch) => {
	dispatchLoading(dispatch, "BondRequest", true);
	axios
		.put(`${BOND_URL}/request/cancel`, { id: id }, { withCredentials: true })
		.then((result) => {
			if (result?.data?.success) {
				modalClose();
				dispatch(GetBondRequestList({ id: id }));
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
			} else elseHandler(dispatch, "bondRequest", result?.data);
			dispatchLoading(dispatch, "bondRequest", false);
		})
		.catch(catchHandler(dispatch, "bondRequest"));
	dispatchLoading(dispatch, "BondRequest", false);
};

export const RemoveBondsman = (data, modalClose) => (dispatch) => {
	dispatchLoading(dispatch, "removeBondsman", true);
	axios
		.delete(`${API_URL}/bond/request/bids/approve`, {
			data,
			withCredentials: true,
		})
		.then((result) => {
			if (result?.data?.success) {
				modalClose();
				dispatchToast(dispatch, "success", result?.data?.msg);
				dispatchError(dispatch, "auth", undefined);
			} else elseHandler(dispatch, "removeBondsman", result?.data);
			dispatchLoading(dispatch, "removeBondsman", false);
		})
		.catch(catchHandler(dispatch, "removeBondsman"));
};
