// Image
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, DatePicker, Image, Input, Modal, Progress, Rate, Table, Upload, message } from "antd";
import { Formik, Form, FieldArray, Field } from "formik";
import dayjs from "dayjs";
import Dragger from "antd/es/upload/Dragger";
import PropTypes from "prop-types";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";

// Helper
import {
  API_ACCESS_TOKEN,
  API_URL,
  BtnLoader,
  PROFILE_URL,
  catchHandler,
  dayjsDateTimeFormat,
  dispatchError,
  dispatchLoading,
  dispatchToast,
  elseHandler,
  formatPhoneNumber,
  momentDDMMYY,
  momentDDMMYYAMPM,
} from "../../helpers/utils";

// Redux
import { EditBondsmanStatus } from "../../Admin/Redux/Bondsman";
import { CreateBondRequestAPI, CreateDocumentRequest, GetBondRequestList } from "../../Redux/bondRequest";
import PostalCode from "../postalCode";

// Components
import {
  BailAmountTitle,
  BondRelation,
  ContactNumber,
  ContactNumber2,
  FooterButtonAntdModal,
  FullAddressString,
  FullName,
  PrivacyTooltip,
  RelationTitle,
  RenderTable,
  SelectBailCard,
  UploaderPreviewPdf,
  round,
} from "../Compoents";
import { FormikCheckbox, FormikInput, FormikTextArea, SearchableSelect, SelectDropDown, convertTimeZone } from "../Input";

// Validation
import {
  WriteReviewValidation,
  arrestLocationValidation,
  bailAmountEditBondsmanValidation,
  bailAmountValidation,
  bailInformationEditValidation,
  bailTermsValidation,
  caseInformationValidation,
  contactInformationValidation,
  documentSchema,
  emergencySupportNetworkValidation,
  reasonForRejectValidation,
  relationBondDetailValidation,
  termNotSetBailInfoValidation,
} from "../../helpers/schema/authSchema";

// Image
import svg from "../../Assets/svg";
import Close from "../../Assets/Images/Icons/Close.svg";
import Avatar from "../../Assets/Images/Icons/Avatar.svg";
import Logo from "../../Assets/Images/logo.png";

// Helper
import { contractESignValidation, eSignCourtValidation } from "../../helpers/schema/contractSchema";
import { CourtNames, DetentionCenterData2, PoliceDepartmentList } from "../../helpers/UsDepartmentData";
import { subadminValidationSchema } from "../../helpers/schema/subAdminSchema";
import { AddSubAdmin, EditSubAdmin } from "../../Admin/Redux/SubAdmin";
import { reviewValidationSchema } from "../../helpers/schema/reviewSchema";
import { ZipCodeChangeSchema, companyTaxFormSchema, notificationTemplateSchema, platformFeesSchema } from "../../helpers/schema/profileSchema";
import { UpdateCompanyDetailsAPI, UpdatePlatFormFees } from "../../Redux/auth";
import NewPostalCode from "../postalCode";
import { CreateBondManReview, DeleteBondmanReview, UpdateBondmanReview } from "../../Redux/bondsman";
import { FetchNearbyBondsman, SendBondRequestToBondsman } from "../../View/Redux/NearbyBondsman";
import { EditBondsmanBailAmount } from "../../BondMan/Redux/BailAmount";
import { CreateContract } from "../../BondMan/Redux/Contract";
import { PDFViewer } from "../../BondMan/View/ReviewContract";
import { EditNotificationTemplates } from "../../Redux/Notification";
import type from "../../Assets/type";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const CloseIcon = () => <img src={Close} alt="close" />;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const closeBrModals = (dispatch) => {
  dispatch({
    type: "CHANGE_BR_STEP",
    payload: 0,
  });
  dispatch({
    type: "IS_STEPS_ACTIVE",
    payload: false,
  });
  dispatch({
    type: "EDIT_BR_STEPS",
    payload: false,
  });
  dispatch({
    type: "REQUEST_FOR_CHANGE",
    payload: "",
  });
};

// Step - 1
export const RelationBondModal = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const { isStepActive, currentStep, requestFor } = useSelector((state) => state.bondRequest);
  const { bondRequestLoading } = useSelector((state) => state.loading);

  // State
  const [bondRelation, setBondRelation] = useState("self");
  const [showError, setShowError] = useState(false);

  return (
    <Modal
      open={isStepActive === true && currentStep === 1}
      onOk={() => {
        addHandler();
        dispatch({
          type: "CREATE_BOND_SUCCESS",
          payload: { request_for: bondRelation },
        });
      }}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal maxHeightModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={960}
    >
      <h4 className="antdModalTitle my-3">
        Welcome to Bail Bond Service. <br />
        Are you seeking bail assistance for yourself, a family member or a friend?
      </h4>

      {/*BondRelation Start*/}
      <div className="text-center col-12">
        <BondRelation value={bondRelation} setValue={setBondRelation} name="antd-step-1" />
        <div className="text-center mt-2">
          &nbsp;
          {showError && requestFor === "" && <small className="text-danger">Please select one option from above for bail request</small>}
        </div>
      </div>
      {/*BondRelation End*/}

      <FooterButtonAntdModal
        addTitle={t("button.proceed")}
        addSuperHandler={() => {
          if (requestFor === "") {
            setShowError(true);
          } else {
            dispatch({
              type: "CREATE_BOND_SUCCESS",
              payload: { request_for: bondRelation },
            });
            dispatch({
              type: "CHANGE_BR_STEP",
              payload: 2,
            });
          }
        }}
        loading={bondRequestLoading}
        className={"mb-3 mt-2"}
        addBtnClass={"text-uppercase"}
        backBtnClass={"text-uppercase"}
      />
    </Modal>
  );
};

// Step - 2
export const RelationDetailModal = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // Selectors
  const { isStepActive, currentStep, bondDetails, brStepEdit, requestFor } = useSelector((state) => state.bondRequest);

  const { bondRequestLoading } = useSelector((state) => state.loading);

  const formikRef = useRef();

  const disabledDate = (current) => {
    // Disable dates that are today or in the future
    return current && current >= dayjs().startOf("day");
  };

  return (
    <Modal
      open={isStepActive === true && currentStep === 2}
      onOk={() => {}}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle my-3">
        {/* Relation Bond */}
        <RelationTitle selected={requestFor || bondDetails?.request_for} />
      </h4>

      <div>
        <Formik
          innerRef={formikRef}
          initialValues={{
            name: bondDetails?.full_name || "",
            date: bondDetails?.dob || "",
            toggle: bondDetails?.information_hide || false,
          }}
          validationSchema={relationBondDetailValidation}
          onSubmit={(values) => {
            addHandler();
            const formData = new FormData();
            formData.append("request_for", bondDetails?.request_for);
            formData.append("full_name", values?.name);
            formData.append("dob", values?.date);
            formData.append("information_hide", values?.toggle);
            formData.append("step_completed", 1);
            if (bondDetails?._id) {
              formData.append("id", bondDetails?._id);
            }

            dispatch(CreateBondRequestAPI(brStepEdit, formData, 3, closeBrModals, navigate));
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form className="page-form">
              <div className="row gy-1">
                <FormikInput
                  label={
                    <>
                      {t("input.fullname")}
                      <PrivacyTooltip />
                    </>
                  }
                  type={"text"}
                  value={"name"}
                  placeholder={t("input.pfullname")}
                  className={"col-12 col-md-6"}
                  error={touched.name && errors.name && errors.name}
                />
                <div className="col-12 col-md-6">
                  <label htmlFor={"date"} className="form-label fw-semibold fs-6 text-black">
                    {"Date Of Birth"}
                  </label>

                  <DatePicker
                    onChange={(date, dateString) => {
                      setFieldValue("date", dayjs(date).utc().format());
                    }}
                    className={`form-control form-control-lg antdDatePicker px-11 ${values?.date && values?.date !== "Invalid Date" ? `selected` : ``}`}
                    placeholder={t("input.pdob")}
                    format={"MM/DD/YYYY"}
                    defaultValue={values?.date ? dayjs(values?.date) : null}
                    suffixIcon={svg?.calender}
                    disabledDate={disabledDate}
                  />
                  <small className="text-danger">
                    &nbsp;
                    {errors?.date && touched?.date && errors?.date}
                  </small>
                </div>

                <div className="col-12">
                  <FormikCheckbox
                    type={"checkbox"}
                    label={"Hide my personal information before accepting bid like Name, Contact Number and Email"}
                    name={"toggle"}
                    labelClassName={"d-flex align-items-start mt-0"}
                  />
                </div>
              </div>

              <FooterButtonAntdModal
                addTitle={brStepEdit ? t("button.update") : t("button.next")}
                addSuperHandler={() => {}}
                type={"submit"}
                loading={bondRequestLoading}
                backSuperHandler={() => {
                  brStepEdit
                    ? closeBrModals(dispatch)
                    : dispatch({
                        type: "CHANGE_BR_STEP",
                        payload: currentStep - 1,
                      });
                }}
                className={"mt-4"}
                backTitle={brStepEdit ? false : t("button.back")}
                addBtnClass={`text-uppercase ${brStepEdit ? "mb-3" : ""}`}
                backBtnClass={`text-uppercase`}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

// Step - 3
export const ArrestLocation = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const { isStepActive, currentStep, bondDetails, brStepEdit } = useSelector((state) => state.bondRequest);
  const { bondRequestLoading } = useSelector((state) => state.loading);

  // Use State
  const [addressData, setAddressData] = useState({
    zip_code: "",
    city: "",
    state: "",
  });

  return (
    <Modal
      open={isStepActive === true && currentStep === 3}
      onOk={() => {}}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle my-3">What is the location (city/county) of their arrest? </h4>

      <div>
        <Formik
          initialValues={{
            address1: bondDetails?.address?.address_line_1 || "",
            address2: bondDetails?.address?.address_line_2 || "",
            state: bondDetails?.address?.state || "",
            city: bondDetails?.address?.city || "",
            zip_code: bondDetails?.address?.zip_code || "",
            detention_center: bondDetails?.detention_center || "",
          }}
          validationSchema={arrestLocationValidation}
          onSubmit={(values) => {
            addHandler();

            const formData = new FormData();
            formData.append("id", bondDetails?._id);
            formData.append("step_completed", 2);
            formData.append("address_line_1", values?.address1);
            formData.append("address_line_2", values?.address2);
            formData.append("state", values?.state);
            formData.append("city", values?.city);
            formData.append("zip_code", values?.zip_code);
            formData.append("detention_center", values?.detention_center);
            formData.append("lat", values?.lat || bondDetails?.address?.lat);
            formData.append("long", values?.long || bondDetails?.address?.long);

            dispatch(CreateBondRequestAPI(brStepEdit, formData, 4, closeBrModals));
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className="page-form">
              <div className="row gy-1">
                <FormikInput
                  label={t("input.address1")}
                  type={"text"}
                  value={"address1"}
                  placeholder={t("input.paddress")}
                  className={"col-12 col-md-6"}
                  error={touched.address1 && errors.address1 && errors.address1}
                />

                <FormikInput
                  label={t("input.address2")}
                  type={"text"}
                  value={"address2"}
                  placeholder={t("input.paddress")}
                  className={"col-12 col-md-6"}
                  error={touched.address2 && errors.address2 && errors.address2}
                />

                <FormikInput
                  label={t("input.state")}
                  type={"text"}
                  value={"state"}
                  placeholder={t("input.pstate")}
                  className={"col-12 col-md-6"}
                  fieldValue={addressData?.state}
                  isDisabled={true}
                  error={touched.state && errors.state && errors.state}
                />

                <FormikInput
                  label={t("input.city")}
                  type={"text"}
                  value={"city"}
                  placeholder={t("input.pcity")}
                  className={"col-12 col-md-6"}
                  fieldValue={addressData?.city}
                  isDisabled={true}
                  error={touched.city && errors.city && errors.city}
                />

                <div className="col-12">
                  <label className="form-label fw-semibold fs-6 text-black">{t("input.postalcode")}</label>
                  <PostalCode defaultValue={values?.zip_code} setAddressData={setAddressData} setFieldValue={setFieldValue} />
                  <small className="text-danger">
                    &nbsp;
                    {touched.zip_code && errors.zip_code && errors.zip_code}
                  </small>
                </div>
                {/* <SelectDropDown
                                    divClass={"col-12 col-lg-12"}
                                    dataList={DetensionCenterData}
                                    dataId={"detention_center"}
                                    setFieldValue={setFieldValue}
                                    labelText={t("input.detentioncenter")}
                                    error={
                                        touched.detention_center &&
                                        errors.detention_center &&
                                        errors.detention_center
                                    }
                                    initialValue={values?.detention_center}
                                /> */}
                <SearchableSelect
                  divClass={"col-12 col-lg-12"}
                  dataList={DetentionCenterData2}
                  dataId={"detention_center"}
                  setFieldValue={setFieldValue}
                  labelText={t("input.detentioncenter")}
                  error={touched.detention_center && errors.detention_center && errors.detention_center}
                  initialValue={values?.detention_center}
                />
              </div>

              <FooterButtonAntdModal
                addTitle={brStepEdit ? t("button.update") : t("button.next")}
                addSuperHandler={() => {}}
                type={"submit"}
                loading={bondRequestLoading}
                backSuperHandler={() => {
                  brStepEdit
                    ? closeBrModals(dispatch)
                    : dispatch({
                        type: "CHANGE_BR_STEP",
                        payload: currentStep - 1,
                      });
                }}
                backTitle={brStepEdit ? false : t("button.back")}
                addBtnClass={`text-uppercase ${brStepEdit ? "mb-3" : ""}`}
                backBtnClass={"text-uppercase"}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

// Step - 4
export const BailAmount = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const { isStepActive, currentStep, bondDetails, brStepEdit, requestFor } = useSelector((state) => state.bondRequest);
  const { bondRequestLoading } = useSelector((state) => state.loading);

  return (
    <Modal
      open={isStepActive === true && currentStep === 4}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="antdModalTitle my-3">
        <BailAmountTitle selected={requestFor} />
      </h4>

      <div>
        <Formik
          initialValues={{
            bailAmount: "",
          }}
          validationSchema={bailAmountValidation}
          onSubmit={(values) => {
            const formData = new FormData();

            formData.append("step_completed", 3);
            formData.append("id", bondDetails?._id);
            {
              values?.bailAmount && formData.append("bail_amount", values?.bailAmount);
            }

            dispatch(CreateBondRequestAPI(brStepEdit, formData, 5, closeBrModals));
          }}
        >
          {({ errors, touched, setFieldTouched }) => (
            <Form className="page-form">
              <div className="row">
                <FormikInput
                  label={t("input.bailAmount")}
                  type={"number"}
                  value={"bailAmount"}
                  placeholder={t("input.pbailAmount")}
                  error={touched.bailAmount && errors.bailAmount && errors.bailAmount}
                  contactField={<span className="countryCode position-absolute">$</span>}
                  inputClassName="pl-3_5"
                  className={"col-12 col-md-8 m-md-auto"}
                />
              </div>

              <FooterButtonAntdModal
                addTitle={brStepEdit ? t("button.update") : t("button.next")}
                addSuperHandler={() => {}}
                type={"submit"}
                loading={bondRequestLoading}
                backTitle={t("button.back")}
                className={"mt-2"}
                backSuperHandler={() => {
                  brStepEdit
                    ? closeBrModals(dispatch)
                    : dispatch({
                        type: "CHANGE_BR_STEP",
                        payload: currentStep - 1,
                      });
                }}
                skipButton={
                  <>
                    <span className="mx-2">|</span>
                    <Button
                      className={`btn btn-transparent btn-lg antdButton backHandler fw-semibold f16 txt-error text-uppercase`}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch({
                          type: "CHANGE_BR_STEP",
                          payload: currentStep + 1,
                        });
                        setFieldTouched("bailAmount", false);
                      }}
                    >
                      {t("button.skip")}
                    </Button>
                  </>
                }
                addBtnClass={"text-uppercase"}
                backBtnClass={"text-uppercase"}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

// Step - 5 :
export const BailTerms = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const { isStepActive, currentStep, bondDetails, brStepEdit } = useSelector((state) => state.bondRequest);
  const { bondRequestLoading } = useSelector((state) => state.loading);

  // Use State
  const [isTermsSet, setIsTermsSet] = useState(true);

  return (
    <Modal
      open={isStepActive === true && currentStep === 5}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="antdModalTitle my-3">{t("modal.bailTerms.title")}</h4>

      <div>
        <Formik
          initialValues={{
            bailTerms: "true",
            bailCondition: "",
          }}
          validationSchema={isTermsSet ? bailTermsValidation : ""}
          onSubmit={(values) => {
            const bailTerms = values?.bailTerms === "true" ? true : false;
            const formData = new FormData();
            formData.append("id", bondDetails?._id);
            formData.append("step_completed", 4);
            formData.append("bail_terms", bailTerms);
            formData.append("restrictions", values?.bailCondition);
            dispatch(CreateBondRequestAPI(brStepEdit, formData, 6, closeBrModals));
          }}
        >
          {({ errors, touched, values, setFieldValue, handleChange }) => (
            <Form className="page-form">
              <div className="row">
                <div className="d-sm-flex align-items-center justify-content-between mb-3 bailTerms">
                  <label htmlFor={t("modal.bailTerms.terms")} className="form-label fw-semibold fs-6 text-black mb-0 me-2">
                    {t("modal.bailTerms.terms")}
                  </label>
                  <div role="group" aria-labelledby="my-radio-group" className="d-flex gap-4 align-items-center">
                    <label className="d-flex align-items-center gap-1 f16">
                      <Field
                        type="radio"
                        name="bailTerms"
                        value={"true"}
                        className="radioButtonCondition"
                        onChange={(e) => {
                          handleChange(e);
                          // Set the value of the other field when "One" is selected
                          if (e.target.value === "true") {
                            setIsTermsSet(true);
                          }
                        }}
                      />
                      <span>{`Yes`}</span>
                    </label>
                    <label className="d-flex align-items-center gap-1 f16">
                      <Field
                        type="radio"
                        name="bailTerms"
                        value={"false"}
                        className="radioButtonCondition"
                        onChange={(e) => {
                          handleChange(e);

                          if (e.target.value === "false") {
                            setIsTermsSet(false);
                            setFieldValue("bailCondition", "");
                          }
                        }}
                      />
                      <span>{`No`}</span>
                    </label>
                  </div>
                </div>

                <FormikTextArea
                  label={t("modal.bailTerms.conditions")}
                  type={"text"}
                  value={"bailCondition"}
                  placeholder={t("input.bailTermsPlaceholder")}
                  className={"mb-2"}
                  error={isTermsSet && touched.bailCondition && errors.bailCondition && errors.bailCondition}
                  disabled={values?.bailTerms !== "true"}
                />
              </div>

              <FooterButtonAntdModal
                addTitle={brStepEdit ? t("button.update") : t("button.next")}
                addSuperHandler={() => {}}
                type={"submit"}
                loading={bondRequestLoading}
                backTitle={t("button.back")}
                backSuperHandler={() => {
                  brStepEdit
                    ? closeBrModals(dispatch)
                    : dispatch({
                        type: "CHANGE_BR_STEP",
                        payload: currentStep - 1,
                      });
                }}
                addBtnClass={"text-uppercase"}
                backBtnClass={"text-uppercase"}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

// Step - 6 :
export const ContactInformationModal = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const { isStepActive, currentStep, bondDetails, brStepEdit } = useSelector((state) => state.bondRequest);
  const { bondRequestLoading } = useSelector((state) => state.loading);

  return (
    <Modal
      open={isStepActive === true && currentStep === 6}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle my-3">
        <div className="d-inline-block">
          <span>
            {t("modal.contactInfo.title")}
            {bondDetails?.request_for === "self" && <span className="f20 fw-light"> {t("modal.contactInfo.titleAdd")}</span>}
            <span> {t("modal.contactInfo.titleConct")}</span>{" "}
          </span>
          <PrivacyTooltip />
        </div>
      </h4>

      <div>
        <Formik
          initialValues={{
            contactNumber: bondDetails?.contact_info?.contact_number || "",
            email: bondDetails?.contact_info?.email || "",
          }}
          validationSchema={contactInformationValidation}
          onSubmit={(values) => {
            const formData = new FormData();
            formData.append("id", bondDetails?._id);
            formData.append("step_completed", 5);
            formData.append("contact_number", values?.contactNumber);
            formData.append("country_code", "+1");
            formData.append("email", values?.email);
            dispatch(CreateBondRequestAPI(brStepEdit, formData, 7, closeBrModals));
          }}
        >
          {({ errors, touched }) => (
            <Form className="page-form">
              <div className="row">
                <FormikInput
                  label={t("input.contact")}
                  type={"number"}
                  value={"contactNumber"}
                  placeholder={t("input.pcontact")}
                  error={touched.contactNumber && errors.contactNumber && errors.contactNumber}
                  contactField={<span className="countryCode position-absolute">+1</span>}
                  inputClassName="pl-3_5"
                  className={"col-12 col-md-6"}
                />

                <FormikInput
                  label={t("input.email")}
                  type={"email"}
                  value={"email"}
                  placeholder={t("input.pemail")}
                  error={touched.email && errors.email && errors.email}
                  className={"col-12 col-md-6"}
                />
              </div>

              <FooterButtonAntdModal
                addTitle={brStepEdit ? t("button.update") : t("button.next")}
                addSuperHandler={() => {}}
                type={"submit"}
                loading={bondRequestLoading}
                className={"mt-2"}
                backSuperHandler={() => {
                  brStepEdit
                    ? closeBrModals(dispatch)
                    : dispatch({
                        type: "CHANGE_BR_STEP",
                        payload: currentStep - 1,
                      });
                }}
                backTitle={brStepEdit ? false : t("button.back")}
                addBtnClass={`text-uppercase ${brStepEdit ? "mb-3" : ""}`}
                backBtnClass={"text-uppercase"}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

// Step - 7
export const EmergencySupportNetworkModal = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Selectors
  const { isStepActive, currentStep, bondDetails, brStepEdit } = useSelector((state) => state.bondRequest);
  const { bondRequestLoading } = useSelector((state) => state.loading);

  return (
    <Modal
      open={isStepActive === true && currentStep === 7}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle my-3">
        <div className="d-inline-block">
          <span> {t("modal.emergency")}</span> <PrivacyTooltip className={"mt--1"} />
        </div>
      </h4>

      <div>
        <Formik
          initialValues={{
            name: bondDetails?.emergency_contact_info?.name || "",
            relation: bondDetails?.emergency_contact_info?.relation || "",
            contactNumber: bondDetails?.emergency_contact_info?.contact_number || "",
            notes: bondDetails?.emergency_contact_info?.additional_note || "",
          }}
          validationSchema={emergencySupportNetworkValidation}
          onSubmit={(values) => {
            dispatch({
              type: "IS_STEPS_ACTIVE",
              payload: false,
            });
            const formData = new FormData();
            formData.append("step_completed", 6);
            formData.append("id", bondDetails?._id);
            formData.append("name", values?.name);
            formData.append("relation", values?.relation);
            formData.append("contact_number", values?.contactNumber);
            formData.append("country_code", "+1");
            formData.append("additional_note", values?.notes);
            dispatch(CreateBondRequestAPI(brStepEdit, formData, 0, closeBrModals, navigate));
          }}
        >
          {({ errors, touched }) => (
            <Form className="page-form">
              <div className="row gy-1">
                <FormikInput
                  label={t("input.ename")}
                  type={"text"}
                  value={"name"}
                  placeholder={t("input.pename")}
                  error={touched.name && errors.name && errors.name}
                  className={"col-12 col-md-6"}
                />

                <FormikInput
                  label={t("input.relation")}
                  type={"text"}
                  value={"relation"}
                  placeholder={t("input.prelation")}
                  error={touched.relation && errors.relation && errors.relation}
                  className={"col-12 col-md-6"}
                />

                <FormikInput
                  label={t("input.econtact")}
                  type={"number"}
                  value={"contactNumber"}
                  placeholder={t("input.pecontact")}
                  error={touched.contactNumber && errors.contactNumber && errors.contactNumber}
                  contactField={<span className="countryCode position-absolute">+1</span>}
                  inputClassName="pl-3_5"
                  className={"col-12 col-md-6"}
                />

                <FormikInput
                  label={t("input.notes")}
                  type={"text"}
                  value={"notes"}
                  placeholder={t("input.pnotes")}
                  error={touched.notes && errors.notes && errors.notes}
                  className={"col-12 col-md-6"}
                />
              </div>

              <FooterButtonAntdModal
                addTitle={brStepEdit ? t("button.update") : t("button.submit")}
                addSuperHandler={() => {}}
                type={"submit"}
                loading={bondRequestLoading}
                className={"mt-2"}
                backSuperHandler={(e) => {
                  brStepEdit
                    ? closeBrModals(dispatch)
                    : dispatch({
                        type: "CHANGE_BR_STEP",
                        payload: currentStep - 1,
                      });
                }}
                backTitle={brStepEdit ? false : t("button.back")}
                addBtnClass={`text-uppercase ${brStepEdit ? "mb-3" : ""}`}
                backBtnClass={"text-uppercase"}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

// Step - 8
export const CaseInformationEditModal = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Selectors
  const { isStepActive, currentStep, bondDetails, brStepEdit } = useSelector((state) => state.bondRequest);
  const { bondRequestLoading } = useSelector((state) => state.loading);

  return (
    <Modal
      open={isStepActive === true && currentStep === 8}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal maxHeightModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle my-3">Case Information</h4>

      <div>
        <Formik
          initialValues={{
            police_department: bondDetails?.police_department,
            booking_number: bondDetails?.booking_number,
            court_name: bondDetails?.court_name,
            case_number: bondDetails?.case_number,
            attorney_detail: bondDetails?.attorney_detail,
            court_date_time: bondDetails?.court_date_time,
            request_nature: bondDetails?.request_nature,
            request_reason: bondDetails?.request_reason,
            additional_comments: bondDetails?.additional_comments,
          }}
          validationSchema={caseInformationValidation}
          onSubmit={(values) => {
            const formData = new FormData();
            formData.append("step_completed", 7);

            formData.append("id", bondDetails?._id);

            formData.append("police_department", values?.police_department);

            formData.append("booking_number", values?.booking_number);

            formData.append("court_name", values?.court_name);

            formData.append("case_number", values?.case_number);

            formData.append("attorney_detail", values?.attorney_detail);

            formData.append("court_date_time", values?.court_date_time);

            formData.append("request_nature", values?.request_nature);

            formData.append("request_reason", values?.request_reason);

            formData.append("additional_comments", values?.additional_comments);

            dispatch(CreateBondRequestAPI(brStepEdit, formData, 7, closeBrModals));
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form className="page-form">
              <div className="row gy-1">
                <SelectDropDown
                  divClass={"col-12 col-lg-6"}
                  dataList={PoliceDepartmentList}
                  dataId={"police_department"}
                  setFieldValue={setFieldValue}
                  labelText={t("input.police_department")}
                  error={touched.police_department && errors.police_department && errors.police_department}
                  initialValue={values?.police_department}
                />

                <FormikInput
                  label={t("input.booking_number")}
                  type={"text"}
                  value={"booking_number"}
                  placeholder={t("input.pbooking_number")}
                  error={touched.booking_number && errors.booking_number && errors.booking_number}
                  className={"col-12 col-lg-6"}
                />

                <SelectDropDown
                  divClass={"col-12 col-lg-6"}
                  dataList={CourtNames}
                  dataId={"court_name"}
                  setFieldValue={setFieldValue}
                  labelText={t("input.court_name")}
                  error={touched.court_name && errors.court_name && errors.court_name}
                  initialValue={values?.court_name}
                />

                <FormikInput
                  label={t("input.case_number")}
                  type={"text"}
                  value={"case_number"}
                  placeholder={t("input.pcase_number")}
                  error={touched.case_number && errors.case_number && errors.case_number}
                  className={"col-12 col-lg-6"}
                />

                <FormikInput
                  label={t("input.attorney_detail")}
                  type={"text"}
                  value={"attorney_detail"}
                  placeholder={t("input.pattorney_detail")}
                  error={touched.attorney_detail && errors.attorney_detail && errors.attorney_detail}
                  className={"col-12 col-lg-6"}
                />

                <div className={"col-12 col-lg-6"}>
                  <label htmlFor={"date"} className="form-label fw-semibold fs-6 text-black">
                    {t("input.court_date_time")}
                  </label>
                  <DatePicker
                    onChange={(date, dateString) => {
                      setFieldValue("court_date_time", dayjs(date).utc().format());
                    }}
                    showTime={true}
                    className={`form-control form-control-lg antdDatePicker px-11 ${values?.court_date_time && values?.court_date_time !== "Invalid Date" ? `selected` : ``}`}
                    placeholder={t("input.pdob")}
                    disabledDate={(currentDate) => {
                      return dayjs().isAfter(currentDate);
                    }}
                    // format={"DD/MM/YYYY"}
                    format={dayjsDateTimeFormat}
                    suffixIcon={svg?.calender}
                    defaultValue={dayjs(values?.court_date_time)}
                  />
                  <small className="text-danger">
                    &nbsp;
                    {errors?.court_date_time && touched?.court_date_time && errors?.court_date_time}
                  </small>
                </div>

                <FormikTextArea
                  label={t("input.request_reason")}
                  type={"text"}
                  value={"request_reason"}
                  placeholder={t("input.prequest_reason")}
                  className={"col-12"}
                  error={touched.request_reason && errors.request_reason && errors.request_reason}
                />

                <FormikTextArea
                  label={t("input.request_nature")}
                  type={"text"}
                  value={"request_nature"}
                  placeholder={t("input.prequest_nature")}
                  className={"col-12"}
                  error={touched.request_nature && errors.request_nature && errors.request_nature}
                />

                <FormikTextArea
                  label={t("input.additional_comments")}
                  type={"text"}
                  value={"additional_comments"}
                  placeholder={t("input.padditional_comments")}
                  className={"col-12"}
                  error={touched.additional_comments && errors.additional_comments && errors.additional_comments}
                />
              </div>
              <FooterButtonAntdModal
                addTitle={t("button.update")}
                addSuperHandler={() => {}}
                type={"submit"}
                loading={bondRequestLoading}
                // backTitle={t("button.back")}
                className={"mt-2"}
                backSuperHandler={(e) => {
                  closeBrModals(dispatch);
                }}
                addBtnClass={"text-uppercase mb-3"}
                backBtnClass={"text-uppercase"}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

// Step - 9
export const BailInformationEditModal = ({ addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const { isStepActive, currentStep, bondDetails, brStepEdit } = useSelector((state) => state.bondRequest);
  const { bondRequestLoading } = useSelector((state) => state.loading);

  // Use State
  const [isTermsSet, setIsTermsSet] = useState(bondDetails?.bail_terms);

  return (
    <Modal
      open={isStepActive === true && currentStep === 9}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
        closeBrModals(dispatch);
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="antdModalTitle my-3">Bail Information</h4>
      <div>
        <Formik
          initialValues={{
            bailAmount: bondDetails?.bail_amount || bondDetails?.preferred_bond_amount,
            bailCondition: bondDetails?.restrictions,
            bailTerms: bondDetails?.bail_terms ? "true" : "false",
          }}
          validationSchema={isTermsSet ? bailInformationEditValidation : termNotSetBailInfoValidation}
          onSubmit={(values) => {
            if (values?.bailAmount) {
              const bailData = new FormData();
              bailData.append("id", bondDetails?._id);
              bailData.append("step_completed", 3);
              bailData.append("bail_amount", values?.bailAmount);
              dispatch(CreateBondRequestAPI(brStepEdit, bailData, 5, closeBrModals));
            }

            const formData = new FormData();

            formData.append("step_completed", 4);
            formData.append("id", bondDetails?._id);
            formData.append("bail_terms", values?.bailTerms);
            formData.append("restrictions", values?.bailCondition);

            dispatch(CreateBondRequestAPI(brStepEdit, formData, 5, closeBrModals));
          }}
        >
          {({ errors, touched, values, setFieldValue, handleChange }) => (
            <Form className="page-form">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="d-sm-flex align-items-center justify-content-between mb-3 bailTerms">
                    <label htmlFor={t("modal.bailTerms.terms")} className="form-label fw-semibold fs-6 text-black mb-0 me-2">
                      {t("modal.bailTerms.terms")}
                    </label>
                    <div role="group" aria-labelledby="my-radio-group" className="d-flex gap-4 align-items-center">
                      <label className="d-flex align-items-center gap-1 f16">
                        <Field
                          type="radio"
                          name="bailTerms"
                          value={"true"}
                          className="radioButtonCondition"
                          onChange={(e) => {
                            handleChange(e);
                            // Set the value of the other field when "One" is selected
                            if (e.target.value === "true") {
                              setIsTermsSet(true);
                            }
                          }}
                        />
                        <span>{`Yes`}</span>
                      </label>
                      <label className="d-flex align-items-center gap-1 f16">
                        <Field
                          type="radio"
                          name="bailTerms"
                          value={"false"}
                          className="radioButtonCondition"
                          onChange={(e) => {
                            handleChange(e);

                            if (e.target.value === "false") {
                              setIsTermsSet(false);
                            }
                          }}
                        />
                        <span>{`No`}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <FormikInput
                  label={t("input.bailAmount")}
                  type={"text"}
                  value={"bailAmount"}
                  placeholder={t("input.pbailAmount")}
                  error={touched.bailAmount && errors.bailAmount && errors.bailAmount}
                  contactField={<span className="countryCode position-absolute">$</span>}
                  inputClassName="pl-3_5"
                  className={"col-12"}
                />

                <FormikTextArea
                  disabled={values?.bailTerms === "false" ? true : false}
                  label={t("modal.bailTerms.conditions")}
                  type={"text"}
                  value={"bailCondition"}
                  placeholder={t("input.bailTermsPlaceholder")}
                  error={touched.bailCondition && errors.bailCondition && errors.bailCondition}
                />
              </div>
              <FooterButtonAntdModal
                addTitle={t("button.update")}
                addSuperHandler={() => {}}
                type={"submit"}
                loading={bondRequestLoading}
                className={"mt-2"}
                backSuperHandler={() => {
                  closeBrModals(dispatch);
                }}
                addBtnClass={"text-uppercase mb-3"}
                backBtnClass={"text-uppercase"}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

// Delete Conformation
export const DeleteModal = ({
  modalOpen,
  cancelSuperHandler,
  title,
  description,
  deleteTitle,
  deleteHandler,
  closeTitle,
  loader,
  svg,
  closeTitleClass,
  deleteTitleClass,
  successTitleClass,
  successHandler,
  successTitle,
}) => {
  return (
    <Modal
      open={modalOpen}
      onOk={cancelSuperHandler}
      onCancel={cancelSuperHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <div className="text-center txt-dark">
        <div className="mt-3 antdSVG">{svg}</div>
        <h3 className="antdModalTitle mt-3 mw-100">{title}</h3>
        {description && <p className="txt-gray mt-2 ff_md">{description}</p>}
        <div className="text-center d-flex align-items-center justify-content-center gap-3 mt-3">
          {closeTitle && (
            <div>
              <Button className={`antdButton text-white ff c-grey h46 fw-bolder px-4 text-uppercase ${closeTitleClass}`} onClick={cancelSuperHandler} disabled={loader}>
                {closeTitle}
              </Button>
            </div>
          )}

          {deleteTitle && (
            <div>
              <Button
                className={`antdButton c-warning text-white ff h46 fw-bolder px-4 text-uppercase ${deleteTitleClass}`}
                onClick={deleteHandler}
                loading={loader}
                disabled={loader}
              >
                {deleteTitle}
              </Button>
            </div>
          )}

          {successTitle && (
            <div>
              <Button
                className={`antdButton c-success text-white ff h46 fw-bolder px-4 text-uppercase ${successTitleClass}`}
                onClick={successHandler}
                loading={loader}
                disabled={loader}
              >
                {successTitle}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

// Bondsman Working Hours
export const WorkingHoursModal = ({ modalOpen, cancelSuperHandler, data }) => {
  // Translation
  const { t } = useTranslation();

  return (
    <Modal
      open={modalOpen}
      onOk={cancelSuperHandler}
      onCancel={cancelSuperHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="antdModalTitle mb-3">{t("pages.bondsmanDetail.workingHrs")}</h4>
      <div className="row gy-2">
        {data?.map((entry, index) => (
          <React.Fragment key={index}>
            <div className="col-4 fw-medium">{`${entry?.day} :`}</div>
            <div className="col-8 fw-bolder">
              {entry?.hours?.map((hourEntry, hourIndex) => (
                <div className="fw-medium f14" key={hourIndex}>
                  {`${hourEntry?.open_hour} - ${hourEntry?.close_hour}`}
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </Modal>
  );
};

// Accept Bids Success Modal
export const AcceptBidModal = ({ modalOpen, cancelSuperHandler, value, condition }) => {
  // Translation
  const { t } = useTranslation();

  // Navigate
  const navigate = useNavigate();

  // Dispatch
  const dispatch = useDispatch();

  const { bondDetails } = useSelector((state) => state.bondRequest);

  // Dummy
  const data = {
    imgUrl: value?.bondsman_id?.photo_thumb ? value?.bondsman_id?.photo_thumb : value?.bondsman_id?.photo,
    name: value ? `${value?.bondsman_id?.first_name} ${value?.bondsman_id?.last_name}` : "",
    location: FullAddressString(value?.bondsman_id),
  };

  const closeHandler = () => {
    if (condition) {
      navigate(`/my-case/${value?.bond_request_id}/request-detail`);
    } else {
      cancelSuperHandler();
    }
  };

  return (
    <Modal
      open={modalOpen}
      onOk={cancelSuperHandler}
      onCancel={closeHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <div className="text-center">
        <div className="mt-3 antdSVG">{svg?.acceptBidIcon}</div>

        <h3 className="antdModalTitle mt-3">{t("pages.bondsmanDetail.bAccept")}</h3>

        <div className="d-flex justify-content-center align-items-center text-black mt-3">
          <div className="flex-shrink-0 text-center">
            <Image
              src={data?.imgUrl ? `${PROFILE_URL}/${data?.imgUrl}` : Avatar}
              className="img-fluid rounded-circle"
              preview={<img src={data?.imgUrl ? `${PROFILE_URL}/${data?.imgUrl}` : Avatar} alt="Profile" />}
              alt="bondman-image"
              style={{
                height: "50px",
                width: "50px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="flex-grow ms-sm-2 mt-3 mt-sm-0 text-start">
            <h3 className="fw-bolder mb-1 f20">{data?.name}</h3>
            <div className="d-flex align-items-center">
              {svg?.location}
              <p className="f14 ms-1">{data?.location}</p>
            </div>
          </div>
        </div>

        <p className="txt-gray mt-3">{t("pages.bondsmanDetail.bDescription")}</p>

        <div className="my-3 text-center">
          <div
            className="btn c-success text-uppercase fw-semibold fs-6 px-2 px-md-4 txt-white bidAmount h46 d-inline-flex align-items-center"
            onClick={() => {
              navigate(`/chat`);
              dispatch({
                type: "HANDLE_ACTIVE_REQUEST_NUMBER",
                payload: bondDetails?.request_number,
              });
            }}
          >
            {svg?.chating}
            <span className="ms-2">{t("button.startChat")}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// Payment Successful Modal
export const PaymentStatusModal = ({ modalOpen, cancelSuperHandler, data, caseId }) => {
  // Translation
  const { t } = useTranslation();

  return (
    <Modal
      open={modalOpen}
      onOk={cancelSuperHandler}
      onCancel={cancelSuperHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={600}
    >
      <div className="text-center">
        <div className="mt-3 antdSVG">{data?.isSuccess ? svg?.acceptBidIcon : svg?.paymentFail}</div>

        <h3 className="antdModalTitle mt-3">{data?.isSuccess ? t("pages.caseDetail.paymentSuccess") : t("pages.caseDetail.paymentFail")}</h3>

        <p className="txt-gray mt-3">{data?.isSuccess ? t("pages.caseDetail.paymentSuccessDescription") : t("pages.caseDetail.paymentFailDescription")}</p>

        <p className="mt-2">
          {`${t("pages.caseDetail.transactionId")}: `}
          <span className="fw-medium">{data?.tid}</span>
        </p>

        <p className="mt-2">
          {`${t("pages.caseDetail.bondsmanName")}: `}
          <span className="fw-medium">{data?.name}</span>
        </p>

        <p className="mt-2">
          {`${t("pages.caseDetail.amount")}: `}
          <span className="fw-medium">${data?.amount}</span>
        </p>

        <div className="my-3 text-center">
          <div className="btn btn-success btn-lg px-2 px-md-4 fw-bold" onClick={cancelSuperHandler}>
            {t("button.ok")}
          </div>
        </div>
      </div>
    </Modal>
  );
};

// Bail Request Details - Completed - Write Review Modal
export const WriteAReview = ({ modalOpen, cancelHandler, bondDetails, modalPurpose, updateData, params }) => {
  // Translation
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reviewLoading } = useSelector((state) => state.loading);

  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState(false);

  const formikRef = useRef();
  const bondsmanName = bondDetails?.bond_request_status?.approved_bondsman?.first_name + " " + bondDetails?.bond_request_status?.approved_bondsman?.last_name;

  const imgUrl = bondDetails?.bond_request_status?.approved_bondsman?.photo
    ? bondDetails?.bond_request_status?.approved_bondsman?.photo_thumb
    : bondDetails?.bond_request_status?.approved_bondsman?.photo;

  useEffect(() => {
    if (modalPurpose === 1) {
      setRating(updateData?.review_rate);
    }
  }, [modalPurpose]);

  return (
    <Modal
      open={modalOpen}
      onOk={cancelHandler}
      onCancel={cancelHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <div className="text-center txt-dark">
        <h3 className="antdModalTitle mt-3">{modalPurpose === 1 ? "Update A Review" : t("pages.bondsmanDetail.writeReview")}</h3>

        <div className="d-flex align-items-center text-black mt-3">
          <div className="flex-shrink-0 text-center">
            <img
              src={`${PROFILE_URL}/${imgUrl}` || Avatar}
              className="img-fluid rounded-circle shadow"
              style={{
                height: "54px",
                width: "54px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="flex-grow ms-sm-2 mt-3 mt-sm-0 text-start">
            <h3 className="fw-bolder mb-1 f20">{bondsmanName}</h3>
            <p className="f16">
              <span className="txt-primary fw-bold">#{bondDetails?.request_number}</span>
              <span className="txt-grey fw-medium ms-2">{momentDDMMYY(convertTimeZone(bondDetails?.updated_at))}</span>
            </p>
          </div>
        </div>

        <hr />

        <div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              rating: updateData?.review_rate || 0,
              review: updateData?.message || "",
            }}
            validationSchema={WriteReviewValidation}
            onSubmit={(values) => {
              const reviewObj = {
                bondsman_id: bondDetails?.bond_request_status?.approved_bondsman?._id,
                client_id: bondDetails?.client_id?._id,
                bond_request_id: bondDetails?._id,
                review_rate: rating,
                message: values?.review,
              };

              const feedBackData = {
                review_id: updateData?._id,
                review_rate: values?.rating,
                message: values?.review,
              };

              if (modalPurpose === 1) {
                dispatch(UpdateBondmanReview({ ...feedBackData }, cancelHandler, false, params));
              } else {
                if (params) {
                  dispatch(CreateBondManReview({ ...reviewObj }, cancelHandler, params));
                } else {
                  dispatch(CreateBondManReview({ ...reviewObj }, cancelHandler));
                }
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <Form className="page-form">
                <div className="mt-3">
                  <Rate
                    value={values.rating}
                    allowHalf
                    className="f40 d-block"
                    style={{ color: "#FBC01B" }}
                    onChange={(e) => {
                      setRating(e);
                      setFieldValue("rating", e);
                    }}
                  />
                  <span className="text-danger d-block mt-1">
                    &nbsp;
                    {touched.rating && errors.rating}
                  </span>
                </div>
                <div className="row">
                  <FormikTextArea
                    label={"Write a review"}
                    type={"text"}
                    value={"review"}
                    placeholder={t("input.typeMessage")}
                    className={"col-12 text-start"}
                    error={touched.review && errors.review && errors.review}
                  />
                </div>

                <FooterButtonAntdModal addTitle={t("button.submit")} addSuperHandler={() => {}} type={"submit"} loading={reviewLoading} addBtnClass={"text-uppercase"} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

// Bail Request Details - Completed - View Review & Replay
export const ReviewPreview = ({ modalOpen, cancelHandler, reviewList, setState, setModalPurpose, setUpdateReviewData, params }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  const { reviewLoading } = useSelector((state) => state.loading);

  const review = reviewList?.[0];

  const image_url = review?.bondsman_id?.photo_thumb
    ? `${PROFILE_URL}/${review?.bondsman_id?.photo_thumb}`
    : review?.bondsman_id?.photo
    ? `${PROFILE_URL}/${review?.bondsman_id?.photo}`
    : Avatar;

  const preview_url = review?.bondsman_id?.photo ? `${PROFILE_URL}/${review?.bondsman_id?.photo}` : Avatar;

  return (
    <Modal
      open={modalOpen}
      onOk={cancelHandler}
      onCancel={cancelHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={576}
    >
      <div className="text-center">
        <h3 className="antdModalTitle mt-3">Review</h3>

        <div className="mt-3">
          <Rate value={review?.review_rate} allowHalf className="f40" style={{ color: "#FBC01B" }} disabled={true} />
        </div>

        <p className="mt-3 text-start f16 fw-medium">{review?.message}</p>

        {review?.bondsman_feedback && (
          <>
            <hr />
            <div className="c-light p-3 text-start replyCss f16">
              <p>{review?.bondsman_feedback}</p>
            </div>
          </>
        )}

        {review?.bondsman_feedback && (
          <div className="d-flex align-items-center text-black mt-2">
            <div className="flex-shrink-0 text-center">
              <Image
                className="img-fluid rounded-circle"
                src={image_url}
                preview={<img src={preview_url} alt="Client Preview" />}
                alt={FullName(review?.bondsman_id)}
                style={{ height: "46px", width: "46px" }}
              />
            </div>
            <div className="flex-grow ms-sm-2 mt-3 mt-sm-0 text-start">
              <h3 className="fw-medium mb-1 f16">{FullName(review?.bondsman_id)}</h3>
              <p className="f12">
                <span className="txt-grey fw-medium">{momentDDMMYY(convertTimeZone(review?.updated_at))}</span>
              </p>
            </div>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-center gap-2 my-3 mt-4">
          <div
            className="btn c-grey text-uppercase fw-semibold fs-6 px-2 px-md-4 txt-white h46"
            onClick={() => {
              setState(true);
              setModalPurpose(1);
              cancelHandler();
              setUpdateReviewData(review);
            }}
          >
            {reviewLoading && <BtnLoader />}
            {t("button.edit")}
          </div>

          <div
            className="btn c-warning text-uppercase fw-semibold fs-6 px-2 px-md-4 txt-white h46"
            onClick={() => {
              dispatch(DeleteBondmanReview(review?._id, cancelHandler, params));
            }}
          >
            {reviewLoading && <BtnLoader />}
            {t("button.delete")}
          </div>
        </div>
      </div>
    </Modal>
  );
};

// Bondsman Modal Starting
// Request Document
export const RequestDocumentModal = ({ modalOpen, cancelHandler, bailId }) => {
  // Use Dispatch
  const dispatch = useDispatch();

  const { bondRequestLoading } = useSelector((state) => state.loading);

  return (
    <Modal
      open={modalOpen}
      onOk={cancelHandler}
      onCancel={cancelHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <Formik
        initialValues={{
          docNames: [{ name: "" }],
        }}
        validationSchema={documentSchema}
        onSubmit={(values, { setSubmitting }) => {
          const namesArray = values?.docNames?.map((item) => item?.name);
          dispatch(CreateDocumentRequest({ bond_request_id: bailId, names: namesArray }, cancelHandler));
          setSubmitting(false);
        }}
      >
        {({ errors, touched, values, setTouched }) => (
          <Form>
            <h3 className="antdModalTitle my-3">Request Documents</h3>
            <FieldArray
              name="docNames"
              render={(arrayHelpers) => (
                <div>
                  {values?.docNames && values?.docNames.length > 0 ? (
                    values?.docNames?.map((docName, index) => (
                      <div key={index}>
                        <FormikInput
                          label={"Required Document"}
                          type={"text"}
                          name={`docNames.${index}.name`}
                          value={`docNames.${index}.name`}
                          placeholder={"Enter Document Name"}
                          error={touched?.docNames?.[index]?.name && errors?.docNames?.[index]?.name}
                          icon={values?.docNames.length !== 1 && svg?.onRemoveDocument}
                          iconClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />

                        <div className={`text-end`}>
                          {index + 1 === values?.docNames.length && (
                            <button
                              onClick={() => {
                                arrayHelpers.insert(values?.docNames.length, {
                                  name: "",
                                });
                              }}
                              className={`btn c-error text-uppercase fw-semibold fs-6 px-3 mt-2 text-white lh-1 fs-1 requestDocumentAdd`}
                              disabled={docName?.name?.length > 0 ? false : true}
                            >
                              +
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <button type="button" onClick={() => arrayHelpers.push("")}>
                      Add a Document name
                    </button>
                  )}
                </div>
              )}
            />
            <div className="text-center">
              <button type="submit" className="btn btn-success text-uppercase fw-semibold fs-6 px-4 text-white h46 my-2" disabled={bondRequestLoading}>
                {bondRequestLoading && <BtnLoader />}
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

// Admin Modal
export const ReasonRejectBondsman = ({ modalOpen, cancelHandler, updateData, loader }) => {
  // Translation
  const { t } = useTranslation();

  // Use
  const dispatch = useDispatch();

  const formikRef = useRef();

  return (
    <Modal
      open={modalOpen}
      onOk={cancelHandler}
      onCancel={cancelHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <div>
        <h3 className="antdModalTitle mt-3">{t("label.reasonReject")}</h3>

        <div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              reason: "",
            }}
            validationSchema={reasonForRejectValidation}
            onSubmit={(values) => {
              dispatch(EditBondsmanStatus(updateData?._id, "rejected", values?.reason, cancelHandler));
            }}
          >
            {({ errors, touched }) => (
              <Form className="page-form mt-3">
                <div className="row">
                  <FormikTextArea
                    label={t("input.rejectReasonLabel")}
                    type={"text"}
                    value={"reason"}
                    placeholder={t("input.prejectReasonLabel")}
                    className={"col-12 text-start"}
                    error={touched.reason && errors.reason && errors.reason}
                  />
                </div>

                <FooterButtonAntdModal addTitle={t("button.submitReject")} addSuperHandler={() => {}} type={"submit"} loading={loader} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

// Bulk Import Bondsman
export const BulkImportBondsMan = ({ modalOpen, modalPurpose, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Use Dispatch
  const dispatch = useDispatch();

  const { bulkBondsmanLoading } = useSelector((state) => state.loading);

  const [error, setError] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [alreadyExist, setAlreadyExist] = useState([]);
  const [invalidData, setInvalidData] = useState([]);

  const [onUploadStart, setOnUploadStart] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (modalPurpose === 0) {
      setAlreadyExist([]);
      setInvalidData([]);
      setError(false);
    }
  }, [modalOpen, modalPurpose]);

  function onFileChange(info) {
    const { status } = info?.file;
    if (status === "removed") {
      setUploadFile(null);
    }
  }

  const props = {
    accept: "text/csv",
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  // const customRequest = async ({ file }) => {
  //     try {
  //         setOnUploadStart("Start");
  //         dispatchLoading(dispatch, "bulkBondsman", true);
  //         const formData = new FormData();
  //         formData.append("file", file);
  //         axios
  //             .post(`https://httpbin.org/post`, API_ACCESS_TOKEN,  {
  //                 withCredentials: true,
  //                 onUploadProgress: (progressEvent) => {
  //                     const percentCompleted = Math.round(
  //                         (progressEvent.loaded * 100) / progressEvent.total
  //                     );
  //                     setUploadProgress(percentCompleted);
  //                 },
  //             })
  //             .then((result) => {
  //                 if (result?.status === 200) {
  //                     setUploadFile(file);
  //                     setOnUploadStart("Stop");
  //                     setError(false);
  //                     dispatchLoading(dispatch, "bulkBondsman", false);
  //                 } else {
  //                     setOnUploadStart("Stop");
  //                     setError(false);
  //                     dispatchLoading(dispatch, "bulkBondsman", false);
  //                 }
  //             });
  //     } catch (err) {}
  // };

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      setOnUploadStart("Start");
      dispatchLoading(dispatch, "bulkBondsman", true);
      const formData = new FormData();
      formData.append("file", file);
      // const response = await fetch(`${API_URL}/admin/bondsman/bulk-add`, {
      //     method: "POST",
      //     headers: {
      //         Authorization: API_ACCESS_TOKEN,
      //     },
      //     body: formData,
      //     onUploadProgress: (progressEvent) => {
      //         const percentCompleted = Math.round(
      //             (progressEvent.loaded * 100) / progressEvent.total
      //         );
      //         setUploadProgress(percentCompleted);
      //     },
      // });

      const response = await axios.post(`${API_URL}/admin/bondsman/bulk-add`, formData, {
        headers: {
          Authorization: API_ACCESS_TOKEN,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      const data = await response.json();

      // Handle success
      if (response.ok) {
        onSuccess();
        setError(false);
        setUploadFile(file);
        setOnUploadStart("Stop");
        setAlreadyExist(data?.data?.alreadyExist);
        setInvalidData(data?.data?.invalidData);
        dispatchLoading(dispatch, "bulkBondsman", false);
        message.success("File uploaded successfully!");
      } else {
        setOnUploadStart("Stop");
        setError(false);
        dispatchLoading(dispatch, "bulkBondsman", false);
        onError();
        message.error(data.message || "File upload failed");
      }
    } catch (error) {
      onError();
      message.error("An error occurred during file upload.");
    }
  };

  const submitHandler = () => {
    if (uploadFile) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onOk={cancelHandler}
      onCancel={cancelHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <div>
        <h3 className="antdModalTitle my-3">{t("pages.bondsmanList.bulkImport")}</h3>

        <div>
          <label className="form-label fw-semibold fs-6 text-black">{t("pages.bondsmanList.chooseFile")}</label>

          <Dragger
            {...props}
            listType="picture"
            maxCount={1}
            className={`antdUpload antdDragger docUpload ${uploadFile && "d-none"}`}
            withCredentials
            onChange={onFileChange}
            customRequest={customRequest}
          >
            <>
              {onUploadStart === "Start" ? (
                <Progress percent={uploadProgress} />
              ) : (
                <div className="">
                  <div>{svg?.fileUpload}</div>
                  <p className="mt-2">
                    {t("pages.caseDetail.DragDrop")} / <span className="txt-error">{t("pages.caseDetail.click")}</span>
                  </p>
                  <p className="mt-0">{t("pages.bondsmanList.uploadInc")}</p>
                </div>
              )}
            </>
          </Dragger>

          {uploadFile && (
            <>
              <div className="antdDragger uploadPreviewBorder p-3 text-center justify-content-center position-relative">
                <div className="onRemove cursor-pointer d-flex" onClick={() => setUploadFile(null)}>
                  {svg?.onRemoveDocument}
                </div>
                <div className="h-100">
                  <div className="uploadedFileSuccess">{svg?.uploadedFileSuccess}</div>
                  <p className="txt-error mt-1 fw-bolder text-capitalize f24">{uploadFile?.name}</p>
                </div>
              </div>
            </>
          )}

          <small className="text-danger d-block text-start">{error && "Please upload the csv file"} </small>
        </div>

        {alreadyExist?.length !== 0 && <RenderTable data={alreadyExist} title={"Uploaded data with following records already exists"} />}
        {invalidData?.length !== 0 && <RenderTable data={invalidData} title={"Uploaded data with following records invalid data"} />}

        <div className="text-center mt-3">
          <button className="btn c-success text-uppercase fw-semibold fs-6 px-4 text-white h38" onClick={submitHandler} disabled={bulkBondsmanLoading}>
            {t("button.add")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
// Preview Invoice
export const ViewPreviewInvoice = ({
  modalOpen,
  cancelHandler,
  updateData,
  clientDetail,
  bondsmanDetail,
  totalAmount,
  invoiceId,
  taxEdit,
  editInvoiceServiceTax,
  invoiceNumber,
}) => {
  // Translation
  const { t } = useTranslation();

  // Selector
  const { user } = useSelector((state) => state.auth);

  const amount = updateData?.reduce((accumulator, item) => {
    return accumulator + parseFloat(item?.amount);
  }, 0);

  // Tax
  const tax = editInvoiceServiceTax?.percentage;

  let taxAmount = round(amount * (tax / 100), 2);
  // Total With Tax
  let totalAmountWithTax = round(amount + taxAmount, 2);

  const serviceTax = {
    service: "Service Tax",
    amount: taxAmount,
    remain_amount: 0,
  };

  const sendArray = [...updateData, serviceTax];

  return (
    <Modal
      open={modalOpen}
      onOk={cancelHandler}
      onCancel={cancelHandler}
      footer={[]}
      className={`antdModal previewInvoiceModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={1024}
    >
      <div className="download-header py-3 text-center c-white">
        <img src={Logo} alt="logo" className="h-100" />
      </div>

      <div className="row gy-2 mx-0 mt-2">
        <div className="col-12 col-md-6">
          <span className="fw-bolder">Invoice Number : </span> {invoiceId ? invoiceNumber : "NA"}
        </div>
        <div className="col-12 col-md-6">
          <span className="fw-bolder">Invoice Date : </span> {momentDDMMYY(new Date())}
        </div>
      </div>

      <div className="row gy-3 gy-md-2 mx-0 mt-2 mx-2 txt-dark ">
        <div className="col-12 col-md-6">
          <h4 className="fw-bolder mb-0 txt-primary f20">Client Details</h4>
          <p className="mt-2">
            <span className="fw-bolder">Client Name : </span> {FullName(clientDetail?.client_id)}
          </p>
          <p className="mt-2">
            <span className="fw-bolder">Email : </span> {clientDetail?.client_id?.email}
          </p>
          <p className="mt-2">
            <span className="fw-bolder">Contact Number : </span> {ContactNumber(clientDetail?.client_id)}
          </p>
        </div>
        <div className="col-12 col-md-6">
          <h4 className="fw-bolder mb-0 txt-primary f20">Bail Bondsman Details</h4>
          <p className="mt-2">
            <span className="fw-bolder">Bondsman Name : </span>
            {FullName(user)}
          </p>
          <p className="mt-2">
            <span className="fw-bolder">Email : </span> {user?.email}
          </p>
          <p className="mt-2">
            <span className="fw-bolder">Contact Number : </span>
            {ContactNumber(user)}
          </p>
        </div>
      </div>

      <div className="px-2 mt-3">
        <Table
          className="antdModalInovice"
          dataSource={sendArray}
          pagination={false}
          columns={[
            {
              title: t("table.serviceDetails"),
              dataIndex: "service",
              key: "service",
              className: "text-capitalize",
              width: "75%",
              render: (_, data) => {
                const title = data?.service === "Service Tax" ? `${data?.service} (${tax}%)` : data?.service;

                return <p>{title}</p>;
              },
            },
            {
              title: t("table.amount"),
              dataIndex: "amount",
              key: "amount",
              render: (_, data) => <p>{data?.amount || data?.amount === 0 ? "$" + data?.amount : "-"}</p>,
            },
          ]}
        />
        {updateData?.length !== 0 && (
          <div className="d-flex justify-content-between pe-0 align-items-center">
            <div className="col-6 ff_md f14 ps-3">Thank you for your business</div>
            <div className="col-6 text-end">
              <button className="c-success px-5 h46 text-light ff_bd f14 border-0">
                Total Amount : <span>${totalAmountWithTax}</span>
              </button>
            </div>
          </div>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="c-white px-3 py-4 mt-5">
        <div className="row gy-3">
          <div className="col-12">
            <h4 className="ff_bd f14 mb-2 txt-dark">Payment Terms</h4>
            <p className="ff_md f12 terms-light">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="col-12">
            <h4 className="ff_bd f14 mb-2 txt-dark txt-dark">Cancellation Policy</h4>
            <p className="ff_md f12 terms-light">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// Create Contract for E-Signature
export const ContractESignature = ({ modalOpen, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Form
  const formikRef = useRef();

  // Use State
  const [addressData, setAddressData] = useState({
    zip_code: "",
    city: "",
    state: "",
  });
  return (
    <Modal
      open={modalOpen}
      onOk={cancelHandler}
      onCancel={cancelHandler}
      footer={[]}
      className="antdModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <div>
        <h4 className="antdModalTitle my-3">{t("modal.createESign")}</h4>
        <div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              defendant: "",
              contactNumber: "",
              address1: "",
              address2: "",
              state: "",
              city: "",
              zip_code: "",
            }}
            validationSchema={contractESignValidation}
            onSubmit={(values) => {}}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form className="page-form">
                <div className="row gy-2">
                  <FormikInput
                    label={t("input.defendant")}
                    type={"text"}
                    value={"defendant"}
                    placeholder={t("input.pdefendant")}
                    error={touched.defendant && errors.defendant && errors.defendant}
                    className={"col-12 col-md-6"}
                  />

                  <FormikInput
                    label={t("input.contact")}
                    type={"text"}
                    value={"contactNumber"}
                    placeholder={t("input.pcontact")}
                    error={touched.contactNumber && errors.contactNumber && errors.contactNumber}
                    contactField={<span className="countryCode position-absolute">+1</span>}
                    inputClassName="pl-3_5"
                    className={"col-12 col-md-6"}
                  />

                  <FormikInput
                    label={t("input.address1")}
                    type={"text"}
                    value={"address1"}
                    placeholder={t("input.paddress")}
                    className={"col-12 col-md-6"}
                    error={touched.address1 && errors.address1 && errors.address1}
                  />

                  <FormikInput
                    label={t("input.address2")}
                    type={"text"}
                    value={"address2"}
                    placeholder={t("input.paddress")}
                    className={"col-12 col-md-6"}
                    error={touched.address2 && errors.address2 && errors.address2}
                  />

                  <FormikInput
                    label={t("input.state")}
                    type={"text"}
                    value={"state"}
                    placeholder={t("input.pstate")}
                    className={"col-12 col-md-6"}
                    fieldValue={addressData?.state}
                    isDisabled={true}
                    error={touched.state && errors.state && errors.state}
                  />

                  <FormikInput
                    label={t("input.city")}
                    type={"text"}
                    value={"city"}
                    placeholder={t("input.pcity")}
                    className={"col-12 col-md-6"}
                    fieldValue={addressData?.city}
                    isDisabled={true}
                    error={touched.city && errors.city && errors.city}
                  />

                  <div className={"col-12"}>
                    <label className="form-label fw-semibold fs-6 text-black">{t("input.postalcode")}</label>
                    <PostalCode defaultValue={values?.zip_code} setAddressData={setAddressData} setFieldValue={setFieldValue} />
                    <small className="text-danger">
                      &nbsp;
                      {touched.zip_code && errors.zip_code && errors.zip_code}
                    </small>
                  </div>

                  <div className="col-12 text-center">
                    <button className="btn btn-success btn-lg text-uppercase theme-btn px-4">{t("button.next")}</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

// Case / Court Information
export const ContractCourtInformation = ({ modalOpen, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Form
  const formikRef = useRef();

  return (
    <Modal
      open={modalOpen}
      onOk={cancelHandler}
      onCancel={cancelHandler}
      footer={[]}
      className="antdModal maxHeightModal"
      maskClosable={false}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle my-3">{t("modal.createESignCourt")}</h4>

      <div>
        <Formik
          innerRef={formikRef}
          initialValues={{
            court_name: "",
            case_number: "",
            judgeName: "",
            bailAmount: "",
            court_date_time: "",
            police_department: "",
            booking_number: "",
            request_nature: "",
            request_reason: "",
            additional_comments: "",
          }}
          validationSchema={eSignCourtValidation}
          onSubmit={(values) => {}}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form className="page-form">
              <div className="row gy-2">
                <SelectDropDown
                  divClass={"col-12"}
                  dataList={CourtNames}
                  dataId={"court_name"}
                  setFieldValue={setFieldValue}
                  labelText={t("input.pcourt_name")}
                  error={touched.court_name && errors.court_name && errors.court_name}
                  initialValue={values?.court_name}
                />

                <FormikInput
                  label={t("input.case_number")}
                  type={"text"}
                  value={"case_number"}
                  placeholder={t("input.pcase_number")}
                  error={touched.case_number && errors.case_number && errors.case_number}
                  className={"col-12 col-lg-6"}
                />

                <FormikInput
                  label={t("input.jname")}
                  type={"text"}
                  value={"judgeName"}
                  placeholder={t("input.pjname")}
                  error={touched.judgeName && errors.judgeName && errors.judgeName}
                  className={"col-12 col-lg-6"}
                />

                <FormikInput
                  label={t("input.bailAmount")}
                  type={"text"}
                  value={"bailAmount"}
                  placeholder={t("input.pbailAmount")}
                  error={touched.bailAmount && errors.bailAmount && errors.bailAmount}
                  contactField={<span className="countryCode position-absolute">$</span>}
                  inputClassName="pl-3_5"
                  className={"col-12 col-lg-6"}
                />

                <div className={"col-12 col-lg-6"}>
                  <label htmlFor={"date"} className="form-label fw-semibold fs-6 text-black">
                    {t("input.court_date_time")}
                  </label>
                  <DatePicker
                    onChange={(date, dateString) => {
                      setFieldValue("court_date_time", dateString);
                    }}
                    className={`form-control form-control-lg antdDatePicker px-11 ${values?.court_date_time && values?.court_date_time !== "Invalid Date" ? `selected` : ``}`}
                    placeholder={t("input.pdob")}
                    disabledDate={(currentDate) => {
                      return dayjs().isAfter(currentDate);
                    }}
                    format={"MM/DD/YYYY"}
                    suffixIcon={svg?.calender}
                    defaultValue={dayjs(values?.court_date_time)}
                  />
                  <small className="text-danger">
                    &nbsp;
                    {errors?.court_date_time && touched?.court_date_time && errors?.court_date_time}
                  </small>
                </div>

                <SelectDropDown
                  divClass={"col-12 col-lg-6"}
                  dataList={PoliceDepartmentList}
                  dataId={"police_department"}
                  setFieldValue={setFieldValue}
                  labelText={t("input.ppolice_department")}
                  error={touched.police_department && errors.police_department && errors.police_department}
                  initialValue={values?.police_department}
                />

                <FormikInput
                  label={t("input.booking_number")}
                  type={"text"}
                  value={"booking_number"}
                  placeholder={t("input.pbooking_number")}
                  error={touched.booking_number && errors.booking_number && errors.booking_number}
                  className={"col-12 col-lg-6"}
                />

                <FormikTextArea
                  label={t("input.request_reason")}
                  type={"text"}
                  value={"request_reason"}
                  placeholder={t("input.prequest_reason")}
                  className={"col-12"}
                  error={touched.request_reason && errors.request_reason && errors.request_reason}
                />

                <FormikTextArea
                  label={t("input.request_nature")}
                  type={"text"}
                  value={"request_nature"}
                  placeholder={t("input.prequest_nature")}
                  className={"col-12"}
                  error={touched.request_nature && errors.request_nature && errors.request_nature}
                />

                <FormikTextArea
                  label={t("input.additional_comments")}
                  type={"text"}
                  value={"additional_comments"}
                  placeholder={t("input.padditional_comments")}
                  className={"col-12"}
                  error={touched.additional_comments && errors.additional_comments && errors.additional_comments}
                />

                <div className="col-12 text-center">
                  <button className="btn btn-success btn-lg text-uppercase theme-btn px-4">{t("button.next")}</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export const EmailTemplatePreview = ({ modalOpen, addHandler, cancelHandler, updateData }) => {
  const { t } = useTranslation();

  const cancelSuperHandler = (e) => {
    e.preventDefault();
    cancelHandler();
  };

  const html = `<!DOCTYPE html>
    <html>
        <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap" rel="stylesheet" />
            <title>Bail Bond</title>
            <style>
                body {
                    font-family: "Open Sans", sans-serif;
                    line-height: 1.5;
                    margin: 0;
                    padding: 0;
                    background-color: #f4f4f4;
                }
    
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    padding: 10px;
                    background-color: #ffffff;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
    
                header {
                    text-align: center;
                    padding: 10px 0;
                }
    
                header img {
                    max-width: 100%;
                    height: auto;
                }
    
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    margin-top: 0;
                    font-family: "Open Sans", sans-serif;
                }
    
                p {
                    color: #555555;
                    margin-top: 0;
                    font-family: "Open Sans", sans-serif;
                }
    
                a {
                    text-decoration: none;
                    font-family: "Open Sans", sans-serif;
                }
    
                .order-details {
                    margin-top: 20px;
                }
    
                .btn {
                    display: inline-block;
                    padding: 10px 20px;
                    color: #ffffff;
                    font-weight: 600;
                    font-family: "Open Sans", sans-serif;
                    font-size: 16px;
                    text-decoration: none;
                    border-radius: 5px;
                    text-transform: uppercase;
                    width: 150px;
                    max-width: 100%;
                }
    
                .success-button {
                    background-color: #3aa61c;
                }
    
                .primary-button {
                    background-color: #0d9af2;
                }
    
                footer {
                    text-align: center;
                    color: #2a2a2a;
                }
    
                .email-body {
                    background-color: #ffffff;
                    padding: 30px 40px;
                }
    
                hr {
                    border: 1px solid #cecece;
                    margin: 16px 0;
                }
            </style>
        </head>
        <body>
            <table style="width: 600px; margin: 0 auto; max-width: 100%; font-family: 'Open Sans', sans-serif;" border="0" align="center" cellpadding="0" cellspacing="0">
                <tbody>
                    <tr>
                        <td align="center">
                            <table class="col-600" width="600" border="0" align="center" cellpadding="0" cellspacing="0" style="background-color: #ffffff;">
                                <tbody>
                                    <!--Header-->
                                    <tr>
                                        <td>
                                            <table style="margin: 0;" width="100%" border="0" align="center" cellpadding="20" cellspacing="0">
                                                <tr>
                                                    <td style="text-align: center; padding-bottom: 0;">
                                                        <a href="{{url('/')}}">
                                                            <img
                                                                src="https://bail-bond-dev.s3.ap-south-1.amazonaws.com/resources/mail/logo/BailBondsLogo_transparent4.png"
                                                                width="298"
                                                                height="60"
                                                                style="object-fit: contain;"
                                                                alt="Company Logo"
                                                            />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <!--Header-->

                                    ${updateData?.htmlTemplate}
                                    
                                    <!--Footer-->
										  <tr>
											  <td>
												  <table style="margin: 0;" width="100%" border="0" align="center" cellpadding="20" cellspacing="0">
													  <tr>
														  <td style="text-align: center; padding-top: 10px;">
															  <h6 style="font-size: 16px; font-weight: 600; margin-bottom: 5px; color: #0d9af2; font-family: 'Open Sans', sans-serif;">
																  Thanks
															  </h6>
															  <p style="margin-bottom: 0; font-size: 16px; font-weight: 400; color: #2a2a2a; font-family: 'Open Sans', sans-serif;">
																  BailBondsApp
															  </p>
														  </td>
													  </tr>
												  </table>
											  </td>
										  </tr>
										  <!--Footer-->
									  </tbody>
								  </table>
							  </td>
						  </tr>
					  </tbody>
				  </table>
			  </body>
		  </html>`;

  return (
    <Modal
      open={modalOpen}
      onOk={addHandler}
      onCancel={cancelSuperHandler}
      footer={[]}
      maskClosable={false}
      className={`adminModal antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle">{updateData?.title}</h4>
      <div className="border border-2 my-3">
        <iframe srcDoc={html} className="d-block" loading={"lazy"} style={{ height: 700, width: "100%" }} />
      </div>
    </Modal>
  );
};

export const StaticPagePreview = ({ modalOpen, addHandler, cancelHandler, updateData }) => {
  const { t } = useTranslation();

  const cancelSuperHandler = (e) => {
    e.preventDefault();
    cancelHandler();
  };

  const html = `${updateData?.html}`;

  return (
    <Modal
      open={modalOpen}
      onOk={addHandler}
      onCancel={cancelSuperHandler}
      footer={[]}
      maskClosable={false}
      className={`adminModal antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle">{updateData?.title}</h4>
      <div className="border border-2 my-3">
        <iframe srcDoc={html} className="d-block" loading={"lazy"} style={{ height: 700, width: "100%" }} />
      </div>
    </Modal>
  );
};

export const SubAdminModal = ({ modalOpen, cancelHandler, modalPurpose, updateData, params }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  const { subAdminLoading } = useSelector((state) => state.loading);

  const formikRef = useRef();

  return (
    <Modal
      open={modalOpen}
      onCancel={cancelHandler}
      footer={[]}
      maskClosable={false}
      className={`adminModal antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={768}
    >
      <h4 className="antdModalTitle my-3 mb-4">Sub Admin Details</h4>
      {modalOpen && (
        <Formik
          innerRef={formikRef}
          initialValues={{
            fname: updateData?.first_name || "",
            lname: updateData?.last_name || "",
            email: updateData?.email || "",
          }}
          validationSchema={subadminValidationSchema}
          onSubmit={(values) => {
            const formData = new FormData();
            if (modalPurpose === 1) {
              formData.append("id", updateData?._id);
            }
            formData.append("first_name", values?.fname);
            formData.append("last_name", values?.lname);
            formData.append("email", values?.email);

            if (modalPurpose === 1) {
              dispatch(EditSubAdmin(formData, cancelHandler, params));
            } else {
              dispatch(AddSubAdmin(formData, cancelHandler, params));
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className="page-form">
              <div className="row gy-1">
                <FormikInput
                  label={t("input.fname")}
                  type={"text"}
                  value={"fname"}
                  placeholder={t("input.pfname")}
                  error={touched.fname && errors.fname && errors.fname}
                  className={"col-sm-6"}
                />

                <FormikInput
                  label={t("input.lname")}
                  type={"text"}
                  value={"lname"}
                  placeholder={t("input.plname")}
                  error={touched.lname && errors.lname && errors.lname}
                  className={"col-sm-6"}
                />

                <FormikInput
                  label={t("input.email")}
                  type={"text"}
                  value={"email"}
                  placeholder={t("input.pemail")}
                  className={"col-12"}
                  error={touched.email && errors.email && errors.email}
                />

                <div className="text-center mt-3">
                  <button type="submit" className="btn btn-success btn-lg theme-btn px-4" disabled={subAdminLoading}>
                    {subAdminLoading && <BtnLoader />}
                    {t("button.save")}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export const ReviewModal = ({ modalOpen, cancelHandler, modalPurpose, updateData, param }) => {
  // Translation
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Form
  const formikRef = useRef();

  // const isReplay = updateData?.bondsman_feedback

  const [isReplay, setIsReplay] = useState(updateData?.bondsman_feedback && updateData?.bondsman_feedback !== "" ? true : false);

  const handleDelete = () => {
    const feedBackData = {
      review_id: updateData?._id,
      bondsman_feedback: "",
    };
    dispatch(UpdateBondmanReview({ ...feedBackData }, cancelHandler));
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={cancelHandler}
      footer={[]}
      maskClosable={false}
      className={`adminModal antdModal reviewModal txt-dark maxHeightModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={576}
    >
      <h4 className="antdModalTitle my-3">{"Review"}</h4>
      <div className="d-flex align-items-center">
        <Image
          toolbarRender={() => {}}
          preview={<img src={`${PROFILE_URL}/${updateData?.client_id?.photo}`} alt="Profile" />}
          src={
            updateData?.client_id?.photo_thumb
              ? `${PROFILE_URL}/${updateData?.client_id?.photo_thumb}`
              : updateData?.client_id?.photo
              ? `${PROFILE_URL}/${updateData?.client_id?.photo}`
              : Avatar
          }
          alt="Profile"
          className="img-fluid position-absolute start-0 top-0 w-100 h-100 rounded-circle object-fit-cover"
          rootClassName="client-picture rounded-circle position-relative w-100"
        />
        <div className="text-break w-100 d-flex align-items-center justify-content-between">
          <div>
            <h3 className="fs-5 fw-semibold text-black mb-1 lh-normal">{FullName(updateData?.client_id)}</h3>
            <p className="mb-0 fs-6 fw-medium">
              <span className="txt-primary">#{updateData?.bond_request_id?.request_number}</span>
              <span className="txt-grey ms-2">{momentDDMMYY(convertTimeZone(updateData?.created_at))}</span>
            </p>
          </div>
        </div>
      </div>
      <hr className="my-3" />
      <div className="text-center mb-3">
        <Rate value={updateData?.review_rate} allowHalf className="antdRate fs-1" style={{ color: "#FBC01B" }} disabled={true} />
      </div>
      <p className="mb-0 f16 fw-medium">{updateData?.message}</p>
      <hr className="my-3" />

      {isReplay ? (
        <div className="">
          <div className="mb-3">
            <div className="col-12">
              <p className="p-3 c-light chatReply f16">{updateData?.bondsman_feedback}</p>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center justify-content-center">
            <button type="button" className="btn c-grey text-white h46 fs-6 fw-medium px-3" onClick={() => setIsReplay(false)}>
              Edit
            </button>

            <button type="button" className="btn c-warning text-white h46 fs-6 fw-medium px-3" onClick={handleDelete}>
              Delete
            </button>
          </div>
        </div>
      ) : (
        <Formik
          innerRef={formikRef}
          initialValues={{
            reviews: updateData?.bondsman_feedback || "",
          }}
          validationSchema={reviewValidationSchema}
          onSubmit={(values) => {
            const feedBackData = {
              review_id: updateData?._id,
              bondsman_feedback: values?.reviews,
            };
            dispatch(UpdateBondmanReview({ ...feedBackData }, cancelHandler, false, param));
          }}
        >
          {({ values, errors, touched }) => (
            <Form className="page-form reviewForm">
              <div className="d-flex">
                <div className="w-100">
                  <Field
                    type={"text"}
                    className={`
                            form-control form-control-lg 
                            `}
                    id={"reviews"}
                    name={"reviews"}
                    placeholder={"Type your message..."}
                  />

                  <small className="text-danger d-block">
                    {touched.reviews && errors.reviews && errors.reviews}
                    &nbsp;
                  </small>
                </div>

                <button type="submit" className="btn btn-success submitBtn ms-3" disabled={!values?.reviews}>
                  {svg?.sendChat}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <br />
    </Modal>
  );
};

export const CompanyTaxDetailModal = ({ modalOpen, cancelHandler, companyDetails, setEditTax }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  const handler = () => {
    cancelHandler();
    setEditTax && setEditTax(true);
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={cancelHandler}
      footer={[]}
      maskClosable={false}
      className={`adminModal antdModal companyTax`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="antdModalTitle my-3">{t("header.companyTaxDetails")}</h4>
      <Formik
        initialValues={{
          companyName: companyDetails?.company_name || "",
          companyTaxNumber: companyDetails?.tax_number || "",
          tax: companyDetails?.tax === 0 ? companyDetails?.tax : companyDetails?.tax || 7.5,
        }}
        validationSchema={companyTaxFormSchema}
        onSubmit={(values) => {
          const updatedCompanyData = {
            tax_number: values?.companyTaxNumber,
            tax: values?.tax,
            company_name: values?.companyName,
          };
          dispatch(UpdateCompanyDetailsAPI(updatedCompanyData, handler));
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
              <FormikInput
                label={t("input.company_name")}
                type={"text"}
                value={"companyName"}
                placeholder={t("input.pcompany_name")}
                className={"col-12"}
                error={touched.companyName && errors.companyName && errors.companyName}
              />
              <FormikInput
                label={t("input.company_tax_number")}
                type={"text"}
                value={"companyTaxNumber"}
                placeholder={t("input.ptax_number")}
                className={"col-12 col-md-6"}
                error={touched.companyTaxNumber && errors.companyTaxNumber && errors.companyTaxNumber}
              />
              <FormikInput
                label={t("input.serviceTax")}
                type={"text"}
                value={"tax"}
                placeholder={t("input.pserviceTax")}
                className={"col-12 col-md-6"}
                error={touched.tax && errors.tax && errors.tax}
                contactField={<span className="countryCode position-absolute percantageTax">%</span>}
                inputClassName="pe-3_5"
              />
            </div>
            <FooterButtonAntdModal
              addTitle={t("button.save")}
              addSuperHandler={() => {}}
              type={"submit"}
              // loading={bondRequestLoading}
              addBtnClass={`text-uppercase mb-3`}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const SendRequestModal = ({ modalOpen, cancelHandler, selectedBondsman, selectedType, count }) => {
  // Translation
  const { t } = useTranslation();

  // Use Selector
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { bondRequestList, bondListCount } = useSelector((state) => state.bondRequest);
  const { sendRequestLoading } = useSelector((state) => state.loading);

  // Ref
  const myElementRef = useRef(null);

  // Navigate
  const navigate = useNavigate();

  // Navigate
  const dispatch = useDispatch();

  // State
  const [selectBail, setSelectBail] = useState();
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    const newElementHeight = myElementRef?.current?.offsetHeight;

    setElementHeight(myElementRef?.current?.clientHeight + 16);

    const handleResize = () => {
      // Access the new height after a resize event
      setElementHeight(newElementHeight + 16);
    };

    // Attach the event listener on component mount
    window.addEventListener("resize", handleResize);

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handler
  const sendRequestHandler = () => {
    if (!isLoggedIn) {
      navigate("/auth/sign-up/client");
    } else {
      if (selectedType === "all") {
        dispatch(
          SendBondRequestToBondsman(
            {
              bond_request_id: selectBail,
              all_select: true,
            },
            navigate,
            count
          )
        );
      } else {
        dispatch(
          SendBondRequestToBondsman(
            {
              bond_request_id: selectBail,
              selected_bondsman: selectedBondsman,
            },
            navigate,
            count
          )
        );
      }
    }
  };

  const createNewBailHandler = () => {
    if (!isLoggedIn) {
      navigate("/auth/sign-up/client");
    } else {
      setSelectBail([]);
      dispatch({
        type: "CHANGE_BR_STEP",
        payload: 1,
      });
      dispatch({
        type: "IS_STEPS_ACTIVE",
        payload: true,
      });
    }
  };

  // Case List
  const [itemLimit, setItemLimit] = useState(16);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(GetBondRequestList({ limit: itemLimit, status: "pending" }));
    }
  }, [isLoggedIn, itemLimit]);

  const newBondRequestList = bondRequestList ? Object?.values(bondRequestList) : [];

  return (
    <Modal
      open={modalOpen}
      onCancel={cancelHandler}
      maskClosable={false}
      className={`adminModal antdModal SendRequestModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      footer={[
        <div className="SendRequestModal-Footer text-center shadow" ref={myElementRef} style={{ background: "#E6F5FF" }}>
          <div className="c-white py-2 py-md-3">
            <button className="btn c-success px-3 px-sm-4 txt-white h46 mt-2 mt-lg-0 fw-medium text-uppercase" onClick={sendRequestHandler} disabled={!selectBail}>
              {sendRequestLoading && <BtnLoader />}
              Send Request
            </button>
          </div>
          <div className="py-2 py-md-3">
            <p className="f16 text-center txt-grey">Or You Can</p>
            <h4 className="antdModalTitle my-2">{"Select Existing Request"}</h4>
            <p className="f16 text-center col-10 m-auto">If you not having existing bail request then please create new.</p>
            <button className="btn c-error px-3 px-sm-4 txt-white h46 mt-3 fw-medium text-uppercase" onClick={createNewBailHandler}>
              Create New Bail Request
            </button>
          </div>
        </div>,
      ]}
    >
      <h4 className="antdModalTitle my-3">{"Select Existing Request"}</h4>
      <p className="f16 text-center col-10 m-auto">Choose existing request or create new request and send them to selected bondsman.</p>

      <div className="" style={{ paddingBottom: `${elementHeight}px` }}>
        {bondRequestList && <SelectBailCard data={newBondRequestList} setValue={setSelectBail} value={selectBail} name={"select-bail"} className={"min-fit-content"} />}
      </div>
    </Modal>
  );
};

export const ChangeZipCodeModal = ({ modalOpen, cancelHandler, setState, state, params, condition }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Selector
  const { locationDetail } = useSelector((state) => state.location);

  // State
  const [addressData, setAddressData] = useState({
    zip_code: state?.zip_code || "",
    city: state?.city || "",
    state: state?.state || "",
    lat: state?.lat || "",
    long: state?.long || "",
  });

  const closeHandler = () => {
    if (condition) {
      navigate("/");
    } else {
      cancelHandler();
    }
  };

  const defaultValueAddress = addressData?.zip_code - addressData?.city - addressData?.state;

  return (
    <Modal
      open={modalOpen}
      onCancel={closeHandler}
      maskClosable={false}
      className={`adminModal antdModal maxHeightModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      footer={[]}
      width={740}
    >
      <h4 className="antdModalTitle my-3">{"Change Zip Code For Bondsman List"}</h4>

      <Formik
        initialValues={{
          zip_code: state?.zip_code || "",
          city: state?.city || "",
          state: state?.state || "",
          lat: state?.lat || "",
          long: state?.long || "",
        }}
        validationSchema={ZipCodeChangeSchema}
        onSubmit={(values) => {
          const formData = new FormData();
          formData.append("zip", values?.zip_code);
          formData.append("state", values?.state);
          formData.append("city", values?.city);
          formData.append("lat", values?.lat);
          formData.append("long", values?.long);
          setState(addressData);
          cancelHandler();

          const location = {
            zip_code: values?.zip_code,
            city: values?.state,
            state: values?.city,
            lat: values?.lat,
            long: values?.long,
          };

          if (condition) {
            dispatch({
              type: "GET_LOCATION",
              payload: location,
            });
          }

          if (params) {
            dispatch(FetchNearbyBondsman(params?.page, params?.limit, params?.search, values?.lat, values?.long));
          }
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="page-form ">
            <div className="col-12">
              <label className="form-label fw-semibold fs-6 text-black">{t("input.postalcode")}</label>

              <NewPostalCode
                setAddressData={setAddressData}
                setFieldValue={setFieldValue}
                defaultValue={locationDetail ? `${values?.zip_code}-${values?.city}-${values?.state}` : ""}
              />
              <small className="text-danger">
                &nbsp;
                {touched.zip_code && errors.zip_code && errors.zip_code}
              </small>
            </div>

            <div className="text-center my-2">
              <button type="submit" className="btn btn-success btn-lg theme-btn px-4">
                SUBMIT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

// Admin Bondsman PlatForm Fees
export const AdminBondsmanPlatFormFees = ({ modalOpen, cancelHandler, updateData, setState }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  return (
    <Modal
      open={modalOpen}
      onCancel={cancelHandler}
      footer={[]}
      maskClosable={false}
      className={`adminModal antdModal maxHeightModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="antdModalTitle my-3">Platform Fees</h4>
      <Formik
        initialValues={{
          platform_fee: updateData?.platform_fee || 0,
        }}
        validationSchema={platformFeesSchema}
        onSubmit={(values) => {
          const updatedCompanyData = {
            bondsman_id: updateData?.id,
            platform_fee: values?.platform_fee,
          };
          dispatch(UpdatePlatFormFees(updatedCompanyData, cancelHandler, setState));
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
              <FormikInput
                label={t("input.platform_fee")}
                type={"text"}
                value={"platform_fee"}
                placeholder={t("input.pplatform_fee")}
                error={touched.platform_fee && errors.platform_fee && errors.platform_fee}
                contactField={<span className="countryCode position-absolute percantageTax">%</span>}
                inputClassName="pe-3_5"
                className={"col-12"}
              />
            </div>
            <FooterButtonAntdModal
              addTitle={t("button.submit")}
              addSuperHandler={() => {}}
              type={"submit"}
              // loading={bondRequestLoading}
              addBtnClass={`text-uppercase mb-3 mt-2`}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const ConnectAccount = ({ modalOpen, addHandler, cancelHandler }) => {
  // Translation
  const { t } = useTranslation();

  // Navigate
  const navigate = useNavigate();

  const cancelSuperHandler = (e) => {
    e.preventDefault();
    cancelHandler();
  };

  return (
    <Modal
      open={modalOpen}
      onOk={addHandler}
      onCancel={cancelSuperHandler}
      footer={[]}
      maskClosable={false}
      className={`adminModal antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <div className="text-center">
        <div className="mt-3 antdSVG">{svg?.coountAccount}</div>
        <h3 className="antdModalTitle mt-3 mw-100">Add Stripe / Bank account details</h3>
        <p className="txt-gray mt-2 ff_md">Please connect your Stripe / bank account first to receive your payment. Afterward, you can create an invoice.</p>
        <button className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative mt-3 h46" onClick={() => navigate(`/bond-man/payout`)}>
          Let’s connect
        </button>
      </div>
    </Modal>
  );
};

export const UploadContractModal = ({ modalOpen, modalPurpose, addHandler, cancelHandler, updateData, bailId, setState }) => {
  // Translation
  const { t } = useTranslation();

  // Use Dispatch
  const dispatch = useDispatch();

  const { createContractLoading, uploadContractLoading } = useSelector((state) => state.loading);

  const cancelSuperHandler = (e) => {
    e.preventDefault();
    cancelHandler();
  };

  const [reviewContract, setReviewContract] = useState(null);

  const [error, setError] = useState(false);

  const [companyIcon, setCompanyIcon] = useState(null);
  const [companyIcon2, setCompanyIcon2] = useState(null);
  const [fileSelect, setSelectedFile] = useState(null);
  const [bufferData, setBufferData] = useState(null);

  const [onUploadStart, setOnUploadStart] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  function onFileChange(info) {
    const { status, originFileObj } = info?.file;

    if (info) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedFile(e?.target?.result);
        setBufferData(e?.target?.result);
      };
      reader.readAsArrayBuffer(originFileObj);
    }

    setCompanyIcon2(originFileObj);

    if (status === "removed") {
      setCompanyIcon(null);
    }
  }

  const formData = new FormData();
  formData.append("bond_request_id", bailId);
  formData.append("file", companyIcon2);

  const reviewHandler = () => {
    if (companyIcon) {
      // dispatch({
      //     type: "PREVIEW_CONTRACT_BUFFER_FILE",
      //     payload: fileSelect,
      // });
      setReviewContract(true);
      setError(false);
    } else {
      setError(true);
    }
  };

  const createContractHandler = () => {
    dispatch(CreateContract(formData, cancelHandler, setState, bailId));
  };

  const props = {
    accept: "application/pdf",
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const customRequest = async ({ file }) => {
    try {
      setOnUploadStart("Start");

      dispatchLoading(dispatch, "uploadContract", true);
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(`https://httpbin.org/post`, formData, {
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        })
        .then((result) => {
          if (result?.status === 200) {
            setCompanyIcon(file);
            dispatchLoading(dispatch, "uploadContract", false);
            setOnUploadStart("Stop");
            setError(false);
          } else {
            dispatchLoading(dispatch, "uploadContract", false);
            setOnUploadStart("Stop");
            setError(false);
          }
        });
    } catch (err) {}
  };

  const widthRef = useRef(null);
  const contractHeight = useRef(null);

  // Resize
  const [windowSize, setWindowSize] = useState(widthRef.current ? widthRef.current.offsetWidth : 768);

  const [heightSize, setHeightSize] = useState();

  useEffect(() => {
    const handleWindowResize = () => {
      if (widthRef.current) {
        const width = widthRef.current.offsetWidth;
        setWindowSize(width);
      }

      if (contractHeight.current) {
        const height = contractHeight.current.offsetHeight;
        setHeightSize(height);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    if (reviewContract) handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [reviewContract, window]);

  const blob = new Blob([fileSelect], { type: "application/pdf" });
  const url = URL?.createObjectURL(blob);

  return (
    <Modal
      open={modalOpen}
      onOk={addHandler}
      onCancel={cancelSuperHandler}
      footer={[]}
      maskClosable={false}
      className={`adminModal ${reviewContract ? `reviewContractModal` : `antdModal`}`}
      closable={true}
      centered={true}
      closeIcon={!reviewContract ? <CloseIcon /> : <></>}
      bodyStyle={
        reviewContract
          ? {
              height: "calc(90vh - 4rem)",
              overflowY: "auto",
            }
          : {}
      }
      width={reviewContract ? "calc(95% - 4rem)" : 576}
    >
      <>
        {!reviewContract ? (
          <div className="text-center">
            <h3 className="antdModalTitle mt-3 mw-100">Upload Contract File</h3>
            <p className="txt-gray mt-2 ff_md mb-3">
              Please prepare your contract with all required details and upload it as a PDF. Once you have uploaded the PDF, we will notify the client to sign it.
            </p>

            <Dragger
              {...props}
              listType="picture"
              maxCount={1}
              className={`antdUpload antdDragger docUpload ${companyIcon && "d-none"}`}
              withCredentials
              onChange={onFileChange}
              customRequest={customRequest}
            >
              <>
                {onUploadStart === "Start" ? (
                  <Progress percent={uploadProgress} />
                ) : (
                  <div className="">
                    <div>{svg?.fileUpload}</div>
                    <p className="mt-2">
                      <span className="fw-medium">{t("pages.caseDetail.DragDrop")}</span> / <span className="txt-error">{t("pages.caseDetail.click")}</span>
                    </p>
                    <p className="mt-0 txt-dark-2">You can upload PDF file format</p>
                  </div>
                )}
              </>
            </Dragger>

            {companyIcon && (
              <>
                <div className="antdDragger uploadPreviewBorder p-3 text-center justify-content-center position-relative">
                  <div className="onRemove cursor-pointer d-flex" onClick={() => setCompanyIcon(null)}>
                    {svg?.onRemoveDocument}
                  </div>
                  <div className="h-100">
                    <div className="uploadedFileSuccess">{svg?.uploadedFileSuccess}</div>
                    <p className="txt-error mt-1 fw-bolder text-capitalize f24">{companyIcon?.name}</p>
                  </div>
                </div>
              </>
            )}

            <small className="text-danger d-block text-start">{error && "Please upload the contract"} </small>

            <button className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative mt-3 h46" onClick={reviewHandler} disabled={uploadContractLoading}>
              {uploadContractLoading && <BtnLoader />}Review Contract
            </button>
          </div>
        ) : (
          <>
            <div ref={widthRef} className="ff">
              <div className="contractHeader b-shadow" ref={contractHeight}>
                <div
                  className={`d-inline-flex align-items-center text-decoration-none txt-error mb-md-4 mb-3 cursor-pointer f16`}
                  onClick={() => {
                    setReviewContract(false);
                  }}
                >
                  {svg?.back}
                  {"Back"}
                </div>

                <div className="d-md-flex justify-content-between mb-3">
                  <div className="subpage-title">
                    <h2 className="fs-2 fw-bold text-black mb-0">Review Contract</h2>
                    <p className="fs-6 mb-0 text-black mt-2 mt-md-3">Review contract for accurate client details, terms, conditions, and legality.</p>
                  </div>
                  <div>
                    <button
                      className="btn c-error btn-lg text-uppercase theme-btn px-4 txt-white me-3  mt-2 mt-md-0 px-md-2 px-lg-3"
                      onClick={() => {
                        setReviewContract(false);
                      }}
                    >
                      Change Contract
                    </button>

                    <button
                      className="btn btn-success btn-lg text-uppercase theme-btn px-4 mt-2 mt-md-0 px-md-2 px-lg-3"
                      onClick={createContractHandler}
                      disabled={createContractLoading}
                    >
                      {createContractLoading && <BtnLoader />}
                      Send For E-Sign
                    </button>
                  </div>
                </div>
              </div>

              <div className="" style={{ paddingTop: `${heightSize}px` }}>
                <PDFViewer pdfUrl={url} className="w-100" width={windowSize} />
              </div>
            </div>
          </>
        )}
      </>
    </Modal>
  );
};

export const BondsmanBailAmountEditModal = ({ modalOpen, addHandler, cancelHandler, data, bailId }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const { bondDetails } = useSelector((state) => state.bondRequest);

  const { bondRequestLoading } = useSelector((state) => state.loading);

  // Use State
  const [isTermsSet, setIsTermsSet] = useState(data?.bail_terms);

  return (
    <Modal
      open={modalOpen}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="antdModalTitle my-3">Bail Information</h4>
      <div>
        <Formik
          initialValues={{
            bailAmount: "",
            bailCondition: data?.restrictions || "",
            bailTerms: data?.bail_terms ? "true" : "false",
          }}
          validationSchema={bailAmountEditBondsmanValidation}
          onSubmit={(values) => {
            const formData = new FormData();

            formData.append("bond_request_id", data?._id);
            formData.append("bail_amount", values?.bailAmount);
            formData.append("bail_terms", values?.bailTerms);
            formData.append("restrictions", values?.bailCondition);

            dispatch(EditBondsmanBailAmount(formData, cancelHandler));
          }}
        >
          {({ errors, touched, values, setFieldValue, handleChange }) => (
            <Form className="page-form">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="d-sm-flex align-items-center justify-content-between mb-3 bailTerms">
                    <label htmlFor={t("modal.bailTerms.terms")} className="form-label fw-semibold fs-6 text-black mb-0 me-2">
                      {t("modal.bailTerms.terms")}
                    </label>
                    <div role="group" aria-labelledby="my-radio-group" className="d-flex gap-4 align-items-center">
                      <label className="d-flex align-items-center gap-1 f16">
                        <Field
                          type="radio"
                          name="bailTerms"
                          value={"true"}
                          className="radioButtonCondition"
                          onChange={(e) => {
                            handleChange(e);
                            // Set the value of the other field when "One" is selected
                            if (e.target.value === "true") {
                              setIsTermsSet(true);
                            }
                          }}
                        />
                        <span>{`Yes`}</span>
                      </label>
                      <label className="d-flex align-items-center gap-1 f16">
                        <Field
                          type="radio"
                          name="bailTerms"
                          value={"false"}
                          className="radioButtonCondition"
                          onChange={(e) => {
                            handleChange(e);

                            if (e.target.value === "false") {
                              setIsTermsSet(false);
                            }
                          }}
                        />
                        <span>{`No`}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <FormikInput
                  label={t("input.bailAmount")}
                  type={"number"}
                  value={"bailAmount"}
                  placeholder={t("input.pbailAmount")}
                  error={touched.bailAmount && errors.bailAmount && errors.bailAmount}
                  contactField={<span className="countryCode position-absolute">$</span>}
                  inputClassName="pl-3_5"
                  className={"col-12"}
                />

                <FormikTextArea
                  disabled={values?.bailTerms === "false" ? true : false}
                  label={t("modal.bailTerms.conditions")}
                  type={"text"}
                  value={"bailCondition"}
                  placeholder={t("input.bailTermsPlaceholder")}
                  error={touched.bailCondition && errors.bailCondition && errors.bailCondition}
                />

                <div className="text-center">
                  <button className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative mt-3 h46 text-uppercase" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export const BailAmountZeroModal = ({ modalOpen, addHandler, cancelHandler, setState }) => {
  return (
    <Modal
      open={modalOpen}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="fs-5 fw-medium text-center my-3">Bid can't be placed as bail amount is 0, please update the bail amount.</h4>

      <div className="text-center">
        <button
          className="btn btn-success text-uppercase fw-semibold fs-6 px-4 position-relative mt-0 mb-2 h46 text-uppercase"
          onClick={() => {
            setState(true);
            cancelHandler();
          }}
        >
          Edit Bail Amount
        </button>
      </div>
    </Modal>
  );
};

export const NotificationTemplateModal = ({ modalOpen, addHandler, cancelHandler, updateData, params }) => {
  // Translation
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();

  const { notificationTemplateLoading } = useSelector((state) => state.loading);

  return (
    <Modal
      open={modalOpen}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
    >
      <h4 className="antdModalTitle my-3">{updateData?.title}</h4>

      <Formik
        initialValues={{
          title: updateData?.title || ``,
          description: updateData?.body || ``,
        }}
        validationSchema={notificationTemplateSchema}
        onSubmit={(values) => {
          const contentData = {
            content: [
              {
                code: updateData?.code,
                title: values?.title,
                body: values?.description,
                type: updateData?.type,
              },
            ],
          };

          dispatch(EditNotificationTemplates(contentData, cancelHandler, params));
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
              <FormikInput label={"Title"} type={"text"} value={"title"} placeholder={"Enter Title"} error={touched.title && errors.title && errors.title} className={"col-12"} />

              <FormikTextArea
                label={`Description`}
                type={"text"}
                value={"description"}
                placeholder={`Enter Description`}
                className={"col-12"}
                error={touched.description && errors.description && errors.description}
              />
            </div>

            <div className="text-center my-2">
              <button type="submit" className="btn btn-success btn-lg theme-btn px-4">
                {notificationTemplateLoading && <BtnLoader />} Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const NotificationPreviewModal = ({ modalOpen, addHandler, cancelHandler, updateData }) => {
  // Translation
  const { t } = useTranslation();

  let icon = null;
  switch (updateData?.type) {
    case 2:
    case 16:
      icon = type?.avatar;
      break;
    case 3:
    case 17:
    case 18:
      icon = type?.bondsman_approved;
      break;
    case 4:
      icon = type?.bondsman_rejected;
      break;
    case 5:
      icon = type?.docs_request;
      break;
    case 6:
      icon = type?.docs_request_uploaded;
      break;
    case 7:
      icon = type?.invoice_created;
      break;
    case 8:
      icon = type?.bids_request;
      break;
    case 9:
      icon = type?.bids_request_accepted;
      break;
    case 10:
      icon = type?.contract_create;
      break;
    case 11:
      icon = type?.e_sign_contract_success;
      break;
    case 12:
      icon = type?.invoice_payment_success_client;
      break;
    case 13:
      icon = type?.bond_request_submitted;
      break;
    case 14:
      icon = type?.bail_request_received;
      break;
    case 15:
      icon = type?.reminder_client;
      break;
    case 19:
      icon = type?.bond_processing;
      break;
    case 20:
      icon = type?.bond_submitted_court;
      break;
    case 21:
      icon = type?.release_expected;
      break;
    case 22:
      icon = type?.request_completed;
      break;
    case 23:
      icon = type?.bail_bondsman_match;
      break;
    default:
      icon = svg?.defaultNotification;
      break;
  }
  return (
    <Modal
      open={modalOpen}
      onOk={() => {
        addHandler();
      }}
      onCancel={() => {
        cancelHandler();
      }}
      footer={[]}
      maskClosable={false}
      className={`antdModal NotificationList`}
      closable={true}
      centered={true}
      closeIcon={<CloseIcon />}
      width={1024}
    >
      <h4 className="antdModalTitle my-3">{updateData?.title}</h4>

      <div className="Notification-items cursor-pointer-default">
        <div className="d-flex">
          <div className="notification-icon d-flex align-items-center justify-content-center">{icon}</div>
          <div className="ms-3 d-flex align-items-center justify-content-between w-100 notification-detail">
            <div className="">
              <p className="fw-semibold fs-18 text-black">{updateData?.title}</p>
              <p className="txt-grey fw-6">{updateData?.body}</p>
            </div>
            <div className="notification-date">{momentDDMMYYAMPM(convertTimeZone(updateData?.created_at))}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/**
 * Prop Types of the Modals
 */
const commonAddCancelPropTypes = {
  addHandler: PropTypes.string.isRequired,
  cancelHandler: PropTypes.string.isRequired,
};
RelationBondModal.propTypes = { ...commonAddCancelPropTypes };
RelationDetailModal.propTypes = { ...commonAddCancelPropTypes };
ArrestLocation.propTypes = { ...commonAddCancelPropTypes };
BailAmount.propTypes = { ...commonAddCancelPropTypes };
BailTerms.propTypes = { ...commonAddCancelPropTypes };
ContactInformationModal.propTypes = { ...commonAddCancelPropTypes };
EmergencySupportNetworkModal.propTypes = { ...commonAddCancelPropTypes };
CaseInformationEditModal.propTypes = { ...commonAddCancelPropTypes };
BailInformationEditModal.propTypes = { ...commonAddCancelPropTypes };
CompanyTaxDetailModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  companyDetails: PropTypes.object,
};
