import html2pdf from "html2pdf.js";
import ReactDOMServer from "react-dom/server";
import BailProcessCharge, {
    BailProcessChargeRecipt,
} from "./BailProcessCharge";
import PaymentReceipt, { TransactionHistroy } from "./PaymentReceipt";

export const PrintInvoiceHandler = ({ data }) => {
    html2pdf()
        .set({
            filename: `Invoice_${data?.invoice_number}.pdf`,
            pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        })
        .from(ReactDOMServer.renderToString(BailProcessCharge(data)))
        .save();
};

export const PrintReciptHandler = ({ data }) => {
    html2pdf()
        .set({
            filename: `Receipt_${data?.invoice_number}.pdf`,
            pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        })
        .from(ReactDOMServer.renderToString(BailProcessChargeRecipt(data)))
        .save();
};

export const PrintTransactionHistory = ({ data }) => {
    html2pdf()
        .set({
            filename: `${data?.invoice?.invoice_number}.pdf`,
            pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        })
        .from(ReactDOMServer.renderToString(TransactionHistroy(data)))
        .save();
};
