import axios from "../../helpers/axios";
import {
    API_URL,
    catchHandler,
    dispatchError,
    dispatchLoading,
    dispatchToast,
    elseHandler,
} from "../../helpers/utils";

const BONDSMAN_URL = `${API_URL}/admin/`;

export const FetchNewBondManList = (status) => async (dispatch) => {
    dispatchLoading(dispatch, "bondsman", true);
    axios
        .get(BONDSMAN_URL + "bondsman", {
            params: { status },
            withCredentials: true,
        })
        .then((result) => {
            if (result?.data?.success) {
                dispatch({
                    type: "GET_ADMIN_BONDSMAN_NEW_COUNT",
                    payload: result?.data?.data?.count,
                });
                dispatchError(dispatch, "bondsman", undefined);
            } else elseHandler(dispatch, "bondsman", result?.data);
            dispatchLoading(dispatch, "bondsman", false);
        })
        .catch(catchHandler(dispatch, "bondsman"));
};
