import navigation from "../Assets/navigation";
import svg from "../Assets/svg";

export const bondsManNavigationData = [
	{
		path: "dashboard",
		label: "Dashboard",
		svg: navigation?.dashboard,
	},
	{
		path: "all-bails",
		label: "All Bail Request",
		svg: navigation?.allBonds,
	},
	{
		path: "my-cases",
		label: "My Cases",
		svg: navigation?.myCases,
	},
	{
		path: "client-history-and-management",
		label: "Client History & Management",
		svg: navigation?.clientHistory,
	},
	{
		path: "calendar-and-appointments",
		label: "Calendar & Appointments",
		svg: navigation?.calender,
	},
	{
		path: "earnings-overview",
		label: "Earnings Overview",
		svg: navigation?.earning,
	},
	{
		path: "analytics-and-reports",
		label: "Analytics & Reports",
		svg: navigation?.analytics,
	},
	// {
	//     path: "referral-and-partnership-management",
	//     label: "Referral & Partnership Management",
	//     svg: navigation?.reference,
	// },
	// {
	//     path: "educational-resources-and-updates",
	//     label: "Educational Resources & Updates",
	//     svg: navigation?.education,
	// },
	{
		path: "reviews-and-feedback",
		label: "Reviews & Feedback",
		svg: navigation?.review,
	},
	// {
	//     path: "document-repository",
	//     label: "Document Repository",
	//     svg: navigation?.document,
	// },
];

export const AdminNavigationData = [
	{
		path: "dashboard",
		label: "Dashboard",
		svg: navigation?.dashboard,
	},
	{
		path: "bail-request",
		label: "Bail Request",
		svg: navigation?.allBonds,
	},
	{
		path: "bondsman-list",
		label: "Bondsman’s List",
		svg: navigation?.bondsmanList,
		length: "",
	},
	{
		path: "client-list",
		label: "Client’s List",
		svg: navigation?.clientList,
	},
	{
		path: "payment-history",
		label: "Payment History",
		svg: navigation?.earning,
	},
	{
		path: "email-template",
		label: "Email Templates",
		svg: navigation?.emailTemplate,
	},
	{
		path: "notification-templates",
		label: "Notification Templates",
		svg: (
			<svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M23.2892 19.9643C20.4949 17.1328 20.4391 14.4129 20.4391 14.385V10.684C20.4375 8.77323 19.6778 6.94114 18.3266 5.58999C16.9755 4.23884 15.1434 3.47906 13.2325 3.47746C12.2489 3.41978 11.2639 3.56315 10.3375 3.89882C9.41113 4.23449 8.56288 4.75543 7.84454 5.42983C7.12619 6.10423 6.55282 6.91795 6.15941 7.82131C5.766 8.72468 5.56082 9.69874 5.55638 10.684V14.385C5.55638 14.4129 5.50524 17.1328 2.71094 19.9643C2.47544 20.1972 2.31606 20.4961 2.25381 20.8214C2.19156 21.1468 2.22936 21.4834 2.36224 21.7868C2.48704 22.0969 2.70243 22.3621 2.98029 22.5479C3.25814 22.7337 3.58556 22.8314 3.91979 22.8283H22.0804C23.5226 22.885 24.3507 20.955 23.2892 19.9643ZM12.7676 2.54758C13.5726 2.51763 14.3779 2.59582 15.1621 2.78005C15.2039 2.0143 15.1295 1.16903 14.5297 0.618079C14.2253 0.320919 13.8403 0.119644 13.4225 0.0392049C13.0047 -0.0412344 12.5725 0.00269875 12.1795 0.165558C11.7865 0.328418 11.4499 0.603043 11.2114 0.955391C10.973 1.30774 10.8432 1.72232 10.8381 2.14773V2.78005C11.4697 2.62585 12.1175 2.54781 12.7676 2.54758ZM9.61996 23.7582C10.8065 26.7477 15.1946 26.7468 16.3802 23.7582H9.61996Z"
					fill="black"
				/>
			</svg>
		),
	},
	{
		path: "static-pages",
		label: "Static Pages",
		svg: navigation?.staticPage,
	},
	{
		path: "settings",
		label: "Settings",
		svg: navigation?.setting,
	},
	{
		path: "sub-admins",
		label: "Sub Admins",
		svg: navigation?.subAdmin,
	},
];
export const SubAdminNavigationData = [
	{
		path: "dashboard",
		label: "Dashboard",
		svg: navigation?.dashboard,
	},
	{
		path: "bail-request",
		label: "Bail Request",
		svg: navigation?.allBonds,
	},
	{
		path: "bondsman-list",
		label: "Bondsman’s List",
		svg: navigation?.bondsmanList,
		length: "",
	},
	{
		path: "client-list",
		label: "Client’s List",
		svg: navigation?.clientList,
	},
	{
		path: "payment-history",
		label: "Payment History",
		svg: navigation?.earning,
	},
	{
		path: "email-template",
		label: "Email Templates",
		svg: navigation?.emailTemplate,
	},
	{
		path: "notification-templates",
		label: "Notification Templates",
		svg: (
			<svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M23.2892 19.9643C20.4949 17.1328 20.4391 14.4129 20.4391 14.385V10.684C20.4375 8.77323 19.6778 6.94114 18.3266 5.58999C16.9755 4.23884 15.1434 3.47906 13.2325 3.47746C12.2489 3.41978 11.2639 3.56315 10.3375 3.89882C9.41113 4.23449 8.56288 4.75543 7.84454 5.42983C7.12619 6.10423 6.55282 6.91795 6.15941 7.82131C5.766 8.72468 5.56082 9.69874 5.55638 10.684V14.385C5.55638 14.4129 5.50524 17.1328 2.71094 19.9643C2.47544 20.1972 2.31606 20.4961 2.25381 20.8214C2.19156 21.1468 2.22936 21.4834 2.36224 21.7868C2.48704 22.0969 2.70243 22.3621 2.98029 22.5479C3.25814 22.7337 3.58556 22.8314 3.91979 22.8283H22.0804C23.5226 22.885 24.3507 20.955 23.2892 19.9643ZM12.7676 2.54758C13.5726 2.51763 14.3779 2.59582 15.1621 2.78005C15.2039 2.0143 15.1295 1.16903 14.5297 0.618079C14.2253 0.320919 13.8403 0.119644 13.4225 0.0392049C13.0047 -0.0412344 12.5725 0.00269875 12.1795 0.165558C11.7865 0.328418 11.4499 0.603043 11.2114 0.955391C10.973 1.30774 10.8432 1.72232 10.8381 2.14773V2.78005C11.4697 2.62585 12.1175 2.54781 12.7676 2.54758ZM9.61996 23.7582C10.8065 26.7477 15.1946 26.7468 16.3802 23.7582H9.61996Z"
					fill="black"
				/>
			</svg>
		),
	},
	{
		path: "static-pages",
		label: "Static Pages",
		svg: navigation?.staticPage,
	},
	{
		path: "settings",
		label: "Settings",
		svg: navigation?.setting,
	},
];
// Client Navigation Links
export const clientNavigationLinks = [
	{ id: "home", path: "/", title: "home", svg: svg?.home },
	{
		id: "profile",
		path: "/profile",
		title: "header.profile",
		svg: svg?.profile,
	},

	{
		id: "findABondsman",
		path: "/find-bondsman",
		title: "header.findABondsman",
		svg: svg?.findABondsman,
	},
	{
		id: "my-cases",
		path: "/my-case",
		title: "header.myCase",
		svg: svg?.myCase,
	},
	{
		id: "cpassword",
		title: "auth.cpassword",
		svg: svg?.password,
		path: "/change-password",
	},
	{
		id: "notification",
		title: "input.notification",
		svg: svg?.notificationSetting,
		path: "/notification-settings",
	},
	{
		id: "contactUs",
		path: "/contact-us",
		title: "footer.ContactUs",
		svg: svg?.contact,
	},
	{ id: "logout", title: "auth.logout", svg: svg?.logout },
];

// Bondmans Navigation Link
export const bondsmanNavigationLinks = [
	{ id: "home", path: "/", title: "home", svg: svg?.home },
	{
		id: "profile",
		path: "/bond-man/profile",
		title: "header.profile",
		svg: svg?.profile,
	},
	{
		id: "about",
		path: "/about",
		title: "header.about",
		svg: svg?.about,
	},
	{
		id: "payout",
		path: "/bond-man/payout",
		title: "header.payout",
		svg: svg?.payout,
	},
	{
		id: "companyTaxDetails",
		title: "header.companyTaxDetails",
		svg: svg?.headerTax,
	},
	{
		id: "contactUs",
		path: "/contact-us",
		title: "footer.ContactUs",
		svg: svg?.contact,
	},
	{
		id: "cpassword",
		path: "/change-password",
		title: "auth.cpassword",
		svg: svg?.password,
	},
	{
		id: "notification",
		title: "input.notification",
		svg: svg?.notificationSetting,
		path: "/bond-man/notification-settings",
	},
	{ id: "logout", title: "auth.logout", svg: svg?.logout },
];

// Admin Navigation Link
export const adminNavigationLinks = [
	{ id: "home", path: "/", title: "home", svg: svg?.home },
	{
		id: "profile",
		path: "/admin/profile",
		title: "header.profile",
		svg: svg?.profile,
	},
	{
		id: "about",
		path: "/about",
		title: "header.about",
		svg: svg?.about,
	},
	{
		id: "contactUs",
		path: "/contact-us",
		title: "footer.ContactUs",
		svg: svg?.contact,
	},
	{
		id: "cpassword",
		path: "/admin/change-password",
		title: "auth.cpassword",
		svg: svg?.password,
	},
	{ id: "logout", title: "auth.logout", svg: svg?.logout },
];
export const subAdminNavigationLinks = [
	{ id: "home", path: "/", title: "home", svg: svg?.home },
	{
		id: "profile",
		path: "/sub-admin/profile",
		title: "header.profile",
		svg: svg?.profile,
	},
	{
		id: "about",
		path: "/about",
		title: "header.about",
		svg: svg?.about,
	},
	{
		id: "contactUs",
		path: "/contact-us",
		title: "footer.ContactUs",
		svg: svg?.contact,
	},
	{
		id: "cpassword",
		path: "/sub-admin/change-password",
		title: "auth.cpassword",
		svg: svg?.password,
	},
	{ id: "logout", title: "auth.logout", svg: svg?.logout },
];

// Mobile Menu Without Login
export const menubarMobile = [
	{ id: "home", path: "/", title: "home", svg: svg?.home },
	{
		id: "about",
		path: "/about",
		title: "header.about",
		svg: svg?.about,
	},
	{
		id: "contactUs",
		path: "/contact-us",
		title: "footer.ContactUs",
		svg: svg?.contact,
	},
];

// Resource
export const ResourceEducationData = [
	{
		_id: 1,
		title: "Legal Websites and Publications",
		description:
			"Check legal websites, publications, and resources related to criminal justice and law. Websites such as FindLaw, Legal Information Institute (LII), and other legal databases may have information on bail bonds.",
	},
	{
		_id: 2,
		title: "Online Courses and Programs",
		description:
			"Some online platforms offer courses related to criminal justice and legal studies. Websites like Coursera, edX, and Khan Academy might have relevant courses or educational materials.",
		isWebinar: {
			created_at: new Date(),
			speaker: "Amanda Jane",
		},
	},
	{
		_id: 3,
		title: "Local Colleges and Universities",
		description: "Contact local colleges or universities that offer criminal justice or legal studies programs. They may have courses or resources related to bail bonds.",
	},
	{
		_id: 4,
		title: "Legal Aid Organizations",
		description:
			"Legal aid organizations often provide educational resources on legal topics. Check with local or national legal aid organizations for information on bail bonds.",
	},
	{
		_id: 5,
		title: "Professional Associations",
		description:
			"Explore professional associations related to the legal field, such as the American Bar Association (ABA) or state-specific bar associations. They may have resources or updates related to bail bonds.",
		isWebinar: {
			created_at: new Date(),
			speaker: "Amanda Jane",
		},
	},
	{
		_id: 6,
		title: "Government Websites",
		description: "Government websites, especially those related to the judiciary or legal system, may have educational resources and updates on bail bonds.",
	},
	{
		_id: 7,
		title: "News Outlets and Legal Blogs",
		description:
			"Stay informed about recent developments by following legal news outlets or blogs. They may cover legislative changes, court rulings, or other updates related to bail bonds.",
	},
];

export const FindBondsmanData = [
	{
		_id: "65b791bb2284b847be3f74a1",
		first_name: "Hardik",
		last_name: "Patel",
		email: "hardik3dpl+bondman5@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "California",
			city: "Carlsbad",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		tax_number: "TAX001",
		is_approve: true,
		status: "approved",
		photo: "profile_f7dd6b98-9e8c-421e-9a7a-53621335b030.png",
		photo_thumb: "profile_f7dd6b98-9e8c-421e-9a7a-53621335b030_thumb.png",
		role: ["bail_bondsman"],
		is_online: 0,
		last_login: "2024-01-29T11:54:46.748Z",
		created_at: "2024-01-29T11:10:40.991Z",
		updated_at: "2024-01-29T11:54:35.367Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "LOrem LOrem LOrem",
		bondsman_license: "BONDMAN005",
		experience: 3,
		working_hours: [
			{
				day: "Monday",
				hours: [
					{
						open_hour: "03:23 AM",
						close_hour: "07:23 AM",
						status: true,
						_id: "65b791df2284b847be3f74d5",
					},
				],
				_id: "65b791df2284b847be3f74d4",
			},
		],
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
		tax: 0,
	},
	{
		_id: "65b0a40983d98e1fd8ecabe5",
		first_name: "Hardik",
		last_name: "Three",
		email: "hardik3dpl+bondman3@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "California",
			city: "Carlsbad",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		is_approve: true,
		status: "approved",
		photo: "profile_d3e0bdc0-9070-4caa-b681-2056c54f1e80.jpeg",
		photo_thumb: "profile_d3e0bdc0-9070-4caa-b681-2056c54f1e80_thumb.jpeg",
		role: ["bail_bondsman"],
		is_online: 0,
		last_login: "2024-01-24T05:56:38.151Z",
		created_at: "2024-01-24T04:40:04.271Z",
		updated_at: "2024-01-24T05:46:45.770Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "LOrem Ipsum",
		bondsman_license: "BONDMAN1116",
		experience: 2,
		working_hours: [
			{
				day: "Monday",
				hours: [
					{
						open_hour: "12:16 AM",
						close_hour: "12:36 AM",
						status: true,
						_id: "65b0a43983d98e1fd8ecac2a",
					},
				],
				_id: "65b0a43983d98e1fd8ecac29",
			},
		],
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
	},
	{
		_id: "65afc1543540741f3f770d80",
		first_name: "Hardik",
		last_name: "Patel",
		email: "hardik3dpl+bondman2@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "California",
			city: "Carlsbad",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		is_approve: true,
		status: "approved",
		photo: null,
		photo_thumb: null,
		role: ["bail_bondsman"],
		is_online: 0,
		last_login: "2024-01-29T11:52:47.098Z",
		created_at: "2024-01-23T12:30:29.221Z",
		updated_at: "2024-01-23T13:39:06.249Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "Lorem LOrem",
		bondsman_license: "LIC0002",
		experience: 3,
		working_hours: [
			{
				day: "Monday",
				hours: [
					{
						open_hour: "03:08 AM",
						close_hour: "07:08 AM",
						status: true,
						_id: "65afc1743540741f3f770db4",
					},
				],
				_id: "65afc1743540741f3f770db3",
			},
		],
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
	},
	{
		_id: "65afc6113540741f3f7715bd",
		first_name: "Peter",
		last_name: "Parker",
		email: "hardik3dpl+bondman4@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "California",
			city: "Carlsbad",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		is_approve: true,
		status: "approved",
		photo: null,
		photo_thumb: null,
		role: ["bail_bondsman"],
		is_online: 0,
		last_login: "2024-01-24T05:41:00.412Z",
		created_at: "2024-01-23T12:30:29.221Z",
		updated_at: "2024-01-23T14:08:17.991Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "Test",
		bondsman_license: "BONDMAN0737",
		experience: 2,
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
	},
	{
		_id: "65ae1e8203323262aef57b6b",
		first_name: "Hardik Patel",
		last_name: "Bondsman",
		email: "hardik3dpl+bondman@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "Illinois",
			city: "Bethalto",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		is_approve: true,
		status: "approved",
		photo: "profile_5713f952-8c89-4493-97f7-e50fe3f1d086.jpeg",
		photo_thumb: "profile_5713f952-8c89-4493-97f7-e50fe3f1d086_thumb.jpeg",
		role: ["bail_bondsman"],
		last_login: "2024-01-29T13:39:28.668Z",
		created_at: "2024-01-22T05:14:46.991Z",
		updated_at: "2024-01-23T13:41:55.074Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
		bondsman_license: "LIC1",
		experience: 2,
		working_hours: [
			{
				day: "Monday",
				hours: [
					{
						open_hour: "12:22 AM",
						close_hour: "06:22 AM",
						status: true,
						_id: "65ae1eb503323262aef57b89",
					},
				],
				_id: "65ae1eb503323262aef57b88",
			},
		],
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
		is_online: 1,
		tax: 10,
		tax_number: "TAX001",
		company_name: "Hardik Patel",
	},
];

export const PreviouslyBondsmanData = [
	{
		_id: "65b791bb2284b847be3f74a0",
		first_name: "Hardik",
		last_name: "Patel",
		email: "hardik3dpl+bondman5@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "California",
			city: "Carlsbad",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		tax_number: "TAX001",
		is_approve: true,
		status: "approved",
		photo: "profile_f7dd6b98-9e8c-421e-9a7a-53621335b030.png",
		photo_thumb: "profile_f7dd6b98-9e8c-421e-9a7a-53621335b030_thumb.png",
		role: ["bail_bondsman"],
		is_online: 0,
		last_login: "2024-01-29T11:54:46.748Z",
		created_at: "2024-01-29T11:10:40.991Z",
		updated_at: "2024-01-29T11:54:35.367Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "LOrem LOrem LOrem",
		bondsman_license: "BONDMAN005",
		experience: 3,
		working_hours: [
			{
				day: "Monday",
				hours: [
					{
						open_hour: "03:23 AM",
						close_hour: "07:23 AM",
						status: true,
						_id: "65b791df2284b847be3f74d5",
					},
				],
				_id: "65b791df2284b847be3f74d4",
			},
		],
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
		tax: 0,
	},
	{
		_id: "65b0a40983d98e1fd8ecabe0",
		first_name: "Hardik",
		last_name: "Three",
		email: "hardik3dpl+bondman3@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "California",
			city: "Carlsbad",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		is_approve: true,
		status: "approved",
		photo: "profile_d3e0bdc0-9070-4caa-b681-2056c54f1e80.jpeg",
		photo_thumb: "profile_d3e0bdc0-9070-4caa-b681-2056c54f1e80_thumb.jpeg",
		role: ["bail_bondsman"],
		is_online: 0,
		last_login: "2024-01-24T05:56:38.151Z",
		created_at: "2024-01-24T04:40:04.271Z",
		updated_at: "2024-01-24T05:46:45.770Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "LOrem Ipsum",
		bondsman_license: "BONDMAN1116",
		experience: 2,
		working_hours: [
			{
				day: "Monday",
				hours: [
					{
						open_hour: "12:16 AM",
						close_hour: "12:36 AM",
						status: true,
						_id: "65b0a43983d98e1fd8ecac2a",
					},
				],
				_id: "65b0a43983d98e1fd8ecac29",
			},
		],
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
	},
	{
		_id: "65afc1543540741f3f770d81",
		first_name: "Hardik",
		last_name: "Patel",
		email: "hardik3dpl+bondman2@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "California",
			city: "Carlsbad",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		is_approve: true,
		status: "approved",
		photo: null,
		photo_thumb: null,
		role: ["bail_bondsman"],
		is_online: 0,
		last_login: "2024-01-29T11:52:47.098Z",
		created_at: "2024-01-23T12:30:29.221Z",
		updated_at: "2024-01-23T13:39:06.249Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "Lorem LOrem",
		bondsman_license: "LIC0002",
		experience: 3,
		working_hours: [
			{
				day: "Monday",
				hours: [
					{
						open_hour: "03:08 AM",
						close_hour: "07:08 AM",
						status: true,
						_id: "65afc1743540741f3f770db4",
					},
				],
				_id: "65afc1743540741f3f770db3",
			},
		],
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
	},
	{
		_id: "65afc6113540741f3f7715b3",
		first_name: "Peter",
		last_name: "Parker",
		email: "hardik3dpl+bondman4@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "California",
			city: "Carlsbad",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		is_approve: true,
		status: "approved",
		photo: null,
		photo_thumb: null,
		role: ["bail_bondsman"],
		is_online: 0,
		last_login: "2024-01-24T05:41:00.412Z",
		created_at: "2024-01-23T12:30:29.221Z",
		updated_at: "2024-01-23T14:08:17.991Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "Test",
		bondsman_license: "BONDMAN0737",
		experience: 2,
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
	},
	{
		_id: "65ae1e8203323262aef57b63",
		first_name: "Hardik Patel",
		last_name: "Bondsman",
		email: "hardik3dpl+bondman@gmail.com",
		phone_no: "8866688666",
		country_code: "1",
		address: {
			state: "Illinois",
			city: "Bethalto",
			zip: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		is_approve: true,
		status: "approved",
		photo: "profile_5713f952-8c89-4493-97f7-e50fe3f1d086.jpeg",
		photo_thumb: "profile_5713f952-8c89-4493-97f7-e50fe3f1d086_thumb.jpeg",
		role: ["bail_bondsman"],
		last_login: "2024-01-29T13:39:28.668Z",
		created_at: "2024-01-22T05:14:46.991Z",
		updated_at: "2024-01-23T13:41:55.074Z",
		deleted_at: null,
		is_active: 1,
		availability_status: "available",
		bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
		bondsman_license: "LIC1",
		experience: 2,
		working_hours: [
			{
				day: "Monday",
				hours: [
					{
						open_hour: "12:22 AM",
						close_hour: "06:22 AM",
						status: true,
						_id: "65ae1eb503323262aef57b89",
					},
				],
				_id: "65ae1eb503323262aef57b88",
			},
		],
		approve_by: "6596342f638fee7a8cc9547b",
		message: null,
		is_online: 1,
		tax: 10,
		tax_number: "TAX001",
		company_name: "Hardik Patel",
	},
];

export const SelectBailData = [
	{
		_id: "65b883693b258af68939f48b",
		request_number: "JWWF467556",
		address: {
			address_line_1: "Gota",
			address_line_2: "Jagatpur",
			state: "California",
			city: "Carlsbad",
			zip_code: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		bail_amount: 852,
		case_number: "CASE 30 10 34",
		preferred_bond_amount: 852,
		court_date_time: "2024-01-31T05:05:17.000Z",
		status: "submitted",
		is_new: 1,
		created_at: "2024-01-30T05:04:41.158Z",
		bond_request_status: {
			status: "pending",
		},
		client_id: {
			_id: "65ae05a303323262aef57703",
			first_name: "Hardik Patel",
			last_name: "Clients",
			photo: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f.png",
			photo_thumb: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f_thumb.png",
			address: {
				city: "Carlsbad",
			},
		},
	},
	{
		_id: "65b882bf3b258af68939f3fb",
		request_number: "VOYD928853",
		address: {
			address_line_1: "Gota",
			address_line_2: "Jagtpur",
			state: "California",
			city: "Carlsbad",
			zip_code: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		bail_amount: 963,
		case_number: "CASE 30 10 31",
		preferred_bond_amount: 963,
		court_date_time: "2024-01-31T05:02:28.000Z",
		status: "submitted",
		is_new: 1,
		created_at: "2024-01-30T05:01:51.770Z",
		bond_request_status: {
			status: "pending",
		},
		client_id: {
			_id: "65ae05a303323262aef57703",
			first_name: "Hardik Patel",
			last_name: "Clients",
			photo: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f.png",
			photo_thumb: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f_thumb.png",
			address: {
				city: "Carlsbad",
			},
		},
	},
	{
		_id: "65b8826f3b258af68939f3b3",
		request_number: "ALVI089110",
		address: {
			address_line_1: "Gota",
			address_line_2: "Jagatpur",
			state: "California",
			city: "Carlsbad",
			zip_code: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		bail_amount: 852,
		case_number: "CASE 30 10 30",
		preferred_bond_amount: 852,
		court_date_time: "2024-01-31T05:01:05.000Z",
		status: "submitted",
		is_new: 1,
		created_at: "2024-01-30T05:00:31.285Z",
		bond_request_status: {
			status: "pending",
		},
		client_id: {
			_id: "65ae05a303323262aef57703",
			first_name: "Hardik Patel",
			last_name: "Clients",
			photo: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f.png",
			photo_thumb: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f_thumb.png",
			address: {
				city: "Carlsbad",
			},
		},
	},
	{
		_id: "65b881dc3d200c8b9479c103",
		request_number: "BBIO239758",
		address: {
			address_line_1: "Gota",
			address_line_2: "Jagatpur",
			state: "California",
			city: "Carlsbad",
			zip_code: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		bail_amount: 741,
		case_number: "CASE 30 10 27",
		preferred_bond_amount: 741,
		court_date_time: "2024-01-31T04:59:56.000Z",
		status: "submitted",
		is_new: 1,
		created_at: "2024-01-30T04:58:04.304Z",
		bond_request_status: {
			status: "pending",
		},
		client_id: {
			_id: "65ae05a303323262aef57703",
			first_name: "Hardik Patel",
			last_name: "Clients",
			photo: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f.png",
			photo_thumb: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f_thumb.png",
			address: {
				city: "Carlsbad",
			},
		},
	},
	{
		_id: "65b883013b258af68939f443",
		request_number: "YELM996235",
		address: {
			address_line_1: "CASE 30 10 32",
			address_line_2: "Jagatpur",
			state: "California",
			city: "Carlsbad",
			zip_code: "92010",
			lat: 33.1639,
			long: -117.3009,
		},
		bail_amount: 985,
		case_number: "CASE 30 10 32",
		preferred_bond_amount: 985,
		court_date_time: "2024-01-31T05:03:50.000Z",
		status: "submitted",
		is_new: 0,
		created_at: "2024-01-30T05:02:57.385Z",
		bond_request_status: {
			status: "pending",
		},
		client_id: {
			_id: "65ae05a303323262aef57703",
			first_name: "Hardik Patel",
			last_name: "Clients",
			photo: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f.png",
			photo_thumb: "profile_d64f1efc-e167-4955-b53e-8bb5c121cf0f_thumb.png",
			address: {
				city: "Carlsbad",
			},
		},
	},
];
