import axios from "axios";
import { API_URL, dispatchLoading } from "../helpers/utils";

// http://localhost:5000/api/v1/postalCode/coordinates?postalcode=
const POSTAL_URL = API_URL + "/postalCode";

/**
 * API to search Zip code in US
 * @param {Number} value Zip code value
 * @returns
 */
export const SearchPostalCode = (value, setPostalCodes) => async (dispatch) => {
  dispatchLoading(dispatch, "postalCode", true);
  axios
    .get(`${POSTAL_URL}/coordinates?postalcode=${value}`)
    // .then((response) => response?.json())
    .then((result) => {
      if (result?.status === 200) {
        if (result?.data?.postalcodes) {
          setPostalCodes(result?.data?.postalcodes);
        }
      }
      // console.log("Zip Code result: ", result?.data?.postalcodes);
    })
    .catch((error) => {
      // console.log("Error from Zip code", error);
    });
  dispatchLoading(dispatch, "postalCode", false);
};
